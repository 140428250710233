import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import Loader from 'components/Loader';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { sheetReader } from 'utils/fuctions/sheetReader.js';
import { generateSheetHeaders } from 'utils/fuctions/generateSheetHeaders.js';

const initialState = {
  file: '',
  data: [],
  hasHeader: true,
  columns: []
};

const defaultRequiredFields = [
  {
    id: 'name',
    name: 'name',
    label: 'Nome',
    isRequired: true
  },
  {
    id: 'phone',
    name: 'phone',
    label: 'Telefone',
    isRequired: true
  }
];
const defaultOptionalFields = [
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    isRequired: false
  }
];

const ImportContactsToBroadcastListModalHandler = ({
  isOpen,
  setIsOpen,
  recordTypeId,
  importToBroadcastListService,
  ...props
}) => {
  const [state, setState] = useState(initialState);
  const [fields, setFields] = useState(defaultRequiredFields);
  const [optionalFields, setOptionalFields] = useState([]);
  const [option, setOption] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [dataToImport, setDataToImport] = useState();
  const [messageFieldsInvalid, setMessageFieldsInvalid] = useState(
    '<h3>Há registros que não serão importados por conterem dados inválidos. Deseja importar apenas os dados válidos ?</h3>'
  );

  const { handleSubmit, control, setValue } = useForm();
  const systemConfiguration = useSelector((stt) => stt.systemConfiguration);

  const { getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.CONTACT,
    recordTypeId
  );

  useEffect(() => {
    if (isOpen) {
      const getCtFields = async () => {
        const custFields = await getCustomFields();
        const fieldsDTO = custFields.map((field) => ({
          id: field.id,
          name: field.name,
          label: field.label,
          isRequired: field.recordTypesConfig.isRequired
        }));
        setOptionalFields([...defaultOptionalFields, ...fieldsDTO]);
      };
      getCtFields();
    }
  }, [getCustomFields, isOpen]);

  useEffect(() => {
    const [headers] = state.data;
    if (!headers) return;
    const columns = headers.map((e) => ({ col: e }));
    setState((s) => ({ ...s, columns }));
  }, [state.data]);

  const changeFile = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const result = await sheetReader(file);

    const data = result
      .split(/\r?\n|\r/)
      .map((item) => item.split(/,|\t/))
      .filter((item) => {
        return item.length >= 2 && item[0] !== '' && item[1] !== '';
      });

    const maxLimit = state.hasHeader ? 50001 : 50000;
    if (data.length > maxLimit) {
      toast.error(`A importação deve ter no máximo 50.000 registros`);
      return;
    }

    setState((s) => ({ ...s, data, file }));
  };

  const changeHasHeader = (e) => {
    const hasHeader = e.target.checked;
    if (!hasHeader) {
      const headers = generateSheetHeaders(state.columns.length);
      setState((s) => ({ ...s, hasHeader, data: [headers, ...s.data] }));
    } else {
      setState((s) => {
        s.data.shift();
        return { ...s, hasHeader, data: [...s.data] };
      });
    }
  };

  const filterMatrixByColumns = (columns, matrix) => {
    const filteredMatrix = [];
    for (let i = 0; i < matrix.length; i++) {
      const row = [];
      for (let j = 0; j < columns.length; j++) {
        row.push(matrix[i][columns[j]]);
      }
      filteredMatrix.push(row);
    }
    return filteredMatrix;
  };

  const onAddField = () => {
    if (optionalFields.length === 0 || option === '') return;

    const [field] = optionalFields.filter((f) => f.name === option);

    setOptionalFields(optionalFields.filter((f) => f.name !== option));
    setFields([...fields, field]);
    setOption('');
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      for (const key in data) {
        if (!data[key]) {
          toast.info(`Preencha todos os campos selecionados`);
          return;
        }
      }
      for (const f of optionalFields) {
        if (
          f.isRequired &&
          (!Object.keys(data).includes(f.name) || !data[f.name])
        ) {
          toast.info(`Preencha todos os campos marcados com "*"`);
          return;
        }
      }

      const columns = Object.values(data).map((h) => state.data[0].indexOf(h));
      const filteredData = filterMatrixByColumns(columns, state.data);
      const dataEntries = Object.entries(data);
      const headers = filteredData.shift().map((h) => {
        for (let i = 0; i < dataEntries.length; i++) {
          const [key, value] = dataEntries[i];

          if (h === value) {
            dataEntries.splice(i, 1);

            return key;
          }
        }
      });

      let index = 0;
      const invalidIndex = [];
      const handleDataDTO = [];

      const invalidFields = [];

      const phones = [];
      for (const fieldData of filteredData) {
        const item = {};
        for (const header of headers) {
          item[header] = fieldData[headers.indexOf(header)];
          if (item[header].length === 0 || item[header].length > 100) {
            invalidIndex.push(index);
            invalidFields.push({
              name: fieldData,
              index: index,
              message: 'Campo com mais de 100 caracteres ou vazio'
            });
            handleDataDTO.push(item);
            index = index + 1;
            continue;
          }

          if (header === 'phone') {
            if (!item[header]) {
              invalidFields.push({
                name: item[header],
                index: index,
                message: 'O telefone do cliente está vazio'
              });
              invalidIndex.push(index);
              handleDataDTO.push(item);
              index = index + 1;
              continue;
            }

            if (item[header].length < 8) {
              invalidFields.push({
                name: item[header],
                index: index,
                message: 'O telefone do cliente está incompleto'
              });
              invalidIndex.push(index);
              handleDataDTO.push(item);
              index = index + 1;
              continue;
            }

            if (phones.includes(item[header])) {
              invalidFields.push({
                name: item[header],
                index: index,
                message: 'O telefone do cliente está duplicado'
              });
              invalidIndex.push(index);
              handleDataDTO.push(item);
              index = index + 1;
              continue;
            }
            phones.push(item[header]);
            handleDataDTO.push(item);
            index = index + 1;
          }
        }
      }

      let msgFields = '';
      let showModalError = false;

      for (const invalidField of invalidIndex) {
        showModalError = true;
        delete handleDataDTO[invalidField];
      }

      invalidFields.map((invalidField) => {
        msgFields = `${msgFields} <hr style=" margin: 0"/>  <p style="margin: 0"> Linha ${
          invalidField.index + 1
        }: ${invalidField.message}</p>`;
      });

      msgFields = `<h3> As seguintes informações apresentam inconsistências e não serão importadas: </h3>  ${msgFields}`;

      setMessageFieldsInvalid(msgFields);
      setOpenModalConfirmation(showModalError);

      const dataDTO = handleDataDTO.filter((el) => el);
      setDataToImport(dataDTO);
      if (!invalidIndex.length >= 1) {
        try {
          setIsSubmitting(true);
          const response = await importToBroadcastListService({
            parsedData: dataDTO,
            recordTypeId: Number(recordTypeId)
          });

          const importedContacts = response?.data?.data;
          const { existingContacts, createdContacts } = importedContacts;

          const existingTotal = existingContacts.length;
          const createdTotal = createdContacts.length;
          const total = existingTotal + createdTotal;

          const msg = `${total} contato(s) importado(s). ${existingTotal} contato(s) já existente(s) e ${createdTotal} contato(s) criado(s)`;
          toast.success(msg);

          setState(initialState);

          if (props.callback) {
            return props.callback();
          }
        } catch (error) {
          console.log(error);
          const msg =
            error?.response?.data?.message || 'Erro ao importar contatos';
          toast.error(msg);
        } finally {
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      console.log(error);
      const msg = error?.response?.data?.message || 'Erro ao importar contatos';
      toast.error(msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitData = async (dataDTO) => {
    setIsSubmitting(true);
    setOpenModalConfirmation(false);
    try {
      const response = await importToBroadcastListService({
        parsedData: dataDTO,
        recordTypeId: Number(recordTypeId)
      });

      const importedContacts = response?.data?.data;
      const { existingContacts, createdContacts } = importedContacts;

      const existingTotal = existingContacts.length;
      const createdTotal = createdContacts.length;
      const total = existingTotal + createdTotal;

      const msg = `${total} contato(s) importado(s). ${existingTotal} contato(s) já existente(s) e ${createdTotal} contato(s) criado(s)`;
      toast.success(msg);

      setState(initialState);

      if (props.callback) {
        return props.callback();
      }
    } catch (error) {
      console.log(error);
      const msg = error?.response?.data?.message || 'Erro ao importar contatos';
      toast.error(msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {!state.file ? (
        <ModalBody>
          <Form>
            <FormGroup>
              <label className="form-control-label d-block mb-3">
                Insira um arquivo do tipo CSV, XLS ou XLSX ---
              </label>
              <label
                htmlFor="import_contacts_list"
                className="btn btn-primary btn-sm"
                style={buttonStyle(systemConfiguration.primary_color)}
              >
                Selecionar um arquivo
              </label>
              <Input
                style={{ display: 'none' }}
                type="file"
                id="import_contacts_list"
                name="import_contacts_list"
                accept=".csv, .xls, .xlsx"
                value={state.file}
                onChange={changeFile}
              />
            </FormGroup>
          </Form>
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <div style={{ marginBottom: '24px' }}>
              <div style={{ marginBottom: '8px', fontSize: '0.875rem' }}>
                Arquivo
              </div>
              <div
                style={{
                  color: '#525f7f',
                  fontSize: '1rem',
                  fontWeight: '600'
                }}
              >
                {state.file.name}
              </div>
            </div>
            {isSubmitting ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Loader />
                <h3>Importando contatos, por favor aguarde.</h3>
              </div>
            ) : (
              <Form id="import-contacts-form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup check style={{ marginBottom: '24px' }}>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="hasHeader"
                      checked={state.hasHeader}
                      onChange={changeHasHeader}
                    />
                    Utilizar primeira linha como cabeçalho
                  </Label>
                </FormGroup>
                {fields.map((field) => (
                  <Controller
                    key={field.id}
                    control={control}
                    name={field.name}
                    defaultValue=""
                    render={(props) => (
                      <FormGroup>
                        <Label className="form-control-label text-capitalize">
                          {`${field.label}${field.isRequired ? '*' : ''}`}
                        </Label>
                        <FlexSelect
                          isClearable={true}
                          dataOptions={state.columns}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          value={props.value}
                          valueController={setValue}
                          fieldName={field.name}
                          labelName="col"
                          valueName="col"
                        />
                      </FormGroup>
                    )}
                  />
                ))}
                <div>
                  <div style={{ marginBottom: '8px', fontSize: '0.875rem' }}>
                    Adicionar coluna
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: '8px', width: '100%' }}>
                      <FlexSelect
                        isClearable={true}
                        isDisabled={optionalFields.length === 0 ? true : false}
                        dataOptions={optionalFields.map(
                          ({ label, name, isRequired }) => ({
                            label: `${label}${isRequired ? '*' : ''}`,
                            value: name
                          })
                        )}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={option}
                        valueController={(_, value) => {
                          setOption(value);
                        }}
                        fieldName="option"
                        labelName="label"
                        valueName="value"
                      />
                    </div>
                    <Button
                      type="button"
                      color="default"
                      disabled={option === '' ? true : false}
                      onClick={onAddField}
                    >
                      <li className="fas fa-plus"></li>
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </ModalBody>

          <ModalConfirmation
            isModalOpen={openModalConfirmation}
            onModalToggle={setOpenModalConfirmation}
            unmountOnClose={true}
            idResource={dataToImport}
            deleteResource={handleSubmitData}
            message={messageFieldsInvalid}
            modalContainerClasses="modal-lg"
            messageContainsHtml={true}
          />
          <ModalFooter>
            <ReactButton
              btnColor="confirmation"
              type="submit"
              form="import-contacts-form"
              disabled={!state.file || isSubmitting}
            >
              Importar
            </ReactButton>
            <ReactButton
              btnColor="cancelation"
              disabled={isSubmitting}
              onClick={() => {
                setState(initialState);
                setOption('');
                setIsOpen(!isOpen);
              }}
            >
              Cancelar
            </ReactButton>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default ImportContactsToBroadcastListModalHandler;
