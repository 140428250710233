import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FiMeh } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import AlternativeHeader from 'components/Headers/AlternativeHeader.js';

import AgentIndicators from './components/AgentIndicators';
import CollectiveIndicators from './components/CollectiveIndicators';
import AgentConversationInfo from './components/AgentIndicators/components/AgentConversationInfo';

import { AgentCtxProvider } from './contexts/AgentCtx';
import { getDashboardChatIndividually } from './services/getBFFAgentDashboard';

const Dashboard = () => {
  const telephonyServicePanelAgentCode = useSelector(
    (state) => state.telephonyServicePanel.agentCode
  );
  const telephonyServicePanelExtension = useSelector(
    (state) => state.telephonyServicePanel.extension
  );
  const systemConfigurationPrimaryColor = useSelector(
    (state) => state.systemConfiguration.primary_color
  );

  const [dashboardChatIndividually, setDashboardChatIndividually] = useState({
    averageFirstResponseTime: '00:00:00',
    averageServiceTime: '00:00:00',
    averageWaitingTime: '00:00:00',
    totalServiceTime: '00:00:00'
  });

  useEffect(() => {
    const login = localStorage.getItem('login');
    if (login) {
      getDashboardChatIndividually({ agent: 'adm' }).then((data) => {
        setDashboardChatIndividually(data.data);
      });
    }
  }, []);

  return (
    <>
      <AlternativeHeader />

      {!telephonyServicePanelAgentCode ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <p
            className="mb-1 text-muted"
            style={{
              padding: '0.5rem',
              fontWeight: 'bold',
              fontSize: '1.2rem'
            }}
          >
            Ops... Você não está logado em nenhuma fila no momento
          </p>
          <FiMeh size={25} color={systemConfigurationPrimaryColor} />
        </div>
      ) : (
        <AgentCtxProvider agent={telephonyServicePanelAgentCode}>
          <Container className="mt--6" fluid>
            <Row>
              <Col xl="6">
                <AgentIndicators
                  agent={telephonyServicePanelAgentCode}
                  extension={telephonyServicePanelExtension}
                  dashboardChatIndividually={dashboardChatIndividually}
                />
              </Col>
              <Col xl="6">
                <CollectiveIndicators />
              </Col>
              <Col xl="12">
                <AgentConversationInfo />
              </Col>
            </Row>
          </Container>
        </AgentCtxProvider>
      )}
    </>
  );
};

export default Dashboard;
