import React from 'react';

import { Container } from './styled';

import ExtensionCardItem from '../ExtensionCarditem';

const ExtensionCardContainer = ({ extensions, updateSelectedExension }) => {
  return (
    <Container>
      {extensions.map((extension) => (
        <ExtensionCardItem
          key={extension.extension}
          data={extension}
          updateSelectedExension={updateSelectedExension}
        />
      ))}
    </Container>
  );
};

export default ExtensionCardContainer;
