import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FiPlus, FiDelete } from 'react-icons/fi';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import PJSIPConfigurationService from '../service';
import Modal from '../../../components/Modal/DefaultModal';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  Button,
  CardBody
} from 'reactstrap';
import { ScreenPrompt } from 'components/ScreenPrompt';

const Status = (props) => {
  const history = useHistory();

  const {
    reset,
    control,
    handleSubmit,
    errors,
    register,
    unregister,
    formState
  } = useForm();
  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [localNetworks, setLocalNetworks] = useState([]);

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      PJSIPConfigurationService.get(id)
        .then((res) => {
          let nets = [];
          for (let i = 1; i <= 10; i++) {
            if (res.data.data[`local_net_${i}`]) nets.push(`local_net_${i}`);
          }

          setLocalNetworks(nets);
          reset(res.data.data);
          setIsUpdate(true);
        })
        .catch((err) => {
          const { message } = err.response.data;
          toast.error(message, {
            autoClose: 3000,
            closeOnClick: true
          });
          history.replace('/admin/pjsip_configuration');
        });
    }
  }, [id, reset, history]);

  const handleSaveClick = () => {
    return setIsOpenModal(true);
  };

  const onSubmit = async (data) => {
    try {
      const formatedData = { ...data };

      // Preenche com null as redes locais vazias
      for (let i = 1; i <= 10; i++) {
        if (!formatedData[`local_net_${i}`]) {
          formatedData[`local_net_${i}`] = null;
        }
      }

      // Coloca null nos atributos não obrigatorios que estão vazios
      Object.keys(formatedData).forEach((att) => {
        if (!formatedData[att] || String(formatedData[att]).length === 0) {
          formatedData[att] = null;
        }
      });

      // if (isUpdate) formatedData['reloadNow'] = args.reloadNow;

      isUpdate
        ? await PJSIPConfigurationService.update(id, formatedData)
        : await PJSIPConfigurationService.create(formatedData);
      toast.success('Configuração PJSIP salva!', {
        autoClose: 3000,
        closeOnClick: true
      });
    } catch (err) {
      const { message } = err.response.data;
      toast.error(message, {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      history.push('/admin/pjsip_configuration/');
    }
  };

  const addLocalNetwork = () => {
    const length = localNetworks.length;
    if (length >= 10) return;
    const value = `local_net_${localNetworks.length + 1}`;
    setLocalNetworks([...localNetworks, value]);
  };

  const removeNetwork = () => {
    const currentValues = [...localNetworks];
    const deleted = currentValues.pop();
    unregister(deleted);
    setLocalNetworks(currentValues);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/pjsip_configuration');
  };

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Adicionar Configuração PJSIP</h3>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation">
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Nome*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="name"
                          rules={{ required: 'Campo obrigatório' }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          Transporte*
                        </label>
                        <Controller
                          as={
                            <Input type="select">
                              <option value="">Selecione</option>
                              <option value="UDP">UDP</option>
                              <option value="TCP">TCP</option>
                              <option value="WSS">WSS (WebRTC)</option>
                            </Input>
                          }
                          defaultValue=""
                          control={control}
                          name="transport"
                          rules={{ required: 'Selecione o transporte' }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="transport"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Bind*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="bind"
                          placeholder="192.168.0.1:8080"
                          rules={{
                            required: 'Campo obrigatório',
                            validate: (value) => {
                              const regex =
                                /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):[0-9]+$/g;
                              return regex.test(value)
                                ? true
                                : 'O IP precisa estar no padrão IPv4 com a porta separada por :';
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="bind"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          External Media Address
                        </label>
                        <Controller
                          as={Input}
                          control={control}
                          name="external_media_address"
                          rules={{
                            validate: (value) => {
                              if (!value || String(value).length === 0)
                                return true;
                              const regex =
                                /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
                              return regex.test(value)
                                ? true
                                : 'O IP precisa estar no padrão IPv4';
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="external_media_address"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">
                          External Signaling Address
                        </label>
                        <Controller
                          as={Input}
                          control={control}
                          name="external_signaling_address"
                          rules={{
                            validate: (value) => {
                              if (!value || String(value).length === 0)
                                return true;
                              const regex =
                                /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
                              return regex.test(value)
                                ? true
                                : 'O IP precisa estar no padrão IPv4';
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="external_signaling_address"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          External Signaling Port
                        </label>
                        <Controller
                          as={Input}
                          control={control}
                          name="external_signaling_port"
                          rules={{
                            validate: (value) => {
                              if (!value || String(value).length === 0)
                                return true;
                              if (!/^[0-9]*$/.test(value))
                                return 'Somente números';
                              return Number(value) > 0
                                ? true
                                : 'O número da porta deve ser um valor positivo';
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="external_signaling_port"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="text-right">
                        <Button
                          color="success"
                          type="button"
                          onClick={addLocalNetwork}
                          disabled={localNetworks.length >= 10}
                        >
                          <FiPlus size={16} /> Rede Local
                        </Button>
                        {localNetworks.length > 0 && (
                          <Button
                            color="danger"
                            type="button"
                            onClick={removeNetwork}
                          >
                            <FiDelete size={16} />
                          </Button>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      {localNetworks.map((network, index) => (
                        <Col key={index} md="6" className="mb-3">
                          <label className="form-control-label">{`Rede Local ${
                            index + 1
                          }`}</label>
                          <input
                            className="form-control"
                            type="text"
                            name={network}
                            ref={register({
                              required: 'Campo obrigatório',
                              validate: (value) => {
                                const regex =
                                  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/([0-9]|[1-2][0-9]|3[0-2])$/g;
                                return regex.test(value)
                                  ? true
                                  : 'Deve estar no padrão IP/Máscara';
                              }
                            })}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name={network}
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      ))}
                    </Row>

                    <Modal
                      show={isOpenModal}
                      onModalToggle={(state) => setIsOpenModal(state)}
                      title="Esta ação irá reiniciar a telefonia"
                      showCloseButton={false}
                    >
                      <p>
                        As ligações em andamento irão desconectar. Deseja
                        reiniciar agora ou quando não houver ligações?
                      </p>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={(data) =>
                          handleSubmit(onSubmit)({ ...data, reloadNow: false })
                        }
                      >
                        Quando não houver ligação
                      </Button>
                      <Button
                        size="sm"
                        onClick={(data) =>
                          handleSubmit(onSubmit)({ ...data, reloadNow: true })
                        }
                      >
                        Agora
                      </Button>
                    </Modal>

                    <hr />
                    <Button color="primary" onClick={handleSaveClick}>
                      Salvar
                    </Button>
                    <Button color="danger" onClick={() => cancelAction()}>
                      Cancelar
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Status;
