import ReactLoader from 'components/Loader';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiPhone } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Button, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import io from 'socket.io-client';
import { apiUc } from '../../../../http';
import store from '../../../../store';
import {
    actionCreatorLogin,
    actionCreatorLogout
} from '../../../../store/action/telephonyServicePanel/loginAction';
import AddQueueForm from '../AddQueueForm';
import LoginForm from '../LoginForm';
import LogoutForm from '../LogoutForm';
import PauseUnpauseForm from '../PauseUnpauseForm';
import ServicePanelItem from '../ServicePanelItem';
import * as S from '../styles';
import { agentStatusColors } from '../utils/colorByState';
import { queueAgentEvents } from '../utils/events';
import { queueAgentStates } from '../utils/states';
import UptimeCounter from '../UptimeCounter';
import PauseService from '../../../../views/Pause/service';

export const TelephonyServicePanel = () => {
    const [agentSessions, setAgentSessions] = useState([]);
    const [extensions, setExtensions] = useState([]);
    const [queues, setQueues] = useState([]);
    const [pauses, setPauses] = useState([]);
    const [allPauses, setAllPauses] = useState([]);
    const [isPaused, setIsPaused] = useState(false);
    const [currentPauseId, setCurrentPauseId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [addAnotherQueue, setAddAnotherQueue] = useState(false);
    const [refetchAgentSessionsIndex, setRefetchAgentSessionsIndex] =
        useState(0);
    const [activePauseCallData, setActivePauseCallData] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const socket = useMemo(
        () =>
            io(process.env.REACT_APP_PROXY_URL, {
                path: process.env.REACT_APP_AMI_SOCKET_PATH,
                transports: ['websocket'],
                upgrade: false,
                query: { token: localStorage.getItem('token') }
            }),
        []
    );
    const socketWarning = useMemo(
        () =>
            io(process.env.REACT_APP_PROXY_URL, {
                path: process.env.REACT_APP_SCALE_PATH_SOCKET
            }),
        []
    );

    const isLogged = !!agentSessions && !!agentSessions.length;

    useEffect(() => {
        if (agentSessions.length > 0) {
            localStorage.setItem(
                'currentLoggedInExtension',
                agentSessions[0].extension
            );
            store.dispatch(
                actionCreatorLogin({
                    agentCode: agentSessions[0].agent_code,
                    extension: agentSessions[0].extension
                })
            );
        } else {
            localStorage.removeItem('currentLoggedInExtension');
            store.dispatch(actionCreatorLogout());
        }
    }, [agentSessions]);

    const handleAddSession = useCallback((newSession) => {
        setAgentSessions((currentSessions) => {
            const loggedAgentCode = localStorage.getItem('agent_code');
            if (loggedAgentCode !== newSession.agent.agent_code) {
                return currentSessions;
            }

            const isLoggedIn = !!currentSessions && !!currentSessions.length;

            if (isLoggedIn) {
                const isTheSameExtension =
                    currentSessions[0].extension === newSession.extension;

                if (!isTheSameExtension) return currentSessions;
            }

            const changedSessions = cloneDeep(currentSessions);

            const alreadyAdded = changedSessions.find((session) => {
                return (
                    session.extension === newSession.extension &&
                    session.queue.name === newSession.agent.queue.name &&
                    session.state === newSession.state
                );
            });

            if (alreadyAdded) return currentSessions;

            const newSessionDTO = {
                state: newSession.state,
                extension: newSession.extension,
                caller: null,
                date: newSession.date,
                is_incoming_call: newSession.isIncomingCall,
                pause_id: newSession.agent.pause_id,
                queue: newSession.agent.queue,
                agent: newSession.agent,
                agent_code: newSession.agent?.agent_code
            };

            return [...currentSessions, newSessionDTO];
        });
    }, []);

    const handleRemoveSession = useCallback((session) => {
        setAgentSessions((prev) => {
            const newItems = prev.filter(
                (item) =>
                    item.queue.name !== session.queueName ||
                    item.extension !== session.extension
            );

            if (newItems.length === prev.length) return prev;

            return [...newItems];
        });
    }, []);

    const changeToUnplaced = useCallback((eventData) => {
        setAgentSessions((currentSessions) => {
            setActivePauseCallData(null);
            const changedSessions = cloneDeep(currentSessions);

            changedSessions.forEach((session) => {
                if (session.extension === eventData.extension) {
                    session.shouldEnablePauseForm = !eventData.agent?.pause_id;
                    session.state = queueAgentStates.UNPLACED;
                    session.caller = null;
                    session.date = eventData.date;
                    session.is_incoming_call = null;
                    session.pause_id = null;
                    session.agent = eventData.agent;
                }
            });

            return changedSessions;
        });
    }, []);

    const changeToPause = useCallback((eventData) => {
        setCurrentPauseId(eventData.agent.pause_id);
        setAgentSessions((currentSessions) => {
            setActivePauseCallData(null);
            const changedSessions = cloneDeep(currentSessions);

            changedSessions.forEach((session) => {
                if (session.extension === eventData.extension) {
                    session.state = queueAgentStates.PAUSE;
                    session.caller = null;
                    session.date = eventData.date;
                    session.is_incoming_call = null;
                    session.agent = eventData.agent;
                    session.shouldEnablePauseForm = false;
                    session.pause_id = eventData.agent?.pause?.id;
                }
            });

            return changedSessions;
        });
        setIsLoading(false);
    }, []);

    const changeToAvailable = useCallback((eventData) => {
        setAgentSessions((currentSessions) => {
            setActivePauseCallData(null);
            const changedSessions = cloneDeep(currentSessions);

            changedSessions.forEach((session) => {
                if (session.extension === eventData.extension) {
                    session.state = queueAgentStates.AVAILABLE;
                    session.caller = null;
                    session.date = eventData.date;
                    session.is_incoming_call = null;
                    session.pause_id = null;
                    session.shouldEnablePauseForm = true;
                    session.agent = eventData.agent;
                }
            });

            return changedSessions;
        });
    }, []);

    const changeToRing = useCallback((eventData) => {
        setAgentSessions((currentSessions) => {
            const isCurrentPaused = currentSessions.some(
                (session) =>
                    !!session.pause_id &&
                    (session.state === queueAgentStates.PAUSE ||
                        session.action === queueAgentStates.PAUSE)
            );

            if (
                isCurrentPaused &&
                currentSessions[0].extension === eventData.extension
            ) {
                setActivePauseCallData(queueAgentStates.RING);
                return currentSessions;
            } else {
                setActivePauseCallData(null);
            }

            const changedSessions = cloneDeep(currentSessions);
            changedSessions.forEach((session) => {
                if (session.extension === eventData.extension) {
                    session.shouldEnablePauseForm =
                        eventData.agent?.action !== queueAgentStates.PAUSE;
                    session.state = queueAgentStates.RING;
                    session.caller = null;
                    session.date = eventData.date;
                    session.is_incoming_call = eventData.isIncomingCall;
                    session.pause_id = null;
                    session.agent = eventData.agent;
                }
            });

            return changedSessions;
        });
    }, []);

    const changeToRinging = useCallback((eventData) => {
        setAgentSessions((currentSessions) => {
            const isCurrentPaused = currentSessions.some(
                (session) =>
                    !!session.pause_id &&
                    (session.state === queueAgentStates.PAUSE ||
                        session.action === queueAgentStates.PAUSE)
            );

            if (
                isCurrentPaused &&
                currentSessions[0].extension === eventData.extension
            ) {
                setActivePauseCallData(queueAgentStates.RINGING);
                return currentSessions;
            } else {
                setActivePauseCallData(null);
            }

            const changedSessions = cloneDeep(currentSessions);
            changedSessions.forEach((session) => {
                if (session.extension === eventData.extension) {
                    session.shouldEnablePauseForm =
                        eventData.agent?.action !== queueAgentStates.PAUSE;
                    session.state = queueAgentStates.RINGING;
                    session.caller = null;
                    session.date = eventData.date;
                    session.is_incoming_call = true;
                    session.pause_id = null;
                    session.agent = eventData.agent;
                }
            });

            return changedSessions;
        });
    }, []);

    const changeToTabulationPause = useCallback((eventData) => {
        setAgentSessions((currentSessions) => {
            const changedSessions = cloneDeep(currentSessions);
            changedSessions.forEach((session) => {
                if (session.extension === eventData.extension) {
                    session.state = queueAgentStates.TABULATION_PAUSE;
                    session.caller = null;
                    session.date = eventData.date;
                    session.is_incoming_call = null;
                    session.pause_id = null;
                    session.agent = eventData.agent;
                }
            });

            return changedSessions;
        });
    }, []);

    const getAgentStatus = useCallback(() => {
        const agentCode = localStorage.getItem('agent_code');
        setIsLoading(true);
        apiUc
            .get(`/api/agent-status/${agentCode}`)
            .then((response) => {
                const sessionData = response.data.data;
                setAgentSessions(sessionData);
            })
            .catch(() => {
                setAgentSessions([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const alertWarning = useCallback((eventData) => {
        const agentLocal = localStorage.getItem('agent_code');
        const agentSocket = eventData.agentCode;
        if (agentSocket === agentLocal) {
            toast.info('Você irá entrar em pausa automática em 10 minutos!');
        }
    }, []);

    useEffect(() => {
        PauseService.getAllPauses()
            .then((response) => {
                setAllPauses(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        socket.on('connect', () => {
            console.log('Telephony service panel socket connected!');
        });

        return () => {
            if (socket) socket.disconnect();
        };
    }, [socket]);

    useEffect(() => {
        socket.on(queueAgentEvents.QUEUE_MEMBER_ADDED, (eventData) => {
            handleAddSession(eventData);
        });
    }, [socket, handleAddSession]);

    useEffect(() => {
        socket.on(queueAgentEvents.QUEUE_MEMBER_REMOVED, async (eventData) => {
            const currentLoggedInExtension = localStorage.getItem(
                'currentLoggedInExtension'
            );
            const agentCode = localStorage.getItem('agent_code');
            const isUsingScale = localStorage.getItem('is_using_scale');
            if (
                eventData.extension === currentLoggedInExtension &&
                JSON.parse(isUsingScale)
            ) {
                await apiUc
                    .get(`/api/agent-status/${agentCode}`)
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch(() => {
                        window.location.href = '/activelogout';
                    });
            }
            return handleRemoveSession(eventData);
        });
    }, [socket, handleRemoveSession]);

    useEffect(() => {
        socket.on(queueAgentEvents.QUEUE_MEMBER_REMOVED, (eventData) => {
            return handleRemoveSession(eventData);
        });
    }, [socket, handleRemoveSession]);

    useEffect(() => {
        socket.on(queueAgentEvents.QUEUE_MEMBER_UNREGISTERED, (eventData) => {
            changeToUnplaced(eventData);
        });
    }, [socket, changeToUnplaced]);

    useEffect(() => {
        socket.on(queueAgentEvents.QUEUE_MEMBER_PAUSE, (eventData) => {
            setIsLoading(true);
            changeToPause(eventData);
        });
    }, [socket, changeToPause]);

    useEffect(() => {
        socket.on(queueAgentEvents.QUEUE_MEMBER_PAUSE_ALERT, (eventData) => {
            changeToPause(eventData);
        });
    }, [socket, changeToPause]);

    useEffect(() => {
        socket.on(
            queueAgentEvents.QUEUE_MEMBER_TABULATION_PAUSE,
            (eventData) => {
                console.log('QUEUE_MEMBER_TABULATION_PAUSE', eventData);
                changeToTabulationPause(eventData);
            }
        );
    }, [socket, changeToTabulationPause]);

    useEffect(() => {
        socket.on(queueAgentEvents.QUEUE_MEMBER_AVAILABLE, (eventData) => {
            changeToAvailable(eventData);
        });
    }, [socket, changeToAvailable]);

    useEffect(() => {
        socket.on(queueAgentEvents.QUEUE_MEMBER_RING, (eventData) => {
            changeToRing(eventData);
        });
    }, [socket, changeToRing]);

    useEffect(() => {
        socket.on(queueAgentEvents.QUEUE_MEMBER_RINGING, (eventData) => {
            changeToRinging(eventData);
        });
    }, [socket, changeToRinging]);
    useEffect(() => {
        socketWarning.on(
            queueAgentEvents.AUTOMATIC_PAUSE_ALERT,
            (eventData) => {
                alertWarning(eventData);
            }
        );
    }, [socketWarning, alertWarning]);

    useEffect(() => {
        getAgentStatus();
    }, [getAgentStatus, refetchAgentSessionsIndex]);

    useEffect(() => {
        if (isDropdownOpen) getAgentStatus();
    }, [getAgentStatus, isDropdownOpen]);

    useEffect(() => {
        apiUc
            .get('/api/filas')
            .then(({ data }) => {
                const allowedQueues = [];
                const agentQueues = JSON.parse(localStorage.getItem('queues'));

                data.data.forEach((queue) => {
                    agentQueues.forEach((agentQueue) => {
                        if (queue.id === agentQueue.id) {
                            allowedQueues.push(queue);
                        }
                    });
                });

                setQueues([...allowedQueues]);
            })
            .catch(() => {
                toast.error('Não foi possível carregar as filas', {
                    autoClose: 3000,
                    closeOnClick: true
                });
            });
    }, []);

    useEffect(() => {
        apiUc
            .get('/api/ramais/all')
            .then(({ data }) => {
                const allowedExtension = [];
                const agentExtensions = JSON.parse(
                    localStorage.getItem('extensions')
                );

                data.data.forEach((extension) => {
                    agentExtensions.forEach((agentExtension) => {
                        if (
                            extension.extension_number === agentExtension.ramal
                        ) {
                            allowedExtension.push(extension);
                        }
                    });
                });

                setExtensions([...allowedExtension]);
            })
            .catch(() => {
                toast.error('Não foi possível carregar os ramais', {
                    autoClose: 3000,
                    closeOnClick: true
                });
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (!agentSessions) return;
        const allPauses = agentSessions
            .map((element) => element.queue.pauses)
            .reduce((prevPauses, currentPauses = []) => {
                // verifica se a pause já foi adicionada
                const prevIds = prevPauses.map((p) => p.id);
                const validCurrentPauses = currentPauses.filter(
                    (p) => !prevIds.includes(p.id)
                );

                return [...prevPauses, ...validCurrentPauses];
            }, []);

        setPauses(allPauses);

        const isAgentPaused = agentSessions.some(
            (session) =>
                !!session.pause_id &&
                (session.state === queueAgentStates.PAUSE ||
                    session.action === queueAgentStates.PAUSE)
        );

        const isOnActivePauseCall = agentSessions.some(
            (session) =>
                !!session.pause_id &&
                (session.action === queueAgentStates.RING ||
                    session.action === queueAgentStates.RINGING)
        );

        setIsPaused(isAgentPaused || isOnActivePauseCall);

        if (isAgentPaused || isOnActivePauseCall) {
            setCurrentPauseId(agentSessions[0].pause_id);
        }
    }, [agentSessions]);

    const showError = (message) => {
        toast.error(message, {
            autoClose: 3000,
            closeOnClick: true
        });
    };

    const handleLogin = (data) => {
        const agent_code = localStorage.getItem('agent_code');
        setIsLoading(true);
        apiUc
            .post('/api/agent-session', {
                ...data,
                agent_code
            })
            .then(() => {
                setRefetchAgentSessionsIndex((prev) => prev + 1);
            })
            .catch((error) => {
                const msg =
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.message;
                showError(msg || 'Ocorreu um erro ao tentar logar na fila');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleAddQueues = useCallback(({ queueIds, extension }) => {
        const agentCode = localStorage.getItem('agent_code');

        setIsLoading(true);
        setAddAnotherQueue(false);

        apiUc
            .post('/api/agent-session', {
                queueIds,
                agent_code: agentCode,
                extension
            })
            .catch((error) => {
                const errorMessage =
                    error.response?.data?.message || 'Erro ao fazer login';
                showError(errorMessage);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handlePause = useCallback(({ pauseId }) => {
        const agentCode = localStorage.getItem('agent_code');
        setIsLoading(true);

        const pause_id = Number(pauseId);

        apiUc
            .post(`/api/agent-pause/${agentCode}`, { pause_id })
            .then(() => {
                setIsPaused(true);
                setCurrentPauseId(pause_id);
            })
            .catch(() => {
                showError('Não foi possível pausar agente');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleUnpause = useCallback(() => {
        const agentCode = localStorage.getItem('agent_code');
        setIsLoading(true);
        apiUc
            .delete(`/api/agent-pause/${agentCode}`)
            .then(() => {
                setIsPaused(false);
                setCurrentPauseId('');
            })
            .catch((err) => {
                showError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleLogoff = ({ queuesToRemove = [] }) => {
        const agentCode = localStorage.getItem('agent_code');
        const extension = agentSessions[0].extension;

        if (!agentCode) return showError('Código de Agente não encontrado');

        const queueIds = queuesToRemove.map((q) => q.id);

        setIsLoading(true);
        apiUc({
            method: 'delete',
            data: {
                extension,
                queueIds,
                agent_code: agentCode
            },
            url: '/api/agent-session'
        })
            .then(() => {
                queuesToRemove.forEach((q) => {
                    handleRemoveSession({
                        queueName: q.name,
                        extension
                    });
                });
            })
            .catch(({ response, message }) => {
                const { data } = response;
                showError((data && data.message) || message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleHideAddQueueForm = () => {
        setAddAnotherQueue(false);
    };

    let componentToRender = (
        <LoginForm
            extensions={extensions}
            queues={queues}
            onSubmitHandler={handleLogin}
            setIsDropdownOpen={setIsDropdownOpen}
        />
    );

    if (isLogged) {
        componentToRender = (
            <>
                <S.InfoWrapper>
                    <S.UserInfoWrapper title="Código do Agente">
                        <S.Label>Código do Agente: </S.Label>
                        <S.Info>{localStorage.getItem('agent_code')}</S.Info>
                    </S.UserInfoWrapper>
                    <S.UserInfoWrapper title="Ramal">
                        <S.Label>Ramal: </S.Label>
                        <S.Info>{agentSessions[0]?.extension || ''}</S.Info>
                    </S.UserInfoWrapper>
                </S.InfoWrapper>

                <PauseUnpauseForm
                    pauses={pauses}
                    allPauses={allPauses}
                    isPaused={isPaused}
                    currentPauseId={currentPauseId}
                    handlePause={handlePause}
                    handleUnpause={handleUnpause}
                />

                <LogoutForm
                    agentQueues={agentSessions}
                    isPaused={isPaused}
                    handleLogoff={handleLogoff}
                />

                {agentSessions.map((agentSession, index) => (
                    <ServicePanelItem
                        key={index}
                        agentSession={agentSession}
                        handleLogoff={handleLogoff}
                        isPaused={isPaused}
                    />
                ))}

                <div className="text-center  py-3">
                    <Button
                        color="link"
                        onClick={() => setAddAnotherQueue(true)}
                    >
                        Logar em Nova Fila
                    </Button>
                </div>
            </>
        );
    }

    if (isLoading) {
        componentToRender = (
            <S.LoadingWrapper>
                <ReactLoader height={50} width={50} />
            </S.LoadingWrapper>
        );
    }

    if (addAnotherQueue) {
        componentToRender = (
            <AddQueueForm
                handleAddQueues={handleAddQueues}
                handleCancel={handleHideAddQueueForm}
                queues={queues}
                agentSessions={agentSessions}
            />
        );
    }

    const getIconColorByStatus = useCallback(() => {
        const defaultColor = 'transparent';
        if (!isLogged) return defaultColor;
        return (
            agentStatusColors[
                activePauseCallData ||
                    agentSessions[0].state ||
                    agentSessions[0].action
            ] || defaultColor
        );
    }, [agentSessions, isLogged, activePauseCallData]);

    const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown nav isOpen={isDropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
                className="nav-link"
                tag="a"
                style={{ cursor: 'pointer' }}
            >
                <FiPhone
                    strokeWidth={isLogged ? 1.2 : 2}
                    style={{
                        cursor: 'pointer',
                        color: 'white',
                        borderRadius: '5%',
                        fontWeight: 'bold',
                        fontSize: '1.4rem',
                        fill: getIconColorByStatus()
                    }}
                />
                {isLogged && (
                    <UptimeCounter startDate={agentSessions[0]?.date} />
                )}
            </DropdownToggle>
            <DropdownMenu
                className="dropdown-menu-xl py-0 overflow-y-visible"
                right
            >
                {componentToRender}
            </DropdownMenu>
        </Dropdown>
    );
};
