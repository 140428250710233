import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, Form, Row, Col, Input } from 'reactstrap';
import IssueService from '../../../../../Issue/service';
import ProductService from '../../../../../Product/service';
import SectorService from '../../../../../Sector/service';
import StatusService from '../../../../../status/service';
import ReasonService from '../../../../../reason/service';
import SubReasonService from '../../../../../subReason/service';
import ChatConfigService from '../../../../../ChatConfiguration/service';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import UserService from 'views/User/service/index';
import { useSocketConversationInstagram } from 'hooks/useSocketConversationInstagram';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
const CloseManyButton = ({ selectedConversations }) => {
  const { control, handleSubmit, errors, setValue } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState([]);

  const [issues, setIssues] = useState([]);
  const [products, setProducts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [recordTypes, setRecordTypes] = useState([]);

  const [reasons, setReasons] = useState([]);

  const [subreasons, setSubreasons] = useState([]);

  const [loadingSubreasons, setLoadingSubreasons] = useState(false);
  const [loading, setLoading] = useState(false);
  const [closingFields, setClosingFields] = useState();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const { socketInstagramConversation: socketInstagram } =
    useSocketConversationInstagram();
  useEffect(() => {
    if (isOpen) {
      UserService.get(window.localStorage.getItem('login'))
        .then((data) => {
          const recordTypesByRequest = data.data.data.recordTypes.map(
            (item) => item.id
          );

          setRecordTypes(recordTypesByRequest);
        })
        .catch((err) => {
          const message =
            (err.response && err.response.data) ||
            'Erro ao carregar os tipos de registros';
          toast.error(message, {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [isOpen]);

  const handleGetSubReasons = useCallback(
    (event) => {
      const [value] = reasons.filter((r) => r.id === event);

      if (value) {
        setLoadingSubreasons(true);
        SubReasonService.getByReasonId(value.id)
          .then((response) => {
            setSubreasons(response.data.content);
          })
          .catch((err) => {
            const { data } = err.response;
            toast.error(data.message, {
              autoClose: 3000,
              closeOnClick: true
            });
          })
          .finally(() => {
            setLoadingSubreasons(false);
          });
      } else {
        setSubreasons([]);
      }
    },
    [reasons]
  );

  //get config chat
  useEffect(() => {
    if (isOpen) {
      ChatConfigService.getAll()
        .then((response) => {
          const { camposEncerramento } = response.data;
          setClosingFields(camposEncerramento);
        })
        .catch((error) => {
          if (error.response) {
            toast.error('Erro ao carregar cam!', {
              autoClose: 3000,
              closeOnClick: true
            });
          }
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      SectorService.getAll()
        .then((response) => {
          setSectors(response.data.data);
        })
        .catch((err) => {});
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      ReasonService.getByRecordTypes({ recordTypes: recordTypes })
        .then((response) => {
          const dataShort = sortData(response.data.content);
          setReasons(dataShort);
        })
        .catch((err) => {
          const message =
            (err.response && err.response.data) || 'Erro ao carregar motivos';
          toast.error(message, {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [isOpen, recordTypes]);

  useEffect(() => {
    if (isOpen) {
      IssueService.getAll()
        .then((response) => {
          setIssues(response.data);
        })
        .catch((err) => {});
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      ProductService.getAll()
        .then((response) => {
          setProducts(response.data);
        })
        .catch((err) => {});
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      StatusService.getByRecordTypes({ recordTypes: recordTypes })
        .then((response) => {
          setStatus(response.data.content);
        })
        .catch((err) => {});
    }
  }, [isOpen, recordTypes]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async (data) => {
    const statusItem =
      status?.find((item) => {
        return item.id === data.status_id;
      })?.name ?? '';
    const motivo =
      reasons?.find((item) => {
        return item.id === data.reason_id;
      })?.name ?? '';
    const submotivo =
      subreasons?.find((item) => {
        return item.id === data.subreason_id;
      })?.name ?? '';

    setLoading(true);

    try {
      selectedConversations.forEach((c) => {
        socketInstagram.emit('encerra_conversa_atendente', {
          id: c._id,
          body: {
            ...data,
            motivo,
            submotivo,
            status: statusItem ? statusItem : undefined
          }
        });
      });

      toast.success('Conversas encerradas com sucesso!', {
        autoClose: 3000,
        closeOnClick: true
      });
      setIsOpen(!isOpen);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao encerrar conversas!', {
        autoClose: 3000,
        closeOnClick: true
      });
      setIsOpen(!isOpen);
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const sortData = (data) => {
    const dataSort = data.sort((a, b) => {
      if (a.name) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      } else {
        if (a.nome.toLowerCase() < b.nome.toLowerCase()) return -1;
        if (a.nome.toLowerCase() > b.nome.toLowerCase()) return 1;
        return 0;
      }
    });
    return dataSort;
  };

  return (
    <>
      {verifyRoles(['ENDING_CONVERSATIONS']) && (
        <Button
          outline
          size="sm"
          type="button"
          style={{
            ...buttonStyle(systemConfiguration.primary_color),
            margin: '0rem'
          }}
          disabled={selectedConversations.length < 1}
          onClick={toggleModal}
        >
          Encerrar várias
        </Button>
      )}

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Encerrar conversas
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-40px' }} />

          {loading ? (
            <Row className="justify-content-md-center mt-6">
              <Loader
                type="TailSpin"
                color="#35ac50"
                height={100}
                width={100}
              />
            </Row>
          ) : (
            <Form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row className="mb-3">
                <Col md="6">
                  <label className="form-control-label">Status</label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        dataOptions={status}
                        isMulti={false}
                        isClearable={false}
                        value={props.value}
                        valueController={setValue}
                        closeMenuOnSelect={true}
                        isSearchable={false}
                        fieldName="status_id"
                        labelName="name"
                        valueName="id"
                      />
                    )}
                    control={control}
                    name="status_id"
                    defaultValue=""
                    // rules={{ required: 'Status é obrigatório!' }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="status_id"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
                <Col md="6">
                  <label className="form-control-label">Motivo*</label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        dataOptions={reasons}
                        isMulti={false}
                        isClearable={false}
                        value={props.value}
                        valueController={(name, value) => {
                          setValue(name, value);
                          handleGetSubReasons(value);
                        }}
                        closeMenuOnSelect={true}
                        isSearchable={false}
                        fieldName="reason_id"
                        labelName="name"
                        valueName="id"
                      />
                    )}
                    control={control}
                    name="reason_id"
                    rules={{ required: 'Campo obrigatório' }}
                    defaultValue=""
                  />
                  <ErrorMessage
                    errors={errors}
                    name="reason_id"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
              </Row>
              {closingFields && (
                <Row>
                  <Col
                    md={closingFields.setor ? { size: 6 } : { size: 12 }}
                    className="mb-3"
                  >
                    <label className="form-control-label">Submotivo*</label>
                    <Controller
                      render={(props) => (
                        <FlexSelect
                          dataOptions={subreasons}
                          isMulti={false}
                          isClearable={false}
                          value={props.value}
                          valueController={setValue}
                          closeMenuOnSelect={true}
                          isSearchable={false}
                          fieldName="subreason_id"
                          labelName="name"
                          valueName="id"
                        />
                      )}
                      disabled={loadingSubreasons}
                      control={control}
                      name="subreason_id"
                      rules={{ required: 'Campo obrigatório' }}
                      defaultValue={''}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="subreason_id"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                  {closingFields.setor && (
                    <Col md="6">
                      <label className="form-control-label">Setor</label>
                      <Controller
                        render={(props) => (
                          <FlexSelect
                            dataOptions={sectors}
                            isMulti={false}
                            isClearable={false}
                            value={props.value}
                            valueController={setValue}
                            closeMenuOnSelect={true}
                            isSearchable={false}
                            fieldName="setor"
                            labelName="name"
                            valueName="name"
                          />
                        )}
                        control={control}
                        name="setor"
                        defaultValue=""
                      />
                    </Col>
                  )}
                </Row>
              )}
              {closingFields && (
                <>
                  <Row className="mb-3">
                    {closingFields.produto && (
                      <Col md="6">
                        <label className="form-control-label">Produto</label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={products}
                              isMulti={false}
                              isClearable={false}
                              value={props.value}
                              valueController={setValue}
                              closeMenuOnSelect={true}
                              isSearchable={false}
                              fieldName="produto"
                              labelName="nome"
                              valueName="nome"
                            />
                          )}
                          control={control}
                          name="produto"
                          defaultValue=""
                        />
                      </Col>
                    )}
                    {closingFields.assunto && (
                      <Col md="6">
                        <label className="form-control-label">Assunto</label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={issues}
                              isMulti={false}
                              isClearable={false}
                              value={props.value}
                              valueController={setValue}
                              closeMenuOnSelect={true}
                              isSearchable={false}
                              fieldName="assunto"
                              labelName="nome"
                              valueName="nome"
                            />
                          )}
                          control={control}
                          name="assunto"
                          defaultValue=""
                          // rules={{ required: 'Assunto é obrigatório!' }}
                        />
                        {/* <ErrorMessage
                    errors={errors}
                    name="assunto"
                    render={({ message }) => errorFormMessage(message)}
                  /> */}
                      </Col>
                    )}
                  </Row>

                  {closingFields.observacao && (
                    <Row className="mb-5">
                      <Col md="12">
                        <label className="form-control-label">Observação</label>
                        <Controller
                          as={
                            <Input
                              type="textarea"
                              rows="3"
                              name="observacao"
                              id="observacao"
                            />
                          }
                          control={control}
                          name="observacao"
                          defaultValue=""
                        />
                      </Col>
                    </Row>
                  )}

                  <Row className="mb-5">
                    <Col md="12">
                      <label className="form-control-label">
                        Mensagem de Encerramento
                      </label>
                      <Controller
                        as={
                          <Input
                            type="textarea"
                            rows="3"
                            name="closingMessage"
                            id="closingMessage"
                          />
                        }
                        control={control}
                        name="closingMessage"
                        defaultValue=""
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Button
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
                style={buttonStyle(
                  systemConfiguration.cancelation_button_color
                )}
              >
                Fechar
              </Button>

              <Button
                color="primary"
                type="submit"
                style={buttonStyle(
                  systemConfiguration.confirmation_button_color
                )}
              >
                Encerrar
              </Button>
            </Form>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default CloseManyButton;
