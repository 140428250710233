import { useState, useEffect } from 'react';
//import ChatBroadcastListService from '../../ChatBroadcastList/service';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';

export function useGetWhatsapp() {
  useEffect(() => {
    FlexChatIntegration.getWhatsapp()
      .then((response) => {
        const list = response.data.data.map((item) => {
          return { value: item.id, label: item.nome_canal };
        });
        setWhatsappList(list);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  const [whatsappList, setWhatsappList] = useState([]);

  return {
    whatsappList,
    setWhatsappList
  };
}
