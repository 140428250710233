import { calculateOffsetFromPageAndLimit } from 'views/CRM/shared/utils/pagination';
import { apiCrm } from '../../../../http';

class ContactService {
  getContacts(recordTypeId, params = {}, headers = { page: 1, limit: 10 }) {
    const offset = calculateOffsetFromPageAndLimit(headers.page, headers.limit);
    return apiCrm.get(`contact/by-record-type/${recordTypeId}`, {
      params,
      headers: { ...headers, offset }
    });
  }

  createContact(recordTypeId, data) {
    return apiCrm.post(`contact/${recordTypeId}`, data);
  }

  getContactById(contactId) {
    return apiCrm.get(`contact/${contactId}`);
  }

  updateContact(recordTypeId, contactId, data) {
    return apiCrm.put(`contact/${recordTypeId}/${contactId}`, data);
  }

  deleteContact(contactId) {
    return apiCrm.delete(`contact/${contactId}`);
  }

  getAccounts(params = {}) {
    return apiCrm.get(`/account`, {
      params
    });
  }

  getAccountById(registerId, contactId) {
    return apiCrm.get(`/account/${registerId}/${contactId}`);
  }

  searchContacts(params = {}) {
    return apiCrm.get(`/contact`, { params });
  }

  importContacts(recordTypeId, data) {
    return apiCrm.post(`contact/${recordTypeId}/import`, data);
  }

  getContactsComments(contactId) {
    return apiCrm.get(`contacts-comment/${contactId}`);
  }
  createContactsComments(contactId, data) {
    return apiCrm.post(`contacts-comment/${contactId}`, data);
  }
  updateContactsComments(commentId, data) {
    return apiCrm.put(`contacts-comment/${commentId}`, data);
  }
  deleteContactsComments(commentId) {
    return apiCrm.delete(`contacts-comment/${commentId}`);
  }

  async getExcel(params) {
    return apiCrm.get('/export-contact-xlsx', {
      params,
      responseType: 'arraybuffer'
    });
  }

  async getCsv(params) {
    return apiCrm.get('/export-contact-csv', {
      params,
      responseType: 'arraybuffer'
    });
  }

  async getPdf(params) {
    return apiCrm.get('/export-contact-pdf', {
      params,
      responseType: 'arraybuffer'
    });
  }
}

export default new ContactService();
