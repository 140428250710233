import React, { useEffect, useMemo, useState } from 'react';
import store from 'store';
import { Button, Badge } from 'reactstrap';
import { useSelector } from 'react-redux';

import { MdPhoneForwarded } from 'react-icons/md';
import { removeConversationInAttendance } from 'store/action/conversation-in-attendance-action';

import {
  MessageReceived,
  MessageCount,
  TagBody,
  TagStyle,
  ClientName,
  LeftZone,
  RightZone,
  QueueName,
  ChannelName,
  TimeZone
} from '../styled';
import { getProps, getTime } from './assets';
import { TagChatClient } from 'components/TagChatClient';
import { IconsChannel } from 'components/IconsChannel/IconsChannel';

const QueueTag = ({
  conversation = {},
  allQueues = [],
  functionAnswerConversation,
  idNameTags = {}
}) => {
  // eslint-disable-next-line no-unused-vars
  const [iconName, setIconName] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [queueColor, setQueueColor] = useState('');
  const [countTags, setCountTags] = useState(0);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [tagProps, setTagProps] = useState({});
  const [time, setTime] = useState('00:00:00');
  const conversationInAttendance = useSelector(
    (state) => state.conversationInAttendance
  );

  //setting timer
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(getTime(conversation.dateOfLastAction));
    }, 1000);
    return () => clearInterval(timer);
  }, [conversation]);

  //New messages
  useEffect(() => {
    conversation.novas_mensagens > 99
      ? setNewMessagesCount('+99')
      : setNewMessagesCount(conversation.novas_mensagens);

    setCountTags(conversation?.tags?.length || 0);
  }, [conversation]);

  //getting tag data
  useEffect(() => {
    let copyConversation = { ...conversation };
    if (!conversation._id && conversation.id) {
      copyConversation._id = copyConversation.id;
    }
    if (
      !!conversationInAttendance &&
      conversationInAttendance?.conversation?._id === copyConversation?._id
    ) {
      setTagProps(getProps('atendida'));
    } else {
      setTagProps(getProps(copyConversation.situacao));
    }

    //getting queue color
    allQueues.forEach((el) => {
      copyConversation.fila === el.name && setQueueColor(el.color);
    });

    if (copyConversation?.cliente?.nome) {
      let nameArray = copyConversation?.cliente?.nome.split(' ');
      let initials = '';

      for (let i = 0; i < nameArray.length; i++) {
        if (nameArray[i][0]) initials = initials.concat(nameArray[i][0]);
        if (i === 1) break;
      }

      setIconName(initials);
    } else {
      setIconName('I');
    }
  }, [conversation, allQueues, conversationInAttendance]);

  //aswering conversation
  const answerConversation = async () => {
    await store.dispatch(removeConversationInAttendance());
    functionAnswerConversation(
      conversation._id ?? conversation.id,
      conversation.meioTransferencia,
      //telephonyServicePanel,
      conversation.cliente.telefone,
      conversation.canal
    );
  };

  const answerConversationToast = async (id) => {
    await store.dispatch(removeConversationInAttendance());
    functionAnswerConversation(
      id,
      '',
      //telephonyServicePanel,
      '',
      ''
    );
  };

  const getChannelIcon = (channel, transferMode) => {
    if (transferMode === 'call') {
      return <MdPhoneForwarded size="20" color="#d9534f" />;
    } else {
      return <IconsChannel channel={channel} showName={false} />;
    }
  };

  return conversation ? (
    <Button
      className="mb-3"
      style={TagStyle(queueColor)}
      onClick={answerConversation}
      title={`Cliente: ${conversation?.cliente?.nome} \nFila: ${
        conversation.fila
      }\nCanal: ${conversation?.canal}\nMeio de transferência: ${
        conversation.meioTransferencia === 'call'
          ? 'Ligação\nRealize o login em algum ramal para continuar o atendimento!'
          : 'Chat'
      }`}
    >
      <TagBody>
        <LeftZone>
          <ClientName className="mt--2">
            {conversation?.cliente?.nome}
          </ClientName>
          <QueueName className="mt--2 text-muted">
            Fila: {conversation.fila}
          </QueueName>
          <ChannelName className="mt--2 text-muted">
            {getChannelIcon(
              conversation?.canal,
              conversation?.meioTransferencia
            )}
          </ChannelName>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            {conversation?.tags && countTags > 2 ? (
              <>
                <TagChatClient
                  name={idNameTags[conversation.tags[0]]?.name}
                  color={idNameTags[conversation.tags[0]]?.color || '#5086c1'}
                  disabled={idNameTags[conversation.tags[0]]?.disabled}
                />
                <TagChatClient
                  name={idNameTags[conversation.tags[2]]?.name}
                  color={idNameTags[conversation.tags[2]]?.color || '#5086c1'}
                  disabled={idNameTags[conversation.tags[1]]?.disabled}
                />
                <TagChatClient
                  name={`+${countTags - 2}`}
                  allTags={conversation?.tags}
                  idNameTags={idNameTags}
                  isMore={true}
                  color="#5086c1"
                  index={conversation?.id}
                />
              </>
            ) : (
              <>
                {Object.keys(idNameTags).length > 0 &&
                  conversation?.tags &&
                  conversation?.tags?.map((tag, index) => (
                    <TagChatClient
                      key={index}
                      name={idNameTags[tag]?.name}
                      color={idNameTags[tag]?.color || '#5086c1'}
                      disabled={idNameTags[tag]?.disabled}
                    />
                  ))}
              </>
            )}
          </div>
        </LeftZone>
        <RightZone>
          <TimeZone className="mt--2">{time}</TimeZone>
          <h4 className="mt--2">
            <Badge color={tagProps?.badge}>{tagProps?.status}</Badge>
          </h4>
          <MessageReceived>
            {newMessagesCount > 0 && (
              <MessageCount> {newMessagesCount} </MessageCount>
            )}
          </MessageReceived>
        </RightZone>
      </TagBody>
    </Button>
  ) : null;
};

export default QueueTag;
