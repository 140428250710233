import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, Form, Row, Col, Input } from 'reactstrap';
import ReportService from '../../../service';
import IssueService from '../../../../../../Issue/service';
import ProductService from '../../../../../../Product/service';
import SectorService from '../../../../../../Sector/service';
import StatusService from '../../../../../../status/service';
import ReasonService from '../../../../../../reason/service';
import SubReasonService from '../../../../../../subReason/service';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import FlexSelect from 'components/Inputs/FlexSelect';

const EditModal = ({ conversationData = null,id = '' }) => {
  const { reset, control, handleSubmit, errors, setValue } = useForm();
  const [conversation, setConversation] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState([]);
  const [issues, setIssues] = useState([]);
  const [products, setProducts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [rolesObject, setRolesObject] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [subreasons, setSubreasons] = useState([]);
  const [loadingSubreasons, setLoadingSubreasons] = useState(false);

  useEffect(() => {
    if (isOpen === true) {
      SectorService.getAll()
        .then((response) => {
          setSectors(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      ReasonService.getWithoutPagination()
        .then((response) => {
          const dataShort = sortData(response.data.content);
          setReasons(dataShort);
        })
        .catch((err) => {
          const message =
            (err.response && err.response.data) || 'Erro ao carregar motivos';
          toast.error(message, {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen === true) {
      IssueService.getAll()
        .then((response) => {
          setIssues(response.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen === true) {
      ProductService.getAll()
        .then((response) => {
          setProducts(response.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen === true) {
      StatusService.getWithoutPagination()
        .then((response) => {
          setStatus(response.data.content);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    const roles = localStorage.getItem('roles');
    const rolesConvertObject = JSON.parse(roles);
    setRolesObject(rolesConvertObject);
  }, []);

  useEffect(() => {
    // console.log(conversationData);
    setConversation(conversationData);
    reset(conversationData);

    let value = '';

    conversationData.motivo &&
      reasons.forEach((e) => {
        if (conversationData.motivo === e.name) {
          value = e.id;
        }
      });

    if (value) {
      setLoadingSubreasons(true);
      SubReasonService.getByReasonId(value)
        .then((response) => {
          setSubreasons(response.data.content);
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error(data.message, {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setLoadingSubreasons(false);
        });
    } else {
      setSubreasons([]);
    }
  }, [conversationData, reset, reasons]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async (data) => {
    try {
      const formatedData = { ...data };
      ReportService.updateConversation(conversation._id, formatedData);
      toast.success('Atualizado com sucesso!', {
        autoClose: 3000,
        closeOnClick: true
      });
      toggleModal();
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message || 'Erro ao atualizar!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const disabledCloseConversatitonModal = () => {
    let show = false;
    rolesObject.forEach((role) => {
      if (
        role.role.toUpperCase() === 'attendant'.toUpperCase() ||
        role.role.toUpperCase() === 'trainne'.toUpperCase()
      ) {
        show = true;
      }
    });
    return show;
  };

  const handleGetSubReasons = (eventName) => {
    let value = '';

    reasons.forEach((e) => {
      if (eventName === e.name) {
        value = e.id;
      }
    });

    if (value) {
      setLoadingSubreasons(true);
      SubReasonService.getByReasonId(value)
        .then((response) => {
          setSubreasons(response.data.content);
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error(data.message, {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setLoadingSubreasons(false);
        });
    } else {
      setSubreasons([]);
    }
  };

  const sortData = (data) => {
    const dataSort = data.sort((a, b) => {
      if (a.name) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      } else {
        if (a.nome.toLowerCase() < b.nome.toLowerCase()) return -1;
        if (a.nome.toLowerCase() > b.nome.toLowerCase()) return 1;
        return 0;
      }
    });
    return dataSort;
  };

  return (
    <>
      <Button
        size="sm"
        color="info"
        title="Editar encerramento"
        onClick={toggleModal}
        disabled={disabledCloseConversatitonModal()}
        id={`${id}-edit-modal`}
      >
        <i className="fas fa-pen"></i>
      </Button>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {conversation &&
              `Detalhes de Encerramento - ${conversation.cliente.nome || ''}`}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />
          <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col md="6">
                <label className="form-control-label">Status</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={status}
                      isMulti={false}
                      isClearable={false}
                      value={props.value}
                      valueController={setValue}
                      closeMenuOnSelect={true}
                      isSearchable={false}
                      fieldName="status"
                      labelName="name"
                      valueName="name"
                    />
                  )}
                  control={control}
                  name="status"
                  defaultValue=""
                  // rules={{ required: 'Status é obrigatório!' }}
                />
                <ErrorMessage
                  errors={errors}
                  name="status"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
              <Col md="6">
                <label className="form-control-label">Motivo*</label>
                <Controller
                  as={
                    <Input
                      type="select"
                      name="select"
                      onChangeCapture={(e) =>
                        handleGetSubReasons(e.target.value)
                      }
                    >
                      <option value="">Selecione um motivo</option>
                      {reasons.map(({ name }, index) => (
                        <option
                          key={`selectreasons+${name}+${index}`}
                          value={name}
                        >
                          {name}
                        </option>
                      ))}
                    </Input>
                  }
                  control={control}
                  name="motivo"
                  rules={{ required: 'Campo obrigatório' }}
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="motivo"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-3">
                <label className="form-control-label">Submotivo*</label>
                <Controller
                  as={
                    <Input type="select" name="select">
                      <option value="">Selecione um sub motivo</option>
                      {subreasons.map(({ name }, index) => (
                        <option
                          key={`selectsubmotivo+${name}+${index}`}
                          value={name}
                        >
                          {name}
                        </option>
                      ))}
                    </Input>
                  }
                  disabled={loadingSubreasons}
                  control={control}
                  name="submotivo"
                  rules={{ required: 'Campo obrigatório' }}
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="submotivo"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md="6">
                <label className="form-control-label">Produto</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={products}
                      isMulti={false}
                      isClearable={false}
                      value={props.value}
                      valueController={setValue}
                      closeMenuOnSelect={true}
                      isSearchable={false}
                      fieldName="produto"
                      labelName="nome"
                      valueName="nome"
                    />
                  )}
                  control={control}
                  name="produto"
                  defaultValue=""
                />
              </Col>
              <Col md="6">
                <label className="form-control-label">Assunto</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={issues}
                      isMulti={false}
                      isClearable={false}
                      value={props.value}
                      valueController={setValue}
                      closeMenuOnSelect={true}
                      isSearchable={false}
                      fieldName="assunto"
                      labelName="nome"
                      valueName="nome"
                    />
                  )}
                  control={control}
                  name="assunto"
                  defaultValue=""
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="12">
                <label className="form-control-label">Setor</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={sectors}
                      isMulti={false}
                      isClearable={false}
                      value={props.value}
                      valueController={setValue}
                      closeMenuOnSelect={true}
                      isSearchable={false}
                      fieldName="setor"
                      labelName="name"
                      valueName="name"
                    />
                  )}
                  control={control}
                  name="setor"
                  defaultValue=""
                  rules={{ required: 'Setor é obrigatório!' }}
                />
                <ErrorMessage
                  errors={errors}
                  name="setor"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>

            <Row className="mb-5">
              <Col md="12">
                <label className="form-control-label">Observação</label>
                <Controller
                  as={
                    <Input
                      type="textarea"
                      rows="3"
                      name="observacao"
                      id="observacao"
                    />
                  }
                  control={control}
                  name="observacao"
                  defaultValue=""
                />
              </Col>
            </Row>

            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              Fechar
            </Button>

            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditModal;
