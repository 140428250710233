export const functions = () => {
  const formatDataForDisplay = (defaultFieldsConfig) => {
    const {
      title,
      description,
      telephone,
      current_status,
      reason_id,
      subreason_id,
      service_channel,
      client_id,
      current_responsible,
      email,
      account_id,
      files
    } = defaultFieldsConfig;

    return {
      showTitle: title.isActive,
      requiredTitle: title.isRequired,
      isOnlyToShowOnEditTitle: title.isOnlyToShowOnEdit,
      defaultValueTitle: title.defaultValue,

      showClient: client_id.isActive,
      requiredClient: client_id.isRequired,
      isOnlyToShowOnEditClient: client_id.isOnlyToShowOnEdit,

      showTelephone: telephone.isActive,
      requiredTelephone: telephone.isRequired,
      isOnlyToShowOnEditTelephone: telephone.isOnlyToShowOnEdit,
      defaultValueTelephone: telephone.defaultValue,

      showDescription: description.isActive,
      requiredDescription: description.isRequired,
      isOnlyToShowOnEditDescription: description.isOnlyToShowOnEdit,
      defaultValueDescription: description.defaultValue,

      showReason: reason_id.isActive,
      requiredReason: reason_id.isRequired,
      isOnlyToShowOnEditReason: reason_id.isOnlyToShowOnEdit,
      defaultValueMotive: reason_id.defaultValue,

      showSubReason: subreason_id.isActive,
      requiredSubReason: subreason_id.isRequired,
      isOnlyToShowOnEditSubReason: subreason_id.isOnlyToShowOnEdit,
      defaultValueSubmotive: subreason_id.defaultValue,

      showServiceChannel: service_channel.isActive,
      requiredShowServiceChannel: service_channel.isRequired,
      isOnlyToShowOnEditServiceChannel: service_channel.isOnlyToShowOnEdit,
      defaultValueServiceChannel: service_channel.defaultValue,

      showCurrentStatus: current_status.isActive,
      requiredShowCurrentStatus: current_status.isRequired,
      isOnlyToShowOnEditCurrentStatus: current_status.isOnlyToShowOnEdit,
      defaultValueStatus: current_status.defaultValue,

      showCurrentResponsible: current_responsible.isActive,
      requiredShowCurrentResponsible: current_responsible.isRequired,
      isOnlyToShowOnEditCurrentResponsible:
        current_responsible.isOnlyToShowOnEdit,
      defaultValueResponsible: current_responsible.defaultValue,

      showEmail: email.isActive,
      requiredEmail: email.isRequired,
      isOnlyToShowOnEditEmail: email.isOnlyToShowOnEdit,
      defaultValueEmail: email.defaultValue,

      showAccount: account_id.isActive,
      requiredAccount: account_id.isRequired,
      isOnlyToShowOnEditAccount: account_id.isOnlyToShowOnEdit,
      defaultValueAccount: account_id.defaultValue,

      showFiles: files.isActive,
      requiredFiles: files.isRequired
    };
  };

  const formatDataForSubmit = (data) => {
    return {
      fieldDisplaySettings: {
        title: {
          isActive: data.showTitle,
          isRequired: data.requiredTitle,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditTitle,
          defaultValue: data.defaultValueTitle
        },
        description: {
          isActive: data.showDescription,
          isRequired: data.requiredDescription,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditDescription,
          defaultValue: data.defaultValueDescription
        },
        client_id: {
          isActive: data.showClient,
          isRequired: data.requiredClient,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditClient
        },
        telephone: {
          isActive: data.showTelephone,
          isRequired: data.requiredTelephone,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditTelephone,
          defaultValue: data.defaultValueTelephone
        },
        reason_id: {
          isActive: data.showReason,
          isRequired: data.requiredReason,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditReason,
          defaultValue: data.defaultValueMotive
        },
        subreason_id: {
          isActive: data.showSubReason,
          isRequired: data.requiredSubReason,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditSubReason,
          defaultValue: data.defaultValueSubmotive
        },
        service_channel: {
          isActive: data.showServiceChannel,
          isRequired: data.requiredShowServiceChannel,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditServiceChannel,
          defaultValue: data.defaultValueServiceChannel
        },
        current_status: {
          isActive: data.showCurrentStatus,
          isRequired: data.requiredShowCurrentStatus,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditCurrentStatus,
          defaultValue: data.defaultValueStatus
        },
        current_responsible: {
          isActive: data.showCurrentResponsible,
          isRequired: data.requiredShowCurrentResponsible,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditCurrentResponsible,
          defaultValue: data.defaultValueResponsible
        },
        email: {
          isActive: data.showEmail,
          isRequired: data.requiredEmail,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditEmail,
          defaultValue: data.defaultValueEmail
        },
        account_id: {
          isActive: data.showAccount,
          isRequired: data.requiredAccount,
          isOnlyToShowOnEdit: data.isOnlyToShowOnEditAccount,
          defaultValue: data?.defaultValueAccount?.id,
          defaultRecordType: data?.defaultValueAccount?.defaultRecordType
        },
        files: {
          isActive: data.showFiles,
          isRequired: data.requiredFiles
        }
      }
    };
  };

  return { formatDataForDisplay, formatDataForSubmit };
};
