import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import {
  dateRules,
  reportOptions,
  situationOptions,
  closedByOptions,
  booleanOptions,
  satisfactionOptions,
  channelOptions
} from './assets';
import { ImageWordCloud, ContainerWordCloud } from './styled';
import WordCloudService from './service';
import UserService from '../../../User/service';
import StatusService from '../../../status/service';
import IssueService from '../../../Issue/service';
import ProductService from '../../../Product/service';
import SectorService from '../../../Sector/service';
import QueueService from '../../../Queue/service';
import TagService from '../../../TagsChat/service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import FormTooltip from 'components/Inputs/FormTooltip';

import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';

import { UserFlexSelect } from '../../../../components/UserFlexSelect';

const ConversationReport = () => {
  const { control, handleSubmit, errors, setValue, watch, reset, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [status, setStatus] = useState([]);
  const [issues, setIssues] = useState([]);
  const [products, setProducts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoadWordCloud, setIsLoadWordCloud] = useState(false);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState(reportOptions[0]);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const showDisables = watch('disable');
  const [imageWordCloud, setImageWordCloud] = useState();
  const showAllConversations = localStorage.getItem('show_all_conversations');
  const login = localStorage.getItem('login');

  async function loadWordCloud(data) {
    setIsLoadWordCloud(true);
    setFlag(false);
    await WordCloudService.getWordCloud({
      atendente: data.atendente,
      dataFinal: data.dataFinal,
      dataInicial: data.dataInicial,
      encerradaPor: data.encerradaPor,
      fila: data.fila,
      tag: data.tag,
      nome: data.nome,
      conteudoDaMensagem: data.conteudoDaMensagem,
      situacao: data.situacao,
      atendimentoBot: data.atendimentoBot,
      status: data.status,
      sucessoAtendimento: data.sucessoAtendimento,
      isMobile: data.isMobile,
      satisfacao: data.satisfacao,
      canal: data.canal,
      isContatoAtivo: data.isContatoAtivo
    })
      .then((res) => {
        const img = res.data.nuvemDePalavras;
        setImageWordCloud('data:image/png;base64,' + img);
        setIsLoadWordCloud(false);
        setFlag(false);
      })
      .catch((err) => {
        setFlag(true);
        setIsLoadWordCloud(false);
        console.log(err);
      });
  }

  useEffect(() => {
    if (showDisables === true) {
      QueueService.getAllShowDisable(showDisables)
        .then((response) => {
          setQueues(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    } else {
      QueueService.getAll()
        .then((response) => {
          setQueues(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [showDisables]);

  useEffect(() => {
    getUser(true);
  }, [usersShowDisable]);

  const getUser = (showDisable = false) => {
    if (!showDisable) {
      setLoading(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        setUsers(response.data.data);
        if (!showDisable) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err: ' + err);
        if (!showDisable) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    SectorService.getAll()
      .then((response) => {
        setSectors(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    IssueService.getAll()
      .then((response) => {
        setIssues(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    ProductService.getAll()
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    StatusService.getWithoutPagination()
      .then((response) => {
        setStatus(response.data.content);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    TagService.showTags()
      .then((response) => {
        const { data } = response;
        const { list } = data;

        setTags(list);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  const clearData = () => {
    setImageWordCloud('');
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${YYYY}-${MM}-${DD}`;
  };

  const getDataDTO = (data) => {
    let formatedData;

    formatedData = {
      ...data,
      dataInicial: `${toISOFormat(data.dataInicial)}`,
      dataFinal: `${toISOFormat(data.dataFinal)}`
    };
    if (!formatedData.assunto) delete formatedData.assunto;
    if (!formatedData.setor) delete formatedData.setor;
    if (!formatedData.produto) delete formatedData.produto;
    if (!formatedData.atendente) delete formatedData.atendente;
    if (!formatedData.encerradaPor) delete formatedData.encerradaPor;
    if (!formatedData.fila) delete formatedData.fila;
    if (!formatedData.atendimentoBot) delete formatedData.atendimentoBot;
    if (!formatedData.canal) delete formatedData.canal;
    if (!formatedData.cpf) delete formatedData.cpf;
    if (!formatedData.email) delete formatedData.email;
    if (!formatedData.isMobile) delete formatedData.isMobile;
    if (!formatedData.satisfacao) delete formatedData.satisfacao;
    if (!formatedData.nome) delete formatedData.nome;
    if (!formatedData.situacao) delete formatedData.situacao;
    if (!formatedData.status) delete formatedData.status;
    if (!formatedData.sucessoAtendimento)
      delete formatedData.sucessoAtendimento;
    if (!formatedData.encerradaPor) delete formatedData.encerradaPor;
    if (!formatedData.tag) delete formatedData.tag;
    if (!formatedData.conteudoDaMensagem)
      delete formatedData.conteudoDaMensagem;
    if (!formatedData.telefone) delete formatedData.telefone;
    else {
      formatedData = {
        ...formatedData,
        telefone: formatedData.telefone.replace(/\D+/g, '')
      };
    }

    if (formatedData.group === '' && reportType.value === 2) {
      formatedData = {
        ...formatedData,
        group: 'fila'
      };
    } else {
      if (!formatedData.group) delete formatedData.group;
    }

    delete formatedData.reportType;
    delete formatedData.disable;
    return formatedData;
  };

  const onSubmit = (data) => {
    clearData();

    try {
      const formatedData = getDataDTO(data);
      loadWordCloud(formatedData);
      //const formatedType = getFormatedType(reportType.value);
      //getReports(formatedType, formatedData, 1, true);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const cleanFilter = () => {
    reset({
      dataInicial: new Date().toLocaleDateString('pt-br'),
      dataFinal: new Date().toLocaleDateString('pt-br'),
      fila: '',
      encerradaPor: '',
      group: '',
      atendente: showAllConversations === 'true' ? '' : login,
      nome: '',
      situacao: '',
      atendimentoBot: '',
      status: '',
      sucessoAtendimento: '',
      isMobile: '',
      satisfacao: '',
      canal: '',
      conteudoDaMensagem: ''
    });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Nuvem de Palavras</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row className="mb-3"></Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataInicial"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataInicial"
                              rules={dateRules}
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataInicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Data final*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataFinal"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataFinal"
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                              rules={{
                                ...dateRules,
                                validate: (value) => {
                                  if (
                                    new Date(toISOFormat(value, true)) <
                                    new Date(
                                      toISOFormat(
                                        getValues()['dataInicial'],
                                        true
                                      )
                                    )
                                  ) {
                                    return 'A data final não pode ser anterior à inicial!';
                                  } else {
                                    return true;
                                  }
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataFinal"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="3">
                            <label className="form-control-label">Fila</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={queues}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="fila"
                                  labelName="name"
                                  valueName="name"
                                />
                              )}
                              control={control}
                              name="fila"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">
                              Atendente
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={users}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="atendente"
                                  labelName="name"
                                  valueName="login"
                                  isDisabled={
                                    showAllConversations === 'true'
                                      ? false
                                      : true
                                  }
                                />
                              )}
                              control={control}
                              name="atendente"
                              defaultValue={
                                showAllConversations === 'true' ? '' : login
                              }
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">
                              Encerrado por
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={closedByOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="encerradaPor"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="encerradaPor"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">Tag</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={tags}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="tag"
                                  labelName="name"
                                  valueName="name"
                                />
                              )}
                              control={control}
                              name="tag"
                              defaultValue=""
                            />
                          </Col>
                        </Row>
                        <div>
                          <UserFlexSelect
                            usersShowDisable={usersShowDisable}
                            setUsersShowDisable={setUsersShowDisable}
                            name={'Atendente'}
                          />
                        </div>
                        {advancedSearch && (
                          <>
                            <Row className="mb-3 mt-5">
                              <Col>
                                <h3 className="mb-1">Configurações</h3>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="9">
                                <label className="form-control-label">
                                  Conteúdo das mensagens
                                  <FormTooltip
                                    text="Este campo de busca deve ser utilizado para filtrar parte ou toda mensagem de uma conversa.
                                    Assim sendo, muito útil para filtrar informações como: CPF, CNPJ, Número de pedido entre outros."
                                  />
                                </label>
                                <Controller
                                  as={Input}
                                  control={control}
                                  name="conteudoDaMensagem"
                                  defaultValue=""
                                  placeholder="informações da mensagem na conversa(s)"
                                />
                              </Col>
                              <Col>
                                <label className="form-control-label">
                                  É Contato ativo?
                                </label>
                                <Controller
                                  render={(props) => (
                                    <FlexSelect
                                      dataOptions={booleanOptions}
                                      isMulti={false}
                                      isClearable={true}
                                      value={props.value}
                                      valueController={setValue}
                                      closeMenuOnSelect={true}
                                      fieldName="isContatoAtivo"
                                      labelName="label"
                                      valueName="value"
                                    />
                                  )}
                                  control={control}
                                  name="isContatoAtivo"
                                  defaultValue=""
                                />
                              </Col>
                            </Row>

                            {reportType.value === 1 ? (
                              <>
                                <Row className="mb-3">
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Nome do cliente
                                    </label>
                                    <Controller
                                      as={Input}
                                      control={control}
                                      name="nome"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Situação
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={situationOptions}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="situacao"
                                          labelName="label"
                                          valueName="value"
                                        />
                                      )}
                                      control={control}
                                      name="situacao"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Atendimento Bot
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={booleanOptions}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="atendimentoBot"
                                          labelName="label"
                                          valueName="value"
                                        />
                                      )}
                                      control={control}
                                      name="atendimentoBot"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={status}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="status"
                                          labelName="name"
                                          valueName="name"
                                        />
                                      )}
                                      control={control}
                                      name="status"
                                      defaultValue=""
                                    />
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Sucesso Atendimento do Bot
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={booleanOptions}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="sucessoAtendimento"
                                          labelName="label"
                                          valueName="value"
                                        />
                                      )}
                                      control={control}
                                      name="sucessoAtendimento"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Somente acessos por celular
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={booleanOptions}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="isMobile"
                                          labelName="label"
                                          valueName="value"
                                        />
                                      )}
                                      control={control}
                                      name="isMobile"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Satisfação
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={satisfactionOptions}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="satisfacao"
                                          labelName="label"
                                          valueName="value"
                                        />
                                      )}
                                      control={control}
                                      name="satisfacao"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Canal
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={channelOptions}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="canal"
                                          labelName="label"
                                          valueName="value"
                                        />
                                      )}
                                      control={control}
                                      name="canal"
                                      defaultValue=""
                                    />
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <Row className="mb-3">
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Assunto
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={issues}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="assunto"
                                          labelName="nome"
                                          valueName="nome"
                                        />
                                      )}
                                      control={control}
                                      name="assunto"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Situação
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={situationOptions}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="situacao"
                                          labelName="label"
                                          valueName="value"
                                        />
                                      )}
                                      control={control}
                                      name="situacao"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Atendimento Bot
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={booleanOptions}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="atendimentoBot"
                                          labelName="label"
                                          valueName="value"
                                        />
                                      )}
                                      control={control}
                                      name="atendimentoBot"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <label className="form-control-label">
                                      Fila
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={queues}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="fila"
                                          labelName="name"
                                          valueName="name"
                                        />
                                      )}
                                      control={control}
                                      name="fila"
                                      defaultValue=""
                                    />
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Col md="4">
                                    <label className="form-control-label">
                                      Sucesso Atendimento do Bot
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={booleanOptions}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="sucessoAtendimento"
                                          labelName="label"
                                          valueName="value"
                                        />
                                      )}
                                      control={control}
                                      name="sucessoAtendimento"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="4">
                                    <label className="form-control-label">
                                      Setor
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={sectors}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="setor"
                                          labelName="name"
                                          valueName="name"
                                        />
                                      )}
                                      control={control}
                                      name="setor"
                                      defaultValue=""
                                    />
                                  </Col>
                                  <Col md="4">
                                    <label className="form-control-label">
                                      Produto
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={products}
                                          isMulti={false}
                                          isClearable={true}
                                          value={props.value}
                                          valueController={setValue}
                                          closeMenuOnSelect={true}
                                          fieldName="produto"
                                          labelName="nome"
                                          valueName="nome"
                                        />
                                      )}
                                      control={control}
                                      name="produto"
                                      defaultValue=""
                                    />
                                  </Col>
                                </Row>
                              </>
                            )}
                          </>
                        )}

                        <Row className="mb-3">
                          <Col md="2">
                            <>
                              <label
                                className="form-control-label"
                                style={{ marginLeft: '-15px' }}
                              >
                                <ToggleSwitch
                                  checked={advancedSearch}
                                  id="advancedSearch"
                                  onChange={() =>
                                    setAdvancedSearch(!advancedSearch)
                                  }
                                />
                                Busca avançada
                              </label>
                            </>
                          </Col>

                          {advancedSearch === true && (
                            <Col md="2">
                              <Controller
                                name="disable"
                                control={control}
                                render={(props) => (
                                  <>
                                    <label
                                      className="form-control-label"
                                      style={{ marginLeft: '20px' }}
                                    >
                                      <Input
                                        type="checkbox"
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                        checked={props.value}
                                      />
                                      Mostrar desabilitados (Filas)
                                    </label>
                                  </>
                                )}
                                defaultValue={false}
                              />
                            </Col>
                          )}
                        </Row>

                        <Button
                          style={buttonStyle(systemConfiguration.primary_color)}
                          type="submit"
                          disabled={isLoadWordCloud}
                          //onClick={() => loadWordCloud()}
                        >
                          {isLoadWordCloud ? (
                            <Row className="justify-content-md-center">
                              <Loader
                                type="TailSpin"
                                color={'white'}
                                height={18}
                                width={78}
                              />
                            </Row>
                          ) : (
                            'Buscar'
                          )}
                        </Button>
                        <Button
                          onClick={cleanFilter}
                          style={buttonStyle(
                            systemConfiguration.cancelation_button_color
                          )}
                        >
                          Limpar
                        </Button>
                      </Form>
                    </>
                  )}

                  {isLoadWordCloud ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    imageWordCloud && (
                      <Row>
                        <Col md="12">
                          <ContainerWordCloud>
                            <ImageWordCloud src={imageWordCloud} />
                          </ContainerWordCloud>
                        </Col>
                      </Row>
                    )
                  )}
                  {!imageWordCloud && flag == true && (
                    <Row>
                      <Col md="12">
                        <ContainerWordCloud>
                          <p>Conversas não encontradas!</p>
                        </ContainerWordCloud>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConversationReport;
