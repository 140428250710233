import styled from 'styled-components';

export const ActionDiv = styled.div`
  flex: 1;
  text-align: center;
  color: #64748b;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: 7px;
  margin-top: ${(props) => (props.answer ? '10px' : '')};
  margin-bottom: 10px;
  gap: 4px;
`;

export const ActionCenterDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const LineActionDiv = styled.div`
  display: flex;
  background-color: #cbd5e1;
  width: 100px;
  height: 1px;
  flex: 1;
`;
