import React, { useMemo, useEffect, createContext, useContext } from 'react';
import io from 'socket.io-client';
import store from '../store';
import {
  addUnAnsweredConversation,
  removeUnAnsweredConversation
} from 'store/action/unanswered-convertations-action';
import { updateContactNameOnConversation } from 'store/action/contact-events-actions';
import { removeAnsweredConversation } from 'store/action/answered-conversations-action';
import { checkRemoveConversationInAttendance } from 'store/action/conversation-in-attendance-action';

const useSocketAdmConversationContext = createContext();

export function SocketAdmConversationProvider({ children }) {
  const socketAdm = useMemo(() => {
    return io.connect(process.env.REACT_APP_PROXY_URL, {
      path: process.env.REACT_APP_CHANNEL_ADM_SOCKET_PATH,
      transports: ['websocket'],
      upgrade: false
    });
  }, []);

  useEffect(() => {
    return () => {
      socketAdm.close();
    };
  }, [socketAdm]);

  useEffect(() => {
    socketAdm.on('entrou_na_fila', (data) => {
      const attendantQueues = JSON.parse(
        localStorage.getItem('queues') ?? '[]'
      );
      attendantQueues.forEach((attendantQueue) => {
        if (data.conversa?.fila && data.conversa.fila === attendantQueue.name) {
          store.dispatch(addUnAnsweredConversation(data.conversa));
        }
      });
    });
  }, [socketAdm]);

  useEffect(() => {
    socketAdm.on('remove_conversa_fila', async (data) => {
      await store.dispatch(removeUnAnsweredConversation(data));
    });
  }, [socketAdm]);

  useEffect(() => {
    socketAdm.on('contact_update_name', async (data) => {
      await store.dispatch(updateContactNameOnConversation(data));
    });
  }, [socketAdm]);

  return (
    <>
      <useSocketAdmConversationContext.Provider
        value={{
          socketAdm: socketAdm
        }}
      >
        {children}
      </useSocketAdmConversationContext.Provider>
    </>
  );
}

export function useConectionSocketAdmConversation() {
  const context = useContext(useSocketAdmConversationContext);
  return context;
}
