import ServiceCRUD from '../../../../../../../../services/ServiceCRUDCHANNEL';

import { apiInstagramChat as http } from '../../../../../../../../http';

const formatDate = (rangeDate) => {
  return rangeDate.toLocaleDateString('pt-br').split('/').reverse().join('-');
};
class FlexChatIntegrationService extends ServiceCRUD {
  constructor() {
    super('configurations/channels');
  }
  startActiveContactConversationMedia(formatedImage) {
    return http.post('/startActiveContactMidia', formatedImage, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }
  startActiveConversationWithoutContactMedia(formatedImage) {
    return http.post('/startClientActiveMedia', formatedImage, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }
  sendTemplateMessage(conversationId, messageData) {
    return http.post(`/conversa/atendente-enviar-template`, {
      chatId: conversationId,
      messageId: messageData.id,
      params: messageData.params,
      message: messageData.message
    });
  }
  sendTemplateMediaMessage(formatedImage) {
    return http.post(
      `/conversa/atendente-enviar-template-media`,
      formatedImage,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
  }
  startActiveContactConversation(
    contactId,
    usrSocketId,
    messageData,
    queue,
    clientId,
    idSourcePhone = '',
    storeValuesContact,
    template,
    clientFields
  ) {
    return http.post('/startActiveContact', {
      contactId,
      usrSocketId,
      templateId: messageData.id,
      template: template,
      message: messageData.message,
      params: messageData.params,
      queue: queue,
      clientId,
      idSourcePhone,
      userLogin: localStorage.getItem('login'),
      storeValuesContact: storeValuesContact,
      clientFields: clientFields
    });
  }

  sendIsolatedMessage(data) {
    return http.post('/sendIsolatedMessage', data);
  }
  getAllChannels() {
    return http.get(`/configurations/channels`);
  }
  getTemplatesAll() {
    return http.get(`templates`, {
      params: {
        userLogin: localStorage.getItem('login')
      }
    });
  }
  getTemplateById(id) {
    return http.get(`templates/${id}`);
  }
  getContatoAtivo(id) {
    return http.get(`templates/whatsapp/${id}`, {
      params: {
        userLogin: localStorage.getItem('login')
      }
    });
  }
  getContatoAtivoByPhone(number, isLimited = true) {
    return http.get(`/templates/whatsapp/number/${number}`, {
      params: {
        userLogin: localStorage.getItem('login'),
        isLimited
      }
    });
  }
  uploadFile = (id, data) => {
    return http.post(`/whatsapp/send-message/midia/${id}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  };
  uploadFileWebchat = (data) => {
    return http.post(`/webchat/send-message/midia/`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  };
  uploadFileFlex4U = (data) => {
    return http.post(`/flex4u/send-message/midia/`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  };
  async getExcel(params, showDisabled = false) {
    return http.get('/report/broadcastlist/export/excel', {
      params: { ...params, showDisabled },
      responseType: 'arraybuffer'
    });
  }
  async getCsv(params, showDisabled) {
    return http.get('/report/broadcastlist/export/csv', {
      params: { ...params, showDisabled }
    });
  }
  async getPdf(params, showDisabled) {
    return http.get('/report/broadcastlist/export/pdf', {
      params: { ...params, showDisabled },
      responseType: 'blob'
    });
  }
  getWhatsapp() {
    return http.get(`/configurations/whatsapp`);
  }
  syncTemplates() {
    return http.get(`templates/syncronize`);
  }
  postBroadcastList(data) {
    return http.post(`/broadcast-list`, data);
  }
  putBroadcastList(id, data) {
    return http.put(`/broadcast-list/${id}`, data);
  }
  updateBroadcastWithMidia(data, id) {
    return http.put(`broadcast-list/midia/${id}`, data, {
      headers: { 'content-type': 'multipart/form-data' }
    });
  }
  getBroadcastList(showDisabledTransmissionLists = false) {
    return http.get('broadcast-list/all', {
      params: { showDisabled: showDisabledTransmissionLists }
    });
  }
  getBroadcastListById(id) {
    return http.get(`broadcast-list/${id}`);
  }
  getBroadcastListPagination(page, showDisabled, title = '') {
    return http.get('broadcast-list', {
      params: {
        page: page,
        showDisabled,
        title
      }
    });
  }

  createBroadcastWithMidia(data) {
    let newFormData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        newFormData.append(key, data[key]);
      }
    }

    return http.post('broadcast-list/midia', data, {
      headers: { 'content-type': 'multipart/form-data' }
    });
  }
  executeBroadcastList(data) {
    return http.post('/broadcast-list/execute', {
      ...data,
      userLogin: localStorage.getItem('login')
    });
  }
  getAllParams = (
    page = 1,
    size = 10,
    headers = {},
    reportType = 1,
    showDisabled = false
  ) => {
    return http.get(
      `/report/broadcastlist/${
        reportType === 1 ? 'analytical' : 'synthetic'
      }?page=${page}&size=${size}`,
      {
        params: {
          ...headers,
          showDisabled
        }
      }
    );
  };
  changeBroadcastListStatus(id) {
    return http.put(`/broadcast-list/change-status/${id}`);
  }
  getAllDialogTree = () => {
    return http.get('/dialog/chat/resume/all');
  };
  getAllSearchDialogTree = (page, size, query) => {
    if (query !== '') {
      return http.get(`/dialog/chat?name=${query}`, {
        headers: {
          limit: size,
          offset: size * (page - 1)
        }
      });
    } else {
      return http.get(`/dialog/chat`, {
        headers: {
          limit: size,
          offset: size * (page - 1)
        }
      });
    }
  };
  getTreeById = (id) => {
    return http.get(`/dialog/chat/${id}`);
  };
  putDialogTree = (id, data) => {
    return http.put(`/dialog/chat/${id}`, data);
  };
  postDialogTree = (data) => {
    return http.post(`/dialog/chat`, data);
  };
  getActionDialogTree = () => {
    return http.get(`/dialog/chat/action`);
  };
  getDialogTreeResume = () => {
    return http.get(`/dialog/chat/resume/all`);
  };
  getWatsonResume = () => {
    return http.get(`/configurations/watson`);
  };
  getTotal(rangeDate) {
    return http.get(
      `/dashboard/total?dataInicial=${formatDate(
        rangeDate.start
      )}&dataFinal=${formatDate(rangeDate.end)}`
    );
  }
  async getStatusClientSession(clientNumber) {
    const uri = `/client/status/${clientNumber}`;
    const standardSizePhoneWithNine = 13;

    let response = await http.get(uri);

    if (!response.data && clientNumber.length === standardSizePhoneWithNine) {
      const clientNumberWithoutNine =
        clientNumber.substr(0, 4) + clientNumber.substr(5, 13);
      response = await FlexChatIntegrationService.getStatusClientSession(
        clientNumberWithoutNine
      );
    }
    return !!response.data;
  }
}

export default new FlexChatIntegrationService();
