import ServicePanel from './views/ServicePanel';

import ProtocolList from './views/Protocol/list';

import StatusList from './views/status/list';
import StatusForm from './views/status/form';

import UserList from './views/User/list';
import UserForm from './views/User/form';
import UserFormMulti from './views/User/form/multiple_users/formMultiple';

import EmailSettingsList from './views/EmailSettings/list';
import EmailSettingsForm from './views/EmailSettings/form';

import SystemConfig from './views/SystemParams/form';

import { Login } from './views/softphone/pages/login/index';

import UserProfile from './views/UserProfile/form';

import ReasonList from './views/reason/list';
import ReasonForm from './views/reason/form';

import SubReasonList from './views/subReason/list';
import SubReasonForm from './views/subReason/form';

import FieldsConfigList from './views/fieldConfigs/list';
import FieldsConfigForm from './views/fieldConfigs/form';

import UnifiedDashboard from './views/Dashboard/Unified';
import ChatDashboard from './views/Dashboard/Chat';
import AgentDashboard from './views/Dashboard/Agent';

import IssueList from './views/Issue/list';
import IssueForm from './views/Issue/form';
import Configuration from './views/configuration';

import ProductList from './views/Product/list';
import ProductForm from './views/Product/form';

import PredefinedMessageList from './views/PredefinedMessage/list';
import PredefinedMessageForm from './views/PredefinedMessage/form';

import QueueList from './views/Queue/list';
import QueueForm from './views/Queue/form';

import ExtensionList from './views/Extension/list';
import ExtensionForm from './views/Extension/form';
import ExtensionFormMulti from './views/Extension/form/multiple_extensions/formMultiple';

import SectorList from './views/Sector/list';
import SectorForm from './views/Sector/form';

import DdrList from './views/Ddrs/list';
import DdrForm from './views/Ddrs/form';

import VoiceMailList from './views/VoiceMail/list';
import VoiceMailForm from './views/VoiceMail/form';

import SpyList from './views/Spy/list';
import SpyForm from './views/Spy/form';

import ContextList from './views/Context/list';
import ContextForm from './views/Context/form';

import AudioList from './views/Audio/list';
import AudioForm from './views/Audio/form';

import IVRList from './views/IVR/list';
import IVRForm from './views/IVR/form';

import CallbackList from './views/Callback/list';
import CallbackForm from './views/Callback/form';

import ChatDialogTreeList from './views/ChatDialogTree/list';
import ChatDialogTreeForm from './views/ChatDialogTree/form';

import QueueMessageList from './views/QueueMessage/list';
import QueueMessageForm from './views/QueueMessage/form';

import ConversationReportChat from 'views/Report/Chat/Conversation';
import WordCloud from './views/Report/Chat/WordCloud';
import WordCloudByScore from './views/Report/Chat/WordCloudByScore';
import DashboardDataBot from './views/Report/Chat/DashboardDataBot';
import HeatMap from './views/Report/Chat/HeatMap';
import ConversationAnalytics from './views/Report/Chat/ConversationAnalytics';

import ConversationList from 'views/Conversation/list';

import PauseReportChat from './views/Report/Chat/Pause';

import PerformanceReportChat from './views/Report/Chat/Performance';

import ExitRouteList from './views/ExitRoute/list';
import ExitRouteForm from './views/ExitRoute/form';

import InboundRouteList from './views/InboundRoute/list';
import InboudRouteForm from './views/InboundRoute/form';

import PauseList from './views/Pause/list';
import PauseForm from './views/Pause/form';

import ChiefSecretaryList from './views/ChiefSecretary/list';
import ChiefSecretaryForm from './views/ChiefSecretary/form';

import facilityRouteList from './views/Facilityroute/list';
import facilityRouteForm from './views/Facilityroute/form';

import blackListList from './views/BlackList/list';
import blackListForm from './views/BlackList/form';

import PJSIPConfiguration from './views/PJSIPConfiguration/list';
import PJSIPConfigurationForm from './views/PJSIPConfiguration/form';

import TrunkList from './views/Trunk/list';
import TrunkFormIax2 from './views/Trunk/FormIAX2Trunk';
import TrunkFormPjsip from './views/Trunk/FormPJSIPTrunk';
import TrunkFormDigital from './views/Trunk/FormDigitalTrunk';
import TrunkFormAnalogical from './views/Trunk/FormAnalogicalTrunk';

import SpyGrouList from './views/SpyGroup/list';
import SpyGrouForm from './views/SpyGroup/form';

import GroupExitRouteList from './views/GroupExitRoute/list';
import GroupExitRouteForm from './views/GroupExitRoute/form';

import TimeScheduleList from './views/TimeSchedule/list';
import TimeScheduleForm from './views/TimeSchedule/form';

import ProfileList from './views/Profile/list';
import ProfileForm from './views/Profile/form';

import TransshipmentList from './views/Transshipment/list';
import TransshipmentForm from './views/Transshipment/form';

import DialerList from './views/Dialer/list';
import DialerDashboard from './views/Dialer/list/dashboard';
import DialerForm from './views/Dialer/form';

import InternalRouteList from './views/InternalRoute/list';
import InternalRouteForm from './views/InternalRoute/form';

import PerformanceReportCallCenter from './views/Report/Callcenter/Perfomance';

import PauseReportCallCenter from './views/Report/Callcenter/Pause';

import ReceivedCallReportCallCenter from './views/Report/Callcenter/ReceivedCall';

import AuthenticationReportCallcenter from './views/Report/Callcenter/Autentication';

import CallMadeReportCallCenter from './views/Report/Callcenter/CallMade';

import ReceivedCallReportPBXIP from './views/Report/PBXIP/ReceivedCall';

import CallMadeReportPBXIP from './views/Report/PBXIP/CallMade';

import DialerReportAdvanced from './views/Report/Advanced/Dialer';

import CallbackReportAdvanced from './views/Report/Advanced/Callback';

import IVRReportAdvanced from './views/Report/Advanced/IVR';

// import AuditReport from './views/Report/Audit/AuditMain/form';
import AuditReportAutentication from './views/Report/Audit/Authentication';

//import AnalyticalReportOmnichannel from './views/Report/Omnichannel/Analytical';
import PerformanceReportOmnichannel from './views/Report/Omnichannel/Performance';
//import SyntheticReportOmnichannel from './views/Report/Omnichannel/Synthetic';

import QueueToMonitor from './views/Monitoring/Queue';
import QueueMonitoring from './views/Monitoring/Queue/MonitoringScreen';

// import ExtensionsMonitoring from './views/Monitoring/PBXIP';

import SupervisorPanel from './views/Monitoring/SupervisorPanel';
import ExtensionsMonitoring from './views/Monitoring/Extension';

import ConversationChatMonitoring from './views/Monitoring/Chat/conversation';

import ChatConfig from './views/ChatConfiguration/index';

import protocolUpdateForm from './views/Protocol/updateForm';
import ProtocolForm from './views/Protocol/ExternalProtocolWindow';
import ProtocolConfiguration from './views/ProtocolConfiguration';

import { RecordTypeList } from './views/RecordType/list';
import { RecordTypeForm } from './views/RecordType/form';

import TagsList from './views/SatisfactionSurveyLevel/Tags/list';
import ConfigSurveyList from './views/SatisfactionSurveyLevel/ConfigSurvey/list';
import ConfigSurveyForm from './views/SatisfactionSurveyLevel/ConfigSurvey/form';
import AnalyticalReportSatisfactionSurvey from './views/Report/SatisfactionSurvey/Analytical';
import SinteticalReportSatisfactionSurvey from './views/Report/SatisfactionSurvey/Sinthetical';

import DashboardCallCenter from './views/Dashboard/CallCenter';

import { FormShutdownCause } from './views/ShutdownCause/form';
import { ListShutdownCause } from './views/ShutdownCause/list';

import LevelServiceConfiguration from './views/levelServiceConfiguration';

import TagsChatList from './views/TagsChat/list';

import ChatGenerationLink from './views/ChatGenerationLink';

import { ChatBroadcastListList } from './views/ChatBroadcastList/list';
import { ChatBroadcastListListSMS } from './views/ChatBroadcastListSMS/list';
import { ChatBroadcastListForm } from 'views/ChatBroadcastList/form';
import { ChatBroadcastListSMSForm } from 'views/ChatBroadcastListSMS/form';

import ProtocolAnalyticalReport from './views/Report/Protocol/Analytical';

import { IntegrationUrlList } from './views/IntegrationUrl/list';
import { IntegrationUrlForm } from './views/IntegrationUrl/form';

import ContactCRM from './views/CRM/Contact/list/';
import ContactForm from './views/CRM/Contact/form';
import ContactDetails from 'views/CRM/Contact/form/ContactDetails';

import LeadListIndex from './views/CRM/Lead/list';
import LeadForm from './views/CRM/Lead/form';

import LeadStages from './views/CRM/LeadStages/list/index';
import LeadStagesForm from './views/CRM/LeadStages/form';

import LeadProcessList from './views/CRM/LeadProcess/list';
import LeadProcessForm from './views/CRM/LeadProcess/form';
import CallbackDashboard from './views/Callback/dashboard';

import LeadEdit from './views/CRM/Lead/form/LeadDetails';

import AccountList from 'views/CRM/Account/list';
import AccountForm from 'views/CRM/Account/form';
import AccountDetails from 'views/CRM/Account/form/AccountDetails';
import OpportunityStages from 'views/CRM/OpportunityStages/list';
import OpportunityStagesForm from 'views/CRM/OpportunityStages/form';

import SalesProcessList from './views/CRM/SalesProcess/list';
import SalesProcessForm from './views/CRM/SalesProcess/form';

import OpportunityListIndex from './views/CRM/Opportunity/list';
import OpportunityForm from './views/CRM/Opportunity/form';
import OpportunityEdit from './views/CRM/Opportunity/form/OpportunityEdit/list';
import BotFlow from './views/BotFlow';
import BotFlowForm from './views/BotFlow/form';

import Paper from './views/Paper/pages/Paper';
import PaperForm from './views/Paper/pages/CreatePaper';
import PaperEdit from './views/Paper/pages/EditPaper';

import QualityMonitoringReport from './views/Report/QualityMonitoring';

//Sistema de Escalas

import ScaleList from './views/Scales/list';
import ScaleForm from './views/Scales/form';

import DayOffForm from './views/DayOff/form';
import DayOffList from './views/DayOff/list';

import ExtraHourList from './views/ExtraHour/list';
import SupervisorExtraHour from './views/SupervisorExtraHour/list';

import CalendarViewScales from './views/CalendarScales/index';

import AllNotification from './views/AllNotification/';

import ChangeDayOffList from './views/ChangeDayOff/list';
import ChangeDayOffSupervisorList from './views/ChangeDayOffSupervisor/list';

import LeadDashboard from 'views/Dashboard/CRM/components/Leads';
import OpportunityDashboard from 'views/Dashboard/CRM/components/Opportunities';

import TeamList from './views/Team/list';
import TeamForm from './views/Team/form';

import { ScalesReport } from 'views/Report/Scale';
import { ScalesReportTeams } from 'views/Report/ScalesTeams';

import { TransmissionListReport } from 'views/Report/transmissionList';
import { TransmissionListReportSMS } from 'views/Report/broadcastListSMS';
import { BroadcastListDashboard } from 'views/ChatBroadcastList/list/components/dashboard';
import { SMSBroadcastListDashboard } from 'views/ChatBroadcastListSMS/list/components/dashboard';
import MapFieldsList from 'views/CRM/MapFields/list';
import ChatList from 'views/Chat/list';
import ChatForm from 'views/Chat/form';
import WatsonList from 'views/Watson/list';
import WatsonForm from 'views/Watson/form';

import ProtocolProcessList from './views/CRM/ProtocolProcess/list';
import ProtocolProcessForm from './views/CRM/ProtocolProcess/form';
import LeadsReportClosedWonIndex from './views/CRM/Report/ConvertedReport/index';
import ReportLeadTImeIndex from './views/CRM/Report/TimeReport/list/Lead/index';
import ReportOpportunityTimeIndex from './views/CRM/Report/TimeReport/list/Opportunity/index';
import ReportOpportunityRevenueIndex from 'views/CRM/Report/RevenueReport/list/Opportunity';
import TagsClientList from 'views/CRM/Tags/list';
import ChatBotReport from 'views/Report/Advanced/ChatBot';

const routes = [
  {
    path: '/chat/generation/link',
    name: 'Geração de Link do chat',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ChatGenerationLink,
    layout: '/admin',
    sidebar: false,
    roles: ['LINK_CHAT']
  },

  {
    path: '/chat/broadcast-list',
    name: 'List Campanha Ativa Whatsapp',
    exact: true,
    component: ChatBroadcastListList,
    layout: '/admin',
    sidebar: false,
    roles: ['BROADCAST_LIST']
  },

  {
    path: '/chat/broadcast-list/form',
    name: 'Campanha Ativa Whatsapp',
    exact: true,
    component: ChatBroadcastListForm,
    layout: '/admin',
    sidebar: false,
    roles: ['BROADCAST_LIST']
  },

  {
    path: '/chat/broadcast-list-sms/form',
    name: 'Campanha Ativa SMS',
    exact: true,
    component: ChatBroadcastListSMSForm,
    layout: '/admin',
    sidebar: false,
    roles: ['BROADCAST_LIST_SMS']
  },

  {
    path: '/chat/broadcast-list/form/:id',
    name: 'Editar Campanha Ativa Whatsapp',
    exact: true,
    component: ChatBroadcastListForm,
    layout: '/admin',
    sidebar: false,
    roles: ['BROADCAST_LIST']
  },

  {
    path: '/chat/broadcast-list-sms/form/:id',
    name: 'Campanha Ativa SMS',
    exact: true,
    component: ChatBroadcastListSMSForm,
    layout: '/admin',
    sidebar: false,
    roles: ['BROADCAST_LIST_SMS']
  },

  {
    path: '/chat/tags',
    name: 'Tags do chat',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: TagsChatList,
    layout: '/admin',
    sidebar: false,
    roles: ['TAG_CHAT']
  },

  {
    path: '/systemConfig/levelService',
    name: 'Configuração de pesquisa',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: LevelServiceConfiguration,
    layout: '/admin',
    sidebar: false,
    roles: ['SERVICE_LEVEL']
  },

  {
    path: '/shutdown/cause',
    name: 'Causa de encerramento',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ListShutdownCause,
    layout: '/admin',
    sidebar: false,
    roles: ['HANGUP_CAUSE']
  },
  {
    path: '/shutdown/cause/form',
    name: 'Causa de encerramento',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: FormShutdownCause,
    layout: '/admin',
    sidebar: false,
    roles: ['HANGUP_CAUSE']
  },
  {
    path: '/shutdown/cause/form/:id',
    name: 'Motivo',
    exact: false,
    icon: 'ni ni-archive-2 text-green',
    component: FormShutdownCause,
    layout: '/admin',
    sidebar: false,
    roles: ['HANGUP_CAUSE']
  },
  //depois criar role referente a config de protocolo no back
  {
    path: '/protocol/config',
    name: 'Configuração de protocolo',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ProtocolConfiguration,
    layout: '/admin',
    sidebar: false,
    roles: ['CONFIG_PROTOCOL']
  },
  {
    path: '/recordType',
    name: 'Grupos de protocolo',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: RecordTypeList,
    layout: '/admin',
    sidebar: false,
    roles: ['RECORD_TYPES']
  },
  {
    path: '/recordType/form',
    name: 'Criação de grupos',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: RecordTypeForm,
    layout: '/admin',
    sidebar: false,
    roles: ['RECORD_TYPES']
  },
  {
    path: '/recordType/form/:id',
    name: 'Edição de grupos',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: RecordTypeForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CONFIG_PROTOCOL'] // TEMP
  },
  {
    path: '/configSurvey/list',
    name: 'Configuração de pesquisa',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ConfigSurveyList,
    layout: '/admin',
    sidebar: false,
    roles: ['SURVEY_CONFIGURATION']
  },
  {
    path: '/teste/teste',
    name: 'teste',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: Login,
    layout: '/admin',
    sidebar: false
  },
  {
    path: '/configSurvey/form',
    name: 'Configuração de pesquisa',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ConfigSurveyForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SURVEY_CONFIGURATION']
  },
  {
    path: '/configSurvey/form/:id',
    name: 'Configuração de pesquisa',
    exact: false,
    icon: 'ni ni-archive-2 text-green',
    component: ConfigSurveyForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SURVEY_CONFIGURATION']
  },
  {
    path: '/tags',
    name: 'Tags',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: TagsList,
    layout: '/admin',
    sidebar: false,
    roles: ['SURVEY_TAGS']
  },
  {
    path: '/configChat',
    name: 'Configuração chat',
    exact: true,
    icon: 'ni ni-chart-pie-35 text-info',
    component: ChatConfig,
    layout: '/admin',
    sidebar: false,
    roles: ['CHAT_CONFIGURATION']
  },
  {
    path: '/botFlow',
    name: 'Fluxo do bot',
    exact: true,
    icon: 'ni ni-chart-pie-35 text-info',
    component: BotFlow,
    layout: '/admin',
    sidebar: false,
    roles: ['CHAT_CONFIGURATION']
  },
  {
    path: '/botFlow/form',
    name: 'Configuração de pesquisa',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: BotFlowForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CHAT_CONFIGURATION']
  },
  {
    path: '/botFlow/form/:id',
    name: 'Configuração de pesquisa',
    exact: false,
    icon: 'ni ni-archive-2 text-green',
    component: BotFlowForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CHAT_CONFIGURATION']
  },

  {
    collapse: true,
    name: 'Dashboards',
    state: 'componentsDashboard',
    icon: 'ni ni-chart-pie-35 text-info',
    roles: ['ALL'],
    views: [
      {
        path: '/dashboard',
        name: 'Inicial',
        component: UnifiedDashboard,
        layout: '/admin',
        sidebar: true,
        exact: true,
        miniName: 'IN',
        roles: ['DASHBOARD']
      },
      {
        path: '/dashboard/callcenter',
        name: 'CallCenter',
        component: DashboardCallCenter,
        layout: '/admin',
        sidebar: true,
        exact: true,
        miniName: 'CC',
        roles: ['CALLCENTER_DASHBOARD']
      },
      {
        path: '/dashboard/PBXIP',
        name: 'PBXIP',
        component: DashboardCallCenter,
        layout: '/admin',
        sidebar: true,
        exact: true,
        miniName: 'PX',
        roles: ['CALLCENTER_DASHBOARD'] // criar a role
      },
      {
        collapse: true,
        sidebar: true,
        name: 'Chat',
        state: 'componentDash',
        miniName: 'CH',
        roles: ['CHAT_DASHBOARD'],
        views: [
          {
            path: '/dashboard/chat',
            name: 'Dados de Atendimento',
            component: ChatDashboard,
            layout: '/admin',
            sidebar: true,
            exact: true,
            roles: ['CHAT_DASHBOARD']
          },
          {
            path: '/dashboard/dashboarddatabot',
            name: 'Dados do Chatbot - Whatsapp',
            component: DashboardDataBot,
            layout: '/admin',
            sidebar: true,
            roles: ['CHAT_DASHBOARD']
          }
        ]
      },
      {
        path: '/dashboard/agent',
        name: 'Atendente',
        component: AgentDashboard,
        layout: '/admin',
        sidebar: true,
        exact: true,
        miniName: 'AT',
        roles: ['AGENT_DASHBOARD']
      },
      {
        collapse: true,
        sidebar: true,
        name: 'CRM',
        state: 'dashboardCRM',
        miniName: 'CRM',
        roles: ['AGENT_CRM_DASHBOARD'],
        views: [
          {
            path: '/dashboard/crm/leads',
            name: 'Leads',
            component: LeadDashboard,
            layout: '/admin',
            sidebar: true,
            exact: true,
            roles: ['AGENT_CRM_DASHBOARD']
          },
          {
            path: '/dashboard/crm/opportunities',
            name: 'Oportunidades',
            component: OpportunityDashboard,
            layout: '/admin',
            sidebar: true,
            exact: true,
            roles: ['AGENT_CRM_DASHBOARD']
          }
        ]
      }
    ],
    sidebar: true
  },
  {
    path: '/service_panel',
    name: 'Atendimento',
    exact: true,
    icon: 'fas fa-comment-alt text-green',
    component: ServicePanel,
    layout: '/admin',
    sidebar: true,
    roles: ['ATTENDANCE_PANEL']
  },
  {
    path: '/service_panel/:id',
    name: 'Atendimento',
    exact: true,
    icon: 'fas fa-comment-alt text-green',
    component: ServicePanel,
    layout: '/admin',
    sidebar: false,
    roles: ['ATTENDANCE_PANEL']
  },
  {
    path: '/protocol',
    name: 'Protocolo',
    exact: true,
    icon: 'fas fa-headset text-green',
    component: ProtocolList,
    layout: '/admin',
    sidebar: true,
    roles: ['LIST_PROTOCOL']
  },
  {
    path: '/createProtocol',
    name: 'Protocolo',
    exact: true,
    icon: 'fas fa-headset text-green',
    component: ProtocolForm,
    layout: '/protocol',
    sidebar: false,
    roles: ['CREATE_PROTOCOL']
  },
  {
    collapse: true,
    name: 'Monitoramento',
    state: 'componentsCollapseMonit',
    icon: 'ni ni-chart-bar-32 text-pink',
    roles: ['MONITORING_CALLCENTER', 'MONITORING_PBXIP', 'CHATMONITORING'],
    views: [
      {
        collapse: true,
        name: 'CallCenter',
        miniName: 'CC',
        sidebar: true,
        state: 'componentsCollapseMonitoramento1',
        roles: ['MONITORING_CALLCENTER'],
        views: [
          {
            path: '/monitoring/queuesToMonitor',
            name: 'Filas',
            component: QueueToMonitor,
            layout: '/admin',
            sidebar: true,
            roles: ['MONITORING_CALLCENTER']
          },
          {
            path: '/monitoring/supervisorPanel',
            name: 'Painel do Supervisor',
            component: SupervisorPanel,
            layout: '/admin',
            sidebar: true,
            roles: ['SUPERVISOR_PANEL']
          }
        ]
      },
      {
        collapse: true,
        name: 'PBXIP',
        miniName: 'PX',
        sidebar: true,
        state: 'pbxipMonitoring',
        roles: ['MONITORING_PBXIP'],
        views: [
          {
            path: '/monitoring/extensions',
            name: 'Ramais',
            component: ExtensionsMonitoring,
            layout: '/admin',
            sidebar: true,
            exact: true,
            roles: ['MONITORING_PBXIP']
          }
        ]
      },
      {
        collapse: true,
        name: 'Chat',
        miniName: 'CH',
        sidebar: true,
        state: 'componentsCollapseMonitoramento3',
        roles: ['CHATMONITORING'],
        views: [
          {
            path: '/monitoring/chat/conversation',
            name: 'Conversas',
            component: ConversationChatMonitoring,
            layout: '/admin',
            sidebar: true,
            roles: ['CHATMONITORING']
          }
        ]
      }
    ],
    sidebar: true
  },
  {
    collapse: true,
    name: 'Campanhas',
    state: 'componentsCollapseCampanhas',
    icon: 'ni ni-chart-bar-32 text-pink',
    roles: ['DIALER', 'BROADCAST_LIST'],
    views: [
      {
        collapse: true,
        name: 'Telefonia',
        miniName: 'TL',
        sidebar: true,
        state: 'componentsCollapseCampanhas1',
        roles: ['DIALER'],
        views: [
          {
            path: '/dialers',
            name: 'Discador',
            component: DialerList,
            layout: '/admin',
            sidebar: true,
            roles: ['DIALER']
          }
        ]
      },
      {
        collapse: true,
        name: 'Chat',
        miniName: 'CH',
        sidebar: true,
        state: 'componentsCollapseCampanhas2',
        roles: ['BROADCAST_LIST'],
        views: [
          {
            path: '/chat/broadcast-list',
            name: 'Campanha ativa Whatsapp',
            component: ChatBroadcastListList,
            layout: '/admin',
            sidebar: true,
            roles: ['BROADCAST_LIST']
          },
          {
            path: '/chat/broadcast-list-sms',
            name: 'Campanha ativa SMS',
            component: ChatBroadcastListListSMS,
            layout: '/admin',
            sidebar: true,
            roles: ['BROADCAST_LIST_SMS']
          }
        ]
      }
    ],
    sidebar: true
  },
  {
    collapse: true,
    name: 'Relatórios',
    icon: 'ni ni-single-copy-04 text-pink',
    state: 'componentsCollapseRelatorio',
    roles: [
      'AUTHENTICATION_REPORT',
      'OUTGOING_CALLS_CALLCENTER',
      'INCOMING_CALLS_CALLCENTER',
      'PAUSE_REPORT',
      'PERFORMANCE_REPORT',
      'REPORT_PBXIP',
      'INCOMING_CALLS_PBXIP',
      'OUTGOING_CALLS_PBXIP',
      'CONVERSATION_REPORT',
      'PERFORMANCE_CHAT_REPORT',
      'PAUSE_CHAT_REPORT',
      'REPORT_DIALER',
      'CALLBACK_REPORT',
      'URA_REPORT',
      'SURVEY_REPORT',
      'UNIFIED_REPORT',
      'AUDIT',
      'CLIENT'
    ],
    views: [
      {
        collapse: true,
        name: 'CallCenter',
        miniName: 'CC',
        sidebar: true,
        state: 'CallCenterReportCollapse',
        roles: [
          'REPORT_CALLCENTER',
          'AUTHENTICATION_REPORT',
          'PAUSE_REPORT',
          'PERFORMANCE_REPORT',
          'INCOMING_CALLS_CALLCENTER',
          'OUTGOING_CALLS_CALLCENTER'
        ],
        views: [
          {
            path: '/report/callcenter/authentication',
            name: 'Autenticação',
            component: AuthenticationReportCallcenter,
            layout: '/admin',
            sidebar: true,
            roles: ['AUTHENTICATION_REPORT']
          },
          {
            path: '/report/callcenter/receivedcall',
            name: 'Chamadas Recebidas',
            component: ReceivedCallReportCallCenter,
            layout: '/admin',
            sidebar: true,
            roles: ['INCOMING_CALLS_CALLCENTER']
          },
          {
            path: '/report/callcenter/callmade',
            name: 'Chamadas Realizadas',
            component: CallMadeReportCallCenter,
            layout: '/admin',
            sidebar: true,
            roles: ['OUTGOING_CALLS_CALLCENTER']
          },
          {
            path: '/report/callcenter/pause',
            name: 'Pausas',
            component: PauseReportCallCenter,
            layout: '/admin',
            sidebar: true,
            roles: ['PAUSE_REPORT']
          },
          {
            path: '/report/callcenter/performance',
            name: 'Performance',
            component: PerformanceReportCallCenter,
            layout: '/admin',
            sidebar: true,
            roles: ['PERFORMANCE_REPORT']
          }
        ]
      },
      {
        collapse: true,
        name: 'PBXIP',
        miniName: 'PX',
        sidebar: true,
        state: 'PBXIPReportCollapse',
        roles: ['REPORT_PBXIP', 'INCOMING_CALLS_PBXIP', 'OUTGOING_CALLS_PBXIP'],
        views: [
          {
            path: '/report/pbxip/receivedcall',
            name: 'Chamadas Recebidas',
            component: ReceivedCallReportPBXIP, //DialerReportAdvanced
            layout: '/admin',
            sidebar: true,
            roles: ['INCOMING_CALLS_PBXIP']
          },
          {
            path: '/report/pbxip/callmade',
            name: 'Chamada Realizadas',
            component: CallMadeReportPBXIP,
            layout: '/admin',
            sidebar: true,
            roles: ['OUTGOING_CALLS_PBXIP']
          }
        ]
      },
      {
        collapse: true,
        name: 'Chat',
        miniName: 'CH',
        sidebar: true,
        state: 'componentsCollapse2',
        roles: [
          'CONVERSATION_REPORT',
          'PERFORMANCE_CHAT_REPORT'
          //'PAUSE_CHAT_REPORT'
        ],
        views: [
          {
            path: '/report/chat/conversation',
            name: 'Conversas',
            component: ConversationReportChat,
            layout: '/admin',
            sidebar: true,
            roles: ['CONVERSATION_REPORT']
          },
          {
            path: '/report/chat/heatmap',
            name: 'Mapa de Calor',
            component: HeatMap,
            layout: '/admin',
            sidebar: true,
            roles: ['CONVERSATION_REPORT']
          },
          {
            path: '/report/chat/wordcloud',
            name: 'Nuvem de Palavras',
            component: WordCloud,
            layout: '/admin',
            sidebar: true,
            roles: ['CONVERSATION_REPORT']
          },
          {
            path: '/report/chat/wordCloudByScore',
            name: 'Nuvens de Palavras - Score',
            component: WordCloudByScore,
            layout: '/admin',
            sidebar: true,
            roles: ['CONVERSATION_REPORT']
          },
          {
            path: '/report/chat/conversationAnalytics',
            name: 'Análise de Conversas',
            component: ConversationAnalytics,
            layout: '/admin',
            sidebar: true,
            roles: ['CONVERSATION_REPORT']
          },
          {
            path: '/report/chat/performance',
            name: 'Performance',
            component: PerformanceReportChat,
            layout: '/admin',
            sidebar: true,
            roles: ['PERFORMANCE_CHAT_REPORT']
          },
          {
            path: '/report/chat/pause',
            name: 'Pausa',
            component: PauseReportChat,
            layout: '/admin',
            sidebar: true,
            roles: ['PAUSE_CHAT_REPORT']
          }
        ]
      },
      {
        collapse: true,
        name: 'Unificado',
        miniName: 'UN',
        sidebar: true,
        roles: ['UNIFIED_REPORT'],
        views: [
          // {
          //   path: '/report/omnichannel/analytical',
          //   name: 'Analítico',
          //   component: AnalyticalReportOmnichannel, //DialerReportAdvanced
          //   layout: '/admin',
          //   sidebar: true,
          //   roles: ['UNIFIED_REPORT']
          // },
          {
            path: '/report/omnichannel/performance',
            name: 'Performance',
            component: PerformanceReportOmnichannel,
            layout: '/admin',
            sidebar: true,
            roles: ['UNIFIED_REPORT']
          }
          // {
          //   path: '/report/omnichannel/synthetic',
          //   name: 'Sintético',
          //   component: SyntheticReportOmnichannel,
          //   layout: '/admin',
          //   sidebar: true,
          //   roles: ['UNIFIED_REPORT']
          // }
        ]
      },
      {
        collapse: true,
        name: 'Pesquisa',
        miniName: 'PS',
        sidebar: true,
        state: 'surveyReports',
        roles: ['SURVEY_REPORT'],
        views: [
          {
            path: '/report/survey/analytical',
            name: 'Analítico',
            component: AnalyticalReportSatisfactionSurvey,
            layout: '/admin',
            sidebar: true,
            roles: ['SURVEY_REPORT']
          },
          {
            path: '/report/survey/synthetic',
            name: 'Sintético',
            component: SinteticalReportSatisfactionSurvey,
            layout: '/admin',
            sidebar: true,
            roles: ['SURVEY_REPORT']
          }
        ]
      },
      // {
      //   collapse: true,
      //   name: 'Protocolo',
      //   miniName: 'PT',
      //   sidebar: true,
      //   state: 'ProtocolReportCollapse',
      //   roles: ['PROTOCOL_REPORTS'],
      //   views: [
      //     {
      //       path: '/report/protocol/analytical',
      //       name: 'Analítico/Sintético',
      //       component: ProtocolAnalyticalReport,
      //       layout: '/admin',
      //       sidebar: true,
      //       roles: ['PROTOCOL_REPORTS']
      //     }
      //   ]
      // },
      {
        collapse: true,
        name: 'Avançados',
        miniName: 'AD',
        sidebar: true,
        state: 'AdvancedReportCollapse',
        roles: ['REPORT_DIALER', 'CALLBACK_REPORT', 'URA_REPORT'],
        views: [
          {
            path: '/report/advanced/dialer',
            name: 'Discador',
            component: DialerReportAdvanced,
            layout: '/admin',
            sidebar: true,
            roles: ['REPORT_DIALER']
          },
          {
            path: '/report/advanced/callback',
            name: 'Callback',
            component: CallbackReportAdvanced,
            layout: '/admin',
            sidebar: true,
            roles: ['CALLBACK_REPORT']
          },
          {
            path: '/report/advanced/ivr',
            name: 'URA',
            component: IVRReportAdvanced,
            layout: '/admin',
            sidebar: true,
            roles: ['URA_REPORT']
          },
          {
            path: '/report/advanced/chat-bot',
            name: 'Chatbot',
            component: ChatBotReport,
            layout: '/admin',
            sidebar: true,
            roles: ['URA_REPORT']
          }
        ]
      },
      {
        collapse: true,
        name: 'Auditoria',
        miniName: 'AU',
        sidebar: true,
        state: 'AuditReportCollapse',
        roles: ['AUDIT'],
        views: [
          {
            path: '/report/audit/authentication',
            name: 'Autenticação',
            component: AuditReportAutentication,
            layout: '/admin',
            sidebar: true,
            roles: ['AUDIT']
          }
          // {
          //   path: '/audit',
          //   name: 'Auditoria',
          //   component: AuditReport,
          //   layout: '/admin',
          //   sidebar: true,
          //   miniName: 'AU',
          //   roles: ['AUDIT']
          // }
        ]
      },
      {
        collapse: true,
        name: 'Escala',
        miniName: 'ES',
        sidebar: true,
        state: 'scaleReport',
        roles: ['SCALE_REPORT_AGENT', 'SCALE_REPORT_TEAM'],
        views: [
          {
            path: '/report/scale/analytical',
            name: 'Analítico',
            component: ScalesReport,
            layout: '/admin',
            sidebar: true,
            roles: ['SCALE_REPORT_AGENT']
          },
          {
            path: '/report/scale/teams/analytical',
            name: 'Equipes',
            component: ScalesReportTeams,
            layout: '/admin',
            sidebar: true,
            roles: ['SCALE_REPORT_TEAM']
          }
        ]
      },
      {
        collapse: true,
        name: 'Campanha ativa',
        miniName: 'CA',
        sidebar: true,
        state: 'TransmissionListReport',
        roles: ['BROADCAST_LIST_REPORT', 'BROADCAST_LIST_SMS'],
        views: [
          {
            path: '/report/TransmissionList',
            name: 'Campanha ativa Whatsapp',
            component: TransmissionListReport,
            layout: '/admin',
            sidebar: true,
            roles: ['BROADCAST_LIST_REPORT']
          },
          {
            path: '/report/TransmissionListSMS',
            name: 'Campanha ativa SMS',
            component: TransmissionListReportSMS,
            layout: '/admin',
            sidebar: true,
            roles: ['BROADCAST_LIST_SMS']
          }
        ]
      },
      {
        collapse: true,
        name: 'Monitoria de qualidade',
        miniName: 'QA',
        sidebar: true,
        state: 'QualityMonitoringReport',
        roles: ['VIEW_QUALITY_MONITORING_REPORTS'],
        views: [
          {
            path: '/report/QualityMonitoring',
            name: 'Analítico/Sintético',
            component: QualityMonitoringReport,
            layout: '/admin',
            sidebar: true,
            roles: ['VIEW_QUALITY_MONITORING_REPORTS']
          }
        ]
      }
    ],
    sidebar: true
  },
  {
    path: '/protocol/:id/update',
    name: 'ProtocolForm',
    exact: false,
    icon: 'ni ni-archive-2 text-green',
    component: protocolUpdateForm,
    layout: '/admin',
    sidebar: false,
    roles: ['LIST_PROTOCOL']
  },
  {
    path: '/reason',
    name: 'Motivo',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ReasonList,
    layout: '/admin',
    sidebar: false,
    roles: ['REASON']
  },
  {
    path: '/reason/form',
    name: 'Motivo',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ReasonForm,
    layout: '/admin',
    sidebar: false,
    roles: ['REASON']
  },
  {
    path: '/reason/form/:id',
    name: 'Motivo',
    exact: false,
    icon: 'ni ni-archive-2 text-green',
    component: ReasonForm,
    layout: '/admin',
    sidebar: false,
    roles: ['REASON']
  },
  {
    path: '/subReason',
    name: 'Sub motivo',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: SubReasonList,
    layout: '/admin',
    sidebar: false,
    roles: ['SUBREASON']
  },
  {
    path: '/subReason/form',
    name: 'Sub motivo',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: SubReasonForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SUBREASON']
  },
  {
    path: '/subReason/form/:id',
    name: 'Sub motivo',
    exact: false,
    icon: 'ni ni-archive-2 text-green',
    component: SubReasonForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SUBREASON']
  },
  {
    path: '/status',
    name: 'Status',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: StatusList,
    layout: '/admin',
    sidebar: false,
    roles: ['STATUS']
  },
  {
    path: '/status/form',
    name: 'Status',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: StatusForm,
    layout: '/admin',
    sidebar: false,
    roles: ['STATUS']
  },
  {
    path: '/status/form/:id',
    name: 'Status',
    exact: false,
    icon: 'ni ni-archive-2 text-green',
    component: StatusForm,
    layout: '/admin',
    sidebar: false,
    roles: ['STATUS']
  },
  {
    path: '/fieldsConfig',
    name: 'fields_config_list',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: FieldsConfigList,
    layout: '/admin',
    sidebar: false,
    roles: ['CUSTOM_FIELDS_CONFIG']
  },
  {
    path: '/fieldsConfig/form',
    name: 'fields_config_form',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: FieldsConfigForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CUSTOM_FIELDS_CONFIG']
  },

  {
    path: '/fieldsConfig/form/:id',
    name: 'fields_config_form',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: FieldsConfigForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CUSTOM_FIELDS_CONFIG']
  },
  {
    path: '/user',
    name: 'Usuário',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: UserList,
    layout: '/admin',
    sidebar: false,
    roles: ['USERS_LIST']
  },
  {
    path: '/user/form',
    name: 'Usuário',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: UserForm,
    layout: '/admin',
    sidebar: false,
    roles: ['USER']
  },
  {
    path: '/user/form/edit_multiple',
    name: 'Formulário de edição de múltiplos usuários.',
    exact: true,
    component: UserFormMulti,
    layout: '/admin',
    sidebar: false,
    roles: ['ALL']
  },
  {
    path: '/user/form/:login',
    name: 'Usuário',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: UserForm,
    layout: '/admin',
    sidebar: false,
    roles: ['USER']
  },

  {
    path: '/email',
    name: 'Configuração de email',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: EmailSettingsList,
    layout: '/admin',
    sidebar: false,
    roles: ['EMAIL_CONFIGURATION']
  },
  {
    path: '/email/form',
    name: 'Motivo',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: EmailSettingsForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EMAIL_CONFIGURATION']
  },
  {
    path: '/email/form/:id',
    name: 'Motivo',
    exact: false,
    icon: 'ni ni-archive-2 text-green',
    component: EmailSettingsForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EMAIL_CONFIGURATION']
  },
  {
    path: '/systemConfig',
    name: 'systemConfig',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: SystemConfig,
    layout: '/admin',
    sidebar: false,
    roles: ['SYSTEM_CONFIGURATION']
  },
  {
    path: '/extensions',
    name: 'Configurações de ramal',
    exact: true,
    component: ExtensionList,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/extensions/form/iax2',
    name: 'Formulário de ramal IAX2',
    exact: true,
    component: ExtensionForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/extensions/form/iax2/:id/:actionType',
    name: 'Formulário de edição de ramal IAX2',
    exact: true,
    component: ExtensionForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/extensions/form/sip',
    name: 'Formulário de ramal SIP',
    exact: true,
    component: ExtensionForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/extensions/form/sip/:id/:actionType',
    name: 'Formulário de edição de ramal SIP',
    exact: true,
    component: ExtensionForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/extensions/form/pjsip',
    name: 'Formulário de ramal PJSIP',
    exact: true,
    component: ExtensionForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/extensions/form/pjsip/:id/:actionType',
    name: 'Formulário de edição de ramal PJSIP',
    exact: true,
    component: ExtensionForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/extensions/form/analogico',
    name: 'Formulário de ramal Analógico',
    exact: true,
    component: ExtensionForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/extensions/form/analogico/:id/:actionType',
    name: 'Formulário de edição de ramal Analógico',
    exact: true,
    component: ExtensionForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/extensions/form/edit_multiple',
    name: 'Formulário de edição de múltiplos ramais',
    exact: true,
    component: ExtensionFormMulti,
    layout: '/admin',
    sidebar: false,
    roles: ['EXTENSION']
  },
  {
    path: '/userprofile',
    name: 'Perfil de Usuário',
    layout: '/admin',
    component: UserProfile,
    sidebar: false,
    roles: ['USERS_PROFILE']
  },
  {
    path: '/issue/form',
    name: 'Assunto',
    exact: true,
    component: IssueForm,
    layout: '/admin',
    sidebar: false,
    roles: ['ISSUE']
  },
  {
    path: '/issue/form/:id',
    name: 'Assunto',
    exact: true,
    component: IssueForm,
    layout: '/admin',
    sidebar: false,
    roles: ['ISSUE']
  },
  {
    path: '/issue',
    name: 'Assunto',
    miniName: 'AS',
    component: IssueList,
    layout: '/admin',
    sidebar: false,
    roles: ['ISSUE']
  },
  {
    path: '/product/form',
    name: 'Produto',
    exact: true,
    component: ProductForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PRODUCT']
  },
  {
    path: '/product/form/:id',
    name: 'Produto',
    exact: true,
    component: ProductForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PRODUCT']
  },
  {
    path: '/product',
    name: 'Produto',
    miniName: 'PD',
    component: ProductList,
    layout: '/admin',
    sidebar: false,
    roles: ['PRODUCT']
  },
  {
    path: '/predefinedMessage/form',
    name: 'Mensagem',
    exact: true,
    component: PredefinedMessageForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PREDEFINEDMESSAGE']
  },
  {
    path: '/predefinedMessage/form/:id',
    name: 'Mensagem',
    exact: true,
    component: PredefinedMessageForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PREDEFINEDMESSAGE']
  },
  {
    path: '/predefinedMessage',
    name: 'Mensagem',
    miniName: 'MS',
    component: PredefinedMessageList,
    layout: '/admin',
    sidebar: false,
    roles: ['PREDEFINEDMESSAGE']
  },
  {
    path: '/queue/form',
    name: 'Fila',
    exact: true,
    component: QueueForm,
    layout: '/admin',
    sidebar: false,
    roles: ['QUEUE']
  },
  {
    path: '/queue/form/:id',
    name: 'Fila',
    exact: true,
    component: QueueForm,
    layout: '/admin',
    sidebar: false,
    roles: ['QUEUE']
  },
  {
    path: '/queue',
    name: 'Fila',
    miniName: 'FL',
    component: QueueList,
    layout: '/admin',
    sidebar: false,
    roles: ['QUEUE']
  },
  {
    path: '/conversation/list/:id',
    name: 'Listar Conversa',
    exact: true,
    component: ConversationList,
    layout: '/admin',
    sidebar: false,
    roles: ['ALL']
  },
  {
    path: '/sector',
    name: 'Setor',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: SectorList,
    layout: '/admin',
    sidebar: false,
    roles: ['SECTOR']
  },

  {
    path: '/sector/form',
    name: 'Setor',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: SectorForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SECTOR']
  },

  {
    path: '/sector/form/:id',
    name: 'Setor',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: SectorForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SECTOR']
  },

  {
    path: '/ddr',
    name: 'Ddr',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: DdrList,
    layout: '/admin',
    sidebar: false,
    roles: ['DDR']
  },

  {
    path: '/ddr/form',
    name: 'Ddr',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: DdrForm,
    layout: '/admin',
    sidebar: false,
    roles: ['DDR']
  },

  {
    path: '/ddr/form/:id',
    name: 'Ddr',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: DdrForm,
    layout: '/admin',
    sidebar: false,
    roles: ['DDR']
  },

  {
    path: '/voicemail',
    name: 'Voicemail',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: VoiceMailList,
    layout: '/admin',
    sidebar: false,
    roles: ['VOICEMAIL']
  },

  {
    path: '/voicemail/form',
    name: 'Voicemail',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: VoiceMailForm,
    layout: '/admin',
    sidebar: false,
    roles: ['VOICEMAIL']
  },

  {
    path: '/voicemail/form/:id',
    name: 'Voicemail',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: VoiceMailForm,
    layout: '/admin',
    sidebar: false,
    roles: ['VOICEMAIL']
  },

  {
    path: '/spy',
    name: 'Spy',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: SpyList,
    layout: '/admin',
    sidebar: false,
    roles: ['SPY']
  },

  {
    path: '/spy/form',
    name: 'Spy',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: SpyForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SPY']
  },

  {
    path: '/spy/form/:id',
    name: 'Spy',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: SpyForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SPY']
  },
  {
    path: '/context',
    name: 'Context',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ContextList,
    layout: '/admin',
    sidebar: false,
    roles: ['CONTEXT']
  },
  {
    path: '/context/form',
    name: 'Context',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ContextForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CONTEXT']
  },

  {
    path: '/context/form/:id',
    name: 'Context',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ContextForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CONTEXT']
  },

  {
    path: '/callback',
    name: 'Callback',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: CallbackList,
    layout: '/admin',
    sidebar: false,
    roles: ['CALLBACK']
  },
  {
    path: '/callback/form',
    name: 'Callback',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: CallbackForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CALLBACK']
  },
  {
    path: '/callback/form/:id',
    name: 'Callback',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: CallbackForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CALLBACK']
  },

  {
    path: '/audio',
    name: 'AudioList',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: AudioList,
    layout: '/admin',
    sidebar: false,
    roles: ['AUDIO']
  },
  {
    path: '/audio/form',
    name: 'AudioForm',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: AudioForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AUDIO']
  },
  {
    path: '/audio/form/:id',
    name: 'AudioFormEdit',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: AudioForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AUDIO']
  },
  {
    path: '/chatDialogTree',
    name: 'dialogTree',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ChatDialogTreeList,
    layout: '/admin',
    sidebar: false,
    roles: ['DIALOG_TREE']
  },
  {
    path: '/chatDialogTree/form',
    name: 'chatDialogTree_form',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ChatDialogTreeForm,
    layout: '/admin',
    sidebar: false,
    roles: ['DIALOG_TREE']
  },
  {
    path: '/chatDialogTree/form/:id',
    name: 'chatDialogTree_form_edit',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ChatDialogTreeForm,
    layout: '/admin',
    sidebar: false,
    roles: ['DIALOG_TREE']
  },

  {
    path: '/ivr',
    name: 'ivr_list',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: IVRList,
    layout: '/admin',
    sidebar: false,
    roles: ['ALL']
  },
  {
    path: '/ivr/form',
    name: 'ivr_form',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: IVRForm,
    layout: '/admin',
    sidebar: false,
    roles: ['ALL']
  },
  {
    path: '/ivr/form/:id',
    name: 'ivr_form_edit',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: IVRForm,
    layout: '/admin',
    sidebar: false,
    roles: ['ALL']
  },
  {
    path: '/queuemessage/form',
    name: 'Mensagem de fila',
    exact: true,
    component: QueueMessageForm,
    layout: '/admin',
    sidebar: false,
    roles: ['QUEUEMESSAGE']
  },
  {
    path: '/queuemessage/form/:id',
    name: 'Mensagem de fila',
    exact: true,
    component: QueueMessageForm,
    layout: '/admin',
    sidebar: false,
    roles: ['QUEUEMESSAGE']
  },
  {
    path: '/queuemessage',
    name: 'Mensagem de fila',
    miniName: 'MF',
    component: QueueMessageList,
    layout: '/admin',
    sidebar: false,
    roles: ['QUEUEMESSAGE']
  },

  {
    path: '/exitRoute',
    name: 'ExitRoute',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ExitRouteList,
    layout: '/admin',
    sidebar: false,
    roles: ['OUTBOUND_ROUTE']
  },
  {
    path: '/exitRoute/form',
    name: 'ExitRoute',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ExitRouteForm,
    layout: '/admin',
    sidebar: false,
    roles: ['OUTBOUND_ROUTE']
  },
  {
    path: '/exitRoute/form/:id',
    name: 'ExitRoute',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ExitRouteForm,
    layout: '/admin',
    sidebar: false,
    roles: ['OUTBOUND_ROUTE']
  },
  {
    path: '/inboundRoute',
    name: 'InboundRoute',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: InboundRouteList,
    layout: '/admin',
    sidebar: false,
    roles: ['INBOUND_ROUTE']
  },
  {
    path: '/inboundRoute/form',
    name: 'InboundRoute',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: InboudRouteForm,
    layout: '/admin',
    sidebar: false,
    roles: ['INBOUND_ROUTE']
  },
  {
    path: '/inboundRoute/form/:id',
    name: 'InboundRoute',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: InboudRouteForm,
    layout: '/admin',
    sidebar: false,
    roles: ['INBOUND_ROUTE']
  },
  {
    path: '/pause/form',
    name: 'Pausa',
    exact: true,
    component: PauseForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PAUSE']
  },
  {
    path: '/pause/form/:id',
    name: 'Pausa',
    exact: true,
    component: PauseForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PAUSE']
  },
  {
    path: '/pause',
    name: 'Pausa',
    component: PauseList,
    layout: '/admin',
    sidebar: false,
    roles: ['PAUSE']
  },
  {
    path: '/ChiefSecretary/form',
    name: 'ChiefSecretary',
    exact: true,
    component: ChiefSecretaryForm,
    layout: '/admin',
    sidebar: false,
    roles: ['BOSS_SECRETARY']
  },
  {
    path: '/ChiefSecretary/form/:id',
    name: 'ChiefSecretary',
    exact: true,
    component: ChiefSecretaryForm,
    layout: '/admin',
    sidebar: false,
    roles: ['BOSS_SECRETARY']
  },
  {
    path: '/ChiefSecretary',
    name: 'ChiefSecretary',
    component: ChiefSecretaryList,
    layout: '/admin',
    sidebar: false,
    roles: ['BOSS_SECRETARY']
  },
  {
    path: '/facilityRoute/form/:id',
    name: 'FacilityRoute',
    exact: true,
    component: facilityRouteForm,
    layout: '/admin',
    sidebar: false,
    roles: ['EASE_ROUTE']
  },
  {
    path: '/facilityRoute',
    name: 'FacilityRoute',
    component: facilityRouteList,
    layout: '/admin',
    sidebar: false,
    roles: ['EASE_ROUTE']
  },
  {
    path: '/BlackList/form',
    name: 'BlackList',
    exact: true,
    component: blackListForm,
    layout: '/admin',
    sidebar: false,
    roles: ['BLACK_LIST']
  },
  {
    path: '/BlackList/form/:id',
    name: 'BlackList',
    exact: true,
    component: blackListForm,
    layout: '/admin',
    sidebar: false,
    roles: ['BLACK_LIST']
  },
  {
    path: '/BlackList',
    name: 'BlackList',
    component: blackListList,
    layout: '/admin',
    sidebar: false,
    roles: ['BLACK_LIST']
  },
  {
    path: '/SpyGroup/form',
    name: 'SpyGroup',
    exact: true,
    component: SpyGrouForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SPY_GROUP']
  },
  {
    path: '/SpyGroup/form/:id',
    name: 'SpyGroup',
    exact: true,
    component: SpyGrouForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SPY_GROUP']
  },
  {
    path: '/SpyGroup',
    name: 'SpyGroup',
    component: SpyGrouList,
    layout: '/admin',
    sidebar: false,
    roles: ['SPY_GROUP']
  },
  {
    path: '/GroupExitRoute/form',
    name: 'GroupExitRoute',
    exact: true,
    component: GroupExitRouteForm,
    layout: '/admin',
    sidebar: false,
    roles: ['OUTBOUND_ROUTE_GROUP']
  },
  {
    path: '/GroupExitRoute/form/:id',
    name: 'GroupExitRoute',
    exact: true,
    component: GroupExitRouteForm,
    layout: '/admin',
    sidebar: false,
    roles: ['OUTBOUND_ROUTE_GROUP']
  },
  {
    path: '/GroupExitRoute',
    name: 'GroupExitRoute',
    component: GroupExitRouteList,
    layout: '/admin',
    sidebar: false,
    roles: ['OUTBOUND_ROUTE_GROUP']
  },
  {
    path: '/timeschedule/form',
    name: 'Programação de Horário',
    exact: true,
    component: TimeScheduleForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PROGRAMACAO_HORARIO']
  },
  {
    path: '/timeschedule/form/:id',
    name: 'Programação de Horário',
    exact: true,
    component: TimeScheduleForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PROGRAMACAO_HORARIO']
  },
  {
    path: '/timeschedule',
    name: 'Programação de Horário',
    component: TimeScheduleList,
    layout: '/admin',
    sidebar: false,
    roles: ['PROGRAMACAO_HORARIO']
  },
  {
    path: '/pjsip_configuration/form',
    name: 'Configuração PJSIP form',
    exact: true,
    component: PJSIPConfigurationForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PJSIP_CONFIGURATION']
  },
  {
    path: '/pjsip_configuration/form/:id',
    name: 'Configuração PJSIP form edit',
    exact: true,
    component: PJSIPConfigurationForm,
    layout: '/admin',
    sidebar: false,
    roles: ['PJSIP_CONFIGURATION']
  },
  {
    path: '/pjsip_configuration',
    name: 'Configuração PJSIP',
    component: PJSIPConfiguration,
    layout: '/admin',
    sidebar: false,
    roles: ['PJSIP_CONFIGURATION']
  },

  {
    path: '/trunks',
    name: 'Configurações de ramal',
    exact: true,
    component: TrunkList,
    layout: '/admin',
    sidebar: false,
    roles: ['TRUNKS']
  },
  {
    path: '/trunk/form/IAX2',
    name: 'Configuração tronco',
    exact: true,
    component: TrunkFormIax2,
    layout: '/admin',
    sidebar: false,
    roles: ['TRUNKS']
  },
  {
    path: '/trunk/form/IAX2/:id',
    name: 'Configuração tronco para editar',
    exact: true,
    component: TrunkFormIax2,
    layout: '/admin',
    sidebar: false,
    roles: ['TRUNKS']
  },

  {
    path: '/trunk/form/PJSIP',
    name: 'Configuração tronco',
    exact: true,
    component: TrunkFormPjsip,
    layout: '/admin',
    sidebar: false,
    roles: ['TRUNKS']
  },
  {
    path: '/trunk/form/PJSIP/:id',
    name: 'Configuração tronco para editar',
    exact: true,
    component: TrunkFormPjsip,
    layout: '/admin',
    sidebar: false,
    roles: ['TRUNKS']
  },
  {
    path: '/trunk/form/DIGITAL',
    name: 'Configuração tronco',
    exact: true,
    component: TrunkFormDigital,
    layout: '/admin',
    sidebar: false,
    roles: ['TRUNKS']
  },
  {
    path: '/trunk/form/DIGITAL/:id',
    name: 'Configuração tronco para editar',
    exact: true,
    component: TrunkFormDigital,
    layout: '/admin',
    sidebar: false,
    roles: ['TRUNKS']
  },

  {
    path: '/trunk/form/ANALOGICO',
    name: 'Configuração tronco',
    exact: true,
    component: TrunkFormAnalogical,
    layout: '/admin',
    sidebar: false,
    roles: ['TRUNKS']
  },
  {
    path: '/trunk/form/ANALOGICO/:id',
    name: 'Configuração tronco para editar',
    exact: true,
    component: TrunkFormAnalogical,
    layout: '/admin',
    sidebar: false,
    roles: ['TRUNKS']
  },
  {
    path: '/profile/form',
    name: 'Perfil de Acesso',
    exact: true,
    component: ProfileForm,
    layout: '/admin',
    sidebar: false,
    roles: ['USERS_PROFILE']
  },
  {
    path: '/profile/form/:id',
    name: 'Perfil de Acesso',
    exact: true,
    component: ProfileForm,
    layout: '/admin',
    sidebar: false,
    roles: ['USERS_PROFILE']
  },
  {
    path: '/profile',
    name: 'Perfil de Acesso',
    component: ProfileList,
    layout: '/admin',
    sidebar: false,
    roles: ['USERS_PROFILE']
  },
  {
    path: '/transshipment/form',
    name: 'Transbordo',
    exact: true,
    component: TransshipmentForm,
    layout: '/admin',
    sidebar: false,
    roles: ['TRANSSHIPMENT']
  },
  {
    path: '/transshipment/form/:id',
    name: 'Transbordo',
    exact: true,
    component: TransshipmentForm,
    layout: '/admin',
    sidebar: false,
    roles: ['TRANSSHIPMENT']
  },
  {
    path: '/transshipment',
    name: 'Transbordo',
    component: TransshipmentList,
    layout: '/admin',
    sidebar: false,
    roles: ['TRANSSHIPMENT']
  },
  {
    path: '/dialer/form',
    name: 'Discador',
    exact: true,
    component: DialerForm,
    layout: '/admin',
    sidebar: false,
    roles: ['DIALER']
  },
  {
    path: '/dialer/form/:id',
    name: 'Discador',
    exact: true,
    component: DialerForm,
    layout: '/admin',
    sidebar: false,
    roles: ['DIALER']
  },
  {
    path: '/dialer',
    name: 'Discador',
    component: DialerList,
    layout: '/admin',
    sidebar: false,
    roles: ['DIALER']
  },
  {
    path: '/:id/dashboard',
    name: 'Dashboard do discador',
    component: DialerDashboard,
    layout: '/dialer',
    exact: true,
    sidebar: false,
    roles: ['DIALER']
  },

  {
    path: '/internalRoute/form',
    name: 'RotaInterna',
    exact: true,
    component: InternalRouteForm,
    layout: '/admin',
    sidebar: false,
    roles: ['INTERNAL_ROUTE']
  },
  {
    path: '/internalRoute/form/:id',
    name: 'RotaInterna',
    exact: true,
    component: InternalRouteForm,
    layout: '/admin',
    sidebar: false,
    roles: ['INTERNAL_ROUTE']
  },
  {
    path: '/internalRoute',
    name: 'RotaInterna',
    component: InternalRouteList,
    layout: '/admin',
    sidebar: false,
    roles: ['INTERNAL_ROUTE']
  },
  {
    path: '/queues',
    name: 'monitoringQueues',
    component: QueueMonitoring,
    layout: '/monitoring',
    sidebar: false,
    roles: ['MONITORING_CALLCENTER']
  },

  {
    path: '/Scales/form',
    name: 'Scales',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ScaleForm,
    layout: '/admin',
    sidebar: false,
    roles: ['WORK_SCALES']
  },

  {
    path: '/Scales/form/:id',
    name: 'Scales',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: ScaleForm,
    layout: '/admin',
    sidebar: false,
    roles: ['WORK_SCALES']
  },
  {
    path: '/DayOff/form',
    name: 'DayOff',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: DayOffForm,
    layout: '/admin',
    sidebar: false,
    roles: ['DAYS_OFF']
  },
  {
    path: '/DayOff/form/:id',
    name: 'DayOff',
    exact: true,
    icon: 'ni ni-archive-2 text-green',
    component: DayOffForm,
    layout: '/admin',
    sidebar: false,
    roles: ['DAYS_OFF']
  },
  {
    collapse: true,
    name: 'Escalas',
    state: 'componentsScale',
    icon: 'ni ni-calendar-grid-58 text-info',
    roles: [
      'WORK_SCALES',
      'DAYS_OFF',
      'OVERTIME',
      'SUPERVISOR_OVERTIME',
      'CALENDAR'
    ],
    views: [
      {
        path: '/Scales',
        name: 'Configurações de Escala',
        exact: true,
        component: ScaleList,
        layout: '/admin',
        sidebar: true,
        miniName: 'SC',
        roles: ['WORK_SCALES']
      },
      {
        path: '/DayOff',
        name: 'Configurações de Folga',
        exact: true,
        component: DayOffList,
        layout: '/admin',
        sidebar: true,
        miniName: 'DO',
        roles: ['DAYS_OFF']
      },
      {
        path: '/ExtraHour',
        name: 'Configurações de Hora Extra',
        exact: true,
        component: ExtraHourList,
        layout: '/admin',
        sidebar: true,
        miniName: 'EH',
        roles: ['OVERTIME']
      },
      {
        path: '/SupervisorExtraHour',
        name: 'Autenticação de Hora Extra',
        exact: true,
        component: SupervisorExtraHour,
        layout: '/admin',
        sidebar: true,
        miniName: 'SEH',
        roles: ['SUPERVISOR_OVERTIME']
      },
      {
        path: '/Calendar',
        name: 'Calendário',
        exact: true,
        component: CalendarViewScales,
        layout: '/admin',
        sidebar: true,
        miniName: 'CS',
        roles: ['CALENDAR']
      }
    ],
    sidebar: true
  },
  {
    path: '/AllNotification',
    exact: true,
    component: AllNotification,
    layout: '/admin',
    roles: ['OVERTIME_NOTIFICATIONS']
  },
  {
    path: '/ChangeDayOff',
    exact: true,
    component: ChangeDayOffList,
    layout: '/admin',
    roles: ['ALL']
  },
  {
    path: '/ChangeDayOffSupervisor',
    exact: true,
    component: ChangeDayOffSupervisorList,
    layout: '/admin',
    roles: ['ALL']
  },
  {
    path: '/integrationUrl/form',
    name: 'URL de integração',
    exact: true,
    component: IntegrationUrlForm,
    layout: '/admin',
    sidebar: false,
    roles: ['INTEGRATION_URL']
  },
  {
    path: '/integrationUrl/form/:id',
    name: 'URL de integração',
    exact: true,
    component: IntegrationUrlForm,
    layout: '/admin',
    sidebar: false,
    roles: ['INTEGRATION_URL']
  },
  {
    collapse: true,
    name: 'CRM',
    state: 'componentsCRM',
    icon: 'fa fa-cloud text-info',
    roles: ['AGENT_CRM'],
    views: [
      {
        path: '/crm/accounts',
        name: 'Contas',
        exact: true,
        component: AccountList,
        layout: '/admin',
        sidebar: true,
        miniName: 'ACT',
        roles: ['AGENT_CRM']
      },
      {
        path: '/crm/accountForm/:recordTypeId',
        name: 'Formulário de Conta',
        exact: true,
        component: AccountForm,
        layout: '/admin',
        sidebar: false,
        roles: ['AGENT_CRM']
      },
      {
        path: '/crm/contact',
        name: 'Contatos',
        exact: true,
        component: ContactCRM,
        layout: '/admin',
        sidebar: true,
        miniName: 'CONT',
        roles: ['AGENT_CRM']
      },
      {
        path: '/crm/contactForm/:recordTypeId',
        name: 'Contatos Formulário',
        exact: true,
        component: ContactForm,
        layout: '/admin',
        sidebar: false,
        roles: ['AGENT_CRM']
      },
      {
        path: '/crm/contactForm/:recordTypeId/:contactId',
        name: 'Contatos Formulário',
        exact: true,
        component: ContactDetails,
        layout: '/admin',
        sidebar: false,
        roles: ['AGENT_CRM']
      },
      {
        path: '/crm/lead',
        name: 'Leads',
        exact: true,
        component: LeadListIndex,
        layout: '/admin',
        sidebar: true,
        miniName: 'LEAD',
        roles: ['AGENT_CRM']
      },
      {
        path: '/crm/leadForm/:recordTypeId',
        name: 'Leads Formulário',
        exact: true,
        component: LeadForm,
        layout: '/admin',
        sidebar: false,
        roles: ['AGENT_CRM']
      },
      {
        path: '/crm/opportunity',
        name: 'Oportunidades',
        exact: true,
        component: OpportunityListIndex,
        layout: '/admin',
        sidebar: true,
        miniName: 'OPP',
        roles: ['AGENT_CRM']
      },
      {
        path: '/crm/opportunityForm/:recordTypeId',
        name: 'Oportunidades Formulário',
        exact: true,
        component: OpportunityForm,
        layout: '/admin',
        sidebar: false,
        roles: ['AGENT_CRM']
      },
      {
        collapse: true,
        name: 'Máquina de Vendas',
        miniName: 'CRM REL',
        sidebar: true,
        state: 'componentsCollapseCRM',
        roles: ['AGENT_CRM'],
        views: [
          {
            path: '/reportConverted',
            name: 'Conversão de Leads',
            component: LeadsReportClosedWonIndex,
            layout: '/admin',
            sidebar: true,
            roles: ['AGENT_CRM']
          },
          {
            path: '/reportLeadTime',
            name: 'Tempo de Lead',
            component: ReportLeadTImeIndex,
            layout: '/admin',
            sidebar: true,
            roles: ['AGENT_CRM']
          },
          {
            path: '/reportOpportunityTime',
            name: 'Tempo de Oportunidade',
            component: ReportOpportunityTimeIndex,
            layout: '/admin',
            sidebar: true,
            roles: ['AGENT_CRM']
          },
          {
            path: '/reportOpportunityRevenue',
            name: 'Previsão de Receita',
            component: ReportOpportunityRevenueIndex,
            layout: '/admin',
            sidebar: true,
            roles: ['AGENT_CRM']
          }
        ]
      }
    ],
    sidebar: true
  },
  {
    path: '/integrationUrl',
    name: 'URL de integração',
    component: IntegrationUrlList,
    layout: '/admin',
    sidebar: false,
    roles: ['INTEGRATION_URL']
  },
  {
    path: '/crm/leadStages',
    name: 'Fases dos leads',
    exact: true,
    component: LeadStages,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/leadStages/form',
    name: 'Fases do lead Formulário',
    exact: true,
    component: LeadStagesForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/leadStages/form/:id',
    name: 'Fases do lead Formulário',
    exact: true,
    component: LeadStagesForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/leadProcess',
    name: 'Processos do Lead',
    exact: true,
    component: LeadProcessList,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/leadProcess/form',
    name: 'Formulário de Processos do Lead',
    exact: true,
    component: LeadProcessForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/leadProcess/form/:id',
    name: 'Formulário de Processos do Lead',
    exact: true,
    component: LeadProcessForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/:id/dashboard',
    name: 'Dashboard do discador',
    component: CallbackDashboard,
    layout: '/callback',
    exact: true,
    sidebar: false,
    roles: ['ALL']
  },
  {
    path: '/broadcast-list/:id/dashboard',
    name: 'Dashboard do discador',
    component: BroadcastListDashboard,
    layout: '/callback',
    exact: true,
    sidebar: false,
    roles: ['ALL']
  },
  {
    path: '/broadcast-list-sms/:id/dashboard',
    name: 'Dashboard do discador SMS',
    component: SMSBroadcastListDashboard,
    layout: '/callback',
    exact: true,
    sidebar: false,
    roles: ['ALL']
  },
  {
    path: '/crm/leadEdit/:recordTypeId/:id',
    name: 'Tela de edição do Lead',
    exact: true,
    component: LeadEdit,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/accounts/:recordTypeId/:id',
    name: 'Detalhes da conta',
    exact: true,
    component: AccountDetails,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/opportunityStages',
    name: 'Fases das oportunidades',
    exact: true,
    component: OpportunityStages,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/opportunityStages/form',
    name: 'Fases da oportunidade Formulário',
    exact: true,
    component: OpportunityStagesForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/opportunityStages/form/:id',
    name: 'Fases da oportunidade Formulário',
    exact: true,
    component: OpportunityStagesForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/salesProcess',
    name: 'Processos de vendas',
    exact: true,
    component: SalesProcessList,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/salesProcess/form',
    name: 'Formulário de Processos de vendas',
    exact: true,
    component: SalesProcessForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/salesProcess/form/:id',
    name: 'Formulário de Processos de vendas',
    exact: true,
    component: SalesProcessForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/opportunityEdit/:recordTypeId/:id',
    name: 'Tela de edição da Oportunidade',
    exact: true,
    component: OpportunityEdit,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/mapFields',
    name: 'Mapeamento de campos',
    exact: true,
    component: MapFieldsList,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/tagsClient',
    name: 'Tags',
    exact: true,
    component: TagsClientList,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/Team/list',
    name: 'Team List',
    exact: true,
    component: TeamList,
    layout: '/admin',
    sidebar: false,
    roles: ['TEAM_ACCESS']
  },
  {
    path: '/crm/protocolProcess',
    name: 'Processos de atendimento',
    exact: true,
    component: ProtocolProcessList,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/protocolProcess/form',
    name: 'Formulário de Processos de atendimento',
    exact: true,
    component: ProtocolProcessForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/crm/protocolProcess/form/:id',
    name: 'Formulário de Processos de atendimento',
    exact: true,
    component: ProtocolProcessForm,
    layout: '/admin',
    sidebar: false,
    roles: ['AGENT_CRM']
  },
  {
    path: '/Team/form',
    name: 'Team Form',
    exact: true,
    component: TeamForm,
    layout: '/admin',
    sidebar: false,
    roles: ['TEAM_ACCESS']
  },
  {
    path: '/Team/form/:id',
    name: 'Team Form Update',
    exact: true,
    component: TeamForm,
    layout: '/admin',
    sidebar: false,
    roles: ['TEAM_ACCESS']
  },
  {
    path: '/paper',
    exact: true,
    component: Paper,
    layout: '/admin',
    sidebar: false,
    roles: ['CONFIGURE_QUALITY_MONITORING_PAPERS']
  },
  {
    path: '/paper/form',
    exact: true,
    component: PaperForm,
    layout: '/admin',
    sidebar: false,
    roles: ['CONFIGURE_QUALITY_MONITORING_PAPERS']
  },
  {
    path: '/paper/form/:id',
    exact: true,
    component: PaperEdit,
    layout: '/admin',
    sidebar: false,
    roles: ['CONFIGURE_QUALITY_MONITORING_PAPERS']
  },
  {
    path: '/configuration',
    name: 'Configurações',
    exact: true,
    icon: 'ni ni-settings text-green',
    component: Configuration,
    layout: '/admin',
    sidebar: true,
    roles: ['SYSTEM_CONFIGURATION']
  },
  {
    path: '/chat',
    name: 'Configurações',
    exact: true,
    icon: 'ni ni-settings text-green',
    component: ChatList,
    layout: '/admin',
    sidebar: false,
    roles: ['SYSTEM_CONFIGURATION']
  },
  {
    path: '/chat/form/',
    name: 'Configurações',
    exact: true,
    icon: 'ni ni-settings text-green',
    component: ChatForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SYSTEM_CONFIGURATION']
  },
  {
    path: '/chat/form/:id',
    name: 'Configurações',
    exact: true,
    icon: 'ni ni-settings text-green',
    component: ChatForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SYSTEM_CONFIGURATION']
  },
  {
    path: '/watson',
    name: 'Configurações',
    exact: true,
    icon: 'ni ni-settings text-green',
    component: WatsonList,
    layout: '/admin',
    sidebar: false,
    roles: ['SYSTEM_CONFIGURATION']
  },
  {
    path: '/watson/form/',
    name: 'Configurações',
    exact: true,
    icon: 'ni ni-settings text-green',
    component: WatsonForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SYSTEM_CONFIGURATION']
  },
  {
    path: '/watson/form/:id',
    name: 'Configurações',
    exact: true,
    icon: 'ni ni-settings text-green',
    component: WatsonForm,
    layout: '/admin',
    sidebar: false,
    roles: ['SYSTEM_CONFIGURATION']
  }
];

export default routes;
