import ServiceCRUD from '../../../services/ServiceCRUDFLEXCHAT';
import { apiChat, apiCase, apiSMSChat } from '../../../http';

class ChatBroadcastListService extends ServiceCRUD {
  constructor() {
    super('/broadcast-list');
  }

  getContactsFromBroadcastList(broadcastListId, queryParams) {
    return apiChat.get(`/broadcast-list/${broadcastListId}/contacts`, {
      params: queryParams
    });
  }

  getAllContactsFromBroadcastList(broadcastListId, queryParams) {
    return apiChat.get(`/broadcast-list/contacts`, {
      params: queryParams
    });
  }

  updateBroadcastWithMidia(data, id) {
    return apiChat.put(`broadcast-list/midia/${id}`, data, {
      headers: { 'content-type': 'multipart/form-data' }
    });
  }

  createBroadcastWithMidia(data) {
    return apiChat.post('broadcast-list/midia', data, {
      headers: { 'content-type': 'multipart/form-data' }
    });
  }

  getContacts(queryParams) {
    return apiChat.get(`/broadcast-list/contacts`, {
      params: queryParams
    });
  }

  addContactsToBroadcastList(broadcastListId, clients) {
    return apiChat.post('/broadcast-list/contacts', {
      broadcastListId,
      clients
    });
  }

  getAllWithoutPagination(showDisabledTransmissionLists) {
    return apiSMSChat.get(
      `/broadcast-list/all?showDisabled=${showDisabledTransmissionLists}`
    );
  }

  removeContactsFromBroadcastList(broadcastListId, clientIds = []) {
    return apiChat.put('/broadcast-list/contacts', {
      broadcastListId,
      clientIds
    });
  }

  executeBroadcastList(data) {
    return apiChat.post('/broadcast-list/execute', data);
  }

  resumeBroadcastList(data) {
    return apiChat.post(`/broadcast-list/resume/${data.broadcastListId}`);
  }

  pauseBroadcastList(data) {
    return apiChat.post(`/broadcast-list/pause/${data.broadcastListId}`);
  }

  syncBroadcastList(id) {
    return apiChat.get(`/broadcast-list/synchronize/${id}`);
  }

  importList(entityName, data, broadcastListId) {
    return apiCase.post(
      `/entity/${entityName}/import/broadcast-list/${broadcastListId}`,
      data
    );
  }

  changeBroadcastListStatus(id) {
    return apiChat.put(`/broadcast-list/change-status/${id}`);
  }

  getAllSearch(page, size, title, showDisable = false) {
    return apiChat.get(`${this.path}?showDisabled=${showDisable}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      },
      params: {
        title: title
      }
    });
  }
}

export default new ChatBroadcastListService();
