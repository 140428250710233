import React from 'react';
import { ActionCenterDiv, ActionDiv, LineActionDiv } from './styles';
import { IoInformationCircleOutline } from 'react-icons/io5';
export const TransferMessage = ({ action }) => {
  const message =
    action.action === 'transfer_to_queue'
      ? `Fila ${action.queue}`
      : `Usuário ${action.agent}` ?? '';

  return (
    <ActionDiv isAnswer={action.action === 'answer'}>
      <LineActionDiv />
      <ActionCenterDiv>
        <IoInformationCircleOutline />
        {action.action === 'answer'
          ? `Conversa atendida por atendente ${action.agent}`
          : `Conversa transferida para ${message}`}{' '}
      </ActionCenterDiv>
      <LineActionDiv />
    </ActionDiv>
  );
};
