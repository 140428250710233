import DefaultModal from 'components/Modal/DefaultModal';
import { HiMiniChatBubbleLeftEllipsis } from 'react-icons/hi2';
import { LuEye } from 'react-icons/lu';
import React from 'react';
import { Button } from 'reactstrap';

export const HistoryChatBotMOdal = ({ options }) => {
  const [show, setShow] = React.useState(false);
  const onModalToggle = (open) => {
    setShow(open);
  };
  return (
    <>
      <DefaultModal
        show={show}
        onModalToggle={onModalToggle}
        title="Histórico do Chatbot"
        modalContainerClasses="modal-sm"
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '90%',
              flexDirection: 'column',
              flexWrap: 'wrap'
            }}
          >
            {options.map((option, index) => (
              <div
                key={index}
                className="timeline timeline-one-side mx-0"
                data-timeline-axis-style="dotted"
                data-timeline-content="axis"
              >
                <div className="timeline-block pb-5">
                  <span className="timeline-step badge-info ">
                    <HiMiniChatBubbleLeftEllipsis />
                  </span>

                  <div
                    className="timeline-content"
                    style={{ maxWidth: '50rem' }}
                  >
                    <strong>{option?.value}</strong>
                  </div>

                  <small
                    className="text-muted font-weight-bold mt-2"
                    style={{
                      marginLeft: '3.8rem'
                    }}
                  >
                    <li className="fas fa-clock mr-2"></li>

                    {option?.date}
                  </small>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DefaultModal>
      <Button
        size="sm"
        color="info"
        onClick={() => {
          setShow(true);
        }}
      >
        <LuEye />
      </Button>
    </>
  );
};
