import React from 'react';
import { generateFormattedDate } from './assets/generateFormattedDate';
import { Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import ModalImage from 'react-modal-image';
import { FaFileAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import {
  ImageContent,
  MessageContent,
  MessageText,
  UserContent,
  UserName,
  UserTimestamp
} from './components/styled';
import './style.css';

import { getStatusIcon } from './assets/eventStatusUtils';

const UserMessage = ({
  message = '',
  title = '',
  time = '',
  name = '',
  type = 'text',
  options = '',
  description = '',
  source = '',
  status = {}
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const copyToClipBoard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      toast.info(`Mensagem copiada!`, { autoClose: 1500 });
    } catch (err) {
      console.log('error copy => ', err);
      toast.error('Erro ao copiar mensagem!', { autoClose: 1500 });
    }
  };

  function linkify(message) {
    const urlPattern = /(https?:\/\/[^\s]+)/gim;
    let parts = '';

    if (typeof message === 'string') {
      parts = message?.split(urlPattern);

      const elements = parts.map((part, index) => {
        if (part.match(urlPattern)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        } else {
          // Se não corresponder a uma URL, retorna o texto normal
          return <span key={index}>{part}</span>;
        }
      });

      return <>{elements}</>;
    }
  }

  return (
    <UserContent color={systemConfiguration.primary_color}>
      <UserName>{name}:</UserName>
      <MessageContent>
        <MessageText
        // onClick={() => copyToClipBoard(title || message || options)}
        >
          {(message && options) ||
            (message &&
              (type === 'link' ? <div>{linkify(message)}</div> : message)) ||
            options}
          {(type === 'file' || type === 'DOCUMENT') && (
            <div
              className="messagePdf"
              onClick={() => window.open(source, '_blank')}
            >
              <FaFileAlt size="24" color="#d9534f" />
              <label style={{ cursor: 'pointer' }}>
                {description?.split('_upload_atendente_')[0]}
              </label>
            </div>
          )}
        </MessageText>
        {options && (
          <Badge
            className="user-option"
            color="light"
            title={`Opção da ${name}`}
          >
            Opção
          </Badge>
        )}
        {type === 'image' && (
          <div>
            <ImageContent>
              <ModalImage small={source} medium={source} large={source} />
            </ImageContent>
            <MessageText>{description}</MessageText>
          </div>
        )}
        {type === 'audio' && (
          <ImageContent>
            <audio src={source} controls={true} autoPlay={false} />
          </ImageContent>
        )}
        {type === 'video' && (
          <div>
            <ImageContent>
              <video
                src={source}
                autoPlay={false}
                controls={true}
                width="100%"
                height="300"
              />
            </ImageContent>
            <MessageText>{description}</MessageText>
          </div>
        )}
      </MessageContent>

      <UserTimestamp>
        {time && generateFormattedDate(time)} {status && getStatusIcon(status)}
      </UserTimestamp>
    </UserContent>
  );
};

export default UserMessage;
