export const hourRules = {
  required: 'Hora é obrigatório!',
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida! (00:00:00)'
  }
};

export const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

export const toISOFormat = (dateTimeString, bar = false) => {
  const [date] = dateTimeString.split(' ');
  const [DD, MM, YYYY] = date.split('/');

  if (bar) return `${MM}/${DD}/${YYYY}`;
  return `${MM}-${DD}-${YYYY}`;
};

export const watsonToId = (watson) => {
  const data = {};
  watson.forEach((item) => {
    data[item.id] = item.nome_canal;
  });

  return data;
};

export const queueNameId = (queue) => {
  const data = {};
  queue.forEach((item) => {
    data[item.id] = item.name;
  });

  return data;
};
