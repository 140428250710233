import { apiUc } from 'http/index';
import { apiInstagramChat as apiChatTs } from '../../../../http';
import moment from 'moment';

export const getBFFAgentDashboard = async ({ agent, queues }) => {
  const {
    data: { data }
  } = await apiUc.get('/api/bff-agent-dashboard', {
    params: {
      agentCode: agent,
      queues
    }
  });

  return data;
};

export const getDashboardChatIndividually = async ({ agent }) => {
  const { data = { data } } = await apiChatTs.get(
    `/dashboard/serviceData/agent?agentLogin=${agent}&startDate=${moment()
      .startOf('month')
      .format('YYYY-MM-DD')}&endDate=${moment()
      .endOf('month')
      .format('YYYY-MM-DD')}`
  );
  return data;
};
