import React, { useEffect } from 'react';
import { TotalRegister } from '../../../../../components/TotalRegister';
import Pagination from 'components/Pagination';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Loader from 'react-loader-spinner';
import { Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import PaginationSticker from 'components/PaginationSticker';

const Table = ({
  protocols,
  formatColumn,
  loading,
  quantity,
  activePage,
  handlePageChange,
  itemsPerPage,
  quantityCustomFields = 0,
  showAdvancedFilters = false
}) => {
  const [sizePagination, setSizePagination] = React.useState(0);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const addSortFunctionsToTheColumnThatCanBeSortable = () => {
    return formatColumn().map((column) => {
      if (column?.sort) {
        return {
          ...column,
          headerStyle: {
            ...column.headerStyle,
            cursor: 'pointer'
          }
          // onSort: () => handleSort(column)
        };
      }
      return column;
    });
  };

  useEffect(() => {
    const customCalc = showAdvancedFilters ? quantityCustomFields * 20 : 0;
    console.log('showAdvancedFilters', 600 + customCalc);
    setSizePagination(600 + customCalc);
  }, [showAdvancedFilters]);

  return (
    <>
      {quantity > 0 ? (
        <>
          {quantity >= 0 && <TotalRegister totalRegister={quantity} />}
          <ToolkitProvider
            data={protocols}
            keyField="id"
            columns={addSortFunctionsToTheColumnThatCanBeSortable()}
          >
            {(props) => (
              <div className="py-4 table-responsive">
                {loading ? (
                  <Row className="justify-content-md-center">
                    <Loader
                      type="TailSpin"
                      color={systemConfiguration.primary_color}
                      height={100}
                      width={100}
                    />
                  </Row>
                ) : (
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={false}
                    bordered={false}
                  />
                )}
              </div>
            )}
          </ToolkitProvider>
          <Pagination
            itemsCountPerPage={itemsPerPage}
            activePage={activePage}
            totalItemsCount={quantity}
            onChange={handlePageChange.bind(this)}
          />
        </>
      ) : null}
    </>
  );
};

export default Table;
