import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { individual_header_style } from '../../../../../../../../../assets/styles/table_custom_style';
import BootstrapTable from 'react-bootstrap-table-next';
import { Table } from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';

const ListMultiContactsTable = ({
  contacts,
  handleOnSelect,
  selectedRows,
  handleOnSelectAll,
  initMultiContactModal,
  handleSelectcontact,
  controlDelete
}) => {
  const createDynamicColumns = () => {
    const columns = [];

    columns.push(
      {
        dataField: 'name',
        text: 'Nome',
        sort: true,
        style: {
          paddingTop: '25px',
          paddingBottom: '25px'
        },
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'client_phone',
        text: 'Telefone',
        style: {
          paddingTop: '25px',
          paddingBottom: '25px'
        },
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      }
    );

    return [...columns];
  };

  const dynamicFunctionColumns = createDynamicColumns();

  return (
    <>
      {initMultiContactModal ? (
        <ToolkitProvider
          data={contacts || []}
          keyField="id"
          columns={dynamicFunctionColumns}
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                selectRow={{
                  mode: 'checkbox',
                  selected: selectedRows.map((r) => r.id),
                  onSelect: handleOnSelect,
                  onSelectAll: handleOnSelectAll
                }}
                bootstrap4={true}
                bordered={false}
                classes="align-items-center"
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <Table md="10" hover={true}>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {contacts.length ? (
              contacts.map((contact) => {
                return (
                  <tr key={contact.id} style={{ cursor: 'pointer' }}>
                    <td>{contact.name}</td>
                    <td>
                      {contact.client_phone
                        ? contact.client_phone
                        : contact.telefone}
                    </td>
                    <td>
                      <ReactButton
                        btnColor="primary"
                        onClick={() => handleSelectcontact(contact)}
                      >
                        Iniciar Conversa
                      </ReactButton>
                    </td>
                    <td>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => controlDelete(contact.id)}
                      >
                        Excluir
                      </ReactButton>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3">
                  <p>Nenhum contato registrado</p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ListMultiContactsTable;
