import React, { useState } from 'react';
import { Row, Card, Col, ListGroup, ListGroupItem, Button } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import ReportService from '../service';
import { TotalRegister } from '../../../../../components/TotalRegister';
import { AudioStreamReportTelephony as AudioStream } from '../../../../../components/AudioController/AudioStream';
import { toast } from 'react-toastify';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import PapersListModal from '../../../QualityMonitoring/components/PapersListModal';
import {
  individual_column_style,
  individual_header_style,
  individual_icon_header_style,
  icon_column_style
} from '../../../../../assets/styles/table_custom_style';

import AssessmentService from '../../../QualityMonitoring/service';
import { DownloadButton } from '../../CallMade/components/DownloadButton';

const itemStyle = { padding: '5px 0 0 20px' };

const Analytical = ({
  dataReport,
  totalRecords,
  isDownloadAndListen = true,
  needPermission = false
}) => {
  const [papersListModalOpen, setPapersListModalOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState(undefined);
  const [currentCdrUniqueId, setCurrentCdrUniqueId] = useState(undefined);
  const [currentPapersAssessments, setCurrentPapersAssessments] = useState([]);

  async function getPapersAssessments(id) {
    try {
      const { data } = await AssessmentService.getAssessmentsByCdrReport(id);
      setCurrentPapersAssessments(data.data);
    } catch (error) {
      toast.error('Erro ao listar as avaliações dos questionários!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }

  const isDownload = () => {
    if (isDownloadAndListen) {
      return needPermission
        ? [
            verifyRoles(['DOWNLOAD_CALLS']) && {
              dataField: 'download',
              text: 'Download',
              sort: true,
              style: icon_column_style,
              headerStyle: individual_icon_header_style,

              formatter: (rowContent, row) => (
                <DownloadButton row={row}></DownloadButton>
              )
            },

            verifyRoles(['LISTEN_CALLS']) && {
              dataField: 'listen',
              text: 'Escutar',
              sort: true,
              style: icon_column_style,
              headerStyle: individual_icon_header_style,
              // eslint-disable-next-line react/display-name
              formatter: (_, row) => (
                <AudioStream
                  ReportService={ReportService}
                  uniqueid={row.uniqueid}
                  disabled={row.disposition === 'ANSWERED' ? false : true}
                  reasonDisabled={
                    row.disposition === 'ANSWERED' ? '' : 'Não atendida'
                  }
                />
              )
            }
          ]
        : [
            {
              dataField: 'download',
              text: 'Download',
              sort: true,
              style: icon_column_style,
              headerStyle: individual_icon_header_style,
              // eslint-disable-next-line react/display-name
              formatter: (_, row) => {
                return <DownloadButton row={row}></DownloadButton>;
              }
            },
            {
              dataField: 'listen',
              text: 'Escutar',
              sort: true,
              style: icon_column_style,
              headerStyle: individual_icon_header_style,
              // eslint-disable-next-line react/display-name
              formatter: (_, row) => {
                return (
                  <AudioStream
                    ReportService={ReportService}
                    uniqueid={row.uniqueid}
                    disabled={row.disposition === 'ANSWERED' ? false : true}
                    reasonDisabled={
                      row.disposition === 'ANSWERED' ? '' : 'Não atendida'
                    }
                  />
                );
              }
            }
          ];
    }
    return [{}];
  };

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRecords >= 0 && (
              <TotalRegister totalRegister={totalRecords} />
            )}
            <Card>
              <ToolkitProvider
                data={dataReport.listCallInAnalyticalDTO || []}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'calldateStart',
                    text: 'Data início',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'clidNum',
                    text: 'Origem',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'dst',
                    text: 'Destino',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'nameAgent',
                    text: 'Agente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'ddr',
                    text: 'DDR',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'queue',
                    text: 'Fila',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'centroDeCusto',
                    text: 'Setor',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'carrier',
                    text: 'Operadora',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'dispositionFormatted',
                    text: 'Status da Chamada',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'whoDisconnectDisplay',
                    text: 'Quem Desligou',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'ivrWait',
                    text: 'Espera na URA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'ivrOp',
                    text: 'Opção da URA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'transferIn',
                    text: 'Recebeu transferência de',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'transferOut',
                    text: 'Transferiu para',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeWaitingCallFormated',
                    text: 'Tempo em espera',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeCallFormated',
                    text: 'Tempo em ligação',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'monitoring',
                    text: 'Monitoria',
                    sort: true,
                    hidden: !verifyRoles(['ANSWER_QUALITY_MONITORING_PAPERS']),
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (_, row) => {
                      return (
                        <Button
                          color="success"
                          size="sm"
                          onClick={async () => {
                            setCurrentReport(row.acctid);
                            setCurrentCdrUniqueId(row.uniqueid);
                            await getPapersAssessments(row.acctid);
                            setPapersListModalOpen(true);
                          }}
                        >
                          <i className="fas fa-flag"></i>
                        </Button>
                      );
                    }
                  },
                  ...isDownload()
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
              <Row className="mb-3 mt-3">
                <Col md="3">
                  <ListGroup flush>
                    <ListGroupItem disabled style={itemStyle}>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Chamadas atendidas:
                      </span>{' '}
                      {dataReport.quantityCallAnswered}
                    </ListGroupItem>
                    <ListGroupItem disabled style={itemStyle}>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Chamadas não atendidas:
                      </span>{' '}
                      {dataReport.quantityCallNoAnswered}
                    </ListGroupItem>
                    <ListGroupItem disabled style={itemStyle}>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Tempo médio de Serviço:
                      </span>{' '}
                      {dataReport.averageServiceFormated}
                    </ListGroupItem>
                    <ListGroupItem disabled style={itemStyle}>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Tempo médio de Espera:
                      </span>{' '}
                      {dataReport.averageWaitingTimeFormated}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </Row>
      <PapersListModal
        isModalOpen={papersListModalOpen}
        onModalToggle={setPapersListModalOpen}
        cdrReport={currentReport}
        cdrUniqueId={currentCdrUniqueId}
        papersAssessments={currentPapersAssessments}
        dataReport={dataReport}
        ReportService={ReportService}
      />
    </>
  );
};
export default Analytical;
