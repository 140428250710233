import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AudioPlayer from './../AudioStream/components/AudioPlayer';
import ReportService from '../../../views/Report/Callcenter/ReceivedCall/service';
import Loader from 'react-loader-spinner';

export function AudioStreamPlayer({ uniqueid, nameAgent, calldateStart,calldateStartAnswers, Origem, Queue, MonitoringForm, executeDisabled }) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [disable, setDisable] = useState(false);
  const [isLoadedMetadata, setIsLoadedMetadata] = useState(false);
  const [srcAudio, setSrcAudio] = useState(null);

  useEffect(() => {
    if (uniqueid) {
      try {
        const response = ReportService.streamAudio(uniqueid);
        setSrcAudio(() => new Audio(response).src);
      } catch (error) {
        setDisable(true);
        toast.error('Gravação não encontrada', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    } else {
      setDisable(true);
    }
  }, [uniqueid]);

  return (
    <>
      {!disable && (
        <>
          <div
            style={{
              display: isLoadedMetadata ? undefined : 'none'
            }}
          >
            <AudioPlayer srcAudio={srcAudio} onLoad={setIsLoadedMetadata} nameAgent = {nameAgent} calldateStart = {calldateStart} uniqueid = {uniqueid}  ReportService={ReportService} Origem= {Origem} Queue= {Queue} MonitoringForm ={MonitoringForm} calldateStartAnswers= {calldateStartAnswers} executeDisabled={executeDisabled}/>
          </div>

          <div
            style={{
              display: isLoadedMetadata ? 'none' : 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginBottom: '3rem'
            }}
          >
            <Loader
              type="TailSpin"
              color={systemConfiguration.primary_color}
              height={60}
              width={60}
            />
          </div>
        </>
      )}
    </>
  );
}
