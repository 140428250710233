import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'reactstrap';

import Pagination from 'components/Pagination';
import Loader from 'components/Loader';

import FilterContactsForm from './components/FilterContactsForm';
import ListContactsTable from './components/ListContactsTable';

import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { useListContacts } from '../../../hooks/useListContacts';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';

const ContactCRM = ({
  selectedRecordTypeId,
  setSelectedRecordType,
  recordTypes
}) => {
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({});

  const {
    getContacts,
    contacts,
    totalContacts,
    isLoadingContacts,
    clearContacts
  } = useListContacts({
    recordTypeId: selectedRecordTypeId
  });

  const { customFields, getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.CONTACT,
    selectedRecordTypeId
  );

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    clearContacts();
    setActivePage(1);
  }, [selectedRecordTypeId, clearContacts]);

  const handleSearch = useCallback(
    (searchFilters) => {
      setActivePage(1);
      setFilters(searchFilters);
      getContacts(1, searchFilters);
    },
    [getContacts]
  );

  const handlePageChange = (newPage) => {
    setActivePage(newPage);
    getContacts(newPage, filters);
  };

  const reloadContacts = useCallback(() => {
    getContacts(1, {});
  }, [getContacts]);

  return (
    <>
      <FilterContactsForm
        handleSearch={handleSearch}
        customFields={customFields}
        isLoadingContacts={isLoadingContacts}
        selectedRecordTypeId={selectedRecordTypeId}
        setSelectedRecordType={setSelectedRecordType}
        recordTypes={recordTypes}
        recordTypeId={selectedRecordTypeId}
        contacts={contacts}
        totalContacts={totalContacts}
      />

      <Row className="mt-4">
        {isLoadingContacts ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : (
          <ListContactsTable
            contacts={contacts}
            reloadContacts={reloadContacts}
          />
        )}
      </Row>
      <Pagination
        activePage={activePage}
        totalItemsCount={totalContacts}
        onChange={handlePageChange.bind(this)}
      />
    </>
  );
};

export default ContactCRM;
