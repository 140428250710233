import React from 'react';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { IoIosArrowForward } from 'react-icons/io';
import BootstrapTable from 'react-bootstrap-table-next';
import './style/styleInernalTable.css';
import {
  individual_column_style,
  individual_header_style
} from 'assets/styles/table_custom_style';
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp
} from 'react-icons/md';
import { TotalRegister } from '../../../../../components/TotalRegister';
import { icon_column_style } from 'assets/styles/table_custom_style';
import { individual_icon_header_style } from 'assets/styles/table_custom_style';
import { ReportButtonContainer } from '../assets/components/ButtonContainer';
import { wrap } from 'lodash';

const expandRow = {
  renderer: (row) => (
    <BootstrapTable
      keyField="chatId"
      data={row.actions}
      classes="inner-table-chat-details"
      columns={[
        {
          dataField: 'agentName',
          text: 'Agente',
          style: individual_column_style
        },
        {
          text: 'Fila',
          dataField: 'queueName',
          style: individual_column_style
        },
        {
          text: 'Recebeu de',
          formatter: (_, row) => {
            if (row.receivedFromQueue) {
              return `Fila: ${row.receivedFromQueue}`;
            }

            if (row.receivedFromAgent) {
              return `Usuário: ${row.receivedFromAgent}`;
            }

            return '-';
          },
          style: individual_column_style
        },
        {
          text: 'Tempo no Bot',
          dataField: 'durationInBot',
          style: individual_column_style
        },
        {
          text: 'Tempo de espera na fila',
          dataField: 'durationInQueue',
          headerStyle: {
            ...individual_header_style,
            width: '150px',
            wrap: 'wrap'
          }
        },

        {
          text: 'Tempo Para Primeira Resposta',
          dataField: 'durationFirstResponse',
          style: individual_column_style,
          headerStyle: {
            ...individual_header_style,
            width: '180px',
            wrap: 'wrap'
          }
        },
        {
          text: 'Transferiu para',
          formatter: (cell, row) => {
            if (row.transferToQueue) {
              return `Fila: ${row.transferToQueue}`;
            }

            if (row.transferToAgent) {
              return `Usuário: ${row.transferToAgent}`;
            }

            return '-';
          },
          style: individual_column_style
        },
        {
          text: 'Tempo de atendimento',
          dataField: 'durationInAction',
          style: individual_column_style
        }
      ]}
    />
  ),
  showExpandColumn: true,
  expandColumnRenderer: ({ expanded }) => (
    <IoIosArrowForward
      style={{ transition: '.5s' }}
      className={expanded ? 'expandedArrow' : ''}
    />
  ),
  expandHeaderColumnRenderer: ({ isAnyExpands }) =>
    isAnyExpands ? <MdKeyboardDoubleArrowUp /> : <MdKeyboardDoubleArrowDown />
};

const AnalyticalTransfers = ({ dataReport, totalRegister }) => {
  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={dataReport || []}
                keyField="chatId"
                columns={[
                  {
                    dataField: 'customerName',
                    text: 'Cliente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (cell) => cell ?? '-'
                  },
                  {
                    dataField: 'startDate',
                    text: 'Data de Criação',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'endDate',
                    text: 'Data de Encerramento',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'totalTimeChat',
                    text: 'Tempo Total da Conversa',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (cell) => cell ?? '-'
                  },

                  {
                    dataField: 'action',
                    text: 'Ações',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (rowContent, row) => {
                      const id = row?.chatId ?? row?.id;
                      return (
                        <>
                          <ReportButtonContainer id={id} />
                        </>
                      );
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="table-chat-details"
                      expandRow={expandRow}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};

export default AnalyticalTransfers;
