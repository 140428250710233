import ServiceCRUD from '../../../../../services/ServiceCRUDNEWCHANNEL';
import { apiInstagramChat as http } from '../../../../../http/index';

class ChatBotReportService extends ServiceCRUD {
  constructor() {
    super('configurations/channels');
  }

  getWatson({ showDisable = true }) {
    return http.get(`configurations/watson`, {
      params: {
        showDisable
      }
    });
  }

  getOptionsWatson(idWatson) {
    return http.get(`options/watson/${idWatson}`);
  }

  getHistoryWatson({
    finalDate,
    initialDate,
    watsonId,
    channel,
    chatId,
    options,
    queueId,
    page = 1,
    limit = 10,
    showAllWatson = false
  }) {
    return http.get(`historyWatson`, {
      params: {
        finalDate,
        initialDate,
        watsonId,
        channel,
        chatId,
        options,
        queueId,
        page,
        limit,
        showAllWatson
      }
    });
  }
}

export default new ChatBotReportService();
