import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import TagClientService from 'views/CRM/Tags/services/TagClientService';

import FlexChatIntegrationService from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import {
  dateRules,
  reportOptions,
  situationOptions,
  closedByOptions,
  booleanOptions,
  contactOrigin,
  satisfactionOptions,
  channelOptions,
  groupByOptions
} from './assets';
import Pagination from 'components/Pagination';
import UserService from '../../../User/service';
import StatusService from '../../../status/service';
import IssueService from '../../../Issue/service';
import ProductService from '../../../Product/service';
import SectorService from '../../../Sector/service';
import QueueService from '../../../Queue/service';
import TagService from '../../../TagsChat/service';
import ReportService from './service';
import ExportService from './service/Export';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Select from 'react-select';
import { custom_select } from '../../../../assets/styles/multiple_select';
import Loader from 'react-loader-spinner';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportAnalythical from './tables/Analytical';
import ReportSynthetic from './tables/Synthetic';
import ChatBroadcastListService from '../../../ChatBroadcastList/service/index';
import * as fileSaver from 'file-saver';
import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import { Tooltip } from '@material-ui/core';
import FormTooltip from 'components/Inputs/FormTooltip';

import ReasonService from '../../../reason/service';
import SubReasonService from '../../../subReason/service';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { Line } from 'react-chartjs-2';
import { CardCabecalho } from '../DashboardDataBot/styled';
import { add, eachDayOfInterval, format } from 'date-fns';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';
import { FlexSelectSearchTag } from 'components/Inputs/SelectWithSearch';
import AnalyticalTransfers from './tables/AnalyticalTransfers';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { DetailedSynthetic } from './tables/DetailedSynthetic';
import PaginationSticker from 'components/PaginationSticker';

const ConversationReport = () => {
  const { control, handleSubmit, errors, setValue, watch, reset, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [users, setUsers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [queues, setQueues] = useState([]);
  const [status, setStatus] = useState([]);
  const [issues, setIssues] = useState([]);
  const [products, setProducts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [reportType, setReportType] = useState(reportOptions[0]);
  const [ReportTypeAnalytical, setReportTypeAnalytical] = useState([]);
  const [transferData, setTransferData] = useState([]);
  const [detailedSyntheticData, setDetailedSyntheticData] = useState([]);
  const [ReportTypeSynthetic, setReportTypeSynthetic] = useState('');
  const [media, setMedia] = useState('');
  const showDisables = watch('disable');
  const [dataToSend, setDataToSend] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [flagIntervalo, setFlagIntervalo] = useState(false);
  const [intervalData, setIntervalData] = useState({});
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );
  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
  const [reasons, setReasons] = useState([]);
  const [subreasons, setSubreasons] = useState([]);
  const [canalOptions, setCanalOptions] = useState([]);
  const [channels, setChannels] = useState([]);
  const [showTransfers, setShowTransfers] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const showAllConversations = localStorage.getItem('show_all_conversations');
  const filtersReportChat = JSON.parse(
    localStorage.getItem('filters_report_chat') || '{}'
  );

  const login = localStorage.getItem('login');
  const selectedReasonId = watch('motivo');

  const groupByDetailedOptions = [
    {
      label: 'Fila',
      value: 'QUEUE'
    },
    {
      label: 'Agente',
      value: 'AGENT'
    },
    {
      label: 'Cliente',
      value: 'CUSTOMER'
    },
    {
      label: 'Canal',
      value: 'CHANNEL'
    },
    {
      label: 'Motivo',
      value: 'REASON'
    },
    {
      label: 'Submotivo',
      value: 'SUB_REASON'
    }
  ];

  const nomes = [
    'facebook',
    'watson',
    'whatsapp',
    'telegram',
    'instagram',
    'sms',
    'webchat',
    'flex4u'
  ];

  const config = {
    type: 'line',
    options: {
      scales: {
        xAxis: {
          type: 'time'
        }
      }
    }
  };

  const handleGetSubReasons = useCallback(
    async (selectedreasonName) => {
      let value = '';
      if (selectedreasonName) {
        reasons.forEach((e) => {
          if (selectedreasonName === e.name) {
            value = e.id;
          }
        });
      }
      if (value) {
        await SubReasonService.getByReasonId(value)
          .then((response) => {
            const subreasonData = response.data.content;

            setSubreasons(subreasonData);
          })
          .catch((err) => {
            const { data } = err.response;
            toast.error(data.message, {
              autoClose: 3000,
              closeOnClick: true
            });
          });
      } else {
        setSubreasons([]);
      }
    },
    [reasons]
  );

  //messagemGeneratePdfCsv
  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  useEffect(() => {
    if (showDisables === true) {
      QueueService.getAllShowDisable(showDisables)
        .then((response) => {
          setQueues(response.data.data);
        })
        .catch((err) => {});
    } else {
      QueueService.getAll()
        .then((response) => {
          setQueues(response.data.data);
        })
        .catch((err) => {});
    }
  }, [showDisables]);

  useEffect(() => {
    getUser(true);
  }, [usersShowDisable]);

  const getUser = (showDisable = false) => {
    if (!showDisable) {
      setLoading(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        setUsers(response.data.data);
        if (!showDisable) {
          setLoading(false);
        }
      })
      .catch((err) => {
        if (!showDisable) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    SectorService.getAll()
      .then((response) => {
        setSectors(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    setLoading(true);
    FlexChatIntegrationService.getAllChannels().then((res) => {
      setChannels(res.data.data);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    IssueService.getAll()
      .then((response) => {
        setIssues(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    ProductService.getAll()
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    StatusService.getWithoutPagination()
      .then((response) => {
        setStatus(response.data.content);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    TagService.showTags()
      .then((response) => {
        const { data } = response;
        const { list } = data;

        setTags(list);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    ReasonService.getWithoutPagination()
      .then((response) => {
        setReasons(response.data.content);
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data) || 'Erro ao carregar motivos';
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    ChatBroadcastListService.getAllWithoutPagination()
      .then((response) => {
        setTemplates(response.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    FlexChatIntegration.getAllChannels().then((res) => {
      const info = res.data.data;

      let array = [];

      nomes.forEach((nome) => {
        if (!info[nome]) return;
        if (nome === 'whatsapp') {
          info[nome].forEach((item, index) => {
            array.push(info[nome][index] ?? []);
          });
          return;
        }
        array.push(info[nome] ?? []);
      });

      const list = array.map((canal) => {
        return { label: canal?.nome_canal, value: canal?.id };
      });

      setCanalOptions(list);
    });
  }, []);

  useEffect(() => {
    handleGetSubReasons(selectedReasonId);
  }, [handleGetSubReasons, selectedReasonId]);

  const clearData = () => {
    setReportTypeAnalytical([]);
    setReportTypeSynthetic('');
    setDataToSend({});
    setActivePage(1);
    setQuantity(0);
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${YYYY}-${MM}-${DD}`;
  };

  const getFormatedType = (value) => {
    switch (value) {
      case 1:
        return 'analitico';
      case 2:
        return 'sintetico';
      case 3:
        return 'sinteticoDetalhado';
      default:
        break;
    }
  };

  const getDataDTO = (data) => {
    const loginAtendente = localStorage.getItem('login');

    let formatedData;

    formatedData = {
      ...data,
      loginAtendente: loginAtendente,
      dataInicial: `${toISOFormat(data.dataInicial)}`,
      dataFinal: `${toISOFormat(data.dataFinal)}`
    };

    if (!formatedData.motivo) delete formatedData.motivo;
    if (!formatedData.submotivo) delete formatedData.submotivo;
    if (!formatedData.templates) delete formatedData.templates;
    if (!formatedData.origemDoContato) delete formatedData.origemDoContato;
    if (!formatedData.assunto) delete formatedData.assunto;
    if (!formatedData.setor) delete formatedData.setor;
    if (!formatedData.produto) delete formatedData.produto;
    if (!formatedData.atendente) delete formatedData.atendente;
    if (!formatedData.encerradaPor) delete formatedData.encerradaPor;
    if (!formatedData.fila) delete formatedData.fila;
    if (!formatedData.atendimentoBot) delete formatedData.atendimentoBot;
    if (!formatedData.canal) delete formatedData.canal;
    if (!formatedData.cpf) delete formatedData.cpf;
    if (!formatedData.email) delete formatedData.email;
    if (!formatedData.isMobile) delete formatedData.isMobile;
    if (!formatedData.satisfacao) delete formatedData.satisfacao;
    if (!formatedData.nome) delete formatedData.nome;
    if (!formatedData.situacao) delete formatedData.situacao;
    if (!formatedData.status) delete formatedData.status;
    if (!formatedData.sucessoAtendimento)
      delete formatedData.sucessoAtendimento;
    if (!formatedData.encerradaPor) delete formatedData.encerradaPor;
    if (!formatedData.tag) delete formatedData.tag;
    if (!formatedData.conteudoDaMensagem)
      delete formatedData.conteudoDaMensagem;
    if (!formatedData.telefone) delete formatedData.telefone;
    else {
      formatedData = {
        ...formatedData,
        telefone: formatedData.telefone.replace(/\D+/g, '')
      };
    }

    if (formatedData.group === '' && reportType.value === 2) {
      formatedData = {
        ...formatedData,
        group: 'fila'
      };
    } else {
      if (!formatedData.group) delete formatedData.group;
    }

    delete formatedData.reportType;
    delete formatedData.disable;

    if (formatedData?.tags.length > 0)
      formatedData = {
        ...formatedData,
        tags: formatedData?.tags?.map((tag) => tag.value)
      };

    setDataToSend(formatedData);
    return formatedData;
  };

  function getDayLabels(startDay, endDay) {
    let eachDay;

    add(new Date(2014, 8, 1, 10, 19, 50), {
      days: 1
    });

    eachDay = eachDayOfInterval({
      start: add(new Date(startDay), {
        days: 1
      }),
      end: add(new Date(endDay), {
        days: 1
      })
    });

    return eachDay.map((day) => format(new Date(day), 'dd/MM'));
  }

  const getFiles = (typeReport, dataToSend, useLoader) => {
    setLoadingDownload(true);
    if (media === 'csv' || media === 'xlsx') {
      if (showTransfers) {
        const selectedReportType =
          typeReport === 'analitico' ? 'analytical' : 'syntetic';
        ReportService.getDownloadsTransfers(
          selectedReportType,
          dataToSend,
          media
        )
          .then((res) => {
            if (res.data.size === 0)
              toast.info('Nenhum registro foi encontrado!');
            else {
              fileSaver.saveAs(
                new Blob([res.data], { type: res.headers['content-type'] }),
                `Relatório_de_Conversas.${media}`
              );
            }
          })
          .catch((err) => {
            toast.error(err || `Erro ao gerar documento ${media}`, {
              autoClose: 3000,
              closeOnClick: true
            });
          })
          .finally(() => {
            setLoadingReport(false);
            setMedia('');
            setLoadingDownload(false);
          });

        return;
      }
      ExportService.getDownloads(typeReport, dataToSend)
        .then((res) => {
          if (res.data.size === 0)
            toast.info('Nenhum registro foi encontrado!');
          else {
            fileSaver.saveAs(
              new Blob([res.data], { type: res.headers['content-type'] }),
              'Relatório_de_Conversas.csv'
            );
          }
        })
        .catch((err) => {
          toast.error(err || `Erro ao gerar documento ${media}`, {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setMedia('');
          setLoadingReport(false);
          setLoadingDownload(false);
        });

      return;
    } else {
      ExportService.getDownloadsPdf(typeReport, dataToSend)
        .then((res) => {
          if (res.data.size === 0)
            toast.info('Nenhum registro foi encontrado!');
          else {
            fileSaver.saveAs(
              new Blob([res.data], { type: 'application/pdf' }),
              'Relatório_de_Conversas.pdf'
            );
          }
        })
        .catch((err) => {
          toast.error(err || `Erro ao gerar documento ${media}`, {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setMedia('');
          setLoadingReport(false);
          setLoadingDownload(false);
        });
    }
  };

  async function getReports(typeReport, dataToSend, page, useLoader = true) {
    if (dataToSend.group === 'intervalo') {
      let dayLabels = getDayLabels(
        dataToSend.dataInicial,
        dataToSend.dataFinal
      );
      await ReportService.getSynteticReportInterval(dataToSend).then((res) => {
        let newArr = [];
        let labels = [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00'
        ];

        res.data.intervalos.map((intervalo) => {
          newArr.push(intervalo.conversas);
        });

        let dataDay = [];

        let conversasPorDia = res.data.conversasPorDia;

        conversasPorDia.map((conversa) => {
          dataDay.push(conversa.slice(12, conversa.length).split(' '));
        });

        let conversasDia = [];

        dataDay.map((data) => {
          conversasDia.push(data[1]);
        });

        setIntervalData({
          dataHour: {
            total: res.data.quantidadeDeConversas,
            labels: labels,
            datasets: [
              {
                label: 'Conversas',
                data: newArr,
                fill: false,
                backgroundColor: ['rgb(54, 162, 235)'],
                tension: 0
              }
            ]
          },
          dataDay: {
            total: res.data.quantidadeDeConversas,
            labels: dayLabels,
            datasets: [
              {
                label: 'Conversas',
                data: conversasDia,
                fill: false,
                backgroundColor: ['rgb(54, 162, 235)'],
                tension: 0
              }
            ]
          }
        });
      });
      setFlagIntervalo(true);
    } else setFlagIntervalo(false);
    if (media) {
      getFiles(typeReport, dataToSend, useLoader);
      return;
    } else {
      switch (typeReport) {
        case 'analitico':
          await reportAnalitical(useLoader, dataToSend, typeReport, page);
          break;
        case 'sintetico':
          await reportSinthetic(useLoader, dataToSend);
          break;

        case 'sinteticoDetalhado':
          await reportSintheticDetails(dataToSend, page);
          break;
        default:
          break;
      }
    }
  }

  const reportSinthetic = async (useLoader, dataToSend) => {
    setLoadingReport(useLoader);
    try {
      const filtrado = await ReportService.getSynteticReport(
        'filtrado',
        dataToSend
      );

      const atendente = await ReportService.getSynteticReport(
        'atendente',
        dataToSend
      );
      const canal = await ReportService.getSynteticReport('canal', dataToSend);
      const fila = await ReportService.getSynteticReport('fila', dataToSend);

      if (filtrado.data.conversasFiltradas.length === 0) {
        toast.info('Nenhum registro foi encontrado!');
      } else {
        setReportTypeSynthetic({
          group: dataToSend.group,
          filtrado: { ...filtrado.data },
          atendente: { ...atendente.data },
          canal: { ...canal.data },
          fila: { ...fila.data }
        });
      }
      setLoadingReport(false);
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      setLoadingReport(false);
    }
  };

  const reportSintheticDetails = async (dataToSend, page) => {
    if (!dataToSend.group) return;
    setLoadingReport(true);
    ReportService.getChatDetailsSynthetic(page, dataToSend)
      .then((res) => {
        if (res.data.data.count === 0) {
          toast.info('Nenhum registro foi encontrado!');
          setDetailedSyntheticData([]);
          setQuantity(0);
          return;
        }
        setDetailedSyntheticData(res?.data?.data?.data ?? []);
        setQuantity(res?.data?.data.count);
      })
      .catch((err) => console.log('err', err))
      .finally(() => setLoadingReport(false));
  };

  const reportAnalitical = (useLoader, dataToSend, typeReport, page) => {
    setLoadingReport(useLoader);
    if (showTransfers) {
      ReportService.getChatDetails(page, dataToSend)
        .then((res) => {
          if (res.data.data.count === 0) {
            toast.info('Nenhum registro foi encontrado!');
            setTransferData([]);
            setQuantity(0);
            return;
          }
          setTransferData(res?.data?.data?.result);
          setQuantity(res?.data?.totalRegisters);
        })
        .catch((err) => console.log('err', err))
        .finally(() => setLoadingReport(false));
      return;
    }

    ReportService.getReport(typeReport, page, dataToSend)
      .then(async (res) => {
        if (res.data.quantidade === 0) {
          //Production
          toast.info('Nenhum registro foi encontrado!');

          setReportTypeAnalytical([]);
          setQuantity(res.data.quantidade);
          // Simulation
          // setReportTypeAnalytical(exemple);
          // setQuantity(10);
        } else {
          //Production
          const tags = await TagClientService.getAll({
            showDisabled: true
          });

          const existsTags = tags?.data?.data?.length > 0;
          let dataToTable = { ...res.data };

          if (existsTags) {
            const tagIdName = {};
            tags.data.data.forEach((tag) => {
              tagIdName[tag.id] = tag;
            });

            dataToTable.conversas.forEach((conversa) => {
              if (conversa?.tags && conversa?.tags.length > 0)
                conversa.tags = conversa?.tags?.map((tag) => {
                  return tagIdName[tag];
                });
            });
          }

          setReportTypeAnalytical(dataToTable);
          setQuantity(res.data.quantidade);

          // Simulation
          // setReportTypeAnalytical(exemple);
          // setQuantity(10);
        }
        setLoadingReport(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
        setLoadingReport(false);
      });
  };

  const onSubmit = (data) => {
    try {
      const formatedData = getDataDTO(data);
      const formatedType = getFormatedType(reportType.value);
      localStorage.setItem('filters_report_chat', JSON.stringify(getValues()));
      if (formatedData.group === 'intervalo') {
        setFlagIntervalo(true);
      } else {
        setFlagIntervalo(false);
      }
      getReports(formatedType, formatedData, 1, true);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    const formValues = getValues();
    onSubmit(formValues);
  }, [showTransfers]);

  const cleanFilter = () => {
    reset({
      dataInicial: new Date().toLocaleDateString('pt-br'),
      dataFinal: new Date().toLocaleDateString('pt-br'),
      fila: '',
      encerradaPor: '',
      group: '',
      atendente: showAllConversations === 'true' ? '' : login,
      nome: '',
      situacao: '',
      atendimentoBot: '',
      status: '',
      sucessoAtendimento: '',
      isMobile: '',
      satisfacao: '',
      canal: '',
      conteudoDaMensagem: '',
      motivo: '',
      submotivo: '',
      origemDoContato: '',
      templates: '',
      setor: '',
      tag: '',
      produto: '',
      telefone: ''
    });
  };

  const handleType = (e) => {
    cleanFilter();
    clearData();
    setReportType(e);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handlePageChange = (page) => {
    setActivePage(page);
    getReports(getFormatedType(reportType.value), dataToSend, page, false);
    // Checar flag
    // Pegar dados do mensagens por intervalo.
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Conversas</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Tipo de Relatório
                            </label>
                            <Select
                              styles={custom_select}
                              options={reportOptions}
                              isSearchable={false}
                              isClearable={false}
                              defaultValue={reportOptions[0]}
                              name="reportType"
                              onChange={(e) => handleType(e)}
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Agrupar por
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={
                                    reportType.value === 2
                                      ? groupByOptions
                                      : groupByDetailedOptions
                                  }
                                  isMulti={reportType.value === 3}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  isDisabled={reportType.value === 1}
                                  closeMenuOnSelect={!(reportType.value === 3)}
                                  fieldName="group"
                                  labelName="label"
                                  valueName="value"
                                  defaultValue={
                                    reportType.value === 3 ? 'QUEUE' : ''
                                  }
                                />
                              )}
                              rules={{
                                validate: (value) => {
                                  if (reportType.value !== 3) return true;
                                  if (!value.length) return 'Campo obrigatório';
                                }
                              }}
                              control={control}
                              name="group"
                              defaultValue={
                                reportType.value === 3 ? 'QUEUE' : ''
                              }
                            />

                            <ErrorMessage
                              errors={errors}
                              name="group"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataInicial"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataInicial"
                              rules={dateRules}
                              defaultValue={
                                filtersReportChat?.dataInicial
                                  ? filtersReportChat?.dataInicial
                                  : new Date().toLocaleDateString('pt-br')
                              }
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataInicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Data final*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataFinal"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataFinal"
                              defaultValue={
                                filtersReportChat?.dataFinal
                                  ? filtersReportChat?.dataFinal
                                  : new Date().toLocaleDateString('pt-br')
                              }
                              rules={{
                                ...dateRules,
                                validate: (value) => {
                                  if (
                                    new Date(toISOFormat(value, true)) <
                                    new Date(
                                      toISOFormat(
                                        getValues()['dataInicial'],
                                        true
                                      )
                                    )
                                  ) {
                                    return 'A data final não pode ser anterior à inicial!';
                                  } else {
                                    return true;
                                  }
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataFinal"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Origem do Contato
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={contactOrigin}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="origemDoContato"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="origemDoContato"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Campanha Ativa
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={templates || []}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="templates"
                                  labelName="title"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="templates"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Conteúdo das mensagens
                              <FormTooltip
                                text="Este campo de busca deve ser utilizado para filtrar parte ou toda mensagem de uma conversa.
                                    Assim sendo, muito útil para filtrar informações como: CPF, CNPJ, Número de pedido entre outros."
                              />
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="conteudoDaMensagem"
                              defaultValue=""
                              placeholder="informações da mensagem na conversa(s)"
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Nome do cliente
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="nome"
                              defaultValue=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <Controller
                              render={(props) => (
                                <FlexSelectSearchTag
                                  name="tags"
                                  isMulti={true}
                                  setValueSelected={(data) => {
                                    setValue('tags', data);
                                  }}
                                  value={props.value}
                                  defaultValue={[]}
                                />
                              )}
                              control={control}
                              name="tags"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Telefone
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="telefone"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="telefone"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="3">
                            <label className="form-control-label">
                              Atendente
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={users}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="atendente"
                                  labelName="name"
                                  valueName="login"
                                  isDisabled={
                                    showAllConversations === 'true'
                                      ? false
                                      : true
                                  }
                                />
                              )}
                              control={control}
                              name="atendente"
                              defaultValue={
                                showAllConversations === 'true' ? '' : login
                              }
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">Fila</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={queues}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="fila"
                                  labelName="name"
                                  valueName="name"
                                />
                              )}
                              control={control}
                              name="fila"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">Setor</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={sectors}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="setor"
                                  labelName="name"
                                  valueName="name"
                                />
                              )}
                              control={control}
                              name="setor"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">
                              Tipo de Canal
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={channelOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="canal"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="canal"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="3">
                            <label className="form-control-label">
                              Situação
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={situationOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="situacao"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="situacao"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">Status</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={status}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="status"
                                  labelName="name"
                                  valueName="name"
                                />
                              )}
                              control={control}
                              name="status"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">Motivo</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={reasons}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="motivo"
                                  labelName="name"
                                  valueName="name"
                                />
                              )}
                              control={control}
                              name="motivo"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">
                              Submotivo
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={subreasons}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  isDisabled={subreasons.length === 0}
                                  fieldName="submotivo"
                                  labelName="name"
                                  valueName="name"
                                />
                              )}
                              control={control}
                              name="submotivo"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="3">
                            <label className="form-control-label">
                              Encerrado por
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={closedByOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="encerradaPor"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="encerradaPor"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">Tag</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={tags}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="tag"
                                  labelName="name"
                                  valueName="name"
                                />
                              )}
                              control={control}
                              name="tag"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">
                              Atendimento Bot
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={booleanOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="atendimentoBot"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="atendimentoBot"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">
                              Sucesso Atendimento do Bot
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={booleanOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="sucessoAtendimento"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="sucessoAtendimento"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="3">
                            <label className="form-control-label">
                              Assunto
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={issues}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="assunto"
                                  labelName="nome"
                                  valueName="nome"
                                />
                              )}
                              control={control}
                              name="assunto"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">
                              Somente acessos por celular
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={booleanOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="isMobile"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="isMobile"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">
                              Satisfação
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={satisfactionOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="satisfacao"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="satisfacao"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="3">
                            <label className="form-control-label">
                              Produto
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={products}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="produto"
                                  labelName="nome"
                                  valueName="nome"
                                />
                              )}
                              control={control}
                              name="produto"
                              defaultValue=""
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="3">
                            <label className="form-control-label">Canal</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={canalOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="idCanal"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="idCanal"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <div style={{ display: 'flex', gap: '20px' }}>
                              <Controller
                                name="disable"
                                control={control}
                                render={(props) => (
                                  <>
                                    <label
                                      className="form-control-label"
                                      style={{ marginLeft: '20px' }}
                                    >
                                      <Input
                                        type="checkbox"
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                        checked={props.value}
                                      />
                                      Mostrar desabilitados (Filas)
                                    </label>
                                  </>
                                )}
                                defaultValue={false}
                              />
                              <UserFlexSelect
                                usersShowDisable={usersShowDisable}
                                setUsersShowDisable={setUsersShowDisable}
                                name={'Atendente'}
                              />
                            </div>
                          </Col>
                          {reportType.value === 1 && (
                            <Col md="6" style={ImageButtonContainer}>
                              {!showTransfers && (
                                <button
                                  style={ImageButton}
                                  name="pdf"
                                  onClick={() => setMedia('pdf')}
                                  disabled={
                                    loadingReport ||
                                    loadingDownload ||
                                    disabledDownloadButtonCsvAndPdf(
                                      'pdf',
                                      quantity,
                                      limitGenerationPDF
                                    )
                                  }
                                >
                                  <Tooltip
                                    title={
                                      <p style={{ fontSize: '14px' }}>
                                        {messagemGeneratePdf}
                                      </p>
                                    }
                                    arrow
                                  >
                                    {media === 'pdf' ? (
                                      <Loader
                                        type="ThreeDots"
                                        visible={true}
                                        height="40"
                                        width="40"
                                      />
                                    ) : (
                                      <img
                                        src={require('../../../../assets/img/pdf.png')}
                                        alt="pdf"
                                        style={ImageSize}
                                      />
                                    )}
                                  </Tooltip>
                                </button>
                              )}

                              {showTransfers && (
                                <button
                                  id="button_xlsx"
                                  style={ImageButton}
                                  onClick={() => {
                                    setMedia('xlsx');
                                  }}
                                  disabled={
                                    !showTransfers ||
                                    loadingReport ||
                                    loadingDownload
                                  }
                                >
                                  <Tooltip
                                    title={
                                      <p style={{ fontSize: '14px' }}>
                                        {transferData.length
                                          ? `Fazer download do relatório.`
                                          : 'Por favor verifique a quantide de registro, ou realize uma filtragem.'}
                                      </p>
                                    }
                                    arrow
                                  >
                                    {media === 'xlsx' ? (
                                      <Loader
                                        type="ThreeDots"
                                        visible={true}
                                        height="40"
                                        width="40"
                                      />
                                    ) : (
                                      <img
                                        src={require('../../../../assets/img/xlsx.png')}
                                        alt="button_xlsx"
                                        style={ImageSize}
                                      />
                                    )}
                                  </Tooltip>
                                </button>
                              )}

                              <button
                                style={ImageButton}
                                onClick={() => setMedia('csv')}
                                disabled={
                                  loadingReport ||
                                  loadingDownload ||
                                  disabledDownloadButtonCsvAndPdf(
                                    'csv',
                                    quantity,
                                    limitGenerationPDF
                                  )
                                }
                              >
                                <Tooltip
                                  title={
                                    <p style={{ fontSize: '14px' }}>
                                      {messagemGenerateCsv}
                                    </p>
                                  }
                                  arrow
                                >
                                  {media === 'csv' ? (
                                    <Loader
                                      type="ThreeDots"
                                      visible={true}
                                      height="40"
                                      width="40"
                                    />
                                  ) : (
                                    <img
                                      src={require('../../../../assets/img/csv.png')}
                                      alt="csv"
                                      style={ImageSize}
                                    />
                                  )}
                                </Tooltip>
                              </button>
                            </Col>
                          )}
                        </Row>

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                          <Button
                            style={buttonStyle(
                              systemConfiguration.primary_color
                            )}
                            type="submit"
                          >
                            Buscar
                          </Button>
                          <Button
                            onClick={cleanFilter}
                            style={buttonStyle(
                              systemConfiguration.cancelation_button_color
                            )}
                          >
                            Limpar
                          </Button>

                          {reportType?.value === 1 && (
                            <div
                              style={{
                                width: '350px',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                              onClick={() => {
                                clearData();
                                setShowTransfers((prev) => !prev);
                              }}
                            >
                              <ToggleSwitch checked={showTransfers} />
                              Mostrar transferências
                            </div>
                          )}
                        </div>
                      </Form>
                    </>
                  )}

                  {loadingReport ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      {reportType.value === 1 && (
                        <>
                          {!showTransfers && ReportTypeAnalytical.quantidade && (
                            <ReportAnalythical
                              dataReport={ReportTypeAnalytical}
                              options={{
                                sectors: sectors,
                                issues: issues,
                                products: products,
                                status: status
                              }}
                              totalRegister={quantity}
                            />
                          )}

                          {showTransfers && !!transferData.length && (
                            <AnalyticalTransfers
                              dataReport={transferData}
                              options={{
                                sectors: sectors,
                                issues: issues,
                                products: products,
                                status: status
                              }}
                              totalRegister={quantity}
                            />
                          )}
                        </>
                      )}
                      {reportType.value === 2 &&
                        ReportTypeSynthetic !== '' &&
                        (flagIntervalo ? (
                          <div>
                            <Card>
                              <CardHeader>
                                <CardCabecalho>
                                  <div>
                                    <h5 className="h3 mb-0">
                                      Total de conversas
                                    </h5>
                                    <h6 className="surtitle">POR: Intervalo</h6>
                                  </div>
                                  <h5 className="h3 mb-0">
                                    Total: {intervalData.dataHour.total}
                                  </h5>
                                </CardCabecalho>
                              </CardHeader>
                              <CardBody>
                                <div className="chart">
                                  <Line
                                    data={intervalData.dataHour}
                                    options={config}
                                    className="chart-canvas"
                                    id="chart-bars"
                                  />
                                </div>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardHeader>
                                <CardCabecalho>
                                  <div>
                                    <h5 className="h3 mb-0">
                                      Total de conversas
                                    </h5>
                                    <h6 className="surtitle">POR: Dia</h6>
                                  </div>
                                </CardCabecalho>
                              </CardHeader>
                              <CardBody>
                                <div className="chart">
                                  <Line
                                    data={intervalData.dataDay}
                                    options={config}
                                    className="chart-canvas"
                                    id="chart-bars"
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        ) : (
                          <ReportSynthetic dataReport={ReportTypeSynthetic} />
                        ))}
                      {reportType.value === 3 && (
                        <DetailedSynthetic
                          dataReport={detailedSyntheticData}
                          totalRegister={quantity}
                          dataToSend={dataToSend}
                        />
                      )}
                    </>
                  )}
                  {quantity > 0 && (
                    <PaginationSticker
                      activePage={activePage}
                      totalItemsCount={quantity}
                      onChange={handlePageChange.bind(this)}
                      scroll={700}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConversationReport;
