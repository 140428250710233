import React, { useEffect, useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { Row, Form, Col, Input, Button } from 'reactstrap';
import Select from 'react-select/async';
import { toast } from 'react-toastify';

import contactService from '../../services/contactService';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import ReactButton from 'components/Buttons/ReactButton';
import Loader from 'components/Loader';
import TelephoneMask from 'components/Inputs/TelephoneMask';
import AddAccountModal from '../AddAccountModal';
import { useSearchAccounts } from '../../../Account/hooks/useSearchAccounts';
import CustomFieldsInputsForm from '../../../shared/components/CustomFieldsInputsForm';
import {
  maskPhoneNumber,
  removeMaskSpecialCharacters
} from '../../../shared/utils/phoneNumberHelpers';
import { custom_select } from '../../../../../assets/styles/multiple_select';
import { formatCustomFieldsToDisplay } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { ScreenPrompt } from 'components/ScreenPrompt';
import { LocationOn } from '@material-ui/icons';
import { AddNewContactButton } from 'components/Buttons/AddNewContactButton';
import { SearchTagClientInput } from 'components/Inputs/SearchTagClientInput';

const ContactInputs = ({
  contact,
  recordTypeId,
  onCancelAction,
  onSaveAction,
  protocolTelephoneNumber,
  defaultFieldsAutoComplete = []
}) => {
  const history = useHistory();
  const { control, handleSubmit, errors, setValue, reset, formState, watch } =
    useForm();
  const { isDirty } = formState;
  const { isSearching, filterAccounts } = useSearchAccounts();
  const [account, setAccount] = useState();
  const [tags, setTags] = useState([]);

  const { getCustomFields, customFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(entitiesName.CONTACT, recordTypeId);

    useEffect(() => {
      if (contact) {
        setTags(
          contact?.tags?.map((tag) => ({
            label: tag?.name,
            value: tag?.id
          }))
        );

        if (contact.accountId) {
          setAccount({
            label: contact.account.name,
            value: contact.account.id,
          });
        }

        const formattedContact = formatCustomFieldsToDisplay(
          {
            ...contact,
            phone: maskPhoneNumber(contact.phone)
          },
          customFields
        );

        reset(formattedContact);
      }
    }, [contact, reset, customFields]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  const onAddedAccount = useCallback((data) => {
    setAccount({
      label: data.name,
      value: data.id
    });

    if (!contact) reset(defaultFieldsAutoComplete);
  }, []);

  const onSubmit = async (data) => {
    data.accountId = account && account.value ? Number(account.value) : null;
    data.phone = removeMaskSpecialCharacters(data.phone);

    let dataDTO = formatCustomFieldsToSubmit(data, customFields);
    dataDTO.tags = tags?.map((tag) => tag.value);

    const saveContactPromise = contact
      ? contactService.updateContact(recordTypeId, contact.id, dataDTO)
      : contactService.createContact(recordTypeId, dataDTO);

    saveContactPromise
      .then((response) => {
        toast.success(
          `Contato ${contact ? 'atualizado' : 'criado'} com sucesso`,
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
        if (onSaveAction && typeof onSaveAction === 'function') {
          onSaveAction(response.data.data);
        } else {
          history.push('/admin/crm/contact', { recordTypeId });
        }
      })
      .catch((error) => {
        const errMsg = error?.response?.data?.message;
        toast.error(errMsg || 'Houve um erro ao criar o contato.', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    if (onCancelAction && typeof onCancelAction === 'function') {
      onCancelAction();
      return;
    } else {
      history.push('/admin/crm/contact', { recordTypeId });
    }
  };

  if (isLoadingCustomFields) {
    return (
      <Row className="justify-content-md-center">
        <Loader />
      </Row>
    );
  }

  const openGoogleMaps = () => {
    window.open(
      'https://maps.google.com?q=' + contact.latitude + ',' + contact.longitude
    );
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Form
        className="needs-validation"
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        id="contactForm"
      >
        <Row>
          <Col className="mb-3" sm="4">
            <label className="form-control-label text-capitalize">Nome*</label>
            <Controller
              render={(props) => (
                <Input
                  valueController={setValue}
                  value={props.value}
                  onChange={props.onChange}
                  type="text"
                  placeholder="Nome"
                />
              )}
              control={control}
              defaultValue=""
              rules={{ required: 'Campo obrigatório' }}
              name="name"
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col sm="4">
            <label className="form-control-label text-capitalize">
              Telefone
            </label>
            <Controller
              render={(props) => (
                <TelephoneMask
                  valueController={setValue}
                  fieldName="phone"
                  value={props.value}
                  placeholder="(88) 99999-9999"
                />
              )}
              control={control}
              defaultValue={protocolTelephoneNumber}
              name="phone"
              rules={{
                validate: (value) => {
                  if (!value) return true;
                  let phoneFormated = value.replace(/\D/g, '');
                  phoneFormated = phoneFormated.replace(/^0/, '');

                  if (phoneFormated.length < 10 && phoneFormated.length > 0) {
                    return 'Telefone inválido';
                  }
                  return true;
                }
              }}
            />
            <ErrorMessage
              errors={errors}
              name="phone"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col className="mb-3" sm="4">
            <label className="form-control-label text-capitalize">Email</label>
            <Controller
              render={(props) => (
                <Input
                  name="email"
                  valueController={setValue}
                  value={props.value}
                  onChange={props.onChange}
                  placeholder="seu-email@mail.com"
                />
              )}
              control={control}
              defaultValue=""
              // O campo não é obrigatório mas é necessário validar o email com regex
              rules={{
                validate: (value) => {
                  if (!value) return true;

                  const emailRegex =
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

                  return (
                    emailRegex.test(value) || 'Email com formato incorreto'
                  );
                }
              }}
              name="email"
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <div className="d-flex">
              <div style={{ flexGrow: '10' }} className="tour3">
                <label className="form-control-label text-capitalize">
                  Conta
                </label>
                <Select
                  styles={custom_select}
                  placeholder="Busca rápida"
                  isClearable={true}
                  name="accountId"
                  isLoading={isSearching}
                  loadingMessage={() => 'Buscando...'}
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue
                      ? 'Digite para pesquisar'
                      : 'Nenhum resultado encontrado'
                  }
                  loadOptions={async (inputValue) => {
                    const res = await filterAccounts(inputValue);
                    return (
                      res &&
                      res.map((account) => ({
                        label: account.name,
                        value: account.id
                      }))
                    );
                  }}
                  onChange={(data) => {
                    if (data) {
                      const { value, label } = data;
                      setAccount({
                        label,
                        value
                      });
                    } else {
                      setAccount(null);
                    }
                  }}
                  value={
                    account
                      ? {
                          label: account.label,
                          value: account.value
                        }
                      : ''
                  }
                  defaultValue={
                    account
                      ? {
                          label: account.label,
                          value: account.value
                        }
                      : ''
                  }
                />
              </div>

              <div
                style={{
                  alignSelf: 'flex-end',
                  justifyContent: 'space-between',
                  padding: '0.6rem 0.6rem'
                }}
              >
                <AddAccountModal
                  onAddedAccount={onAddedAccount}
                  buttonOpenModal={({ onClick }) => (
                    <AddNewContactButton onClick={onClick} />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col className="mb-3" sm="6">
            <SearchTagClientInput
              setValueSelected={setTags}
              defaultValue={tags}
              isMulti={true}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <CustomFieldsInputsForm
            fields={customFields}
            control={control}
            errors={errors}
            setValue={setValue}
            Controller={Controller}
            watch={watch}
          />
        </Row>
        <hr />

        <ReactButton btnColor="confirmation" type="submit" form="contactForm">
          Salvar
        </ReactButton>

        <ReactButton
          btnColor="cancelation"
          onClick={() => {
            return cancelAction();
          }}
        >
          Cancelar
        </ReactButton>
        {contact && contact.longitude && contact.latitude ? (
          <Button
            color="primary"
            outline
            size="sm"
            type="button"
            title="Abrir endereço do cliente no Google Maps"
            onClick={() => {
              openGoogleMaps();
            }}
            style={{ float: 'right' }}
          >
            <LocationOn />
          </Button>
        ) : null}
      </Form>
    </>
  );
};

export default ContactInputs;
