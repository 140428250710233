import React, { useState, useEffect, useCallback } from 'react';
import Service from './service';
import ServiceReport from './service/Export';
import { useGetQueues } from './hooks/useGetQueus';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import * as fileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';
import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import { dateRules, emailRules } from './assets/rulesValidation';
import {
  active,
  closure,
  serviceSucess,
  situation,
  status,
  botCalls,
  satisfaction,
  channels
} from './assets/types';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportPauseTable from './table';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
// import CPFMask from '../../../../components/Inputs/CPFMask.js';
import TelephoneMask from '../../../../components/Inputs/TelephoneMask';
import csvImage from '../../../../assets/img/csv.png';
import pdfImage from '../../../../assets/img/pdf.png';
import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import Pagination from 'components/Pagination';
import UserService from 'views/Report/Callcenter/Perfomance/services/UserService';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';

const PauseReport = () => {
  const { control, handleSubmit, errors, setValue, getValues, watch, reset } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const showDisableQueue = watch('disable');
  const [reportTypePerformance, setReportTypePerformance] = useState([]);
  const [dataToSubmitDownloads, setDataToSubmitDownloads] = useState();
  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const { queues } = useGetQueues(showDisableQueue, setLoadingForm);

  const [mediaReport, setMediaReport] = useState('');

  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    UserService.getAll(false, undefined, undefined, usersShowDisable).then(
      (response) => {
        setUsers([{ login: 'Todos', name: 'Todos' }, ...response.data.data]);
      }
    );
  }, [usersShowDisable]);

  const [activePage, setActivePage] = useState(1);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );

  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  const downloadMedia = useCallback(
    async (typeReport, type, fileName, midia) => {
      if (midia === 'csv') {
        try {
          const response = await ServiceReport.getDownloads(
            typeReport,
            dataToSubmitDownloads
          );
          fileSaver.saveAs(new Blob([response.data], { type }), fileName);
        } catch (err) {
          toast.error(err || 'Ops, ocorreu um erro!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }

        setMediaReport('');
      } else {
        try {
          const response = await ServiceReport.getDownloadsPdf(
            typeReport,
            dataToSubmitDownloads
          );
          fileSaver.saveAs(new Blob([response.data], { type }), fileName);
        } catch (err) {
          toast.error(err || 'Ops, ocorreu um erro!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
        setMediaReport('');
      }
    },
    [dataToSubmitDownloads]
  );

  const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${YYYY}-${MM}-${DD}`;
  }, []);

  const cleanFilters = () => {
    reset({
      dataInicial: new Date().toLocaleDateString('pt-br'),
      dataFinal: new Date().toLocaleDateString('pt-br'),
      fila: 'Todas',
      atendente: 'Todos',
      apenasAtivos: 'Todos',
      encerradaPor: 'Todos',
      nome: '',
      telefone: '',
      email: '',
      sucessoAtendimento: 'Todos',
      situacao: 'Todos',
      status: 'Todos',
      atendimentoBot: 'Todos',
      satisfacao: 'Todos',
      canal: 'Todos',
      isContatoAtivo: 'Todos'
    });
  };

  const getDataDTO = useCallback(
    async (data) => {
      let dataToSend;
      if (data.telefone) {
        dataToSend = {
          ...data,
          nome: data.nome || 'Todos',
          email: data.email || 'Todos',
          // cpf: data.cpf || 'Todos',
          dataInicial: `${await toISOFormat(data.dataInicial)}`,
          dataFinal: `${await toISOFormat(data.dataFinal)}`,
          telefone: data.telefone.replace(/\D+/g, ''),
          isContatoAtivo: data.isContatoAtivo || 'Todos'
        };
      } else {
        dataToSend = {
          ...data,
          nome: data.nome || 'Todos',
          email: data.email || 'Todos',
          // cpf: data.cpf || 'Todos',
          dataInicial: `${await toISOFormat(data.dataInicial)}`,
          dataFinal: `${await toISOFormat(data.dataFinal)}`,
          telefone: 'Todos',
          isContatoAtivo: data.isContatoAtivo || 'Todos'
        };
      }
      setDataToSubmitDownloads(dataToSend);
      return dataToSend;
    },
    [toISOFormat]
  );

  const getReports = useCallback((typeReport, page = 1, dataToSend) => {
    setLoadingTable(true);
    Service.getReportPerformance(typeReport, page, dataToSend)
      .then((res) => {
        const { performance } = res.data;
        setQuantity(parseInt(res.data.quantity) ?? 0);
        performance.forEach((el, index) => {
          performance[index].uuid = uuid();
        });
        setReportTypePerformance(performance);

        if (performance.length === 0) {
          toast.info('Nenhum registro foi encontrado!');
        }
        setLoadingTable(false);
      })
      .catch((err) => {
        setLoadingTable(false);
        toast.error(err || 'Ops, ocorreu um erro!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      setActivePage(1);
      try {
        delete data['disable'];
        const dataToSend = await getDataDTO(data);
        getReports('performance', 1, dataToSend);
      } catch (err) {
        toast.error(err || 'Erro desconhecido por favor, informar o suporte!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [getDataDTO, getReports]
  );

  const handlePageChange = (page) => {
    setActivePage(page);
    getReports('performance', page, dataToSubmitDownloads);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Performance</h3>
                </CardHeader>
                <CardBody>
                  {loadingForm ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dataInicial"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dataInicial"
                            rules={dateRules}
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dataInicial"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Data final*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dataFinal"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dataFinal"
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                            rules={{
                              ...dateRules,
                              validate: async (value) => {
                                if (
                                  new Date(await toISOFormat(value, true)) <
                                  new Date(
                                    await toISOFormat(
                                      getValues()['dataInicial'],
                                      true
                                    )
                                  )
                                ) {
                                  return 'A data final não pode ser anterior à inicial!';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dataFinal"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-12">
                        <Col md="3">
                          <label className="form-control-label">Fila</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues || []}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                fieldName="fila"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            control={control}
                            name="fila"
                            defaultValue="Todas"
                          />
                        </Col>
                        <Col md="3">
                          <label className="form-control-label">
                            Atendente
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={users || []}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                fieldName="atendente"
                                labelName="name"
                                valueName="login"
                              />
                            )}
                            control={control}
                            name="atendente"
                            defaultValue="Todos"
                          />
                        </Col>
                        <Col md="3">
                          <label className="form-control-label">
                            Apenas Ativos
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={active}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                fieldName="apenasAtivos"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="apenasAtivos"
                            defaultValue={'Todos'}
                          />
                        </Col>
                        <Col md="3">
                          <label className="form-control-label">
                            Encerrado Por
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={closure || []}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                fieldName="encerradaPor"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="encerradaPor"
                            defaultValue="Todos"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-12">
                        <Col className="md-6 mx-4">
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <Controller
                              name="disable"
                              control={control}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Mostrar desabilitados (Fila)
                                  </label>
                                </>
                              )}
                              defaultValue=""
                            />
                            <UserFlexSelect
                              usersShowDisable={usersShowDisable}
                              setUsersShowDisable={setUsersShowDisable}
                              name={'Atendente'}
                            />
                          </div>
                        </Col>
                      </Row>
                      {advancedSearch && (
                        <>
                          <Row className="mb-12">
                            <Col md="4">
                              <label className="form-control-label">
                                Cliente
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                name="nome"
                                defaultValue=""
                              />
                            </Col>

                            <Col md="4">
                              <label className="form-control-label">
                                Telefone
                              </label>
                              <Controller
                                render={(props) => (
                                  <TelephoneMask
                                    valueController={setValue}
                                    fieldName="telefone"
                                    value={props.value}
                                    placeholder="(88)8.9999-9999"
                                  />
                                )}
                                control={control}
                                name="telefone"
                                defaultValue=""
                              />
                            </Col>

                            <Col md="4">
                              <label className="form-control-label">
                                Email
                              </label>
                              <Controller
                                as={
                                  <Input
                                    type="email"
                                    placeholder="email@email.com"
                                  />
                                }
                                control={control}
                                name="email"
                                rules={emailRules}
                                defaultValue=""
                              />
                            </Col>

                            {/* <Col md="3">
                              <label className="form-control-label">CPF</label>
                              <Controller
                                render={(props) => (
                                  <CPFMask
                                    valueController={setValue}
                                    fieldName="cpf"
                                    value={props.value}
                                    placeholder="000.000.000-00"
                                  />
                                )}
                                control={control}
                                name="cpf"
                                rules={cpfRules}
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="cpf"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col> */}
                          </Row>
                          <Row className="mb-12">
                            <Col md="3">
                              <label className="form-control-label">
                                Sucesso Atendimento
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={serviceSucess || []}
                                    isMulti={false}
                                    value={props.value}
                                    valueController={setValue}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    fieldName="sucessoAtendimento"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                control={control}
                                name="sucessoAtendimento"
                                defaultValue="Todos"
                              />
                            </Col>
                            <Col md="3">
                              <label className="form-control-label">
                                Situações
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={situation || []}
                                    isMulti={false}
                                    value={props.value}
                                    valueController={setValue}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    fieldName="situacao"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                control={control}
                                name="situacao"
                                defaultValue="Todos"
                              />
                            </Col>
                            <Col md="3">
                              <label className="form-control-label">
                                Status
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={status || []}
                                    isMulti={false}
                                    value={props.value}
                                    valueController={setValue}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    fieldName="status"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                control={control}
                                name="status"
                                defaultValue="Todos"
                              />
                            </Col>
                            <Col md="3">
                              <label className="form-control-label">
                                Atendimento Bot
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={botCalls || []}
                                    isMulti={false}
                                    value={props.value}
                                    valueController={setValue}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    fieldName="atendimentoBot"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                control={control}
                                name="atendimentoBot"
                                defaultValue="Todos"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-12">
                            <Col md="4">
                              <label className="form-control-label">
                                Satisfação
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={satisfaction || []}
                                    isMulti={false}
                                    value={props.value}
                                    valueController={setValue}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    fieldName="satisfacao"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                control={control}
                                name="satisfacao"
                                defaultValue="Todos"
                              />
                            </Col>
                            <Col md="4">
                              <label className="form-control-label">
                                Canal
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={channels || []}
                                    isMulti={false}
                                    value={props.value}
                                    valueController={setValue}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    fieldName="canal"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                control={control}
                                name="canal"
                                defaultValue="Todos"
                              />
                            </Col>
                            <Col md="4">
                              <label className="form-control-label">
                                É Contato ativo?
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={active}
                                    isMulti={false}
                                    isClearable={true}
                                    value={props.value}
                                    valueController={setValue}
                                    closeMenuOnSelect={true}
                                    fieldName="isContatoAtivo"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                control={control}
                                name="isContatoAtivo"
                                defaultValue="Todos"
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row className="mb-12">
                        <Col className="md-12">
                          <label
                            className="form-control-label"
                            style={{ marginLeft: '-15px' }}
                          >
                            <ToggleSwitch
                              checked={advancedSearch}
                              id="advancedSearch"
                              onChange={() =>
                                setAdvancedSearch(!advancedSearch)
                              }
                            />
                            Busca avançada
                          </label>
                        </Col>
                      </Row>

                      <Row className="mb-12">
                        <Col md="12" style={ImageButtonContainer}>
                          <button
                            type="button"
                            disabled={
                              mediaReport !== '' ||
                              disabledDownloadButtonCsvAndPdf(
                                'pdf',
                                quantity,
                                limitGenerationPDF
                              )
                            }
                            style={ImageButton}
                            onClick={() => {
                              setMediaReport('pdf');
                              downloadMedia(
                                'performance',
                                'application/pdf',
                                `Relatório de performance Chat.pdf`,
                                'pdf'
                              );
                            }}
                          >
                            <Tooltip
                              title={
                                <p style={{ fontSize: '14px' }}>
                                  {messagemGeneratePdf}
                                </p>
                              }
                              arrow
                            >
                              {mediaReport === 'pdf' ? (
                                <Loader
                                  type="ThreeDots"
                                  visible={true}
                                  height="40"
                                  width="40"
                                />
                              ) : (
                                <img
                                  src={pdfImage}
                                  alt="pdf"
                                  style={ImageSize}
                                />
                              )}
                            </Tooltip>
                          </button>

                          <button
                            type="button"
                            disabled={
                              mediaReport !== '' ||
                              disabledDownloadButtonCsvAndPdf(
                                'csv',
                                quantity,
                                limitGenerationPDF
                              )
                            }
                            style={ImageButton}
                            onClick={() => {
                              setMediaReport('csv');
                              downloadMedia(
                                'performance',
                                'application/csv',
                                `Relatório de performance Chat.csv`,
                                'csv'
                              );
                            }}
                          >
                            <Tooltip
                              title={
                                <p style={{ fontSize: '14px' }}>
                                  {messagemGenerateCsv}
                                </p>
                              }
                              arrow
                            >
                              {mediaReport === 'csv' ? (
                                <Loader
                                  type="ThreeDots"
                                  visible={true}
                                  height="40"
                                  width="40"
                                />
                              ) : (
                                <img
                                  src={csvImage}
                                  alt="csv"
                                  style={ImageSize}
                                />
                              )}
                            </Tooltip>
                          </button>
                        </Col>
                      </Row>

                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                        disabled={loadingTable}
                      >
                        {loadingTable ? (
                          <Row className="justify-content-md-center">
                            <Loader
                              type="TailSpin"
                              color={'white'}
                              height={18}
                              width={78}
                            />
                          </Row>
                        ) : (
                          'Buscar'
                        )}
                      </Button>
                      <Button
                        onClick={cleanFilters}
                        style={buttonStyle(
                          systemConfiguration.cancelation_button_color
                        )}
                      >
                        Limpar
                      </Button>
                    </Form>
                  )}
                </CardBody>
                {loadingTable ? (
                  <div className="py-4 table-responsive">
                    <Row className="justify-content-md-center">
                      {loadingTable && (
                        <Loader
                          type="TailSpin"
                          color={systemConfiguration.primary_color}
                          height={100}
                          width={100}
                        />
                      )}
                    </Row>
                  </div>
                ) : (
                  <>
                    {dataToSubmitDownloads &&
                      reportTypePerformance.length !== 0 && (
                        <>
                          <ReportPauseTable
                            totalRegister={quantity}
                            showReports={reportTypePerformance}
                          />
                          <Pagination
                            activePage={activePage}
                            totalItemsCount={quantity}
                            onChange={handlePageChange.bind(this)}
                          />
                        </>
                      )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default PauseReport;
