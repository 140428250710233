import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import DefaultModal from '../Modal/DefaultModal';
import { AudioStreamPlayer } from 'components/AudioController/AudioStreamPlayer';

const AudioStreamReportTelephony = ({
  ReportService,
  uniqueid,
  disabled = false,
  reasonDisabled = ''
}) => {
  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState(new Audio());
  const [disable, setDisable] = useState(false);
  const [reasonDisable, setReasonDisable] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (uniqueid) {
      setAudio(new Audio(ReportService.streamAudio(uniqueid)));
      setDisable(disabled);
      setReasonDisable(reasonDisabled);
    } else {
      setDisable(true);
      setReasonDisable('Não possui registro');
    }
  }, [uniqueid, disabled, ReportService, reasonDisabled]);

  const playAudio = () => {
    if (audio.readyState !== 0) {
      setPlaying(true);
      setIsModalOpen(true);
    } else {
      toast.error('Gravação não encontrada', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const stopAudio = () => {
    if (audio.readyState !== 0) {
      setPlaying(false);
    } else {
      toast.error('Gravação não encontrada', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    isModalOpen ? setPlaying(true) : setPlaying(false);
  }, [isModalOpen]);

  return (
    <>
      <Button
        disabled={disable}
        color={playing ? 'danger' : 'success'}
        size="sm"
        dataplacement="top"
        title={reasonDisable}
        datacontainer="body"
        dataanimation="true"
        onClick={() => (playing ? stopAudio(uniqueid) : playAudio(uniqueid))}
      >
        <i
          className={playing ? 'fas fa-stop-circle' : 'fas fa-play-circle'}
        ></i>
      </Button>

      <DefaultModal
        show={isModalOpen}
        onModalToggle={setIsModalOpen}
        title="Reproduzindo gravação"
        showCloseButton={false}
        modalContainerClasses=""
      >
        {/* <audio
          style={{ margin: '0', borderRadius: '0.4375rem', width: '100%' }}
          controls
          src={srcAudio}
          preload="metadata"
        /> */}

        <div id="player">
          <div class="card">
            <div class="card-content">
              <AudioStreamPlayer
                uniqueid={uniqueid}
                ReportService={ReportService}
                executeDisabled={true}
              />
            </div>
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

export default AudioStreamReportTelephony;
