import React from 'react';
import * as fileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { Row, Card, Button } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style,
  icon_column_style,
  individual_icon_header_style
} from '../../../../../../assets/styles/table_custom_style';
import { AudioStreamReportTelephony as AudioStream } from '../../../../../../components/AudioController/AudioStream';
import { formatDateForAudioDownloads } from '../../../../../../utils/fuctions/formattedDateForAudioDownloads';

export function VoiceProtocolTable({ data, ReportService }) {
  const downloadAudio = (uniqueid, nameAgent, date) => {
    const formattedDate = formatDateForAudioDownloads(date);
    ReportService.downloadAudio(uniqueid)
      .then((res) => {
        res.data.size <= 11
          ? toast.info('Nenhum registro foi encontrado!')
          : fileSaver.saveAs(res.data, `${formattedDate}-${nameAgent}.gsm`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Row className="mt--3">
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <ToolkitProvider
                data={data}
                keyField="_id"
                columns={[
                  {
                    dataField: 'calldateStart',
                    text: 'Data início',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'nameAgent',
                    text: 'Agente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'dispositionFormatted',
                    text: 'Status da Chamada',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeWaitingCallFormated',
                    text: 'Tempo em espera',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeCallFormated',
                    text: 'Tempo em ligação',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'download',
                    text: 'Download',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (rowContent, row) => {
                      return (
                        <Button
                          disabled={
                            row.disposition === 'ANSWERED' ? false : true
                          }
                          datatoggle="tooltip"
                          dataplacement="top"
                          title={
                            row.disposition === 'ANSWERED' ? '' : 'Não atendida'
                          }
                          datacontainer="body"
                          dataanimation="true"
                          color="success"
                          size="sm"
                          onClick={() =>
                            downloadAudio(
                              row.uniqueid,
                              row.nameAgent,
                              row.calldateStart
                            )
                          }
                        >
                          <i className="fas fa-cloud-download-alt"></i>
                        </Button>
                      );
                    }
                  },
                  {
                    dataField: 'listen',
                    text: 'Escutar',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (rowContent, row) => {
                      return (
                        <AudioStream
                          ReportService={ReportService}
                          uniqueid={row.uniqueid}
                          disabled={
                            row.disposition === 'ANSWERED' ? false : true
                          }
                          reasonDisabled={
                            row.disposition === 'ANSWERED' ? '' : 'Não atendida'
                          }
                        />
                      );
                    }
                  }
                ]}
                hora_criacao
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
}
