/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Row, Button } from 'reactstrap';

import ReactLoader from 'components/Loader';
import { protocolNoEditFields } from '../../assets/noEditFields';
import { protocolFieldTypes } from '../../utils/protocolFieldsUtils';
import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';

import { useProtocolFields } from '../../../hooks/useProtocolFields';

const types = new Map();
types.set(protocolFieldTypes.TEXT, 'Campo de Texto');
types.set(protocolFieldTypes.NUMERIC, 'Campo Numérico');
types.set(protocolFieldTypes.CURRENCY, 'Campo Monetário');
types.set(protocolFieldTypes.DATE, 'Campo de data');
types.set(protocolFieldTypes.HOUR, 'Campo de Hora');
types.set(protocolFieldTypes.LIST, 'Lista');
types.set(protocolFieldTypes.TELEPHONE, 'Telefone');
types.set(protocolFieldTypes.CPF, 'CPF');
types.set(protocolFieldTypes.CNPJ, 'CNPJ');
types.set(protocolFieldTypes.MULTIPLE, 'Campo Múltiplo');
types.set(protocolFieldTypes.LINK, 'Hiperlink');

const TableProtocolFields = (fields) => {
  const history = useHistory();
  const { isLoadingProtocolFields } = useProtocolFields();

  const handleUpdate = (fieldData) => {
    const { id, entity } = fieldData;
    fieldData.entityName = entity.toLowerCase();
    history.push(`fieldsConfig/form/${id}`, { ...fieldData });
  };

  const fieldsToList = fields?.fields?.map((data) => {
    return {
      ...data,
      recordTypes: data?.recordTypesConfig
        .map((record) => record.name)
        .join(', ')
    };
  });

  return (
    <>
      {fieldsToList && fieldsToList.length && (
        <ToolkitProvider
          data={fieldsToList || []}
          keyField="id"
          columns={[
            {
              dataField: 'label',
              text: 'Nome',
              sort: true,
              style: individual_column_style,
              headerStyle: individual_header_style
            },
            {
              dataField: 'description',
              text: 'Descrição',
              sort: true,
              style: individual_column_style,
              headerStyle: individual_header_style
            },
            {
              dataField: 'recordTypes',
              text: 'Tipos de registros',
              sort: true,
              style: individual_column_style,
              headerStyle: individual_header_style
            },

            {
              dataField: 'type',
              text: 'Tipo',
              sort: true,
              style: individual_column_style,
              headerStyle: individual_header_style,
              formatter: (_, row) => types.get(row.type)
            },
            {
              dataField: 'edit',
              style: icon_column_style,
              headerStyle: individual_icon_header_style,
              text: 'Editar',
              formatter: (_, row) => {
                const disabled = protocolNoEditFields.includes(row.name);
                const messagemEdition = disabled
                  ? 'Este campo não é editável'
                  : 'Clique aqui para editar';

                return (
                  <Button
                    disabled={disabled}
                    title={messagemEdition}
                    size="sm"
                    color="info"
                    onClick={() => handleUpdate(row)}
                  >
                    <li className="fas fa-edit"></li>
                  </Button>
                );
              }
            }
          ]}
        >
          {(props) => (
            <div className="py-4 table-responsive">
              {isLoadingProtocolFields ? (
                <Row className="justify-content-md-center">
                  <ReactLoader />
                </Row>
              ) : (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  classes="align-items-center"
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      )}
    </>
  );
};

export { TableProtocolFields };
