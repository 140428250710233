import React, { useEffect, useMemo, useState } from 'react';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from 'assets/styles/table_custom_style';

import { TotalRegister } from '../../../../../components/TotalRegister';

const queueColumn = {
  dataField: 'queueName',
  text: 'Fila',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const agentColumn = {
  dataField: 'agentName',
  text: 'Usuário',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const customerColumn = {
  dataField: 'customerName',
  text: 'Cliente',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const channelColumn = {
  dataField: 'channelName',
  text: 'Canal',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const reasonColumn = {
  dataField: 'reasonName',
  text: 'Motivo',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const subReasonColumn = {
  dataField: 'subReasonName',
  text: 'Sub Motivo',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

export const DetailedSynthetic = ({
  dataReport,
  totalRegister,
  dataToSend
}) => {
  const defaultColumns = [
    {
      dataField: 'tma',
      text: 'Tempo médio de atendimento',
      sort: true,
      style: individual_column_style,
      headerStyle: individual_header_style
    },
    {
      dataField: 'tta',
      text: 'Tempo total de atendimento',
      sort: true,
      style: individual_column_style,
      headerStyle: individual_header_style
    },
    {
      dataField: 'tmfr',
      text: 'Tempo médio de primeira resposta',
      sort: true,
      style: individual_column_style,
      headerStyle: individual_header_style
    }
  ];

  const [variableColumns, setVariableColumns] = useState(defaultColumns);

  const group = useMemo(() => {
    return dataToSend?.group ?? [];
  }, [dataToSend?.group]);

  useEffect(() => {
    let arrayGroupColumns = [];
    group.forEach((element) => {
      switch (element) {
        case 'QUEUE':
          arrayGroupColumns.push(queueColumn);
          break;

        case 'AGENT':
          arrayGroupColumns.push(agentColumn);
          break;

        case 'CUSTOMER':
          arrayGroupColumns.push(customerColumn);
          break;

        case 'CHANNEL':
          arrayGroupColumns.push(channelColumn);
          break;

        case 'REASON':
          arrayGroupColumns.push(reasonColumn);
          break;

        case 'SUB_REASON':
          arrayGroupColumns.push(subReasonColumn);
          break;
      }
    });

    setVariableColumns([...arrayGroupColumns, ...defaultColumns]);
  }, [group]);

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={dataReport || []}
                keyField="_id"
                columns={variableColumns}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
