import React from 'react';
import ConversationDetails from 'components/Conversation/ConversationDetails';

// react virtualized
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

import { individual_column_style } from '../../../../../../assets/styles/table_custom_style';
import { formatCurrency } from 'utils/fuctions/formatCurrency';
import { maskPhoneNumber } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { maskCpf } from 'views/CRM/shared/utils/cpfCnpjHelpers';
import { maskCnpj } from 'views/CRM/shared/utils/cpfCnpjHelpers';
import { Button } from 'reactstrap';
import ProtocolHistoryModal from '../../ProtocolHistoryModal';
import ClickToCall from 'views/ServicePanel/components/AttendanceContent/components/Modal/ClickToCall';
import { MdPhoneForwarded } from 'react-icons/md';
import { ProtocolNumberWrapper } from '../../ProtocolNumberWrapper';
import { EditProtocolByNumber } from '../../ProtocolList/styled';
import { functionsUseFul } from 'views/Protocol/list/assets/functionsUseFul';
import { OutlineTooltip } from '../../OutlineTooltip';
import { ClientNameWrapper } from '../../ClientNameWrapper';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const reactVirtualizedTableStyle = {
  ...individual_column_style,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  minWidth: '150px',
  fontSize: '.8125rem'
};

const reactVirtualizedTableHeaderStyle = {
  // ...individual_header_style,
  color: '#8898aa',
  backgroundColor: '#f6f9fc',
  fontWeight: '600',
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
  fontSize: '.65rem',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  borderBottom: '1px solid #e9ecef',
  borderTop: '1px solid #e9ecef',
  paddingLeft: '1.5rem',
  paddingRight: '0',
  whiteSpace: 'break-spaces',
  cursor: 'pointer',
  lineHeight: '1.5rem',
  height: '56.5px',
  marginRight: '0px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
  // ...individual_header_curst_style
};

const ListTable = ({
  data,
  handleOnSortChange = () => null,
  customFields,
  handleUpdate,
  handleCall,
  disableClickToCall,
  protocolDefaultFieldsDisplaySettings
}) => {
  const { buildProtocolTitleString } = functionsUseFul();

  const tableHeight =
    data.length >= 10 ? '638px' : `${data.length * 57 + 75}px`;

  return (
    <div
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        height: tableHeight
      }}
    >
      <div
        style={{
          width: '3000px',
          height: '100%'
        }}
      >
        {
          <AutoSizer>
            {({ width, height }) => {
              return (
                <Table
                  width={width}
                  height={height}
                  headerHeight={57}
                  rowHeight={57}
                  style={{
                    height: '100%',
                    padding: '0 1rem 0 1rem'
                  }}
                  rowStyle={{
                    fontSize: '.8125rem',
                    borderBottom: '1px solid #e9ecef'
                  }}
                  headerStyle={{
                    ...reactVirtualizedTableHeaderStyle
                  }}
                  rowCount={data.length || 0}
                  rowGetter={({ index }) => data[index] || {}}
                  onHeaderClick={(event) => {
                    const { dataKey } = event;
                    handleOnSortChange(dataKey);
                  }}
                >
                  <Column
                    label="NÚMERO"
                    cellRenderer={({ rowData }) => {
                      const { number, description } = rowData;
                      return (
                        <EditProtocolByNumber
                          onClick={() => handleUpdate(rowData.id)}
                          title={`Clique para editar o protocolo ${number}`}
                        >
                          <ProtocolNumberWrapper
                            number={number}
                            description={description}
                            onClick={() => handleUpdate(rowData.id)}
                            isDescriptionShown={
                              protocolDefaultFieldsDisplaySettings &&
                              protocolDefaultFieldsDisplaySettings.description &&
                              protocolDefaultFieldsDisplaySettings.description
                                .isRequired
                            }
                          />
                        </EditProtocolByNumber>
                      );
                    }}
                    dataKey="number"
                    width={250}
                    style={{
                      marginRight: '0px',
                      textAlign: 'center'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  {protocolDefaultFieldsDisplaySettings &&
                    protocolDefaultFieldsDisplaySettings.title.isActive && (
                      <Column
                        label="TÍTULO"
                        dataKey="title"
                        cellRenderer={({ rowData }) => {
                          return (
                            <div>
                              <span style={{}} id={`title-${rowData.id}`}>
                                {buildProtocolTitleString(rowData.title)}
                              </span>

                              <OutlineTooltip
                                placement={'bottom'}
                                target={`title-${rowData.id}`}
                                message={rowData.title}
                              />
                            </div>
                          );
                        }}
                        width={500}
                        style={{
                          marginRight: '0px'
                        }}
                        rowStyle={{
                          ...reactVirtualizedTableStyle
                        }}
                      />
                    )}

                  {protocolDefaultFieldsDisplaySettings &&
                    protocolDefaultFieldsDisplaySettings.current_status
                      .isActive && (
                      <Column
                        label="Status/Fase"
                        dataKey="status"
                        cellRenderer={({ rowData }) => {
                          const [protocol_status] = rowData.protocol_statuses;

                          return protocol_status?.status ? (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <>
                                <div
                                  style={{
                                    marginRight: '5px',
                                    width: '12px',
                                    height: '12px',
                                    border: '1px solid #CDC9C9',
                                    backgroundColor: `${protocol_status.status.color}`
                                  }}
                                ></div>
                                <div>{`${protocol_status.status.name}`}</div>
                              </>
                            </div>
                          ) : (
                            <></>
                          );
                        }}
                        style={{
                          marginRight: '0px',
                          textAlign: 'center'
                        }}
                        width={200}
                        rowStyle={{
                          ...reactVirtualizedTableStyle
                        }}
                      />
                    )}

                  {protocolDefaultFieldsDisplaySettings &&
                    protocolDefaultFieldsDisplaySettings.client_id.isActive && (
                      <Column
                        label="Contato"
                        dataKey="cliente.nome"
                        cellRenderer={({ rowData }) => {
                          const { cliente } = rowData;

                          return (
                            <ClientNameWrapper
                              name={cliente?.nome}
                              telephone={cliente?.telefone}
                              email={cliente?.email}
                            />
                          );
                        }}
                        width={150}
                        style={{
                          marginRight: '0px',
                          textAlign: 'center'
                        }}
                        rowStyle={{
                          ...reactVirtualizedTableStyle
                        }}
                      />
                    )}

                  {protocolDefaultFieldsDisplaySettings &&
                    protocolDefaultFieldsDisplaySettings.account_id
                      .isActive && (
                      <Column
                        label="Conta"
                        dataKey="account.name"
                        cellDataGetter={({ rowData }) => {
                          return rowData?.account?.name;
                        }}
                        width={150}
                        style={{
                          marginRight: '0px',
                          textAlign: 'center'
                        }}
                        rowStyle={{
                          ...reactVirtualizedTableStyle
                        }}
                      />
                    )}

                  {protocolDefaultFieldsDisplaySettings &&
                    protocolDefaultFieldsDisplaySettings.reason_id.isActive && (
                      <Column
                        label="Motivo"
                        dataKey="reason.name"
                        cellDataGetter={({ rowData }) => {
                          return rowData?.reason?.name;
                        }}
                        width={300}
                        style={{
                          marginRight: '0px',
                          textAlign: 'center'
                        }}
                        rowStyle={{
                          ...reactVirtualizedTableStyle
                        }}
                      />
                    )}

                  {protocolDefaultFieldsDisplaySettings &&
                    protocolDefaultFieldsDisplaySettings.subreason_id
                      .isActive && (
                      <Column
                        label="Submotivo"
                        dataKey="subreason.name"
                        cellDataGetter={({ rowData }) => {
                          return rowData?.subreason?.name;
                        }}
                        width={300}
                        style={{
                          marginRight: '0px',
                          textAlign: 'center'
                        }}
                        rowStyle={{
                          ...reactVirtualizedTableStyle
                        }}
                      />
                    )}

                  <Column
                    label="Criação"
                    dataKey="creation_date"
                    cellDataGetter={({ rowData }) => {
                      if (!rowData.creation_date) {
                        return '';
                      }
                      const date = new Date(rowData.creation_date);
                      return `${date.toLocaleDateString(
                        'pt-BR'
                      )} às ${date.toLocaleTimeString('pt-BR', {
                        timeZone: 'America/Fortaleza'
                      })}`;
                    }}
                    width={300}
                    style={{
                      marginRight: '0px',
                      textAlign: 'center'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Responsável"
                    dataKey="protocol_responsibles"
                    cellDataGetter={({ rowData }) => {
                      return rowData?.protocol_responsibles[0]?.usr?.name || '';
                    }}
                    width={250}
                    style={{
                      marginRight: '0px',
                      textAlign: 'center'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Última Alteração"
                    dataKey="last_change_date"
                    cellDataGetter={({ rowData }) => {
                      const date = new Date(rowData.last_change_date);
                      return `${date.toLocaleDateString(
                        'pt-BR'
                      )} às ${date.toLocaleTimeString('pt-BR', {
                        timeZone: 'America/Fortaleza'
                      })}`;
                    }}
                    width={300}
                    style={{
                      marginRight: '0px',
                      textAlign: 'center'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  {customFields.map((field) => {
                    return (
                      <Column
                        label={field.label}
                        dataKey={field.name}
                        cellDataGetter={({ rowData }) => {
                          if (field.type === 'DATE' && !!rowData[field.name]) {
                            return new Date(
                              rowData[field.name]
                            ).toLocaleDateString('pt-br');
                          }

                          if (
                            field.type === 'CURRENCY' &&
                            !!rowData[field.name]
                          ) {
                            return formatCurrency(rowData[field.name]);
                          }

                          if (
                            field.type === 'TELEPHONE' &&
                            !!rowData[field.name]
                          ) {
                            return maskPhoneNumber(rowData[field.name]);
                          }

                          if (field.type === 'CPF' && !!rowData[field.name]) {
                            return maskCpf(rowData[field.name]);
                          }

                          if (field.type === 'CNPJ' && !!rowData[field.name]) {
                            return maskCnpj(rowData[field.name]);
                          }

                          if (
                            field.type === 'MULTIPLE' &&
                            !!rowData[field.name]
                          ) {
                            return rowData[field.name].replace(/;/g, '; ');
                          }

                          return rowData[`${field.name}`];
                        }}
                        width={250}
                        style={{
                          marginRight: '0px'
                        }}
                        rowStyle={{
                          ...reactVirtualizedTableStyle
                        }}
                      />
                    );
                  })}
                  <Column
                    label="Editar"
                    dataKey="edit"
                    cellRenderer={({ rowData }) => {
                      return (
                        <Link to={`/admin/protocol/${rowData.id}/update`}>
                          <Button
                            title="Editar protocolo"
                            color="info"
                            size="sm"
                            onClick={() => handleUpdate(rowData.id)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        </Link>
                      );
                    }}
                    style={{
                      marginRight: '0px',
                      textAlign: 'center'
                    }}
                    width={150}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Histórico"
                    dataKey="history"
                    cellRenderer={({ rowData }) => {
                      return <ProtocolHistoryModal protocolId={rowData.id} />;
                    }}
                    style={{
                      marginRight: '0px',
                      textAlign: 'center'
                    }}
                    width={150}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Chamar"
                    dataKey="call"
                    cellRenderer={({ rowData }) => {
                      return (
                        <ClickToCall
                          title={
                            rowData?.cliente?.telefone !== '' ||
                            rowData.telephone !== ''
                              ? `Chamar cliente ${rowData?.cliente?.nome}\nÉ necessário estar logado em um ramal para realizar a chamada!`
                              : `O cliente ${rowData?.cliente?.nome} não possui telefone cadastrado`
                          }
                          callBack={() => handleCall(rowData)}
                          disableClickToCall={
                            (rowData?.cliente?.telefone !== '' ||
                              rowData?.telephone !== '') &&
                            !disableClickToCall
                              ? false
                              : true
                          }
                          icon={<MdPhoneForwarded size={17} color="#fff" />}
                          color="success"
                          isDefaultButtonStyle={true}
                        />
                      );
                    }}
                    style={{
                      marginRight: '0px',
                      textAlign: 'center'
                    }}
                    width={150}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                </Table>
              );
            }}
          </AutoSizer>
        }
      </div>
    </div>
  );
};
export default ListTable;
