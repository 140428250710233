import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSocketLoggedAttendants } from './hooks/useSocketLoggedAttendants';
import MonitoringHeaderChat from './components/monitoringHeaderChat';
import TableMonitoring from './components/table';
import CardAttendants from './components/CardAttendants';
import { ButtonFilter } from './components/ButtonFilter';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import CloseManyButton from './components/CloseManyButton';
import { useForm, Controller } from 'react-hook-form';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Form,
  Input,
  Button
} from 'reactstrap';

// import { debounce } from 'lodash';

import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import FlexSelect from 'components/Inputs/FlexSelect';
import QueueService from '../../../Queue/service/';
// import { io } from 'socket.io-client';
import { useChatMonitoring } from './hooks/useChatMonitoring';
import { useMakeConversationCounters } from './hooks/useMakeConversationCounters';
import { useConectionSocketAdmConversation } from 'hooks/useSocketAdmConversations';
import exelReport from './services/apiReportMonitoring';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { ImageButton, ImageSize } from 'assets/styles/image_style';

import xlsxImage from '../../../../assets/img/xlsx.png';
import { Tooltip } from '@material-ui/core';
import TransferManyButton from './components/TransferManyButton';
import TableLoader from '../../../../components/ContentLoaders/contentLoaderTable';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';
import { useGetUsers } from 'views/Report/Audit/hooks/useGetUsers';
// eslint-disable-next-line
import MonitoringChatWorker from 'worker-loader!./workers/monitoringChat.worker.js';
import { FlexSelectSearchTag } from 'components/Inputs/SelectWithSearch';

const conversationSituationFilters = {
  ALL: 'all',
  ANSWERED: 'atendida',
  UNANSWERED: 'nao_atendida',
  BOT_ATTENDANCE: 'atendimento_bot',
  HUMAN_ATTENDANCE: 'atendimento_humano'
};

const MonitoringConversation = () => {
  const [canalOptions, setCanalOptions] = useState([]);
  const { control, handleSubmit, setValue, getValues, register } = useForm();
  const [queues, setQueues] = useState([]);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const { users } = useGetUsers(usersShowDisable);
  const [tagsIdName, setTagsIdName] = useState({});

  const [selectedRows, setSelectedRows] = useState([]);
  const [situationFilter, setSituationFilter] = useState(
    conversationSituationFilters.ALL
  );
  const [orderByFilter, setOrderByFilter] = useState('hora');
  const isFirstRender = React.useRef(true);

  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { loggedAttendants } = useSocketLoggedAttendants();
  const { makeConversationCountersDTO } = useMakeConversationCounters();
  const [filtersDataDTO, setFiltersDataDTO] = useState({});
  const [possibleEvents, setPossibleEvents] = useState({});
  const {
    getMonitoringData,
    isLoadingMonitoringData,
    monitoringData,
    setMonitoringData
    //setIsLoadingMonitoringData
  } = useChatMonitoring();

  const getReportExelMonitoring = (params = {}) => {
    exelReport
      .getReportExelMonitoring({
        queryParams: params
      })
      .then((res) => {
        if (res.data.size === 0) toast.info('Nenhum registro foi encontrado!');
        else {
          saveAs(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
            }),
            `realotorio-monitoramento${new Date()}.xlsx`
          );
        }
      });
  };

  const { socketAdm } = useConectionSocketAdmConversation();
  const nomes = [
    'facebook',
    'watson',
    'whatsapp',
    'telegram',
    'instagram',
    'sms',
    'webchat',
    'flex4u'
  ];
  useEffect(() => {
    FlexChatIntegration.getAllChannels().then((res) => {
      const info = res.data.data;
      let array = [];

      nomes.forEach((nome) => {
        if (!info[nome]) return;
        if (nome === 'whatsapp') {
          info[nome].forEach((item, index) => {
            array.push(info[nome][index] ?? []);
          });
          return;
        }
        array.push(info[nome] ?? []);
      });

      const withoutdisabled = array.filter((canal) => {
        return canal.ativado === true;
      });

      const list = withoutdisabled.map((canal) => {
        return { label: canal?.nome_canal, value: canal?.id };
      });

      setCanalOptions(list);
    });
  }, []);
  useEffect(() => {
    QueueService.getAll()
      .then((response) => {
        let arrayQueues = [];
        const attendantQueues = JSON.parse(localStorage.getItem('queues'));
        response.data.data.forEach((queue) => {
          attendantQueues.forEach((attendantQueue) => {
            if (queue.id === attendantQueue.id) arrayQueues.push(queue);
          });
        });
        let filteredQueues = arrayQueues.filter((fila) => {
          return fila['is_chat'];
        });
        setQueues([...filteredQueues]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const monitoringWorker = useMemo(() => new MonitoringChatWorker(), []);

  let attendantQueues = localStorage.getItem('queues');
  if (attendantQueues) {
    attendantQueues = JSON.parse(attendantQueues);
  }

  const data = useMemo(
    () => ({
      monitoringData,
      possibleEvents,
      attendantQueues
    }),
    [monitoringData, possibleEvents, attendantQueues]
  );

  useEffect(() => {
    try {
      if (window.Worker) {
        const events = [
          'conversa_atendida',
          'entrou_na_fila',
          'conversa_iniciada',
          'conversa_encerrada',
          'conversa_transferida_para_atendente',
          'contact_update_name'
        ];

        if (events.includes(possibleEvents.event)) {
          const orderBy = getValues('orderBy');

          if (possibleEvents.event === 'conversa_atendida') {
            data.orderBy = orderBy;
          }

          data.currentFilters = filtersDataDTO;

          const jsonString = JSON.stringify(data);
          const buffer = new TextEncoder().encode(jsonString).buffer;
          monitoringWorker.postMessage(buffer, [buffer]);
        }
        monitoringWorker.onmessage = (e) => {
          setMonitoringData(e.data);
        };
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getValues,
    possibleEvents,
    setMonitoringData,
    monitoringWorker,
    filtersDataDTO
  ]);

  useEffect(() => {
    if (socketAdm) {
      socketAdm.onAny((event, payload) => {
        setPossibleEvents({
          event,
          payload
        });
      });
      return () => socketAdm.offAny();
    }
  }, [socketAdm]);

  const handleFilterConversations = useCallback(
    async ({ orderBy, situation } = {}) => {
      const loginAtendente = localStorage.getItem('login'); //Login de quem fez a requisição
      const atendente = getValues('atendente');
      const fila = getValues('fila');
      const nome = getValues('nome_cliente');
      const telefone = getValues('telefone_cliente');
      const canal = getValues('idCanal');
      const tags = getValues('tags') || [];

      await handleNewFilters({
        tags: tags?.map((tag) => tag?.value) || [],
        loginAtendente,
        atendente,
        fila,
        nome,
        canal,
        telefone,
        situacao: situation || situationFilter,
        orderBy: orderBy || orderByFilter
      });
    },
    [getValues, handleNewFilters, situationFilter, orderByFilter]
  );

  useEffect(() => {
    if (isFirstRender.current === true) {
      isFirstRender.current = false;
      handleFilterConversations();
    }
  }, [handleFilterConversations]);

  useEffect(() => {
    setValue('situacao', situationFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [situationFilter]);

  useEffect(() => {
    setValue('orderBy', orderByFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderByFilter]);

  const handleNewFilters = useCallback(
    async (filters = {}) => {
      const {
        loginAtendente,
        atendente,
        fila,
        nome,
        telefone,
        situacao,
        orderBy,
        canal,
        tags
      } = filters;

      const filtersDTO = {
        loginAtendente,
        atendente,
        fila,
        nomeDoCliente: nome,
        telefoneDoCliente: telefone,
        orderBy,
        canal,
        tags
      };

      if (situacao === conversationSituationFilters.ALL) {
        filtersDTO.situacao = undefined;
      } else if (situacao === conversationSituationFilters.ANSWERED) {
        filtersDTO.situacao = 'em_atendimento';
      } else if (situacao === conversationSituationFilters.UNANSWERED) {
        filtersDTO.situacao = 'nao_atendida';
      } else if (situacao === conversationSituationFilters.BOT_ATTENDANCE) {
        filtersDTO.tipoDeAtendimento = 'atendimento_bot';
      } else if (situacao === conversationSituationFilters.HUMAN_ATTENDANCE) {
        filtersDTO.tipoDeAtendimento = 'atendimento_humano';
      }
      setFiltersDataDTO(filtersDTO);
      await getMonitoringData(filtersDTO);
    },
    [getMonitoringData]
  );

  const handleChangeSituationFilter = useCallback(
    async (newSituationFilter) => {
      setSituationFilter(newSituationFilter);
      await handleFilterConversations({
        situation: newSituationFilter
      });
    },
    [handleFilterConversations]
  );

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x._id !== row._id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const allRows = rows.map((r) => {
      return r;
    });
    if (isSelect) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleOnSortChange = useCallback(
    async (ordernationParam) => {
      if (ordernationParam === orderByFilter) {
        if (ordernationParam === 'cliente') {
          ordernationParam = 'clienteDesc';
        }
        if (ordernationParam === 'telefone') {
          ordernationParam = 'telefoneDesc';
        }
        if (ordernationParam === 'atendente') {
          ordernationParam = 'atendenteDesc';
        }
        if (ordernationParam === 'atendida') {
          ordernationParam = 'atendidaDesc';
        }
        if (ordernationParam === 'fila') {
          ordernationParam = 'filaDesc';
        }
        if (ordernationParam === 'apelidoCanal') {
          ordernationParam = 'apelidoCanalDesc';
        }
        if (ordernationParam === 'canal') {
          ordernationParam = 'canalDesc';
        }
        if (ordernationParam === 'hora') {
          ordernationParam = 'horaAsc';
        }
        if (ordernationParam === 'situacao') {
          ordernationParam = 'situacaoDesc';
        }
      }
      setOrderByFilter(ordernationParam);
      handleFilterConversations({
        orderBy: ordernationParam
      });
    },
    [handleFilterConversations]
  );

  return (
    <>
      <MonitoringHeaderChat
        qtdLoggedAttendants={loggedAttendants.length}
        dataToShow={makeConversationCountersDTO({
          ...monitoringData?.data
        })}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <Row>
                    <Col>Painel de Atendimentos</Col>
                  </Row>
                  <Row style={{ paddingTop: '7px' }}>
                    <Col
                      md="12"
                      style={{
                        display: 'grid',
                        gridTemplateColumns:
                          'repeat(auto-fit, minmax(min(7rem, 100%), 1fr))',
                        gap: '1rem',
                        width: '100%',
                        padding: '0.5rem'
                      }}
                    >
                      <ButtonFilter
                        color="info"
                        type="button"
                        title="visualizar todas conversas atendidas e não atendidas"
                        label="Todos atendimentos"
                        onClick={() =>
                          handleChangeSituationFilter(
                            conversationSituationFilters.ALL
                          )
                        }
                        isActive={
                          situationFilter === conversationSituationFilters.ALL
                        }
                      />

                      <ButtonFilter
                        color="success"
                        type="button"
                        title="Visualizar apenas conversas atendidas"
                        label="Atendidos"
                        onClick={() =>
                          handleChangeSituationFilter(
                            conversationSituationFilters.ANSWERED
                          )
                        }
                        isActive={
                          situationFilter ===
                          conversationSituationFilters.ANSWERED
                        }
                      />

                      <ButtonFilter
                        color="danger"
                        type="button"
                        title="visualizar apenas conversas não atendidas"
                        label=" Não atendidos"
                        onClick={() =>
                          handleChangeSituationFilter(
                            conversationSituationFilters.UNANSWERED
                          )
                        }
                        isActive={
                          situationFilter ===
                          conversationSituationFilters.UNANSWERED
                        }
                      />

                      <ButtonFilter
                        color="warning"
                        type="button"
                        title="visualizar apenas conversas atendidas pelo BOT"
                        label=" Atendimento Bot"
                        onClick={() =>
                          handleChangeSituationFilter(
                            conversationSituationFilters.BOT_ATTENDANCE
                          )
                        }
                        isActive={
                          situationFilter ===
                          conversationSituationFilters.BOT_ATTENDANCE
                        }
                      />

                      <ButtonFilter
                        color="warning"
                        type="button"
                        title="visualizar apenas conversas atendidas por Humano"
                        label="Atendimento humano"
                        onClick={() =>
                          handleChangeSituationFilter(
                            conversationSituationFilters.HUMAN_ATTENDANCE
                          )
                        }
                        isActive={
                          situationFilter ===
                          conversationSituationFilters.HUMAN_ATTENDANCE
                        }
                      />

                      <CardAttendants
                        title="Atendentes Logados"
                        loggedAttendants={loggedAttendants}
                      />

                      <CloseManyButton selectedConversations={selectedRows} />
                      <TransferManyButton id={selectedRows} />
                    </Col>
                  </Row>
                </CardHeader>
                <Form
                  className="needs-validation"
                  onSubmit={handleSubmit(handleFilterConversations)}
                >
                  <input type="hidden" name="situacao" ref={register} />
                  <input type="hidden" name="orderBy" ref={register} />
                  <Row
                    className="mb-3"
                    style={{
                      padding: '0.5rem 1rem 0 1rem'
                    }}
                  >
                    <Col md="3">
                      <label className="form-control-label">Atendente</label>
                      <Controller
                        render={(props) => (
                          <FlexSelect
                            dataOptions={users}
                            closeMenuOnSelect={false}
                            isMulti={true}
                            isClearable={true}
                            value={props.value}
                            valueController={(fieldName, value) => {
                              setValue(fieldName, value);
                            }}
                            fieldName="atendente"
                            labelName="name"
                            valueName="login"
                          />
                        )}
                        control={control}
                        name="atendente"
                        defaultValue=""
                      />
                    </Col>
                    <Col md="3">
                      <label className="form-control-label">Fila</label>
                      <Controller
                        render={(props) => (
                          <FlexSelect
                            dataOptions={queues}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            isClearable={true}
                            value={props.value}
                            valueController={(fieldName, value) => {
                              setValue(fieldName, value);
                            }}
                            fieldName="fila"
                            labelName="name"
                            valueName="name"
                          />
                        )}
                        control={control}
                        name="fila"
                        defaultValue=""
                      />
                    </Col>
                    <Col md="3">
                      <label className="form-control-label">
                        Nome do cliente
                      </label>
                      <Controller
                        render={(props) => (
                          <Input
                            {...props}
                            placeholder="Nome do cliente"
                            name="nome_cliente"
                            value={props.value}
                            onChange={(event) => {
                              setValue('nome_cliente', event.target.value);
                            }}
                          />
                        )}
                        control={control}
                        name="nome_cliente"
                        defaultValue=""
                      />
                    </Col>
                    <Col md="3">
                      <label className="form-control-label">
                        Telefone do cliente
                      </label>
                      <Controller
                        render={(props) => (
                          <Input
                            {...props}
                            placeholder="Telefone do cliente"
                            name="telefone_cliente"
                            value={props.value}
                            onChange={(event) => {
                              setValue('telefone_cliente', event.target.value);
                            }}
                          />
                        )}
                        control={control}
                        name="telefone_cliente"
                        defaultValue=""
                      />
                    </Col>
                  </Row>
                  <Row
                    className="mb-3"
                    style={{
                      padding: '0.5rem 1rem 0 1rem'
                    }}
                  >
                    <Col md="6">
                      <Controller
                        render={(props) => (
                          <FlexSelectSearchTag
                            name="tags"
                            setValueSelected={(data) => {
                              setValue('tags', data);
                            }}
                            setAllTags={(data) => {
                              const tagsById = {};
                              data.length > 0 &&
                                data.forEach((tag) => {
                                  tagsById[tag.id] = tag;
                                });
                              setTagsIdName(tagsById);
                            }}
                            value={props.value}
                          />
                        )}
                        control={control}
                        name="tags"
                        defaultValue=""
                      />
                    </Col>
                    <Col md="3">
                      <label className="form-control-label">Canal</label>
                      <Controller
                        render={(props) => (
                          <FlexSelect
                            dataOptions={canalOptions}
                            isMulti={true}
                            isClearable={true}
                            value={props.value}
                            valueController={setValue}
                            closeMenuOnSelect={false}
                            fieldName="idCanal"
                            labelName="label"
                            valueName="label"
                          />
                        )}
                        control={control}
                        name="idCanal"
                        defaultValue=""
                      />
                    </Col>
                  </Row>

                  <Row
                    style={{
                      padding: '0.5rem 1rem 0 1rem'
                    }}
                  >
                    <div className="ml-3">
                      <UserFlexSelect
                        usersShowDisable={usersShowDisable}
                        setUsersShowDisable={setUsersShowDisable}
                        name={'Atendentes'}
                      />
                    </div>
                  </Row>

                  <Row
                    className="mb-12"
                    style={{
                      padding: '0.5rem 1rem 0 1rem'
                    }}
                  >
                    <Col
                      md="6"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginTop: '1rem'
                      }}
                    >
                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                        title="Filtrar Conversas"
                        disabled={isLoadingMonitoringData}
                      >
                        Filtrar
                      </Button>
                    </Col>

                    <Col
                      md="6"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginTop: '1rem'
                      }}
                    >
                      <button
                        id="button_xlsx"
                        style={ImageButton}
                        onClick={() => getReportExelMonitoring(filtersDataDTO)}
                        disabled={
                          monitoringData?.data?.conversas.length ? false : true
                        }
                      >
                        <Tooltip
                          title={
                            <p style={{ fontSize: '14px' }}>
                              {monitoringData?.data?.conversas.length
                                ? 'Fazer download do relatório de conversas que estão abertas atualmente'
                                : 'Nenhum registro para reazlizar download'}
                            </p>
                          }
                          arrow
                        >
                          <img src={xlsxImage} alt="xlsx" style={ImageSize} />
                        </Tooltip>
                      </button>
                    </Col>
                  </Row>
                </Form>

                {isLoadingMonitoringData ? (
                  <Row
                    className="mb-12"
                    style={{
                      display: 'block',
                      overflowX: 'auto',
                      width: '100%',
                      height: '100%',
                      margin: '1rem 0 0 0'
                    }}
                  >
                    <TableLoader />
                  </Row>
                ) : (
                  <TableMonitoring
                    data={monitoringData?.data?.conversas || []}
                    isCurrentAgentsLogged={loggedAttendants}
                    selectedRows={selectedRows}
                    handleOnSelect={handleOnSelect}
                    handleOnSelectAll={handleOnSelectAll}
                    handleOnSortChange={handleOnSortChange}
                    idNameTags={tagsIdName}
                  />
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MonitoringConversation;
