import React from 'react';
import { HiChat } from 'react-icons/hi';

import AgentIndicatorCard from '../AgentIndicatorCard';

const TMACardChat = ({ data }) => {
  return (
    <AgentIndicatorCard icon={HiChat}>
      <AgentIndicatorCard.Header>
        <AgentIndicatorCard.Title>T.M.A Chat</AgentIndicatorCard.Title>
      </AgentIndicatorCard.Header>
      <AgentIndicatorCard.Body value={data} />
    </AgentIndicatorCard>
  );
};

export default React.memo(TMACardChat);
