import React, { useState, useEffect } from 'react';

import './style.css';

const PaginationContainerSticky = ({ children, scroll = 380 }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [scrollState, setScroll] = useState(scroll);

  useEffect(() => {
    setScroll(scroll);
    toggleVisibility();
  }, [scroll]);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > scrollState) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <div
      className="main-container-pagination"
      style={{
        position: 'sticky',
        bottom: '20px',
        trasition: '2s',
        display: isVisible ? 'flex' : 'none'
      }}
    >
      <div className="intern-main-container-pagination">{children}</div>
    </div>
  );
};

export default PaginationContainerSticky;
