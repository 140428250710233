import React, { useState, useEffect } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { MdOutlineContactPhone } from 'react-icons/md';
import { useClickToCallOnPhoneField } from 'views/CRM/shared/hooks/useClickToCallOnPhoneField';
import { removeMaskSpecialCharacters } from 'views/CRM/shared/utils/phoneNumberHelpers';
import TemplatesModal from 'views/ServicePanel/components/QueueContent/components/Contact/TemplatesModal';
import { getContactsAttempts } from '../../../../shared/ContactAttempt/utils';

const LeadActionsModal = ({ leadData }) => {
  const { canMakeCall, makeCall, canSendMessage } =
    useClickToCallOnPhoneField();
  const [dropdown, setDropdown] = useState(false);
  const [whatsAppMessageContactInfoLead, setWhatsAppMessageContactInfoLead] =
    useState({});
  const [isModalTemplateMessageLeadOpen, setIsModalTemplateMessageLeadOpen] =
    useState(false);
  const [callUniqueId, setCallUniqueId] = useState(null);
  const [chatCreatedOnIsolatedMessageId, setChatCreatedOnIsolatedMessageId] =
    useState(null);

  const toggle = () => setDropdown(!dropdown);

  function handleWhatsAppMessage(phone) {
    if (!phone) return;
    const phoneNumberWithoutMask = removeMaskSpecialCharacters(phone);
    let data = {
      nome: leadData.name,
      telefone: phoneNumberWithoutMask !== '' ? phoneNumberWithoutMask : ''
    };

    setWhatsAppMessageContactInfoLead(data);
    setIsModalTemplateMessageLeadOpen(!isModalTemplateMessageLeadOpen);
    return whatsAppMessageContactInfoLead;
  }

  useEffect(() => {
    if (chatCreatedOnIsolatedMessageId) {
      getContactsAttempts(
        chatCreatedOnIsolatedMessageId,
        'CHAT',
        'LEAD',
        leadData.id
      );
      return;
    }

    if (callUniqueId) {
      getContactsAttempts(callUniqueId, 'TELEFONIA', 'LEAD', leadData.id);
      return;
    }
  }, [callUniqueId, chatCreatedOnIsolatedMessageId, leadData.id]);

  return (
    <>
      <Button color="success" onClick={toggle} size="sm">
        <MdOutlineContactPhone size={17} />
      </Button>
      <Dropdown
        isOpen={dropdown}
        toggle={toggle}
        style={{ top: '-100px', left: '0' }}
      >
        <DropdownToggle
          color="link"
          style={{
            margin: '0',
            padding: '0'
          }}
        ></DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            value="Enviar mensagem whatsapp"
            onClick={() => handleWhatsAppMessage(leadData.phone)}
            disabled={!canSendMessage(leadData.phone)}
          >
            <i className="fas fa-comment" />
            Enviar mensagem whatsapp
          </DropdownItem>
          <DropdownItem
            value="Realizar chamada"
            onClick={() => makeCall(leadData.phone, setCallUniqueId, true)}
            disabled={!canMakeCall(leadData.phone)}
          >
            <i className="fas fa-phone" />
            Realizar chamada
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <TemplatesModal
        isModalOpen={isModalTemplateMessageLeadOpen}
        onModalToggle={setIsModalTemplateMessageLeadOpen}
        clientNumber={whatsAppMessageContactInfoLead.telefone}
        clientName={whatsAppMessageContactInfoLead.nome}
        clientId={whatsAppMessageContactInfoLead.clientId ?? ''}
        setChatCreatedOnIsolatedMessageId={setChatCreatedOnIsolatedMessageId}
        isTryingToCreateAContactAttempt={true}
        isIsolated={true}
        shouldGetData={dropdown}
      />
    </>
  );
};

export default LeadActionsModal;
