import React from 'react';
import CloseConversatitonModal from './components/notificationModal/closeModal';
import TransferConversationModal from './components/notificationModal/transferModal';
import WhisperModal from './components/notificationModal/whisperModal';
import ConversationDetails from 'components/Conversation/ConversationDetails';

// react virtualized
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

import { individual_column_style } from '../../../../../../assets/styles/table_custom_style';
import { Input } from 'reactstrap';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import { TagChatClient } from 'components/TagChatClient';
import { IconsChannel } from 'components/IconsChannel/IconsChannel';

const reactVirtualizedTableStyle = {
  ...individual_column_style,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  minWidth: '150px',
  fontSize: '.8125rem'
};

const reactVirtualizedTableHeaderStyle = {
  // individual_column_style
  color: '#8898aa',
  backgroundColor: '#f6f9fc',
  fontWeight: '600',
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
  fontSize: '.65rem',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  borderBottom: '1px solid #e9ecef',
  borderTop: '1px solid #e9ecef',
  paddingLeft: '1.5rem',
  paddingRight: '0',
  whiteSpace: 'break-spaces',
  cursor: 'pointer',
  lineHeight: '1.5rem',
  height: '56.5px',
  marginRight: '0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
  // ...individual_header_curst_style
};

const TableMonitoring = ({
  data,
  isCurrentAgentsLogged,
  selectedRows,
  handleOnSelect,
  handleOnSelectAll,
  handleOnSortChange,
  idNameTags
}) => {
  return (
    <div
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        height: 'calc(100vh - 200px)'
      }}
    >
      <div
        style={{
          width: '1820px',
          height: '100%'
        }}
      >
        {
          <AutoSizer>
            {({ width, height }) => {
              return (
                <Table
                  width={width}
                  height={height}
                  headerHeight={57}
                  rowHeight={57}
                  style={{
                    height: '100%',
                    padding: '0 1rem 0 1rem'
                  }}
                  rowStyle={{
                    fontSize: '.8125rem',
                    borderBottom: '1px solid #e9ecef'
                  }}
                  headerStyle={{
                    ...reactVirtualizedTableHeaderStyle
                  }}
                  rowCount={data.length || 0}
                  rowGetter={({ index }) => data[index] || {}}
                  onHeaderClick={(event) => {
                    const { dataKey } = event;
                    if (dataKey === 'checkbox') return;
                    handleOnSortChange(dataKey);
                  }}
                >
                  <Column
                    label="Selecionar"
                    dataKey="checkbox"
                    width={30}
                    style={{
                      padding: '1.5rem 1.5rem',
                      marginBottom: '1.5rem'
                    }}
                    headerRenderer={() => {
                      return (
                        <Input
                          type="checkbox"
                          style={{
                            padding: '1.5rem 1.5rem',
                            marginLeft: '-0.5rem',
                            position: 'static'
                          }}
                          checked={selectedRows.length === data.length}
                          onChange={(event) => {
                            handleOnSelectAll(event.target.checked, data);
                          }}
                        />
                      );
                    }}
                    cellRenderer={({ rowData }) => {
                      return (
                        <Input
                          type="checkbox"
                          name={rowData._id}
                          checked={selectedRows.some(
                            (x) => x._id === rowData._id
                          )}
                          onChange={(event) => {
                            handleOnSelect(rowData, event.target.checked);
                          }}
                        />
                      );
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />

                  <Column
                    label="Cliente"
                    cellDataGetter={({ rowData }) => {
                      return rowData?.cliente?.nome;
                    }}
                    dataKey="cliente"
                    width={160}
                    style={{
                      marginRight: '0px'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Tags"
                    cellRenderer={({ rowData }) => {
                      const countTags = rowData?.tags?.length;

                      if (rowData?.tags && rowData.tags.length > 2)
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start'
                            }}
                          >
                            <TagChatClient
                              name={idNameTags[rowData.tags[0]]?.name}
                              color={
                                idNameTags[rowData.tags[0]]?.color || '#5086c1'
                              }
                              disabled={idNameTags[rowData.tags[0]]?.disabled}
                            />
                            <TagChatClient
                              name={idNameTags[rowData.tags[2]]?.name}
                              color={
                                idNameTags[rowData.tags[2]]?.color || '#5086c1'
                              }
                              disabled={idNameTags[rowData.tags[1]]?.disabled}
                            />
                            <TagChatClient
                              name={`+${countTags - 2}`}
                              allTags={rowData?.tags}
                              idNameTags={idNameTags}
                              isMore={true}
                              color="#5086c1"
                              index={rowData?.id || rowData?._id}
                            />
                          </div>
                        );
                      else
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start'
                            }}
                          >
                            {Object.keys(idNameTags).length > 0 &&
                              rowData?.tags &&
                              rowData?.tags?.map((tag, index) => (
                                <TagChatClient
                                  key={index}
                                  name={idNameTags[tag]?.name}
                                  color={idNameTags[tag]?.color || '#5086c1'}
                                  disabled={idNameTags[tag]?.disabled}
                                  sizeText="auto"
                                />
                              ))}
                          </div>
                        );
                    }}
                    dataKey="tags"
                    width={180}
                    style={{
                      marginRight: '0px',
                      paddingLeft: '5px'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Telefone"
                    dataKey="telefone"
                    cellDataGetter={({ rowData }) => {
                      return rowData?.cliente?.telefone;
                    }}
                    width={140}
                    style={{
                      marginRight: '0px'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Atendente"
                    dataKey="atendente"
                    cellDataGetter={({ rowData }) => {
                      return rowData?.atendente?.name;
                    }}
                    style={{
                      marginRight: '0px'
                    }}
                    width={150}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Atendida"
                    dataKey="atendida"
                    cellDataGetter={({ rowData }) => {
                      return rowData?.atendida ? 'Atendida' : 'Não Atendida';
                    }}
                    style={{
                      marginRight: '0px'
                    }}
                    width={150}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Fila"
                    dataKey="fila"
                    width={150}
                    style={{
                      marginRight: '0px'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Nome do Canal"
                    dataKey="apelidoCanal"
                    width={150}
                    style={{
                      marginRight: '0px'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Canal"
                    dataKey="canal"
                    width={180}
                    cellRenderer={({ rowData }) => {
                      return <IconsChannel channel={rowData.canal} />;
                    }}
                    style={{
                      display: 'flex',
                      marginRight: '0px',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    headerStyle={{
                      display: 'flex',
                      marginRight: '0px',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle,
                      paddingLeft: '5px'
                    }}
                  />
                  <Column
                    label="Data e Hora"
                    dataKey="hora"
                    cellDataGetter={(rowData) => {
                      const date = new Date(rowData?.rowData?.hora_criacao);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }}
                    width={150}
                    style={{
                      marginRight: '0px'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Situação"
                    dataKey="situacao"
                    cellDataGetter={(rowData) => {
                      switch (rowData?.rowData?.situacao) {
                        case 'em_atendimento':
                          return 'Em atendimento';
                        case 'nao_atendida':
                          return 'Não atendida';
                        case 'transferida':
                          return 'Transferida';
                        default:
                          return 'Tipo inválido';
                      }
                    }}
                    width={150}
                    style={{
                      marginRight: '0px'
                    }}
                    rowStyle={{
                      ...reactVirtualizedTableStyle
                    }}
                  />
                  <Column
                    label="Ações"
                    cellRenderer={({ rowData }) => {
                      return (
                        <>
                          <ConversationDetails
                            conversationData={rowData}
                            getDataOnModalOpen={true}
                          />
                          {verifyRoles(['CHAT_TRANSFERS']) && (
                            <TransferConversationModal
                              id={rowData._id}
                              name="Transferir conversa"
                              tooltipTitle="Transferir conversa"
                              conversation={rowData}
                              isCurrentAgentsLogged={isCurrentAgentsLogged}
                            ></TransferConversationModal>
                          )}
                          {verifyRoles(['WHISPER_TOOL']) && (
                            <WhisperModal
                              id={rowData._id}
                              name="Sussurar conversa"
                              tooltipTitle="Sussurar conversa"
                            ></WhisperModal>
                          )}
                          {verifyRoles(['ENDING_CONVERSATIONS']) && (
                            <CloseConversatitonModal
                              id={rowData._id ? rowData._id : rowData.id}
                              name="Encerrar conversa"
                              tooltipTitle="Encerrar conversa"
                              reason={rowData.motivo}
                              subReason={rowData.submotivo}
                            ></CloseConversatitonModal>
                          )}
                        </>
                      );
                    }}
                    width={150}
                    minwidth={150}
                    flexGrow={1}
                    rowStyle={{
                      ...reactVirtualizedTableStyle,
                      display: 'flex',
                      width: '100%',
                      minWidth: '150px',
                      overflow: 'auto'
                    }}
                  />
                </Table>
              );
            }}
          </AutoSizer>
        }
      </div>
    </div>
  );
};
export default TableMonitoring;
