import React from 'react';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { ControlAudioProvider } from './hooks/useControlAudioState';
import { SurveyDetailsChat } from '../../../../components/Survey/SurveyDetailsChat';
import { SurveyDetailsTelephony } from '../../../../components/Survey/SurveyDetailsTelephony';
import {
  individual_column_style,
  icon_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../../assets/styles/table_custom_style';
import { TotalRegister } from '../../../../components/TotalRegister';
import { IconsChannel } from 'components/IconsChannel/IconsChannel';

//dado para exibir devem vir, via props
const Table = ({ showReports, totalRegister }) => {
  return (
    <>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={showReports || []}
                keyField="_id"
                columns={[
                  {
                    dataField: 'answer_date',
                    text: 'Data Resposta',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'rate',
                    text: 'Nota',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'name_survey',
                    text: 'Nome da pesquisa',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tags.name',
                    text: 'Tag',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    formatter: (event, row) => {
                      const tagsListen = row.tags.map((info) => {
                        // if (row.tags.length >= 2)
                        return ' ' + info?.name;
                      });

                      return tagsListen.join();
                    }
                  },
                  {
                    dataField: 'user.name',
                    text: 'Atendente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'client.nome',
                    text: 'Cliente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'queue.name',
                    text: 'Fila',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'type_survey',
                    text: 'Tipo de pesquisa',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      return Row === 'nps' ? 'NPS' : 'SATISFAÇÃO';
                    }
                  },
                  {
                    dataField: 'channel',
                    text: 'Canal',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      return <IconsChannel channel={Row} />;
                    }
                  },
                  {
                    dataField: 'origin',
                    text: 'Origem',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (_, Row) => {
                      return <IconsChannel channel={Row.channel} />;
                    }
                  },
                  {
                    dataField: 'details',
                    text: 'Detalhes',
                    sort: false,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (_, Row) => {
                      return (
                        <>
                          {Row.channel !== 'voice' ? (
                            <SurveyDetailsChat id={Row.id_attendance} />
                          ) : (
                            <ControlAudioProvider>
                              <SurveyDetailsTelephony
                                id_attendance={Row.id_attendance}
                              />
                            </ControlAudioProvider>
                          )}
                        </>
                      );
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Table;
