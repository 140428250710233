import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Row, Col, Input } from 'reactstrap';
import FlexSelect from '../../../../components/Inputs/FlexSelect';

export const SmsForm = ({ canal, control, errors, setValue }) => {
  const [brokerChosen, setBrokenChosen] = useState(canal?.broker ?? 'zenvia');
  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <Row>
        <Col md="6">
          <label className="form-control-label">Nome do canal</label>
          <Controller
            as={Input}
            control={control}
            name="nome_canal"
            defaultValue={canal?.nome_canal ?? ''}
            rules={{
              required: 'nome é um campo obrigatório'
            }}
          />
          <ErrorMessage
            errors={errors}
            name="nome_canal"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">Descrição</label>
          <Controller
            as={Input}
            control={control}
            name="descricao"
            rules={{
              required: 'descrição é um campo obrigatório'
            }}
            defaultValue={canal?.descricao ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="descricao"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <label className="form-control-label">Broker de envio</label>
          <Controller
            render={(props) => (
              <FlexSelect
                isClearable={false}
                dataOptions={[
                  {
                    label: 'Zenvia',
                    value: 'zenvia'
                  },
                  {
                    label: 'PontalTech',
                    value: 'pontaltech'
                  }
                ]}
                isMulti={false}
                closeMenuOnSelect={true}
                value={props.value}
                onChange={(e) => setBrokenChosen(e)}
                valueController={setValue}
                fieldName="broker"
                labelName="label"
                valueName="value"
              />
            )}
            control={control}
            name="broker"
            defaultValue={canal?.broker ?? 'zenvia'}
          />
          <ErrorMessage
            errors={errors}
            name="broker"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">
            {brokerChosen === 'zenvia' ? 'Zenvia Acess Token' : 'Usuário'}
          </label>
          <Controller
            as={Input}
            control={control}
            name={
              brokerChosen === 'zenvia' ? 'zenvia_access_token' : 'username'
            }
            rules={{
              required: 'Esse campo é obrigatório'
            }}
            defaultValue={canal?.zenvia_access_token ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name={
              brokerChosen === 'zenvia' ? 'zenvia_access_token' : 'username'
            }
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <label className="form-control-label">
            {brokerChosen === 'zenvia' ? 'Zenvia Key Pass' : 'Senha'}
          </label>
          <Controller
            as={Input}
            type={brokerChosen === 'zenvia' ? 'text' : 'password'}
            control={control}
            name={brokerChosen === 'zenvia' ? 'zenvia_key_pass' : 'password'}
            rules={{
              required: 'Esse campo é obrigatório'
            }}
            defaultValue={canal?.zenvia_key_pass ?? canal?.password ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name={brokerChosen === 'zenvia' ? 'zenvia_key_pass' : 'password'}
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
    </>
  );
};
