import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  BsFillImageFill,
  BsCameraVideoFill,
  BsChatDotsFill
} from 'react-icons/bs';
import { AiFillFilePdf } from 'react-icons/ai';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import QueueService from 'views/Queue/service';
import { custom_select } from 'assets/styles/multiple_select';
import {
  templateParamsRegex,
  broadcastTypeOfClosingConversationOptions
} from '../utils';
import DatePicker from 'components/Inputs/DatePicker';

import TemplateMessageFilling from './components/TemplateMessageFillingList';
import { SelectTemplateWrapper, SelectTemplateOptions } from './styled';
import { ErrorFormMessage } from 'components/ErrorFormMessage';
import HourMaskMMHHSS from 'components/Inputs/HourMaskMMHHSS';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { customFieldTypes } from 'views/fieldConfigs/utils/customFieldTypes';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import { ScreenPrompt } from 'components/ScreenPrompt';
import { SearchTemplateWhatsapp } from 'views/ServicePanel/components/QueueContent/components/Contact/Function';
import { CreatableFlexSelect } from '../../../components/Inputs/CreatableSelect';

export const ChatBroadcastListForm = (props) => {
  const { id } = props.match.params;
  const history = useHistory();
  const {
    reset,
    control,
    handleSubmit,
    errors,
    setValue,
    setError,
    clearErrors,
    watch,
    formState,
    getValues
  } = useForm();
  const { isDirty } = formState;

  const formValues = getValues();
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [queues, setQueues] = useState(null);
  const [tagConversa, setTagConversa] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [templates, setTemplates] = useState(null);
  const [templatesTitle, setTemplatesTitle] = useState(null);

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [templateParams, setTemplateParams] = useState({});
  const [clientFields, setClientFields] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const defaultContactRecordType = 301;
  const { customFields, getCustomFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(
      entitiesName.CONTACT,
      defaultContactRecordType
    );
  const [dialogTreeData, setDialogTreeData] = useState([]);
  const [watsonData, setWatsonData] = useState([]);
  const [whatsappList, setWhatsappList] = useState([]);
  const whatsappOptions = whatsappList.map((item) => {
    return { value: item.source, label: item.nome_canal };
  });
  const whatsapp = useWatch({
    name: 'sourcePhone',
    control
  });

  const _getTemplates = (telefone) => {
    FlexChatIntegration.getContatoAtivoByPhone(telefone)
      .then((res) => {
        const templatesData = res?.data?.data ?? [];
        const templatesDTO = templatesData.map(({ id, description, type }) => ({
          label: description,
          value: id,
          templateType: type
        }));

        setTemplates(templatesDTO);
      })
      .catch(() => toast.error('Erro ao buscar templates'));
  };
  const _getTemplatesTitle = (telefone) => {
    FlexChatIntegration.getContatoAtivoByPhone(telefone)
      .then((res) => {
        const templatesTitleData = res?.data?.data ?? [];
        const templatesTitleDTO = templatesTitleData.map(
          ({ id, description, type, name }) => ({
            label: description,
            value: id,
            templateType: type,
            name: name
          })
        );
        setTemplatesTitle(templatesTitleDTO);
      })
      .catch(() => toast.error('Erro ao buscar templates'));
  };
  useEffect(() => {
    if (!whatsapp) return;
    _getTemplates(whatsapp);
    _getTemplatesTitle(whatsapp);
  }, [whatsapp]);

  const Icons = {
    IMAGE: <BsFillImageFill />,
    VIDEO: <BsCameraVideoFill />,
    DOCUMENT: <AiFillFilePdf />,
    TEXT: <BsChatDotsFill />
  };

  const Colors = {
    DOCUMENT: '#d92222d1',
    IMAGE: '#2dce89',
    VIDEO: '#11cdef',
    TEXT: 'rgb(54 54 96)'
  };

  const currentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };
  const getWhatsapp = () => {
    FlexChatIntegration.getWhatsapp()
      .then((res) => {
        setWhatsappList(res?.data?.data ?? []);
      })
      .catch(() => {
        toast.error('Erro ao buscar whatsapp');
      });
  };
  const getQueues = useCallback(async () => {
    QueueService.getAll()
      .then((response) => {
        const queuesData = response.data.data;
        if (queuesData) {
          const queuesDTO = queuesData.map(({ id, name }) => ({ id, name }));
          setQueues(queuesDTO);
        }
      })
      .catch(() => {
        toast('Ocorreu um erro ao carregar as filas');
      });
  }, []);
  const getClientFields = useCallback(() => {
    if (isLoadingCustomFields) return;
    const defaultFields = [
      { label: 'Nome', value: 'nome' },
      { label: 'Telefone', value: 'phone' }
    ];
    const customizedFields = customFields
      .filter((field) => field.type === customFieldTypes.TEXT)
      .map((field) => ({
        label: field.label,
        value: field.name
      }));

    setClientFields(defaultFields.concat(customizedFields || []));
  }, [customFields, isLoadingCustomFields]);

  const getExpirationDate = (data) => {
    if (!data) {
      return {};
    }
    let info = new Date(data);
    const horas = info.toLocaleTimeString();
    const novaData = info.toLocaleDateString('pt-BR');
    return {
      expiryDay: novaData,
      expirationTime: horas
    };
  };
  const getBroadcastListToEdit = (broadcastListId) => {
    setLoading(true);

    FlexChatIntegration.getBroadcastListById(broadcastListId)
      .then(async (response) => {
        const broadcastListData = response.data.data;
        const res = await FlexChatIntegration.getContatoAtivoByPhone(
          broadcastListData.sourcePhone
        );

        const templatesData = res?.data?.data ?? [];
        const templatesDTO = templatesData.map(
          ({ id, description, type, name }) => ({
            label: description,
            value: id,
            templateType: type,
            name: name
          })
        );
        setTemplates(templatesDTO);

        const label = templatesDTO.find(
          (t) => t.value === broadcastListData.whatsappTemplateId
        )?.label;

        const name = templatesDTO.find(
          (t) => t.value === broadcastListData.whatsappTemplateId
        )?.name;

        setSelectedTemplate({
          label: label,
          value: broadcastListData.whatsappTemplateId,
          templateType: broadcastListData.templateType,
          url: broadcastListData.url,
          data: broadcastListData.message,
          name: name
        });
        reset({
          sourcePhone: broadcastListData.sourcePhone,
          watsonId: broadcastListData.watsonId,
          tagConversa: broadcastListData.tagConversa
            ? broadcastListData.tagConversa.split(',')
            : [],
          title: broadcastListData.title,
          idDialog: broadcastListData.idDialog,
          queueId: broadcastListData.queue_id,
          broadcastTypeOfClosingConversation:
            broadcastListData.typeBroadcastList,
          closingMessage: broadcastListData.closingMessage ?? '',
          ...getExpirationDate(broadcastListData.expiresIn)
        });
        setTagConversa(
          broadcastListData.tagConversa
            ? broadcastListData.tagConversa.split(',')
            : []
        );

        const params = broadcastListData.message.match(templateParamsRegex);

        const currentParams = {};
        if (params) {
          params.forEach((value, index) => {
            currentParams[`${value}`] = broadcastListData.params[index];
          });
        }
        setTemplateParams(currentParams);

        setIsUpdate(true);
      })
      .catch((err) => {
        console.log('err: ', err);
        const msg = err?.response?.data?.message;
        toast.error(msg || 'Erro ao buscar campanha', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    FlexChatIntegration.getDialogTreeResume().then((res) => {
      setDialogTreeData(res.data ?? []);
    });
  }, []);
  useEffect(() => {
    FlexChatIntegration.getWatsonResume().then((res) => {
      setWatsonData(res.data.data ?? []);
    });
  }, []);
  useEffect(() => {
    getQueues();
  }, [getQueues]);

  useEffect(() => {
    getWhatsapp();
  }, []);
  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    getClientFields();
  }, [getClientFields]);

  useEffect(() => {
    if (id) getBroadcastListToEdit(id);
  }, [id]);

  useEffect(() => {
    validateTemplateParams();
  }, [templateParams, validateTemplateParams]);

  useEffect(() => {
    const selectedOption = watch('broadcastTypeOfClosingConversation');
    setSelectedOption(selectedOption);
  }, [watch]);

  const hourRules = {
    required: 'Campo obrigatório!',
    pattern: {
      value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
      message: 'Hora inválida!'
    }
  };
  const validateTemplateMessage = () => {
    if (!selectedTemplate) {
      setError('whatsappTemplateId', {
        type: 'required',
        message: 'Campo obrigatório'
      });
      return false;
    }
    return true;
  };
  function formatDate(dateString) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
  const validateTemplateParams = useCallback(() => {
    if (!selectedTemplate) return;
    const message = selectedTemplate.data;
    const paramsKeys = message.match(templateParamsRegex);
    if (!paramsKeys) return true;

    for (const key of paramsKeys) {
      if (!templateParams[key]?.type || !templateParams[key]?.value) {
        setError('whatsappTemplateParams', {
          type: 'required',
          message: 'Preencha todos os campos da mensagem predefinida'
        });
        return false;
      }
    }
    clearErrors('whatsappTemplateParams');

    return true;
  }, [clearErrors, setError, selectedTemplate, templateParams]);

  const onSubmit = async (data) => {
    if (data.expiryDay) {
      data.expiryDay = formatDate(data.expiryDay);
    }

    const isTemplateValid = validateTemplateMessage();
    if (!isTemplateValid) return;

    const isParamsValid = validateTemplateParams();
    if (!isParamsValid) return;

    if (selectedTemplate.templateType === 'TEXT') {
      const requestBodyDTO = {
        ...data,
        userLogin: localStorage.getItem('login')
      };
      requestBodyDTO.message = selectedTemplate.data;
      requestBodyDTO.whatsappTemplateId = selectedTemplate.value;
      requestBodyDTO.tagConversa = tagConversa;

      const paramsKeys = requestBodyDTO.message.match(templateParamsRegex);
      const templateParamsDTO = [];
      if (paramsKeys) {
        paramsKeys.forEach((key) =>
          templateParamsDTO.push(templateParams[key])
        );
      }

      requestBodyDTO.templateParams = templateParamsDTO;
      requestBodyDTO.templateType = selectedTemplate.templateType;

      try {
        isUpdate
          ? await FlexChatIntegration.putBroadcastList(id, requestBodyDTO)
          : await FlexChatIntegration.postBroadcastList(requestBodyDTO);
        toast.success(
          `Campanha Ativa Whatsapp ${
            isUpdate ? 'atualizada' : 'criada'
          } com sucesso`
        );
        history.push('/admin/chat/broadcast-list');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        toast.error(errorMessage || 'Erro ao criar campanha');
      }
      return;
    }

    const hasFile = files.length > 0;

    if (!hasFile && !selectedTemplate.url) {
      toast.error('O arquivo é obrigatório');
      return;
    }

    const formatedData = new FormData();

    formatedData.append('sourcePhone', data.sourcePhone);
    if (data.broadcastTypeOfClosingConversation === 4) {
      formatedData.append('idDialog', data?.idDialog);
    }
    if (hasFile) {
      formatedData.append('file', files[0]);
    } else {
      formatedData.append('url', selectedTemplate.url);
    }
    if (selectedTemplate.templateType === 'IMAGE') {
      formatedData.append('response_type', 'image');
      formatedData.append('type', 'image');
    } else if (selectedTemplate.templateType === 'VIDEO') {
      formatedData.append('response_type', 'video');
      formatedData.append('type', 'video');
    } else if (selectedTemplate.templateType === 'DOCUMENT') {
      formatedData.append('response_type', 'file');
      formatedData.append('type', 'file');
    }
    if (data.expiryDay) {
      formatedData.append('expiryDay', data.expiryDay);
    }
    if (data.expirationTime) {
      formatedData.append('expirationTime', data.expirationTime);
    }
    if (data.closingMessage) {
      formatedData.append('closingMessage', data.closingMessage);
    }

    const message = selectedTemplate.data;
    const whatsappTemplateId = selectedTemplate.value;
    formatedData.append(
      'broadcastTypeOfClosingConversation',
      String(data.broadcastTypeOfClosingConversation)
    );

    formatedData.append('message', message);
    formatedData.append('whatsappTemplateId', whatsappTemplateId);
    formatedData.append('queueId', data.queueId);
    formatedData.append('title', data.title);
    formatedData.append('templateType', selectedTemplate.templateType);

    const paramsKeys = message.match(templateParamsRegex);
    const templateParamsDTO = [];
    if (paramsKeys) {
      paramsKeys.forEach((key) =>
        templateParamsDTO.push({ ...templateParams[key] })
      );
    }

    let res;
    isUpdate
      ? (res = await FlexChatIntegration.updateBroadcastWithMidia(
          formatedData,
          id
        ))
      : (res = await FlexChatIntegration.createBroadcastWithMidia(
          formatedData
        ));

    if (res.status === 200 || res.status === 201) {
      toast.success(
        `Campanha ${isUpdate ? 'atualizada' : 'criada'} com sucesso`
      );
      history.push('/admin/chat/broadcast-list');
      return;
    }
    const errorMessage = res.response.data.message;
    toast.error(errorMessage ?? 'Erro ao criar campanha');
  };
  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/chat/broadcast-list');
  };
  const onChangeSelected = (data) => {
    if (data) {
      clearErrors();
    }
    if (!data) {
      setTemplateParams({});
    }
    setIsLoading(true);
    FlexChatIntegration.getTemplateById(data.value)
      .then((response) => {
        setSelectedTemplate({
          label: response.data.data.name,
          value: response.data.data.id,
          templateType: response.data.data.type,
          data: response.data.data.description,
          name: response.data.data.name
        });
      })
      .catch(() => {
        toast('Ocorreu um erro ao carregar as mensagens predefinadas');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/chat/broadcast-list"
        buttonTitle="Voltar para a pagina de campanhas"
      />
      <Container className="mt--6" fluid>
        <Row className="mb-3">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Campanha Ativa Whatsapp</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="4">
                          <label className="form-control-label">Título*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="title"
                            rules={{ required: 'O título é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="title"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">Fila*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={queues || []}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="queueId"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queueId"
                            rules={{
                              required: 'Selecione uma fila'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="queueId"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">
                            Whatsapp*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={whatsappOptions || []}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={(name, value) => {
                                  setSelectedTemplate(null);
                                  setValue('whatsappTemplateId', '');
                                  setFiles([]);
                                  setValue(name, value);
                                }}
                                fieldName="sourcePhone"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="sourcePhone"
                            rules={{
                              required: 'Selecione um Whatsapp'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="sourcePhone"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Templates*
                          </label>
                          <SelectTemplateWrapper>
                            <Select
                              styles={{ ...custom_select, width: '100%' }}
                              placeholder="Selecione..."
                              options={templatesTitle || []}
                              isSearchable={true}
                              isClearable={false}
                              name="whatsappTemplateId"
                              defaultValue=""
                              value={selectedTemplate}
                              filterOption={(option, inputValue) =>
                                SearchTemplateWhatsapp({ inputValue, option })
                              }
                              onChange={(data) => {
                                onChangeSelected(data);
                              }}
                              getOptionLabel={(option) => {
                                return (
                                  <SelectTemplateOptions
                                    isMulti={false}
                                    color={Colors[option.templateType]}
                                    title={option.label}
                                  >
                                    {option.name}
                                    {Icons[option.templateType] ?? '!'}
                                  </SelectTemplateOptions>
                                );
                              }}
                            />
                          </SelectTemplateWrapper>
                          <ErrorMessage
                            errors={errors}
                            name="whatsappTemplateId"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Tipo*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={
                                  broadcastTypeOfClosingConversationOptions
                                }
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="broadcastTypeOfClosingConversation"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="broadcastTypeOfClosingConversation"
                            defaultValue={
                              broadcastTypeOfClosingConversationOptions[0]
                            }
                          />
                        </Col>
                      </Row>
                      {selectedOption === 2 ||
                      selectedOption === 3 ||
                      selectedOption === 4 ||
                      selectedOption === 5 ? (
                        <Row className="mb-3">
                          {selectedOption === 2 ? (
                            <Col md="6">
                              <label className="form-control-label">
                                Mensagem De Encerramento*
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                name="closingMessage"
                                rules={{
                                  required: 'A Mensagem é obrigatória!'
                                }}
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="closingMessage"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                          {selectedOption === 4 && (
                            <Col md="6">
                              <label className="form-control-label">
                                Árvore de Diálogo*
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={dialogTreeData}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="idDialog"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="idDialog"
                                rules={{
                                  required: 'A Árvore de Diálogo é obrigatória!'
                                }}
                                defaultValue=""
                              />

                              <ErrorMessage
                                errors={errors}
                                name="idDialog"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          )}
                          {selectedOption === 5 && (
                            <>
                              <Col md="6">
                                <label className="form-control-label">
                                  Watson*
                                </label>
                                <Controller
                                  render={(props) => (
                                    <FlexSelect
                                      isClearable={true}
                                      dataOptions={watsonData}
                                      isMulti={false}
                                      closeMenuOnSelect={true}
                                      value={props.value}
                                      valueController={setValue}
                                      fieldName="watsonId"
                                      labelName="descricao"
                                      valueName="id"
                                    />
                                  )}
                                  control={control}
                                  name="watsonId"
                                  rules={{
                                    required: 'O Watson é obrigatório!'
                                  }}
                                  defaultValue={
                                    id ? id : watsonData[0]?.value ?? ''
                                  }
                                />

                                <ErrorMessage
                                  errors={errors}
                                  name="id"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </Col>
                              <Col md="6" className="mb-3">
                                <label className="form-control-label">
                                  Tags para Watson
                                </label>
                                <Controller
                                  render={(props) => (
                                    <CreatableFlexSelect
                                      isClearable
                                      placeholder={'Adicionar...'}
                                      controlStyle={{ minHeight: '46px' }}
                                      setValue={setTagConversa}
                                      valueController={setValue}
                                      defaultValue={tagConversa}
                                      value={tagConversa}
                                    />
                                  )}
                                  control={control}
                                  name="tagConversa"
                                  defaultValue={[]}
                                />

                                <ErrorMessage
                                  errors={errors}
                                  name="tagConversa"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </Col>
                            </>
                          )}
                          {selectedOption !== 1 && selectedOption !== 4 && (
                            <Col md="3">
                              <label className="form-control-label">
                                Data de encerramento*
                              </label>
                              <Controller
                                render={(props) => (
                                  <DatePicker
                                    fieldName="expiryDay"
                                    valueController={(fieldName, value) => {
                                      setValue(fieldName, value);
                                    }}
                                    defaultValue={props.value}
                                  />
                                )}
                                control={control}
                                name="expiryDay"
                                defaultValue={currentDate()}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="expiryDay"
                                render={({ message }) => (
                                  <ErrorFormMessage message={message} />
                                )}
                              />
                            </Col>
                          )}
                          {selectedOption !== 1 && selectedOption !== 4 && (
                            <Col md="3">
                              <label className="form-control-label">
                                Hora final*
                              </label>
                              <Controller
                                render={(props) => (
                                  <HourMaskMMHHSS
                                    valueController={setValue}
                                    fieldName="expirationTime"
                                    value={props.value}
                                    placeholder="12:00:00"
                                  />
                                )}
                                control={control}
                                name="expirationTime"
                                rules={hourRules}
                                defaultValue="12:00:00"
                              />
                              <ErrorMessage
                                errors={errors}
                                name="expirationTime"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          )}
                        </Row>
                      ) : null}
                      <Row>
                        <Col sm={12}>
                          {!!clientFields &&
                          !!clientFields.length &&
                          !isLoading &&
                          selectedTemplate ? (
                            <>
                              <p className="font-italic font-weight-normal">
                                {selectedTemplate.data}
                              </p>
                              <TemplateMessageFilling
                                templateMessage={selectedTemplate.data}
                                templateParams={templateParams ?? {}}
                                setTemplateParams={setTemplateParams}
                                setFiles={setFiles}
                                files={files}
                                clientFields={clientFields}
                                isUpdate={isUpdate}
                                templateType={selectedTemplate.templateType}
                                url={selectedTemplate.url}
                              />
                            </>
                          ) : isLoading ? (
                            <Col style={{ marginTop: '2rem' }}>
                              <ReactLoader height={60} />
                            </Col>
                          ) : null}
                          <ErrorMessage
                            errors={errors}
                            name="whatsappTemplateParams"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
