import React, { useCallback, useEffect, useState } from 'react';
import { useRegisterLogout } from '../../layouts/Login/logout/hooks/useRegisterLogout';
import EditPassword from '../../views/User/form/components/EditPassword';
import ProfileUpdateModal from '../../views/Profile/ProfileUpdateModal/Screen';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ServicePanel from './ServicePanel';
import ReactLoader from 'components/Loader';
import ImageService from './service';
import { useHistory } from 'react-router-dom';
import { apiUc } from '../../http/index';
// import Register from '../../views/softphone/pages/start/index';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import Notifications from '../../components/Notifications';
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from 'reactstrap';

import { Chat } from '../../views/internalChat/chat';
const MD5 = require('crypto-js/md5');

const AdminNavbar = (props) => {
  const history = useHistory();
  const { socketConversation } = useConversationConnectionSocketForMonitoring();

  const [agentSessions, setAgentSessions] = useState([]);

  let { name, login, agent_code } = useSelector((state) => state.auth);
  const [showPainel, setShowPainel] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [isToDisplayProfileUpdateModal, setIsToDisplayProfileUpdateModal] =
    useState(false);
  name = name || localStorage.getItem('name');
  login = login || localStorage.getItem('login');
  agent_code = agent_code || localStorage.getItem('agent_code');
  const [profileImage, setProfileImage] = useState('');
  const [shouldSign, setshouldSign] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { RegisterLogOutOnClosingScreen, RegisterLoginWhenEnteringTheSystem } =
    useRegisterLogout('logout_voluntario_por_fechamento_de_aba');
  const { RegisterLogout } = useRegisterLogout('logout_voluntario');
  const [isLoading, setIsLoading] = useState(true);
  const openSearch = useCallback(() => {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(function () {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  }, []);

  const [isUsingScale, setIsUsingScale] = useState(false);

  // function that on mobile devices makes the search close
  const closeSearch = useCallback(() => {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  }, []);

  // useEffect(() => {
  //   getProfileImage(setProfileImage, userLogin, setIsLoading);
  // }, [userLogin]);
  useEffect(() => {
    loadImage();
  }, []);
  useEffect(() => {
    const shouldsign = localStorage.getItem('should_sign_in_asterisk');
    const shouldsignFormated = JSON.parse(shouldsign);
    setshouldSign(shouldsignFormated);
    const isUsingScale = localStorage.getItem('is_using_scale');
    setIsUsingScale(JSON.parse(isUsingScale));
  }, []);

  useEffect(() => {
    const agentCode = localStorage.getItem('agent_code');
    apiUc
      .get(`/api/agent-status/${agentCode}`)
      .then((response) => {
        const sessionData = response.data.data;
        setAgentSessions(sessionData);
      })
      .catch(() => {
        setAgentSessions([]);
      });
  }, []);

  const loadImage = () => {
    const userLogin = localStorage.getItem('login');
    ImageService.get(`${MD5(userLogin)}.png`)
      .then((res) => {
        var reader = new window.FileReader();

        reader.readAsDataURL(res.data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          setProfileImage(imageDataUrl);
          setIsLoading(false);
        };
      })
      .catch(() => {
        setProfileImage(require('assets/img/theme/iconG4.png'));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    RegisterLoginWhenEnteringTheSystem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', RegisterLogOutOnClosingScreen, {
      useCapture: true
    });

    return () => {
      window.removeEventListener('beforeunload', RegisterLogOutOnClosingScreen);
    };
  }, [login, RegisterLogOutOnClosingScreen]);

  useEffect(() => {
    const roles = localStorage.getItem('roles');
    const rolesObject = JSON.parse(roles);

    rolesObject.map((role) => {
      if (
        role.role === 'ATTENDANCE_PANEL'.toUpperCase() ||
        role.role === 'trainee'.toUpperCase() ||
        role.role === 'administrator'.toUpperCase() ||
        role.role === 'attendant'.toUpperCase() ||
        role.role === 'CREATE_PROTOCOL'.toUpperCase() ||
        role.role === 'TELEPHONY_SERVICE_PANEL'.toUpperCase() ||
        role.role === 'PAUSE_CHAT_SERVICE_PAINEL'.toUpperCase()
      ) {
        return setShowPainel(true);
      }
      return null;
    });
  }, []);

  const toggleModalPassword = () => {
    setModalPassword(!modalPassword);
  };

  const toggleModalProfileUpdate = () => {
    setIsToDisplayProfileUpdateModal(!isToDisplayProfileUpdateModal);
  };
  const handleLogout = async () => {
    socketConversation.emit('atendente_logoff', {
      login: localStorage.getItem('login')
    });
    await RegisterLogout();
    history.push('/user/logout');
  };

  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand border-bottom',
          { 'navbar-dark ': props.theme === 'dark' },
          { 'navbar-light ': props.theme === 'light' }
        )}
        style={{ background: systemConfiguration.primary_color }}
      >
        <Container fluid>
          <Chat />
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                'navbar-search form-inline mr-sm-3',
                { 'navbar-search-light': props.theme === 'dark' },
                { 'navbar-search-dark': props.theme === 'light' }
              )}
            >
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    'pr-3 sidenav-toggler',
                    { active: props.sidenavOpen },
                    { 'sidenav-toggler-dark': props.theme === 'dark' }
                  )}
                  onClick={props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              {/* <Register /> */}
              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>
              {showPainel && (
                <>
                  <ServicePanel />
                </>
              )}
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    {isLoading ? (
                      <ReactLoader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={36}
                        width={36}
                      />
                    ) : (
                      <>
                        <span className="avatar avatar-sm rounded-circle">
                          <img alt="..." src={profileImage} />
                        </span>
                      </>
                    )}

                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bem-vindo!</h6>
                  </DropdownItem>
                  {/* <Link to="/admin/userprofile" className="" tag={NavLinkRRD}>
                    <DropdownItem>
                      <i className="ni ni-single-02" />
                      <span>Perfil</span>
                    </DropdownItem>
                  </Link> */}
                  <Link to="/admin/configuration">
                    <DropdownItem>
                      <i className="ni ni-settings-gear-65" />
                      <span>Configurações</span>
                    </DropdownItem>
                  </Link>
                  <DropdownItem onClick={() => toggleModalPassword()}>
                    <i className="ni ni-lock-circle-open" />
                    <span>Alterar senha</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => toggleModalProfileUpdate()}>
                    <i className="fas fa-id-badge" />
                    <span>Editar perfil</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  {isUsingScale ? (
                    <></>
                  ) : (
                    <>
                      <DropdownItem divider />
                      <DropdownItem onClick={handleLogout}>
                        <i className="ni ni-user-run" />
                        <span>Sair</span>
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Notifications />
      <EditPassword
        toggleModal={toggleModalPassword}
        isOpen={modalPassword}
        login={login}
      />

      <ProfileUpdateModal
        toggleModal={toggleModalProfileUpdate}
        isOpen={isToDisplayProfileUpdateModal}
        login={login}
        agent_code={agent_code}
        loadImage={loadImage}
      />
    </>
  );
};

export default AdminNavbar;
