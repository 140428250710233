import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import {
  Row,
  Form,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import { toast } from 'react-toastify';

import ReactButton from 'components/Buttons/ReactButton';
import Loader from 'components/Loader';
import CustomFieldsInputsForm from '../../../shared/components/CustomFieldsInputsForm';

import AccountService from '../../services/accountService';

import ListComments from '../Comments/CommentsList';
import CommentModal from '../Comments/CommentsForm';
import ModalComment from '../Comments/ModalUpdateComment';
import { FiEdit, FiPlus } from 'react-icons/fi';

import TelephoneMask from 'components/Inputs/TelephoneMask';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { maskPhoneNumber } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { formatCustomFieldsToDisplay } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { removeMaskSpecialCharacters } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { useClickToCallOnPhoneField } from 'views/CRM/shared/hooks/useClickToCallOnPhoneField';
import TemplatesModal from 'views/ServicePanel/components/QueueContent/components/Contact/TemplatesModal';
import { ScreenPrompt } from 'components/ScreenPrompt';

const AccountInputs = ({
  recordTypeId,
  account,
  showCallIconOnPhoneField = false,
  showMessageIconOnPhoneField = false
}) => {
  const history = useHistory();
  const { control, handleSubmit, errors, setValue, reset, formState, watch } =
    useForm();
  const { isDirty } = formState;

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [
    isModalTemplateMessageAccountOpen,
    setIsisModalTemplateMessageAccountOpen
  ] = useState(false);
  const [
    whatsAppMessageContactInfoAccount,
    setWhatsAppMessageContactInfoAccount
  ] = useState({});

  const [listComments, setListComments] = useState([]);

  const [openModalCreateComment, setOpenModalCreateComment] = useState(false);

  const [dataCommentUpdate, setDataCommentUpdate] = useState([]);
  const [openModalUpdateComment, setOpenModalUpdateComment] = useState(false);

  const { getCustomFields, customFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(entitiesName.ACCOUNT, recordTypeId);
  const { canMakeCall, makeCall, canSendMessage } =
    useClickToCallOnPhoneField();

  useEffect(() => {
    if (account) {
      const formattedAccount = formatCustomFieldsToDisplay(
        {
          ...account,
          phone: maskPhoneNumber(account.phone)
        },
        customFields
      );

      reset(formattedAccount);
    }
  }, [reset, account, customFields]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  function handleWhatsAppMessage(phone) {
    if (!phone) return;
    const phoneNumberWithoutMask = removeMaskSpecialCharacters(phone);
    let data = {
      nome: account?.name,
      telefone: phoneNumberWithoutMask !== '' ? phoneNumberWithoutMask : ''
    };

    setWhatsAppMessageContactInfoAccount(data);
    setIsisModalTemplateMessageAccountOpen(!isModalTemplateMessageAccountOpen);
    return whatsAppMessageContactInfoAccount;
  }

  const onSubmit = async (data) => {
    setIsSubmiting(true);

    data.phone = removeMaskSpecialCharacters(data.phone);

    const dataDTO = formatCustomFieldsToSubmit(data, customFields);

    const saveAccountPromise = account
      ? AccountService.update(account.id, dataDTO)
      : AccountService.create(recordTypeId, dataDTO);

    saveAccountPromise
      .then((response) => {
        toast.success(`Conta ${account ? 'atualizada' : 'criada'} com sucesso`);
        history.push(
          `/admin/crm/accounts/${recordTypeId}/${
            response?.data?.data?.id || account.id
          }`
        );
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message ||
            err.message ||
            `Erro ao ${account ? 'atualizar' : 'criar'} conta`
        );
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/crm/accounts', { recordTypeId });
  };

  const handleUpdateComment = (dataComment) => {
    setOpenModalUpdateComment(true);
    setDataCommentUpdate(dataComment);
  };

  const handleCreateComment = () => {
    setOpenModalCreateComment(true);
  };

  // const reloadComments = () => {
  //   OpportunityService.getOpportunityComments(id).then((res) => {
  //     setListComments(res.data.data);
  //   });
  // };

  if (isLoadingCustomFields) {
    return (
      <Row className="justify-content-md-center">
        <Loader />
      </Row>
    );
  }

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm="6" className="mb-3">
            <label className="form-control-label text-capitalize">Nome*</label>
            <Controller
              render={(props) => (
                <Input
                  value={props.value}
                  onChange={props.onChange}
                  type="text"
                  placeholder="Nome"
                />
              )}
              control={control}
              defaultValue=""
              rules={{ required: 'Campo obrigatório' }}
              name="name"
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col sm="6" className="mb-3">
            <label className="form-control-label text-capitalize">
              Telefone
            </label>
            <Controller
              render={(props) => (
                <InputGroup>
                  <TelephoneMask
                    valueController={setValue}
                    fieldName="phone"
                    value={props.value}
                    placeholder="(99) 91234-5678"
                  />
                  {showMessageIconOnPhoneField && (
                    <InputGroupAddon addonType="append">
                      <Button
                        type="button"
                        color="info"
                        title="Enviar mensagem"
                        disabled={!canSendMessage(props.value)}
                        onClick={() => handleWhatsAppMessage(props.value)}
                      >
                        <i className="fas fa-comment" />
                      </Button>
                    </InputGroupAddon>
                  )}
                  {showCallIconOnPhoneField && (
                    <InputGroupAddon addonType="append">
                      <Button
                        type="button"
                        color="success"
                        title="Realizar chamada"
                        disabled={!canMakeCall(props.value)}
                        onClick={() => makeCall(props.value)}
                      >
                        <i className="fas fa-phone" />
                      </Button>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              )}
              control={control}
              defaultValue=""
              name="phone"
              rules={{
                validate: (value) => {
                  if (!value) return true;
                  let phoneFormated = value.replace(/\D/g, '');
                  phoneFormated = phoneFormated.replace(/^0/, '');

                  if (phoneFormated.length < 10 && phoneFormated.length > 0) {
                    return 'Telefone inválido';
                  }
                  return true;
                }
              }}
            />
            <ErrorMessage
              errors={errors}
              name="phone"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
        <Row className="mb-1">
          <CustomFieldsInputsForm
            fields={customFields}
            control={control}
            errors={errors}
            setValue={setValue}
            Controller={Controller}
            options={{ showCallIconOnPhoneField, showMessageIconOnPhoneField }}
            contactMessageClientName={account?.name}
            watch={watch}
          />
        </Row>

        <ReactButton
          btnColor="confirmation"
          type="submit"
          disabled={isSubmiting}
        >
          Salvar
        </ReactButton>

        {!account && (
          <ReactButton
            btnColor="cancelation"
            onClick={() => {
              return cancelAction();
            }}
          >
            Cancelar
          </ReactButton>
        )}
      </Form>
      <TemplatesModal
        isModalOpen={isModalTemplateMessageAccountOpen}
        onModalToggle={setIsisModalTemplateMessageAccountOpen}
        clientNumber={whatsAppMessageContactInfoAccount.telefone}
        clientName={whatsAppMessageContactInfoAccount.nome}
        clientId={whatsAppMessageContactInfoAccount.clientId ?? ''}
        isIsolated={true}
      />
    </>
  );
};

export default AccountInputs;
