import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { fuctions } from './utils/functions';
import Loader from 'react-loader-spinner';
import ProtocolService from '../service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ModalStatus from './components/ModalStatus';
import ModalUser from './components/ModalUser';
import ModalReason from './components/ModalReason';
import ModalSubReason from './components/ModalSubReason';
import ModalClient from './components/ModalClient';
import ModalRelations from './components/Relations';
import CommentsForm from './components/Comments/CommentsForm';
import { useReactToPrint } from 'react-to-print';
import IconG4 from 'assets/img/theme/iconG4.png';
import Select from 'react-select/async';
import {
  Container,
  Button,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';

import { validateFields, definedShowName } from '../form/assets/functions';
import { useProtocolDefaultFieldsSettings } from '../../fieldConfigs/hooks/useProtocolDefaultFieldsSettings';
import { PageToPrint } from './components/PageToPrint';
import { Print } from './components/PageToPrint/styles';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import CustomFieldsInputsForm from 'views/CRM/shared/components/CustomFieldsInputsForm';
import { formatCustomFieldsToDisplay } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { useClickToCallOnPhoneField } from 'views/CRM/shared/hooks/useClickToCallOnPhoneField';
import { ProtocolAttachments } from './components/Attachments';
import { custom_select } from 'assets/styles/multiple_select';
import { useSearchAccounts } from 'views/CRM/Account/hooks/useSearchAccounts';
import ListSchedules from './components/Schedules/SchedulesList';
import ModalSchedulingProtocol from '../form/components/ModalSchedulingProtocol';
import CommentContainer from './components/Comments/CommentContainer';
import ProtocolSchedulingService from '../service/ProtocolSchedulingService';
import { removeMaskSpecialCharacters } from 'views/CRM/shared/utils/phoneNumberHelpers';
import TemplatesModal from 'views/ServicePanel/components/QueueContent/components/Contact/TemplatesModal';
import { useCustomFields } from '../form/context/CustomFieldsContext';
import ProtocolModal from 'components/Navbars/ServicePanel/ProtocolModal';
import { SubProtocolList } from './components/SubProtocolList';

const { formatDataUpdateProtocol } = fuctions();

const ProtocolFormUpdate = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [protocol, setProtocol] = useState();

  const parentProtocol = protocol?.parent;
  const subProtocols = protocol?.subProtocols || [];
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [openModalUser, setOpenModalUser] = useState(false);
  const [openModalReason, setOpenModalReason] = useState(false);
  const [openModalSubReason, setOpenModalSubReason] = useState(false);
  const [openModalUpdateClient, setOpenModalUpdateClient] = useState(false);
  const [openModalRelations, setOpenModalRelations] = useState(false);
  const [returnPath, setReturnPath] = useState();
  const [recordTypeId, setRecordTypeId] = useState();

  const [account, setAccount] = useState();
  const [hasAccountError, setHasAccountError] = useState(false);
  const [valueFieldsReferenceLoading, setValueFieldsReferenceLoading] =
    useState(true);
  const [listComments, setListComments] = useState([]);
  const [listSchedules, setListSchedules] = useState([]);
  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false);
  const [isToUpdateScheduling, setIsToUpdateScheduling] = useState(false);
  const [isToCreateScheduling, setIsToCreateScheduling] = useState(false);
  const [schedulingId, setschedulingId] = useState();
  const [
    isModalTemplateMessageProtocolOpen,
    setIsisModalTemplateMessageProtocolOpen
  ] = useState(false);
  const [
    whatsAppMessageContactInfoProtocol,
    setWhatsAppMessageContactInfoProtocol
  ] = useState({});
  const { control, errors, handleSubmit, setValue, reset, watch, update } =
    useForm();
  const { canMakeCall, makeCall, canSendMessage } =
    useClickToCallOnPhoneField();
  const { isSearching, filterAccounts } = useSearchAccounts();
  const { changeFieldsReference, getValueFieldsReference } = useCustomFields();
  const [isProtocolModalOpen, setIsProtocolModalOpen] = useState(false);

  useEffect(() => {
    const objetoString = localStorage.getItem('valueRoute');
    if (objetoString) {
      const objectFromLocal = JSON.parse(objetoString);
      const { recordTypeId, returnPath } = objectFromLocal;
      setRecordTypeId(recordTypeId);
      setReturnPath(returnPath);
    }
  }, []);

  const { protocolDefaultFieldsDisplaySettings } =
    useProtocolDefaultFieldsSettings(recordTypeId);

  const { customFields, getCustomFields } = useEntityCustomFieldsByRecordType(
    'PROTOCOL',
    recordTypeId
  );
  const handleRedirect = useCallback(
    (id, recordTypeId) => {
      history.push(`/admin/protocol/${id}/update`, {
        recordTypeId: recordTypeId,
        returnPath: '/admin/protocol'
      });
    },
    [history]
  );

  const getProtocolById = useCallback(() => {
    if (id) {
      setLoading(true);
      if (recordTypeId) {
        ProtocolService.getByIdAndRecordType(id, recordTypeId)
          .then((res) => {
            console.log('res: ', res);
            const protocolData = res.data.content;
            const protocolScheduleData = res.data.content.schedules;
            const protocolDTO = formatCustomFieldsToDisplay(
              protocolData,
              customFields
            );

            setProtocol(protocolDTO);
            setListSchedules(protocolScheduleData);
            if (protocolData.account) {
              setAccount({
                label: protocolData?.account?.name,
                value: protocolData?.account?.id
              });
            }

            reset(protocolDTO);
            setLoading(false);
          })
          .catch((erro) => {
            console.log('erro: ', erro);
            toast.error('Erro ao carregar o protocolo', {
              autoClose: 3000,
              closeOnClick: true
            });
          });
      }
    }
  }, [id, recordTypeId, customFields, reset, valueFieldsReferenceLoading]);

  const getCommnents = useCallback(() => {
    if (id) {
      ProtocolService.getAllComments(id)
        .then((res) => {
          setListComments(res.data.content);
          setLoading(false);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [id]);

  useEffect(getProtocolById, [getProtocolById]);
  useEffect(getCommnents, [getCommnents]);
  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    Object.keys(protocol || []).forEach((key) => {
      if (protocol[key] && key === 'current_status') {
        setValue(key, protocol?.protocol_statuses[0]?.status?.name || '');
      } else if (protocol[key] && key === 'client_id') {
        setValue(key, protocol?.client?.nome || '');
      } else if (protocol[key] && key === 'reason_id') {
        setValue(key, protocol?.reason?.name || '');
      } else if (protocol[key] && key === 'subreason_id') {
        setValue(key, protocol?.subreason?.name || '');
      } else {
        setValue(key, protocol[key] || '');
      }
    });
  }, [protocol, customFields, setValue, reset]);

  useEffect(() => {
    if (customFields.length === 0) setValueFieldsReferenceLoading(false);
    setValueFieldsReferenceLoading(true);
    let valueFieldsReferenceCopy = { ...getValueFieldsReference() };
    if (protocol) {
      customFields.length > 0 &&
        customFields.map((field) => {
          if (Object.keys(field).length && !field?.isReference && field?.name) {
            valueFieldsReferenceCopy[field.parentName] =
              field.options
                .filter((opt) => opt.value === protocol[field.name])[0]
                ?.parentsValue?.map((item) => ({ label: item, value: item })) ||
              [];
          }
        });

      changeFieldsReference(valueFieldsReferenceCopy);
      setValueFieldsReferenceLoading(false);
    }
  }, [customFields, protocol]);

  const selectNameToActiveContactMessage = () => {
    if (!protocol) return;
    return protocol?.client?.nome
      ? protocol?.client?.nome
      : protocol?.account?.name || '';
  };

  function handleWhatsAppMessage(phone) {
    if (!phone) return;
    const phoneNumberWithoutMask = removeMaskSpecialCharacters(phone);
    let data = {
      nome: selectNameToActiveContactMessage(),
      telefone: phoneNumberWithoutMask !== '' ? phoneNumberWithoutMask : ''
    };

    setWhatsAppMessageContactInfoProtocol(data);
    setIsisModalTemplateMessageProtocolOpen(
      !isModalTemplateMessageProtocolOpen
    );
    return whatsAppMessageContactInfoProtocol;
  }

  const cancelAction = () => {
    const path = returnPath;
    history.push(path, { recordTypeId } || null);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handleUpdateComment = (commentId) => {
    return async (data) => {
      try {
        await ProtocolService.updateComment(id, commentId, data);

        toast.success('Comentário atualizado com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
        getCommnents();
      } catch (error) {
        const msg = error?.response?.data?.message;
        toast.error(msg || 'Ocorreu um erro ao atualizar comentário');
      }
    };
  };

  const handleDeleteComment = (commentId) => {
    return async () => {
      if (!window.confirm('Apagar comentário?')) return;

      try {
        await ProtocolService.deleteComment(id, commentId);

        toast.success('Comentário excluido com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
        getCommnents();
      } catch (error) {
        const msg = error?.response?.data?.message;
        toast.error(msg || 'Ocorreu um erro ao deletar comentário');
      }
    };
  };

  const handleCreateComment = async (data) => {
    try {
      await ProtocolService.createCommentProtocol(id, data).then((response) => {
        toast.success(
          response.data.message || 'Comentário adicionado com sucesso',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      });

      getCommnents();
    } catch (error) {
      const msg = error?.response?.data?.message;
      toast.error(msg || 'Ocorreu um erro ao adicionar comentário');
    }
  };

  const updateProtocol = async (data) => {
    try {
      if (
        protocolDefaultFieldsDisplaySettings?.account_id?.isRequired &&
        !account
      ) {
        setHasAccountError(true);
        return;
      }

      const dataToSubmit = formatDataUpdateProtocol(data);
      const dataDTO = formatCustomFieldsToSubmit(dataToSubmit, customFields);
      const dataSubmitWithoutNull = Object.fromEntries(
        Object.entries(dataDTO).filter(([, v]) => v != null)
      );

      if (account) {
        dataSubmitWithoutNull.account_id = account.value;
      }

      await ProtocolService.updateByRecordType(
        id,
        recordTypeId,
        dataSubmitWithoutNull
      );
      toast.info('Protocolo Atualizado com sucesso');
      getProtocolById();
    } catch (error) {
      toast.error('Error ao atualizar o protocolo');
    }
  };

  const cancelSchedule = (scheduleId) => {
    if (!window.confirm('Cancelar agendamento?')) {
      return;
    } else {
      ProtocolSchedulingService.cancelProtocolSchedule(scheduleId)
        .then(() => {
          toast.success('Agendamento cancelado com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
          getProtocolById();
        })
        .catch((error) => {
          toast.error(
            error || 'Houve um erro ao tentar cancelar o agendamento.',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        });
    }
  };

  const handleUpdateSchedule = (dataSchedule) => {
    setIsSchedulingModalOpen(true);
    setIsToUpdateScheduling(true);
    setschedulingId(dataSchedule.id);
    setIsToCreateScheduling(false);
  };

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath={returnPath || '/admin/protocol'}
        buttonTitle="Voltar para a pagina anterior"
        navigatorState={{ recordTypeId }}
      />
      <div style={{ display: 'none' }}>
        <Print ref={componentRef}>
          {protocol ? (
            <>
              <PageToPrint
                protocolNumber={protocol.number}
                comments={listComments}
                protocolData={protocol}
                defaultFields={protocolDefaultFieldsDisplaySettings}
                customFields={customFields}
              />
              <img className="g4Img" src={IconG4} />
            </>
          ) : null}
        </Print>
      </div>
      <>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {protocol && (
                      <h3 className="mb-0">
                        Nº. do Protocolo {protocol.number}
                      </h3>
                    )}
                    <div>
                      <Button
                        style={{
                          alignSelf: 'flex-end',
                          marginLeft: 10,
                          backgroundColor: `#0000ff`,
                          color: 'white'
                        }}
                        onClick={() => setIsProtocolModalOpen(true)}
                      >
                        Sub Protocolo
                      </Button>
                      <ProtocolModal
                        isModalOpen={isProtocolModalOpen}
                        onModalToggle={setIsProtocolModalOpen}
                        unmountOnClose={true}
                        createdFrom={id}
                        fatherProtocol={{
                          protocol,
                          account,
                          contato: protocol?.client
                        }}
                      />
                      <Button
                        style={{
                          alignSelf: 'flex-end',
                          marginLeft: 10,
                          backgroundColor: `${
                            protocol?.cdr_report === null &&
                            protocol?.conversa === null
                              ? 'gray'
                              : '#5e72e4'
                          }`,
                          color: 'white',
                          cursor: `${
                            !protocol?.cdr_report && !protocol?.conversa
                              ? 'default'
                              : 'pointer'
                          }`
                        }}
                        disabled={!protocol?.cdr_report && !protocol?.conversa}
                        title={
                          protocol?.cdr_report === null &&
                          protocol?.conversa === null
                            ? 'Esse protocolo não possui conversas/chamadas relacionadas'
                            : 'Clique para visualizar as conversas/chamadas relacionadas a este protocolo'
                        }
                        onClick={() => setOpenModalRelations(true)}
                      >
                        Dados do Atendimento
                      </Button>
                      <ModalRelations
                        isModalOpen={openModalRelations}
                        onModalToggle={setOpenModalRelations}
                        protocolRelation={protocol ? protocol : []}
                        setLoading={setLoading}
                      />
                      <Button
                        color="danger"
                        style={{
                          alignSelf: 'flex-end'
                        }}
                        onClick={() => {
                          handlePrint();
                        }}
                      >
                        Imprimir
                      </Button>
                    </div>
                  </CardHeader>

                  <CardBody>
                    {loading ? (
                      <Row className="justify-content-md-center">
                        <Loader
                          type="TailSpin"
                          color="#35ac50"
                          height={100}
                          width={100}
                        />
                      </Row>
                    ) : (
                      protocol && (
                        <>
                          <Form
                            className="needs-validation"
                            onSubmit={handleSubmit(updateProtocol)}
                          >
                            {protocolDefaultFieldsDisplaySettings &&
                              !!Object.keys(
                                protocolDefaultFieldsDisplaySettings
                              ).length && (
                                <>
                                  <Row className="mb-3">
                                    {protocolDefaultFieldsDisplaySettings.title
                                      .isActive && (
                                      <Col
                                        md={
                                          protocolDefaultFieldsDisplaySettings
                                            .telephone.isActive
                                            ? { size: 8 }
                                            : { size: 12 }
                                        }
                                      >
                                        <label className="form-control-label">
                                          {definedShowName(
                                            'Titulo',
                                            protocolDefaultFieldsDisplaySettings
                                              .title.isRequired
                                          )}
                                        </label>
                                        <Controller
                                          as={Input}
                                          control={control}
                                          name="title"
                                          defaultValue={protocol?.title}
                                          disabled={false}
                                          rules={
                                            protocolDefaultFieldsDisplaySettings
                                              .title.isRequired
                                          }
                                        />
                                        <ErrorMessage
                                          errors={errors}
                                          name="title"
                                          render={({ message }) =>
                                            errorFormMessage(message)
                                          }
                                        />
                                      </Col>
                                    )}
                                    {protocolDefaultFieldsDisplaySettings
                                      .telephone.isActive && (
                                      <Col
                                        md={
                                          protocolDefaultFieldsDisplaySettings
                                            .title.isActive
                                            ? { size: 4 }
                                            : { size: 12 }
                                        }
                                      >
                                        <label className="form-control-label">
                                          Telefone
                                        </label>
                                        <Controller
                                          render={(props) => (
                                            <InputGroup>
                                              <Input
                                                name="telephone"
                                                disabled={true}
                                                defaultValue={
                                                  protocol?.telephone
                                                }
                                                value={props.value}
                                              />
                                              <InputGroupAddon addonType="append">
                                                <Button
                                                  type="button"
                                                  color="info"
                                                  title="Enviar mensagem"
                                                  disabled={
                                                    !canSendMessage(props.value)
                                                  }
                                                  onClick={() =>
                                                    handleWhatsAppMessage(
                                                      props.value
                                                    )
                                                  }
                                                >
                                                  <i className="fas fa-comment" />
                                                </Button>
                                              </InputGroupAddon>
                                              <InputGroupAddon addonType="append">
                                                <Button
                                                  type="button"
                                                  color="success"
                                                  title="Realizar chamada"
                                                  disabled={
                                                    !canMakeCall(props.value)
                                                  }
                                                  onClick={() =>
                                                    makeCall(props.value)
                                                  }
                                                >
                                                  <i className="fas fa-phone" />
                                                </Button>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          )}
                                          control={control}
                                          name="telephone"
                                          defaultValue={protocol?.telephone}
                                          disabled={true}
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                  <Row className="mb-3">
                                    {protocolDefaultFieldsDisplaySettings
                                      .description.isActive && (
                                      <Col md={{ size: 12 }}>
                                        <label className="form-control-label">
                                          {definedShowName(
                                            'Descrição',
                                            protocolDefaultFieldsDisplaySettings
                                              .description.isRequired
                                          )}
                                        </label>
                                        <Controller
                                          as={
                                            <Input type="textarea" rows="6" />
                                          }
                                          control={control}
                                          name="description"
                                          defaultValue={
                                            protocol?.description || ''
                                          }
                                          disabled={false}
                                          rules={validateFields(
                                            protocolDefaultFieldsDisplaySettings
                                              .description.isRequired
                                          )}
                                        />
                                        <ErrorMessage
                                          errors={errors}
                                          name="description"
                                          render={({ message }) =>
                                            errorFormMessage(message)
                                          }
                                        />
                                      </Col>
                                    )}
                                  </Row>

                                  <Row className="mb-3">
                                    {protocolDefaultFieldsDisplaySettings.email
                                      .isActive && (
                                      <Col>
                                        <label className="form-control-label">
                                          {definedShowName(
                                            'Email',
                                            protocolDefaultFieldsDisplaySettings
                                              .email.isRequired
                                          )}
                                        </label>
                                        <Controller
                                          as={Input}
                                          control={control}
                                          name="email"
                                          defaultValue={protocol?.email}
                                          disabled={false}
                                          rules={{
                                            validate: (value) => {
                                              const isRequired =
                                                protocolDefaultFieldsDisplaySettings
                                                  .email.isRequired;

                                              if (isRequired && !value)
                                                return 'Campo obrigatório';
                                              if (!isRequired && !value)
                                                return true;

                                              const emailRegex =
                                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

                                              return (
                                                emailRegex.test(value) ||
                                                'Email com formato incorreto'
                                              );
                                            }
                                          }}
                                        />
                                        <ErrorMessage
                                          errors={errors}
                                          name="email"
                                          render={({ message }) =>
                                            errorFormMessage(message)
                                          }
                                        />
                                      </Col>
                                    )}

                                    {protocolDefaultFieldsDisplaySettings
                                      .account_id.isActive && (
                                      <Col>
                                        <label className="form-control-label">
                                          {definedShowName(
                                            'Conta',
                                            protocolDefaultFieldsDisplaySettings
                                              .account_id.isRequired
                                          )}
                                        </label>
                                        <Select
                                          styles={custom_select}
                                          placeholder="Busca conta"
                                          isClearable={true}
                                          name="account_id"
                                          isLoading={isSearching}
                                          loadingMessage={() => 'Buscando...'}
                                          noOptionsMessage={({ inputValue }) =>
                                            !inputValue
                                              ? 'Digite para pesquisar'
                                              : 'Nenhum resultado encontrado'
                                          }
                                          loadOptions={async (inputValue) => {
                                            const res = await filterAccounts(
                                              inputValue
                                            );
                                            return (
                                              res &&
                                              res.map((account) => ({
                                                label: account.name,
                                                value: account.id
                                              }))
                                            );
                                          }}
                                          onChange={(data) => {
                                            if (data) {
                                              const { value, label } = data;
                                              setAccount({
                                                label,
                                                value
                                              });
                                            } else {
                                              setAccount(null);
                                            }
                                          }}
                                          value={
                                            account
                                              ? {
                                                  label: account.label,
                                                  value: account.value
                                                }
                                              : ''
                                          }
                                          defaultValue={
                                            account
                                              ? {
                                                  label: account.label,
                                                  value: account.value
                                                }
                                              : ''
                                          }
                                        />
                                        {hasAccountError &&
                                          errorFormMessage(
                                            'A conta é obrigatória'
                                          )}
                                      </Col>
                                    )}
                                  </Row>

                                  {!valueFieldsReferenceLoading &&
                                    !!customFields.length && (
                                      <Row>
                                        <CustomFieldsInputsForm
                                          fields={customFields}
                                          protocolDTO={protocol}
                                          setValueFieldsReferenceLoading={
                                            setValueFieldsReferenceLoading
                                          }
                                          valueFieldsReferenceLoading={
                                            valueFieldsReferenceLoading
                                          }
                                          control={control}
                                          watch={watch}
                                          errors={errors}
                                          setValue={setValue}
                                          Controller={Controller}
                                          isOnEditPage={true}
                                          options={{
                                            showCallIconOnPhoneField: true,
                                            showMessageIconOnPhoneField: true
                                          }}
                                          contactMessageClientName={selectNameToActiveContactMessage()}
                                        />
                                      </Row>
                                    )}

                                  <Row style={{ marginBottom: '1.5rem' }}>
                                    <Col md="4" style={{ paddingTop: '10px' }}>
                                      <Button
                                        color="primary"
                                        type="submit"
                                        title="Atualizar dados do protocolo"
                                      >
                                        Atualizar
                                      </Button>
                                    </Col>
                                  </Row>

                                  <Row className="mb-3">
                                    {protocolDefaultFieldsDisplaySettings
                                      .client_id.isActive && (
                                      <>
                                        <Col
                                          md={
                                            protocolDefaultFieldsDisplaySettings
                                              .service_channel.isActive
                                              ? { size: 5 }
                                              : { size: 11 }
                                          }
                                        >
                                          <label className="form-control-label">
                                            {definedShowName(
                                              'Contato',
                                              protocolDefaultFieldsDisplaySettings
                                                .client_id.isRequired
                                            )}
                                          </label>
                                          <Input
                                            value={protocol?.client?.nome || ''}
                                            disabled
                                          ></Input>
                                        </Col>
                                        <Col
                                          md="1"
                                          xl="1"
                                          style={{ paddingTop: '41px' }}
                                        >
                                          <Button
                                            title="Atualizar contato"
                                            size="sm"
                                            color="info"
                                            onClick={() =>
                                              setOpenModalUpdateClient(true)
                                            }
                                          >
                                            <li className="fas fa-user-edit"></li>
                                          </Button>
                                        </Col>
                                      </>
                                    )}
                                    {protocolDefaultFieldsDisplaySettings
                                      .service_channel.isActive && (
                                      <Col
                                        md={
                                          protocolDefaultFieldsDisplaySettings
                                            .client_id.isActive
                                            ? { size: 5 }
                                            : { size: 12 }
                                        }
                                      >
                                        <label className="form-control-label">
                                          {definedShowName(
                                            'Canal de atendimento',
                                            protocolDefaultFieldsDisplaySettings
                                              .telephone.isRequired
                                          )}
                                        </label>
                                        <Controller
                                          as={Input}
                                          control={control}
                                          name="service_channel"
                                          defaultValue={
                                            protocol?.service_channel || ''
                                          }
                                          disabled={true}
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                  <Row className="mb-3">
                                    {protocolDefaultFieldsDisplaySettings
                                      .reason_id.isActive && (
                                      <>
                                        <Col
                                          md={
                                            protocolDefaultFieldsDisplaySettings
                                              .subreason_id.isActive
                                              ? { size: 5 }
                                              : { size: 11 }
                                          }
                                        >
                                          <label className="form-control-label">
                                            {definedShowName(
                                              'Motivo',
                                              protocolDefaultFieldsDisplaySettings
                                                .reason_id.isRequired
                                            )}
                                          </label>
                                          <Input
                                            value={protocol?.reason?.name || ''}
                                            disabled
                                          ></Input>
                                        </Col>
                                        <Col
                                          md="1"
                                          style={{ paddingTop: '41px' }}
                                        >
                                          <Button
                                            title="Atualizar Motivo"
                                            size="sm"
                                            color="info"
                                            onClick={() =>
                                              setOpenModalReason(true)
                                            }
                                          >
                                            <li className="fas fa-user-edit"></li>
                                          </Button>
                                        </Col>
                                      </>
                                    )}
                                    {protocolDefaultFieldsDisplaySettings
                                      .subreason_id.isActive && (
                                      <>
                                        <Col
                                          md={
                                            protocolDefaultFieldsDisplaySettings
                                              .reason_id.isActive
                                              ? { size: 5 }
                                              : { size: 11 }
                                          }
                                        >
                                          <label className="form-control-label">
                                            {definedShowName(
                                              'Submotivo',
                                              protocolDefaultFieldsDisplaySettings
                                                .subreason_id.isRequired
                                            )}
                                          </label>
                                          <Input
                                            value={
                                              protocol?.subreason?.name || ''
                                            }
                                            disabled
                                          ></Input>
                                        </Col>
                                        <Col
                                          md="1"
                                          style={{ paddingTop: '41px' }}
                                        >
                                          <Button
                                            title="Atualizar Submotivo"
                                            size="sm"
                                            color="info"
                                            onClick={() =>
                                              setOpenModalSubReason(true)
                                            }
                                          >
                                            <li className="fas fa-user-edit"></li>
                                          </Button>
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                  <Row className="mb-3">
                                    <Col
                                      md={
                                        protocolDefaultFieldsDisplaySettings
                                          .current_status.isActive
                                          ? { size: 5 }
                                          : { size: 11 }
                                      }
                                    >
                                      <label className="form-control-label">
                                        Responsável*
                                      </label>
                                      <Controller
                                        as={Input}
                                        control={control}
                                        name="current_responsible"
                                        defaultValue={
                                          protocol?.protocol_responsibles[0]
                                            ?.usr?.name || ''
                                        }
                                        disabled={true}
                                      />
                                    </Col>
                                    <Col md="1" style={{ paddingTop: '41px' }}>
                                      <Button
                                        title="Atualizar Responsável"
                                        size="sm"
                                        color="info"
                                        onClick={() => setOpenModalUser(true)}
                                      >
                                        <li className="fas fa-user-edit"></li>
                                      </Button>
                                    </Col>
                                    {protocolDefaultFieldsDisplaySettings
                                      .current_status.isActive && (
                                      <>
                                        <Col
                                          md={
                                            protocolDefaultFieldsDisplaySettings
                                              .current_status.isActive
                                              ? { size: 5 }
                                              : { size: 11 }
                                          }
                                        >
                                          <label className="form-control-label">
                                            {definedShowName(
                                              'Status/Fase',
                                              protocolDefaultFieldsDisplaySettings
                                                .current_status.isRequired
                                            )}
                                          </label>
                                          <Input
                                            value={
                                              protocol?.protocol_statuses[0]
                                                ?.status?.name ??
                                              protocol?.protocol_statuses[0]
                                                ?.name ??
                                              ''
                                            }
                                            disabled
                                          />
                                        </Col>
                                        <Col
                                          md="1"
                                          style={{ paddingTop: '41px' }}
                                        >
                                          <Button
                                            title="Atualizar Status/Fase"
                                            size="sm"
                                            color="info"
                                            onClick={() =>
                                              setOpenModalStatus(true)
                                            }
                                          >
                                            <li className="fas fa-user-edit"></li>
                                          </Button>
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                </>
                              )}
                          </Form>

                          <Row className="mb-3">
                            <Col md={'11'}>
                              <label className="form-control-label">
                                Protocolos Associados (
                                {subProtocols.length + (parentProtocol ? 1 : 0)}
                                )
                              </label>
                            </Col>
                            <Col md="11">
                              <SubProtocolList
                                parentProtocol={parentProtocol}
                                list={subProtocols}
                                handleRedirect={handleRedirect}
                              />
                            </Col>
                          </Row>

                          {listSchedules ? (
                            <ListSchedules
                              listSchedules={listSchedules}
                              handleUpdateSchedule={handleUpdateSchedule}
                              cancelSchedule={cancelSchedule}
                            />
                          ) : (
                            <Row className="justify-content-md-center">
                              <Loader
                                type="TailSpin"
                                color="#35ac50"
                                height={100}
                                width={100}
                              />
                            </Row>
                          )}
                          <ModalSchedulingProtocol
                            className="modal-lg"
                            show={isSchedulingModalOpen}
                            onModalToggle={setIsSchedulingModalOpen}
                            schedulingId={schedulingId}
                            setschedulingId={setschedulingId}
                            isToCreateScheduling={isToCreateScheduling}
                            isToUpdateScheduling={isToUpdateScheduling}
                            protocolId={protocol.id}
                            getProtocolById={getProtocolById}
                            isFromNotification={false}
                          />
                          <Col className="text-right">
                            <Button
                              title="Adicionar novo agendamento"
                              color="success"
                              type="button"
                              onClick={() => {
                                setIsSchedulingModalOpen(true);
                                setIsToCreateScheduling(true);
                              }}
                            >
                              <FiPlus size={16} /> Adicionar agendamento
                            </Button>
                          </Col>

                          <h3 className="form-control-label mt-2">Anexo(s)</h3>
                          <ProtocolAttachments
                            protocolId={protocol?.id}
                            attachments={protocol?.attachments}
                            onAdded={(addedAttachments) => {
                              setProtocol((prev) => ({
                                ...prev,
                                attachments: [
                                  ...prev.attachments,
                                  ...addedAttachments
                                ]
                              }));
                            }}
                            onDeleted={(attachmentId) => {
                              const remainAttachments =
                                protocol.attachments.filter(
                                  (att) => att.id !== attachmentId
                                );
                              setProtocol((prev) => ({
                                ...prev,
                                attachments: remainAttachments
                              }));
                            }}
                          />

                          {listComments ? (
                            <Row className="mt-3">
                              <Col md="12">
                                <label className="form-control-label">
                                  Comentário(s)
                                </label>
                              </Col>
                              <Col md="12">
                                <div
                                  className="d-flex flex-column"
                                  style={{
                                    gap: '1rem'
                                  }}
                                >
                                  {listComments.map((comment) => (
                                    <CommentContainer
                                      key={comment.id}
                                      comment={comment}
                                      onDelete={handleDeleteComment(comment.id)}
                                      onUpdate={handleUpdateComment(comment.id)}
                                      email={protocol.email}
                                    />
                                  ))}
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Row className="justify-content-md-center">
                              <Loader
                                type="TailSpin"
                                color="#35ac50"
                                height={100}
                                width={100}
                              />
                            </Row>
                          )}
                          <Row className="my-3">
                            <Col md="12">
                              <CommentsForm
                                onSubmit={handleCreateComment}
                                protocol={protocol}
                                IAlreadySendPublicEmail={listComments?.find(
                                  (comment) => !comment.is_private
                                )}
                              />
                            </Col>
                          </Row>

                          <Row style={{ paddingTop: '20px' }}>
                            <Col className="text-left">
                              <Button
                                color="danger"
                                onClick={() => cancelAction()}
                              >
                                Voltar
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          {protocol && (
            <>
              {protocolDefaultFieldsDisplaySettings &&
                !!Object.keys(protocolDefaultFieldsDisplaySettings).length && (
                  <>
                    <ModalStatus
                      render={(newStatus) => {
                        const copy = { ...protocol };
                        copy.protocol_statuses[0] = newStatus;
                        copy.current_status = newStatus.id;

                        setProtocol({ ...copy });
                      }}
                      recordTypeId={protocol.record_type_id}
                      idProtocol={protocol.id}
                      idStatusProtocol={
                        protocol?.protocol_statuses[0]?.status?.id
                      }
                      isModalOpen={openModalStatus}
                      onModalToggle={setOpenModalStatus}
                      unmountOnClose={true}
                      isRequired={
                        protocolDefaultFieldsDisplaySettings?.current_status
                          ?.isRequired || true
                      }
                    />
                    <ModalUser
                      render={(newUser) => {
                        protocol.current_responsible = newUser.name;
                        setValue('current_responsible', newUser.name);
                      }}
                      idProtocol={protocol.id}
                      idUserProtocol={
                        protocol?.protocol_responsibles[0]?.responsible_id
                      }
                      isModalOpen={openModalUser}
                      onModalToggle={setOpenModalUser}
                      unmountOnClose={true}
                    />

                    <ModalReason
                      render={(newData) => {
                        if (newData.reason) {
                          protocol.reason = newData.reason;
                          protocol.reason_id = newData.reason.id;
                        }
                        if (newData.subreason) {
                          protocol.subreason = newData.subreason;
                          protocol.subreason_id = newData.subreason.id;
                        }
                        setValue('reason_id', newData?.reason?.name);
                        setValue('subreason_id', newData?.subreason?.name);
                      }}
                      recordTypeId={protocol.record_type_id}
                      idProtocol={protocol.id}
                      isModalOpen={openModalReason}
                      onModalToggle={setOpenModalReason}
                      unmountOnClose={true}
                      isRequired={
                        protocolDefaultFieldsDisplaySettings?.reason_id
                          ?.isRequired || true
                      }
                      reasonIsRequiredSubReason={
                        protocolDefaultFieldsDisplaySettings?.subreason_id
                          ?.isRequired
                      }
                      showSubReason={
                        protocolDefaultFieldsDisplaySettings?.subreason_id
                          ?.isActive
                      }
                    />
                    <ModalSubReason
                      render={(newSubreason) => {
                        protocol.subreason = newSubreason;
                        protocol.subreason_id = newSubreason.id;
                        setValue('subreason_id', newSubreason.name);
                      }}
                      idProtocol={protocol.id}
                      idReasonProtocol={protocol?.reason_id}
                      isModalOpen={openModalSubReason}
                      onModalToggle={setOpenModalSubReason}
                      unmountOnClose={true}
                      isRequired={
                        protocolDefaultFieldsDisplaySettings?.subreason_id
                          ?.isRequired || true
                      }
                    />

                    <ModalClient
                      render={(newClient) => {
                        const copy = { ...protocol };
                        copy.client = newClient;
                        setProtocol({ ...copy });
                        setValue('client_id', newClient.nome);
                      }}
                      contact={{ email: protocol.email || '' }}
                      idProtocol={protocol.id}
                      isModalOpen={openModalUpdateClient}
                      onModalToggle={setOpenModalUpdateClient}
                      unmountOnClose={true}
                      isRequired={
                        protocolDefaultFieldsDisplaySettings?.client_id
                          ?.isRequired || true
                      }
                    />
                  </>
                )}
            </>
          )}
        </Container>
      </>
      <TemplatesModal
        isModalOpen={isModalTemplateMessageProtocolOpen}
        onModalToggle={setIsisModalTemplateMessageProtocolOpen}
        clientNumber={whatsAppMessageContactInfoProtocol?.telefone}
        clientName={whatsAppMessageContactInfoProtocol?.nome}
        clientId={whatsAppMessageContactInfoProtocol?.clientId ?? ''}
        isIsolated={true}
      />
    </>
  );
};

export default ProtocolFormUpdate;
