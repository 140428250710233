import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Container, Row } from 'reactstrap';
import { MdPhoneForwarded } from 'react-icons/md';
import Loader from 'components/Loader';
import ClickToCall from '../../../../ServicePanel/components/AttendanceContent/components/Modal/ClickToCall';
import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style
} from '../../../../../assets/styles/table_custom_style';
import { apiChat } from '../../../../../http';
import SimpleHeader from '../../../../../components/Headers/SimpleHeader';
import { formatCurrency } from 'utils/fuctions/formatCurrency';
import { useProtocolDefaultFieldsSettings } from '../../../../fieldConfigs/hooks/useProtocolDefaultFieldsSettings';
import { dateRules } from '../../../assets';
import { functionsUseFul } from '../../assets/functionsUseFul';
import { useHandleProtocolListing } from '../../hooks/useHandleProtocolListing';
import { ClientNameWrapper } from '../ClientNameWrapper';
import FiltersForm from '../FiltersForm';
import { OutlineTooltip } from '../OutlineTooltip';
import ProtocolHistoryModal from '../ProtocolHistoryModal';
import { ProtocolNumberWrapper } from '../ProtocolNumberWrapper';
import Table from '../Table';
import { useSelector } from 'react-redux';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { EditProtocolByNumber } from './styled';
import { maskPhoneNumber } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { maskCpf } from 'views/CRM/shared/utils/cpfCnpjHelpers';
import { maskCnpj } from 'views/CRM/shared/utils/cpfCnpjHelpers';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { Kanban } from 'views/CRM/Opportunity/list/components/OpportunitiesList/components/Kanban';
import { useListStatusByRecordType } from 'views/status/list/hooks/useListStatusByRecordType';
import { InfoCardProtocol } from '../InfoCard';
import ProtocolService from '../../../service';
import { TotalRegister } from 'components/TotalRegister';
import { ListView } from '../ListView';
import { FaLink } from 'react-icons/fa';
import { Graphics } from 'views/Report/Protocol/Synthetic/components/Graphics';
import styled from 'styled-components';
import { useMemorizeFilters } from 'hooks/useMemorizeInputsFilters';

const ProtocolFieldsFilterToggle = styled.div`
  display: inline-block;
  margin-right: 1rem;
  user-select: none;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ProtocolListing = ({
  recordTypes,
  selectedRecordType,
  handleSelectedRecordTypeChange,
  hasChangedRecordType,
  setHasChangedRecordType
}) => {
  const selectedRecordTypeId = selectedRecordType.value;
  const history = useHistory();
  const formRef = useRef(null);
  const { protocolDefaultFieldsDisplaySettings } =
    useProtocolDefaultFieldsSettings(selectedRecordTypeId);
  const { customFields, getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.PROTOCOL,
    selectedRecordTypeId
  );

  const { protocols, protocolsQuantity, isLoadingProtocols, getProtocols } =
    useHandleProtocolListing(selectedRecordTypeId);
  const { status, getStatusByRecordType } =
    useListStatusByRecordType(selectedRecordTypeId);
  const [rowsSelectds, setRowsSelectds] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [dataField, setDataField] = useState();
  const [placement] = useState('bottom');
  const { buildProtocolTitleString } = functionsUseFul();
  const [disableClickToCall, setDisableClickToCall] = useState(true);
  const [orderByFilter, setOrderByFilter] = useState('creation_date');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [showTable, setShowTable] = useState('TABLE');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [typeReportSelected, setTypeReportSelected] = useState('Analítico');
  const [dataGrafics, setDataGrafics] = useState();
  const [, setLoaderGrafics] = useState();
  const [showProtocolFilters, setShowProtocolFilters] = useState(true);
  const { memorizeFilters, getMemorizedFilters } =
    useMemorizeFilters('protocol');

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  useEffect(() => {
    getStatusByRecordType(selectedRecordTypeId);
    const filters = getMemorizedFilters();
    const filterDto = { ...filters };
    memorizeFilters({
      ...filterDto,
      selectedRecordType
    });
    delete filterDto.selectedRecordType;
    getProtocols({
      ...(filterDto || {}),
      page: activePage,
      size: itemsPerPage,
      order_by: orderByFilter,
      order_type: sortOrder
    });
    if (filters?.hasOwnProperty('showProtocolFilters')) {
      setShowProtocolFilters(filters?.showProtocolFilters);
    }
  }, [getStatusByRecordType, getMemorizedFilters, selectedRecordTypeId]);

  const telephonyServicePanel = useSelector(
    (state) => state.telephonyServicePanel
  );

  useEffect(() => {
    const copy = [...rowsSelectds];
    copy.splice(0, rowsSelectds.length);
    setRowsSelectds(copy);
  }, [selectedRecordType]);

  const canSeeAllProtocols = useMemo(() => {
    const flag = localStorage.getItem('show_all_protocols');
    return flag === 'true';
  }, []);

  const acessToken = useMemo(() => {
    const initialToken = localStorage.getItem('token');
    const finalToken = `Bearer ${initialToken}`;
    return finalToken;
  }, []);

  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const allRowIds = protocols.map((r) => {
      return r.id;
    });

    const copy = [...rowsSelectds];
    if (isChecked) {
      allRowIds.forEach((id) => {
        if (!rowsSelectds.includes(id)) {
          copy.push(id);
        }
      });
      setRowsSelectds(copy);
    } else {
      copy.splice(0, rowsSelectds.length);
      setRowsSelectds(copy);
    }
  };
  const handleGetProtocols = useCallback(
    async (params = {}, showToastMessage = true) => {
      setDataField({ ...params });
      await getProtocols(params, showToastMessage);
    },
    [getProtocols]
  );

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    if (telephonyServicePanel.extension === '') {
      setDisableClickToCall(true);
    } else {
      setDisableClickToCall(false);
    }
  }, [telephonyServicePanel]);

  const handleCall = useCallback(
    (row) => {
      let telephoneWithoutSpecialCharacters = row.telephone.replace(/\D/g, '');
      let clientTelephoneWithoutSpecialCharacters =
        row.cliente.telefone.replace(/\D/g, '');
      const data = {
        origem: telephonyServicePanel.extension,
        destino:
          row.telephone && row.telephone !== ''
            ? telephoneWithoutSpecialCharacters
            : clientTelephoneWithoutSpecialCharacters
      };
      if (disableClickToCall) {
        toast.error(
          'É necessário estar logado em um ramal para realizar a chamada!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      } else {
        if (!window.confirm('Tem certeza que deseja realizar a chamada agora?'))
          return;

        apiChat
          .post(`/clicktocall`, data)
          .then(() => {
            toast.success('Realizando chamada!', {
              autoClose: 3000,
              closeOnClick: true
            });
          })
          .catch((err) => {
            toast.error(err.response.data.msg || 'Erro ao realizar chamada!');
          });
      }
    },
    [disableClickToCall, telephonyServicePanel.extension]
  );

  const handleUpdate = useCallback(() => {
    const values = {
      recordTypeId: selectedRecordTypeId,
      returnPath: '/admin/protocol'
    };
    const objetoString = JSON.stringify(values);
    localStorage.setItem('valueRoute', objetoString);
  }, [history, selectedRecordTypeId]);

  const handlePageChange = (activePage) => {
    getProtocols({
      ...(dataField || {}),
      page: activePage,
      size: itemsPerPage,
      order_by: orderByFilter,
      order_type: sortOrder
    });
    setActivePage(activePage);
  };

  function removeMultipleParams(obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([key, value]) => !Array.isArray(value) || value.length <= 1
      )
    );
  }

  const getMediaPath = useCallback(
    (media) => {
      try {
        const [, jwt] = acessToken.split(' ');

        const dataFieldWithoutMultipleParams = removeMultipleParams(dataField);
        const paramsAsString = new URLSearchParams({
          ...(dataFieldWithoutMultipleParams || {}),
          jwt
        });

        for (const key in dataField) {
          if (Array.isArray(dataField[key]) && dataField[key].length > 1) {
            dataField[key].forEach((element) => {
              paramsAsString.append(key, element);
            });
          }
        }

        const queryURL = `${process.env.REACT_APP_CASE_URL}protocols/export/${media}/${selectedRecordTypeId}?${paramsAsString}`;
        return queryURL;
      } catch (err) {
        toast.error('Ops, ocorreu um erro ao realizar o download do arquivo!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [acessToken, dataField, orderByFilter, sortOrder, selectedRecordTypeId]
  );

  const selectProtocol = {
    formatExtraData: rowsSelectds,

    headerFormatter: () => {
      return (
        <input
          defaultValue={false}
          checked={protocols.every((obj) => rowsSelectds.includes(obj.id))}
          type="checkbox"
          onChange={handleHeaderCheckboxChange}
        ></input>
      );
    },
    style: individual_column_style,
    formatter: (_, row, rowIndex, formatExtraData) => {
      return (
        <input
          checked={formatExtraData.includes(row.id)}
          onChange={(value) => {
            if (!formatExtraData.includes(value.target.value)) {
              const copy = [...formatExtraData, value.target.value];
              setRowsSelectds(copy);
            }
            if (
              !value.target.checked &&
              formatExtraData.includes(value.target.value)
            ) {
              const copy = [...rowsSelectds];
              let indexToRemove = copy.indexOf(value.target.value);
              copy.splice(indexToRemove, 1);
              setRowsSelectds(copy);
            }
          }}
          value={row.id}
          type="checkbox"
        />
      );
    }
  };

  const createDynamicColumns = useCallback(() => {
    const columns = [
      {
        dataField: 'number',
        text: 'Número',
        sort: true,
        style: individual_column_style,
        formatter: (_, row) => {
          const showIcon = row?.parent_id || row?.subProtocols?.length;
          const { number, description } = row;
          return (
            <Link
              to={`/admin/protocol/${row.id}/update`}
              onAuxClick={(event) => handleUpdate(row.id)}
            >
              <EditProtocolByNumber
                style={{ color: '#515151' }}
                onClick={() => handleUpdate(row.id)}
                title={`Clique para editar o protocolo ${number}`}
              >
                {showIcon && <FaLink style={{ marginRight: '3px' }}></FaLink>}
                <ProtocolNumberWrapper
                  number={number}
                  description={description}
                  onClick={() => handleUpdate(row.id)}
                  isDescriptionShown={
                    protocolDefaultFieldsDisplaySettings &&
                    protocolDefaultFieldsDisplaySettings.description &&
                    protocolDefaultFieldsDisplaySettings.description.isRequired
                  }
                />
              </EditProtocolByNumber>
            </Link>
          );
        }
      }
    ];

    columns.splice(0, 0, selectProtocol);

    if (
      protocolDefaultFieldsDisplaySettings &&
      Object.keys(protocolDefaultFieldsDisplaySettings).length
    ) {
      if (protocolDefaultFieldsDisplaySettings.title.isActive) {
        columns.push({
          dataField: 'title',
          text: 'Título',
          sort: true,
          style: individual_column_style,
          // eslint-disable-next-line react/display-name
          formatter: (_, row) => {
            return (
              <>
                <div>
                  <span style={{}} id={`title-${row.id}`}>
                    {buildProtocolTitleString(row.title)}
                  </span>

                  <OutlineTooltip
                    placement={placement}
                    target={`title-${row.id}`}
                    message={row.title}
                  />
                </div>
              </>
            );
          }
        });
      }

      if (
        protocolDefaultFieldsDisplaySettings &&
        Object.keys(protocolDefaultFieldsDisplaySettings).length
      ) {
        if (protocolDefaultFieldsDisplaySettings.current_status.isActive) {
          columns.push({
            dataField: 'status',
            text: 'Status/Fase',
            sort: true,
            style: individual_column_style,
            formatter: (_, row) => {
              const [protocol_status] = row.protocol_statuses;

              return protocol_status?.status ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <>
                    <div
                      style={{
                        marginRight: '5px',
                        width: '12px',
                        height: '12px',
                        border: '1px solid #CDC9C9',
                        backgroundColor: `${protocol_status.status.color}`
                      }}
                    ></div>
                    <div>{`${protocol_status.status.name}`}</div>
                  </>
                </div>
              ) : (
                <></>
              );
            }
          });
        }
      }

      if (
        protocolDefaultFieldsDisplaySettings &&
        protocolDefaultFieldsDisplaySettings.client_id.isActive
      ) {
        columns.push({
          dataField: 'cliente.nome',
          text: 'Contato',
          sort: true,
          style: individual_column_style,
          formatter: (_, row) => {
            const { cliente } = row;
            return (
              <ClientNameWrapper
                name={cliente?.nome}
                telephone={cliente?.telefone}
                email={cliente?.email}
              />
            );
          }
        });
      }

      if (
        protocolDefaultFieldsDisplaySettings &&
        protocolDefaultFieldsDisplaySettings.account_id.isActive
      ) {
        columns.push({
          dataField: 'account.name',
          text: 'Conta',
          sort: true,
          style: individual_column_style,
          formatter: (_, row) => {
            const { account } = row;
            return account?.name || '';
          }
        });
      }

      if (
        protocolDefaultFieldsDisplaySettings &&
        protocolDefaultFieldsDisplaySettings.reason_id.isActive
      ) {
        columns.push({
          dataField: 'reason.name',
          text: 'Motivo',
          sort: true,
          style: individual_column_style
        });
      }

      if (
        protocolDefaultFieldsDisplaySettings &&
        protocolDefaultFieldsDisplaySettings.subreason_id.isActive
      ) {
        columns.push({
          dataField: 'subreason.name',
          text: 'Submotivo',
          sort: true,
          style: individual_column_style
        });
      }

      columns.push({
        dataField: 'creation_date',
        text: 'Criação',
        sort: true,
        style: individual_column_style,
        formatter: (_, row) => {
          if (!row.creation_date) {
            return '';
          }
          const date = new Date(row.creation_date);
          return `${date.toLocaleDateString(
            'pt-BR'
          )} às ${date.toLocaleTimeString('pt-BR', {
            timeZone: 'America/Fortaleza'
          })}`;
        }
      });

      columns.push({
        dataField: 'protocol_responsibles',
        text: 'Responsável',
        sort: true,
        style: individual_column_style,
        formatter: (_, row) => {
          return row?.protocol_responsibles[0]?.usr?.name || '';
        }
      });

      columns.push({
        dataField: 'last_change_date',
        text: 'Última Alteração',
        sort: true,
        style: individual_column_style,
        formatter: (_, row) => {
          const date = new Date(row.last_change_date);
          return `${date.toLocaleDateString(
            'pt-BR'
          )} às ${date.toLocaleTimeString('pt-BR', {
            timeZone: 'America/Fortaleza'
          })}`;
        }
      });
    }

    (customFields || []).forEach((field) => {
      columns.push({
        dataField: field.name,
        text: field.label,
        sort: true,
        style: individual_column_style,
        formatter: (_, row) => {
          if (field.type === 'DATE' && !!row[field.name]) {
            return new Date(row[field.name]).toLocaleDateString('pt-br');
          }

          if (field.type === 'CURRENCY' && !!row[field.name]) {
            return formatCurrency(row[field.name]);
          }

          if (field.type === 'TELEPHONE' && !!row[field.name]) {
            return maskPhoneNumber(row[field.name]);
          }

          if (field.type === 'CPF' && !!row[field.name]) {
            return maskCpf(row[field.name]);
          }

          if (field.type === 'CNPJ' && !!row[field.name]) {
            return maskCnpj(row[field.name]);
          }

          if (field.type === 'MULTIPLE' && !!row[field.name]) {
            return row[field.name].replace(/;/g, '; ');
          }

          return row[`${field.name}`];
        }
      });
    });

    return columns;
  }, [
    customFields,
    protocolDefaultFieldsDisplaySettings,
    buildProtocolTitleString,
    placement,
    handleUpdate
  ]);

  const formatColumn = useCallback(() => {
    const dinamicColumns = createDynamicColumns();

    const callColumn = {
      dataField: 'call',
      text: 'Chamar',
      style: icon_column_style,
      formatter: (_, row) => {
        return (
          <ClickToCall
            title={
              row?.cliente?.telefone !== '' || row.telephone !== ''
                ? `Chamar cliente ${row?.cliente?.nome}\nÉ necessário estar logado em um ramal para realizar a chamada!`
                : `O cliente ${row?.cliente?.nome} não possui telefone cadastrado`
            }
            callBack={() => handleCall(row)}
            disableClickToCall={
              (row?.cliente?.telefone !== '' || row?.telephone !== '') &&
              !disableClickToCall
                ? false
                : true
            }
            icon={<MdPhoneForwarded size={17} color="#fff" />}
            color="success"
            isDefaultButtonStyle={true}
          />
        );
      }
    };

    const editeColumn = {
      dataField: 'edit',
      text: 'Editar',
      style: icon_column_style,
      headerStyle: individual_icon_header_style,
      formatter: (_, row) => {
        return (
          <Link to={`/admin/protocol/${row.id}/update`}>
            <Button
              title="Editar protocolo"
              color="info"
              size="sm"
              onClick={() => handleUpdate(row.id)}
            >
              <li className="fas fa-user-edit"></li>
            </Button>
          </Link>
        );
      }
    };

    const historyColumn = {
      dataField: 'history',
      text: 'Histórico',
      style: icon_column_style,
      headerStyle: individual_icon_header_style,
      formatter: (_, row) => {
        return <ProtocolHistoryModal protocolId={row.id} />;
      }
    };

    return [...dinamicColumns, editeColumn, historyColumn, callColumn];
  }, [createDynamicColumns, handleUpdate, handleCall, disableClickToCall]);

  const handleSortProtocolTable = useCallback(
    async (ordenationParam, sortOrder) => {
      setSortOrder(sortOrder);
      setOrderByFilter(ordenationParam);
      await getProtocols({
        ...dataField,
        page: activePage,
        size: 10,
        order_by: ordenationParam,
        order_type: sortOrder
      });
    },
    [activePage, dataField, getProtocols]
  );
  const onTableChange = (protocolId, status_id) => {
    return ProtocolService.protocolUpdateData(
      protocolId,
      { status_id },
      'change_status'
    )
      .then(() => true)
      .catch(() => {
        toast.error('Ocorreu um erro ao atualizar a fase', {
          autoClose: 3000,
          draggable: false
        });
        return false;
      });
  };

  const isToShowProtocolFiltersForm = (showProtocolFilters) => {
    const filters = getMemorizedFilters();
    const isToShow = !showProtocolFilters;
    filters.showProtocolFilters = isToShow;
    setShowProtocolFilters(!showProtocolFilters);
    memorizeFilters(filters);
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mb-0">Protocolos</h3>
                    <div>
                      <ProtocolFieldsFilterToggle
                        className="small"
                        onClick={() =>
                          isToShowProtocolFiltersForm(showProtocolFilters)
                        }
                      >
                        {showProtocolFilters ? (
                          <>
                            <FiChevronUp size={18} /> Minimizar filtros{' '}
                          </>
                        ) : (
                          <>
                            <FiChevronDown size={18} /> Maximizar filtros{' '}
                          </>
                        )}
                      </ProtocolFieldsFilterToggle>

                      <span
                        onClick={() => formRef.current.cleanFilters()}
                        className="small"
                        style={{ cursor: 'pointer' }}
                      >
                        <AiOutlineClear /> Limpar filtros
                      </span>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-2">
                  {showProtocolFilters && (
                    <FiltersForm
                      ids={rowsSelectds}
                      defaultFields={protocolDefaultFieldsDisplaySettings}
                      getProtocols={handleGetProtocols}
                      canSeeAllProtocols={canSeeAllProtocols}
                      dateRules={dateRules}
                      getMediaPath={getMediaPath}
                      quantity={parseInt(protocolsQuantity) || 0}
                      ref={formRef}
                      isDisableClickToCall={disableClickToCall}
                      recordTypes={recordTypes}
                      selectedRecordType={selectedRecordType}
                      handleSelectedRecordTypeChange={
                        handleSelectedRecordTypeChange
                      }
                      setShowTable={setShowTable}
                      showTable={showTable}
                      setActivePage={setActivePage}
                      activePage={activePage}
                      sortOrder={sortOrder}
                      orderByFilter={orderByFilter}
                      itemsPerPage={itemsPerPage}
                      setItemsPerPage={setItemsPerPage}
                      setTypeReportSelected={setTypeReportSelected}
                      typeReportSelected={typeReportSelected}
                      setDataGrafics={setDataGrafics}
                      setLoaderGrafics={setLoaderGrafics}
                      hasChangedRecordType={hasChangedRecordType}
                      setHasChangedRecordType={setHasChangedRecordType}
                      callBackShowAdvancedFilters={(value) => {
                        setShowAdvancedFilters(value);
                      }}
                    />
                  )}
                </CardBody>

                {isLoadingProtocols ? (
                  <div className="col-12">
                    <Row className="justify-content-md-center">
                      <Loader />
                    </Row>
                  </div>
                ) : (
                  <>
                    {typeReportSelected === 'Analítico' && (
                      <>
                        {showTable === 'TABLE' && (
                          <Table
                            protocols={protocols || []}
                            formatColumn={formatColumn}
                            loading={isLoadingProtocols}
                            quantity={parseInt(protocolsQuantity) || 0}
                            activePage={activePage}
                            handlePageChange={handlePageChange}
                            handleSortProtocolTable={handleSortProtocolTable}
                            sortOrder={sortOrder}
                            setSortOrder={setSortOrder}
                            itemsPerPage={itemsPerPage}
                            quantityCustomFields={customFields?.length}
                            showAdvancedFilters={showAdvancedFilters}
                          />
                        )}
                        {showTable === 'KANBAN' && (
                          <>
                            {protocolsQuantity && protocolsQuantity >= 0 ? (
                              <div className="mb-2">
                                <TotalRegister
                                  totalRegister={protocolsQuantity}
                                />
                              </div>
                            ) : null}
                            <Kanban
                              data={protocols ?? []}
                              onTableChange={onTableChange}
                              opportunityStages={status}
                              InfoCard={InfoCardProtocol}
                              isProtocol={true}
                              handleCall={handleCall}
                              disableClickToCall={disableClickToCall}
                            ></Kanban>
                          </>
                        )}
                        {showTable === 'SPLIT' && (
                          <ListView
                            customFields={customFields}
                            protocolList={protocols}
                            handleUpdate={handleUpdate}
                            handleCall={handleCall}
                            disableClickToCall={disableClickToCall}
                            protocolDefaultFieldsDisplaySettings={
                              protocolDefaultFieldsDisplaySettings
                            }
                            status={status}
                          ></ListView>
                        )}
                      </>
                    )}
                    {dataGrafics && typeReportSelected === 'Sintético' && (
                      <Graphics protocolsData={dataGrafics} />
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ProtocolListing;
