import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';

import {
  BsFillPlayFill,
  BsPauseFill,
  BsVolumeUpFill,
  BsFillVolumeMuteFill
} from 'react-icons/bs';

// Estilos
import { Wrapper, Content } from './style.js';
import { useSelector } from 'react-redux';
import FileSaver from 'file-saver';

function AudioPlayer({
  srcAudio,
  onLoad,
  nameAgent,
  calldateStart,
  calldateStartAnswers,
  uniqueid,
  ReportService,
  executeDisabled,
  Origem,
  Queue,
  MonitoringForm
}) {
  //Estado
  const [isPlaying, setIsPlaying] = useState(true);
  const [audioIsMuted, setAudioIsMuted] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  //Refer
  const audioPlayerRef = useRef(); //Referencia ao componente de audio
  const progressBarRef = useRef(); //Referencia ao slider
  const progressVolumeRef = useRef(); //Referencia ao slider
  const backgroundOptionsActive1 = useRef(); //Referencia as opções de velocidade
  const backgroundOptionsActive15x = useRef(); //Referencia as opções de velocidade
  const backgroundOptionsActive2 = useRef(); //Referencia as opções de velocidade
  const animationRef = useRef(); //Referencia a animação

  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const downloadAudio = (uniqueid, nameAgent, date) => {
    const removeHours = calldateStart.split(' ');
    date = removeHours[0];
    ReportService.downloadAudio(uniqueid)
      .then((res) => {
        res.data.size <= 11
          ? toast.info('Nenhum registro foi encontrado!')
          : FileSaver.saveAs(res.data, `${date}-${nameAgent}.gsm`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadAudioRelatorio = (uniqueid, nameAgent, date) => {
    date = `${calldateStartAnswers.slice(8, 10)}/${calldateStartAnswers.slice(
      5,
      7
    )}/${calldateStartAnswers.slice(0, 4)}`;
    ReportService.downloadAudio(uniqueid)
      .then((res) => {
        res.data.size <= 11
          ? toast.info('Nenhum registro foi encontrado!')
          : FileSaver.saveAs(res.data, `${date}-${nameAgent}.gsm`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const timeTravel = (newTime) => {
    progressBarRef.current.value = newTime;
    changeRange();
  };

  useEffect(() => {
    audioPlayerRef.current.playbackRate = playbackRate;
    if (audioCurrentTime == audioDuration) {
      togglePlayPause();
      timeTravel(0);
    }
  }, [audioCurrentTime, playbackRate]);

  const onLoadedMetadata = () => {
    if (onLoad) {
      onLoad(true);
    }
    const seconds = Math.floor(audioPlayerRef.current.duration);
    setAudioDuration(seconds);
    progressBarRef.current.max = seconds;
  };
  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const changePlayerCurrentTime = () => {
    progressBarRef.current.style.setProperty(
      '--seek-before-width',
      `${(progressBarRef.current.value / audioDuration) * 100}%`
    );
    setAudioCurrentTime(progressBarRef.current.value);
  };

  const whilePlaying = () => {
    if (audioPlayerRef.current !== null) {
      progressBarRef.current.value = audioPlayerRef.current.currentTime;
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  };

  const changeRange = () => {
    audioPlayerRef.current.currentTime = progressBarRef.current.value;
    changePlayerCurrentTime();
  };

  let audio = document.getElementById('my-audio');

  const changeRangeVolume = () => {
    progressVolumeRef.current.volume = progressVolumeRef.current.value;
    audio.volume = progressVolumeRef.current.volume;
    if (progressVolumeRef.current.value == 0) {
      setAudioIsMuted(true);
    } else {
      setAudioIsMuted(false);
    }
  };
  const setPlayBack = (value) => {
    setPlaybackRate(value);
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayerRef.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayerRef.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const toggleMuteUnmute = () => {
    const prevValue = audioIsMuted;
    setAudioIsMuted(!prevValue);
    if (!audioIsMuted) {
      audio.muted = true;
      progressVolumeRef.current.value = 0;
      progressVolumeRef.current.volume = progressVolumeRef.current.value;
      audio.volume = progressVolumeRef.current.value;
      progressVolumeRef.current.style.setProperty('--disable-volume', `none`);
    } else {
      audio.muted = false;
      progressVolumeRef.current.value = 0.5;
      audio.volume = progressVolumeRef.current.value;
      progressVolumeRef.current.style.setProperty(
        '--disable-volume',
        `initial`
      );
    }
  };

  return (
    <Wrapper>
      <Content primary={systemConfiguration.primary_color}>
        <audio
          id="my-audio"
          onLoadedMetadata={onLoadedMetadata}
          ref={audioPlayerRef}
          src={srcAudio}
          preload="metadata"
        ></audio>
        {!executeDisabled === true ? (
          <div>
            <Button onClick={() => toggleModal()} color="success" size="sm">
              <i className="fas fa-info-circle"></i>
            </Button>
            <Modal
              className="modal-dialog-centered"
              style={{ width: 1000 }}
              isOpen={isOpen}
            >
              <ModalHeader toggle={toggleModal}>
                Informações da chamada
              </ModalHeader>
              <ModalBody>
                <p>
                  <strong>Origem: </strong>
                  {Origem}
                </p>
                <p>
                  <strong>Fila: </strong>
                  {Queue}
                </p>
                <p>
                  <strong>Data Início: </strong>
                  {calldateStart
                    ? `${calldateStart.slice(0, 2)}/${calldateStart.slice(
                        3,
                        5
                      )}/${calldateStart.slice(6, 8)} ${calldateStart.slice(
                        9,
                        17
                      )}`
                    : ''}
                  {calldateStartAnswers
                    ? `${calldateStartAnswers.slice(
                        8,
                        10
                      )}/${calldateStartAnswers.slice(
                        5,
                        7
                      )}/${calldateStartAnswers.slice(
                        0,
                        4
                      )} ${calldateStartAnswers.slice(11, 19)}`
                    : ''}
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={toggleModal}>
                  Fechar
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        ) : (
          []
        )}

        <div className="speed-audio-change">
          <div className="speed-audio-icon">
            <i className="fas fa-fast-forward"></i>
          </div>
          <div className="speed-audio-options">
            <button
              ref={backgroundOptionsActive1}
              onClick={() => {
                setPlayBack(1);
              }}
            >
              1x
            </button>
            <button
              ref={backgroundOptionsActive15x}
              onClick={() => {
                setPlayBack(1.5);
              }}
            >
              1.5x
            </button>
            <button
              ref={backgroundOptionsActive2}
              onClick={() => {
                setPlayBack(2);
              }}
            >
              2x
            </button>
          </div>
        </div>
        <div onClick={togglePlayPause} className="button-play">
          {!isPlaying ? (
            <BsFillPlayFill
              style={{
                fontSize: '1.5rem',
                // margin: '0.2rem 0.3rem 0 0.2rem',
                cursor: 'pointer'
              }}
            ></BsFillPlayFill>
          ) : (
            <BsPauseFill
              style={{
                fontSize: '1.5rem',
                // margin: '0.2rem 0.3rem 0 0.2rem',
                cursor: 'pointer'
              }}
            ></BsPauseFill>
          )}
        </div>

        {/* current time */}
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
          {calculateTime(audioCurrentTime)}
        </div>

        {/* progress bar */}
        <div className="audio-bar">
          <input
            className="progressBar"
            onChange={changeRange}
            ref={progressBarRef}
            step="0.05"
            type="range"
            defaultValue="0"
          />
        </div>

        {/* duration */}
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
          {calculateTime(audioDuration)}
        </div>

        {/* audio bar */}
        <div
          onClick={toggleMuteUnmute}
          className="button-volume"
          style={{ marginLeft: '10px', marginRight: '10px' }}
        >
          {audioIsMuted ? (
            <BsFillVolumeMuteFill
              style={{
                fontSize: '1.5rem',
                // margin: '0.2rem 0.3rem 0 0.2rem',
                cursor: 'pointer'
              }}
            ></BsFillVolumeMuteFill>
          ) : (
            <BsVolumeUpFill
              style={{
                fontSize: '1.5rem',
                // margin: '0.2rem 0.3rem 0 0.2rem',
                cursor: 'pointer'
              }}
            ></BsVolumeUpFill>
          )}
        </div>

        <div className="volume-bar">
          <input
            className="volumeBar"
            max="1"
            onChange={changeRangeVolume}
            ref={progressVolumeRef}
            style={{ width: '90%' }}
            step="0.05"
            type="range"
            defaultValue="0.5"
          />
        </div>
        <div>
          {MonitoringForm === true ? (
            <Button
              datatoggle="tooltip"
              dataplacement="top"
              datacontainer="body"
              dataanimation="true"
              color="success"
              size="sm"
              onClick={() => downloadAudio(uniqueid, nameAgent, calldateStart)}
            >
              <i className="fas fa-cloud-download-alt"></i>
            </Button>
          ) : !executeDisabled === true ? (
            <Button
              datatoggle="tooltip"
              dataplacement="top"
              datacontainer="body"
              dataanimation="true"
              color="success"
              size="sm"
              onClick={() => downloadAudioRelatorio(uniqueid, nameAgent)}
            >
              <i className="fas fa-cloud-download-alt"></i>
            </Button>
          ) : (
            []
          )}
        </div>
      </Content>
    </Wrapper>
  );
}

export default AudioPlayer;
