import React from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { ColorArea } from '../../assets/styleds';
import { chartExample6 } from 'variables/charts.js';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import randomColor from 'randomcolor';
import { useSelector } from 'react-redux';

export const SyntheticOpportunityTime = ({ data }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const groupByAverageTime = data.opportunityCountByStageAverageTime;
  const groupByStageSuccess = data.opportunityCountByStageSuccessPercentage;

  const groupByStatusLabels = groupByStageSuccess?.map((item) => {
    return item.name;
  });

  const groupByStatusData = groupByStageSuccess?.map((item) => {
    return item.percentage_moved;
  });

  const groupByStatusQuantityData = groupByStageSuccess?.map((item) => {
    return item.quantity;
  });

  const getGraphicColors = groupByStageSuccess?.map(() => {
    return randomColor({ luminosity: 'random', hue: 'random' });
  });

  const getGraphicResponsibleLabels = () => {
    if (!groupByAverageTime) return [];
    return groupByAverageTime?.map(
      (item) => `${item.name} (${item.totalTime})`
    );
  };

  const getGraphicResponsibleData = () => {
    if (!groupByAverageTime) return [];
    return groupByAverageTime?.map((item) => item.quantity);
  };

  return (
    <>
      {groupByAverageTime && groupByAverageTime.length > 0 ? (
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão Geral</h6>
            <h5 className="h3 mb-0">Média de tempo em dias</h5>
          </CardHeader>
          <CardBody>
            <Bar
              data={{
                labels: getGraphicResponsibleLabels(),
                datasets: [
                  {
                    data: getGraphicResponsibleData(),
                    backgroundColor: systemConfiguration?.primary_color,
                    maxBarThickness: 10
                  }
                ]
              }}
              options={{
                showLines: true,
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        color: '#e9ecef',
                        zeroLineColor: '#e9ecef'
                      },
                      ticks: {
                        callback: function (value) {
                          if (!(value % 10)) {
                            return value;
                          }
                        }
                      }
                    }
                  ],
                  xAxes: [
                    {
                      maxBarThickness: 100
                    }
                  ]
                },
                tooltips: {
                  callbacks: {
                    label: function (item, data) {
                      var label = data.datasets[item.datasetIndex].label || '';
                      var yLabel = item.yLabel;
                      var content = '';
                      if (data.datasets.length > 1) {
                        content += label;
                      }
                      content += yLabel;
                      return content;
                    }
                  }
                },
                legend: {
                  display: false
                }
              }}
              className="chart-canvas"
              id="chart-bars"
            />
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão Geral</h6>
            <h5 className="h3 mb-0">Média de tempo em dias</h5>
          </CardHeader>
          <CardBody>
            <h2>Não há dados disponíveis</h2>
          </CardBody>
        </Card>
      )}

      {groupByStageSuccess && groupByStageSuccess.length > 0 ? (
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão Geral</h6>
            <h5 className="h3 mb-0">Porcentagem de sucesso</h5>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4" style={{ alignSelf: 'center' }}>
                <div className="chart">
                  <Pie
                    data={{
                      labels: [...groupByStatusLabels],
                      datasets: [
                        {
                          data: [...groupByStatusQuantityData],
                          backgroundColor: [...getGraphicColors]
                        }
                      ]
                    }}
                    options={chartExample6.options}
                    className="chart-canvas"
                    id="chart-doughnut"
                  />
                </div>
              </Col>

              <Col md="8">
                <Row className="mr-0">
                  {groupByStatusData.map((data, index) => {
                    return (
                      <Col
                        md="2"
                        key={index}
                        className="mt--3 mb--3"
                        style={{
                          minWidth: '177px',
                          minHeight: '130px'
                        }}
                      >
                        <Row
                          className="row flex-nowrap"
                          style={{
                            minHeight: '100px'
                          }}
                        >
                          <Col md="1">
                            <ColorArea color={getGraphicColors[index]} />
                          </Col>
                          <Col md="11">
                            <h2>
                              {groupByStatusQuantityData[index]} Oportunidade(s)
                            </h2>
                            <h3 className="text-muted font-weight-light mt--3">
                              {groupByStatusLabels[index] === ''
                                ? '-'
                                : groupByStatusLabels[index]}
                            </h3>
                            <h2
                              className="mt--1 font-weight-light"
                              style={{
                                paddingBottom: '1.5625rem'
                              }}
                            >
                              {`${groupByStatusData[index]}%`}
                            </h2>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão Geral</h6>
            <h5 className="h3 mb-0">Porcentagem de sucesso</h5>
          </CardHeader>
          <CardBody>
            <h2>Não há dados disponíveis</h2>
          </CardBody>
        </Card>
      )}
    </>
  );
};
