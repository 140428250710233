import React from 'react';
import parse from 'html-react-parser';
import { generateFormattedDate } from './assets/generateFormattedDate';
import { toast } from 'react-toastify';
//import isURL from 'validator/lib/isURL';
import ModalImage from 'react-modal-image';
import Modal from '../../../components/Modal/DefaultModal';

import { FaFileAlt, FaUserCircle } from 'react-icons/fa';
import {
  ClientTimestamp,
  ClientContent,
  ClientName,
  MessageContent,
  MessageText,
  Image,
  ImageContent,
  ContextImageContent,
  ContextMessageContent,
  Bar,
  ContextImage,
  ContentContact,
  InfoContact,
  ContentModalInfoContato
} from './components/styled';
import './style.css';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const ClientMessage = ({
  message = '',
  title = '',
  time = '',
  name = '',
  contact = '',
  type = 'text',
  source = '',
  description = '',
  context
}) => {
  // const urlRegex =
  //   /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  function findUrl(message) {
    if (!message) return '';
    const regexError = /<([^<>]+@[^<>]+)>/;
    const match = regexError.test(message);
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    const matchWebsite = urlRegex.test(message);

    if (match) message = message.replace('<', '').replace('>', '');
    if (matchWebsite) {
      const html = message.replace(urlRegex, function (url, initialWWW) {
        var urlWithHTTP = initialWWW == 'www.' ? 'http://' + url : url;
        return `<a href="${urlWithHTTP}" target="_blank">${url}</a>`;
      });
      return parse(html || '');
    }

    return message;
  }

  const copyToClipBoard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      toast.info(`Mensagem copiada!`, { autoClose: 1500 });
    } catch (err) {
      console.log('error copy => ', err);
      toast.error('Erro ao copiar mensagem!', { autoClose: 1500 });
    }
  };

  return (
    <ClientContent>
      {context ? (
        <ContextMessageContent>
          <Bar></Bar>
          <div>
            <ClientName>{context.escrita_por}:</ClientName>
            {title ||
              (context.texto &&
                (type === 'link' ? (
                  <u onClick={() => window.open(context.texto, '_blank')}>
                    {context.texto}
                  </u>
                ) : (
                  context.texto
                )))}
            {context.response_type === 'file' ? (
              <div
                className="messagePdf"
                onClick={() => window.open(context.source, '_blank')}
              >
                <FaFileAlt size="24" color="#6A5ACD" />
                <label style={{ cursor: 'pointer' }}>clique aqui</label>
              </div>
            ) : null}
            {context.response_type === 'image' && (
              <ContextImageContent style={{ textAling: 'start' }}>
                <ContextImage
                  style={{ maxWidht: '10%', maxHeight: '10%' }}
                  src={context.source}
                  alt={`imagem de ${name}`}
                  onClick={() => window.open(context.source, '_blank')}
                />
              </ContextImageContent>
            )}
            {context.response_type === 'audio' && (
              <ContextImageContent>
                <audio src={context.source} controls={true} autoPlay={false} />
              </ContextImageContent>
            )}
            {context.response_type === 'video' && (
              <ContextImageContent>
                <video
                  src={context.source}
                  autoPlay={false}
                  controls={true}
                  width="10%"
                  height="10%"
                />
              </ContextImageContent>
            )}
            {context.response_type === 'contact' && <div>é contato</div>}
          </div>
        </ContextMessageContent>
      ) : null}
      <ClientName>{name}:</ClientName>
      <MessageContent>
        <MessageText
        // onClick={() => {
        //   if (urlRegex.test(message)) return;
        //   copyToClipBoard(title || message);
        // }}
        >
          {title || (message && findUrl(message))}
          {type === 'file' && (
            <div
              className="messagePdf"
              onClick={() => window.open(source, '_blank')}
            >
              <FaFileAlt size="24" color="#6A5ACD" />
              <label style={{ cursor: 'pointer' }}>{description}</label>
            </div>
            // {/* <embed className="fileBalon" src={source+'#page=1&zoom=20&toolbar=0'}></embed> */}
          )}
        </MessageText>
        {type === 'image' && (
          <div>
            <ImageContent>
              <ModalImage small={source} medium={source} large={source} />
            </ImageContent>
            <MessageText>{description}</MessageText>
          </div>
        )}
        {type === 'sticker' && (
          <div>
            <ImageContent>
              <ModalImage small={source} medium={source} large={source} />
            </ImageContent>
            <MessageText>{description}</MessageText>
          </div>
        )}
        {type === 'audio' && (
          <ImageContent>
            <audio src={source} controls={true} autoPlay={false} />
          </ImageContent>
        )}
        {type === 'video' && (
          <div>
            <ImageContent>
              <video
                src={source}
                autoPlay={false}
                controls={true}
                width="100%"
                height="300"
              />
            </ImageContent>
            <MessageText>{description}</MessageText>
          </div>
        )}
        {type === 'contact' && (
          <ContentContact color={systemConfiguration.primary_color}>
            <InfoContact>
              <FaUserCircle size={40} color="#eCeCeC" />
              {contact.length > 1 ? (
                <div>
                  <span>
                    {contact
                      ? `${contact[0].name.formatted_name} e mais ${
                          contact.length - 1
                        } contato(s)`
                      : 'Não foi possivel exibir contato'}
                  </span>
                </div>
              ) : (
                <div>
                  <span>
                    {contact
                      ? `${contact[0].name.formatted_name}`
                      : 'Não foi possivel exibir contato'}
                  </span>
                </div>
              )}
            </InfoContact>
            <button onClick={() => toggleModal()}>VER CONTATO</button>
            <Modal
              show={isModalOpen}
              onModalToggle={(state) => setIsModalOpen(state)}
              title="Informações de contato(s)"
              showCloseButton={false}
            >
              <ContentModalInfoContato>
                {contact.map((contato, index) => (
                  <div key={index} className="info">
                    <FaUserCircle size={85} color="#cdcdcd" />
                    <span className="contact-name">
                      {contato.name.formatted_name}
                    </span>
                    {contato.phones.map((tel, index) => (
                      <span
                        key={index}
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => copyToClipBoard(tel.phone)}
                      >
                        Celular: {tel.phone}
                      </span>
                    ))}
                    {contato.emails.map((email, index) => (
                      <span key={index}>E-mail: {email.email}</span>
                    ))}
                    {contato.addresses.map((address, index) => (
                      <span key={index}>
                        <div>
                          Endereço: {address.street}. {address.city} -{' '}
                          {address.state}.
                        </div>{' '}
                        <span>
                          {address.country} - CEP: {address.zip}
                        </span>
                      </span>
                    ))}
                    {contato.urls.map((url, index) => (
                      <span key={index}>Site: {url.url}</span>
                    ))}
                  </div>
                ))}
              </ContentModalInfoContato>
            </Modal>
          </ContentContact>
        )}
      </MessageContent>
      <ClientTimestamp>{time && generateFormattedDate(time)}</ClientTimestamp>
    </ClientContent>
  );
};

export default ClientMessage;
