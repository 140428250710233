import React, { useState, useEffect } from 'react';

const Counter = ({ initialSeconds, isPaused }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let intervalId;

    if (!isPaused && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isPaused, seconds]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div>
      <p>
        Tempo: {minutes} minutos e {remainingSeconds} segundos
      </p>
    </div>
  );
};

export default Counter;
