import React, { useEffect, useState } from 'react';
import { DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { FaHeadset } from 'react-icons/fa';
import SelectRamalSoftphone from './selectRamal';
import DialpadWebRTC from './dialpad';
import userAgentSingleton from './userAgent/userAgentSingleton';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import { apiUc } from '../../../../http';

const ModalSoftphone = ({
  updateCallState,
  callState,
  callStateStatus,
  callStateColor
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [extensions, setExtensions] = useState(undefined);

  const [receivedInvitation, setReceivedInvitation] = useState(null);

  useEffect(() => {
    if (extensions == undefined) {
      apiUc
        .get('/api/ramais/all', {
          headers: { login: localStorage.getItem('login') }
        })
        .then(({ data }) => {
          const allowedExtension = [];

          const agentExtensions = JSON.parse(
            localStorage.getItem('extensions')
          ).map((ramal) => ramal.ramal);
          data.data.forEach((extension) => {
            if (
              extension.transport === 'WSS' &&
              agentExtensions.includes(extension.extension_number)
            ) {
              const secret = CryptoJS.AES.decrypt(
                extension.secret,
                process.env.REACT_APP_EXTENSION_SECRET_KEY
              );

              const decryptedSecret = secret.toString(CryptoJS.enc.Utf8);

              allowedExtension.push({
                extension_number: extension.extension_number,
                identifier: extension.identifier,
                transport: extension.transport,
                secret: decryptedSecret
              });
            }
          });
          setExtensions([...allowedExtension]);
        })
        .catch(() => {
          toast.error('Não foi possível carregar os ramais', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  });

  const toggleModal = () => setOpen(!open);

  const updateRamal = async (ramal) => {
    setIsLoading(true);
    if (ramal == undefined) {
      await userAgentSingleton.disconnect();
    } else {
      await userAgentSingleton.connect(
        ramal.number,
        ramal.secret,
        setReceivedInvitation,
        updateCallState,
        setOpen,
        onDisconnect
      );
    }
    setIsLoading(false);
  };

  const onDisconnect = async () => {
    await updateRamal(undefined);
  };

  let componentToRender = (
    <SelectRamalSoftphone
      onSelect={updateRamal}
      isLoading={isLoading}
      extensions={extensions}
    ></SelectRamalSoftphone>
  );
  if (userAgentSingleton.ramal && !isLoading) {
    componentToRender = (
      <DialpadWebRTC
        ramal={userAgentSingleton.ramal}
        onDisconnect={onDisconnect}
        callState={callState}
        callStateColor={callStateColor}
        callStateStatus={callStateStatus}
        receivedInvitation={receivedInvitation}
        setOpen={setOpen}
      ></DialpadWebRTC>
    );
  }

  return (extensions ?? []).length == 0 ? null : (
    <Dropdown nav isOpen={open} toggle={toggleModal}>
      <DropdownToggle
        className="nav-link"
        tag="a"
        style={{ cursor: 'pointer' }}
      >
        <div
          style={
            userAgentSingleton.ramal != undefined
              ? {
                  border: '1px solid white',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '6px 10px 6px 10px'
                }
              : undefined
          }
        >
          <FaHeadset
            strokeWidth={2}
            style={{
              cursor: 'pointer',
              color: 'white',
              borderRadius: '5%',
              fontWeight: 'bold',
              fontSize: '1.4rem',
              fill: '#FFF'
            }}
          />
          {userAgentSingleton.ramal != undefined ? (
            <div
              className="statusRow"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 10px 0 10px'
              }}
            >
              <div
                style={{
                  background: callStateColor,
                  border: '1px solid white',
                  width: '12px',
                  height: '12px',
                  borderRadius: '17px',
                  marginRight: '4px'
                }}
              ></div>
              <p style={{ color: 'white', margin: 0 }}>{`${
                callStateStatus ?? 'Desconectado'
              }`}</p>
            </div>
          ) : null}
        </div>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-xl py-0 overflow-y-visible" right>
        {componentToRender}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ModalSoftphone;
