import DefaultModal from 'components/Modal/DefaultModal';
import React, { useEffect, useState } from 'react';
import { BsFillImageFill, BsCameraVideoFill } from 'react-icons/bs';
import { VideoPreview } from '../../VideoPreview';
import { PreviewPdf } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/PreviewPdf';
import { Row, Input, Col } from 'reactstrap';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReactButton from 'components/Buttons/ReactButton';
import { PreviewImage } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/PreviewImage';
import { Preview } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/styled';
import {
  imageExtensions,
  videoExtensions
} from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/assets';
import TempleteModalSelected from '../../templeteModalSelected';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import { validationFiles } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/assets/validationFiles';
import { generatePreview } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/assets/generatePreview';
import { AiFillFilePdf } from 'react-icons/ai';
import FlexChatIntegration from '../service/FlexChatIntegration';
export const SingleTemplateModal = ({
  isModalOpen,
  setIsModalOpen,
  template,
  queues,
  contactId,
  isIsolated,
  setChatCreatedOnIsolatedMessageId,
  isTryingToCreateAContactAttempt,
  clientNumber,
  clientId,
  clientName = 'Cliente',
  channelId = '',
  isMultiContact,
  selectedContacts,
  setSelectedContacts
}) => {
  if (!clientName) {
    clientName = 'Cliente';
  }

  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [previewImages, setPreviewImages] = useState([]);
  const [pdfPreview, setPdfPreview] = useState([]);
  const [files, setFiles] = useState([]);
  const [videoPreview, setVideoPreview] = useState(undefined);
  const [sendingTemplate, setSendingTemplate] = useState(false);
  const [queuesSelected, setQueueSelected] = useState('');
  const templateParamRegExp = /{{\d+}}/g;
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const [previousSelectedQueue, setPreviousSelectedQueue] = useState([]);
  const [openTempleteModal, setOpenTempleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [storeValuesContact, setStoreValuesContact] = useState({});
  const [clientFields, setClientFields] = useState([]);

  useEffect(() => {
    setQueueSelected(localStorage.getItem('activeContactQueue'));
  }, [previousSelectedQueue]);

  const {
    CheckedRefusedExtensionsFiles,
    isAllFilesWithApprovedExtension,
    isFilesWithApprovedExtension,
    isImagesWithApprovedExtension,
    sizeInvalidFileValidation
  } = validationFiles();

  useEffect(() => {
    if (!isModalOpen) {
      setPreviewImages([]);
      setPdfPreview([]);
      setFiles([]);
      setVideoPreview(undefined);
      setStoreValuesContact('');
    }
  }, [isModalOpen]);

  const { getImagePreview, getPDFPreview, getVideoPreview } = generatePreview();

  const inputParam = `<input
                        class="${template?.id}"
                        id="${template?.id}"
                        style="
                          font-size: 1rem;
                          color: rgb(94, 95, 98);
                          box-shadow: 0 0 0 0;
                          margin: 5;
                          border: 1px solid;
                          border-color: rgb(173, 176, 184);
                          border-radius: 5px;
                          width: 6.5rem;
                          height: 1.5rem;
                          outline: 0;
                        "
                      >`;

  const onInputClick = (event) => {
    event.target.value = '';
  };
  const handleOnChangeFileToTarget = (e, type = '') => {
    const file = e.target.files;
    handleSelectFile(file, type);
  };

  const handleSelectFile = (files, type) => {
    if (!files) return;

    const filesToBeSent = Array.from(files);

    const isCheckedRefusedExtensionsFiles =
      CheckedRefusedExtensionsFiles(filesToBeSent);

    if (isCheckedRefusedExtensionsFiles) {
      toast.error('Erro ao enviar arquivo(s). Formato não permitido');
      return;
    }

    const isInvalidTypeFile = isAllFilesWithApprovedExtension(filesToBeSent);
    if (Object.keys(isInvalidTypeFile).length !== 0) {
      toast.error(
        `${isInvalidTypeFile.message}: ${isInvalidTypeFile.extension}`
      );
      return;
    }

    const isSizeFileValidation = sizeInvalidFileValidation(
      filesToBeSent,
      'WHATSAPP'
    );
    if (Object.keys(isSizeFileValidation).length !== 0) {
      toast.error(`O arquivo é grande demais`);
      return;
    }

    const imagesWithApprovedExtension =
      isImagesWithApprovedExtension(filesToBeSent);

    const selectedImagePreview = getImagePreview(imagesWithApprovedExtension);

    const filesWithApprovedExtension =
      isFilesWithApprovedExtension(filesToBeSent);

    const selectedPdfPreview = getPDFPreview(filesWithApprovedExtension);

    setPreviewImages(selectedImagePreview);
    setPdfPreview([selectedPdfPreview[0]]);
    setFiles(filesToBeSent);
    setVideoPreview(undefined);

    if (type === 'video') {
      let newVideo = getVideoPreview(filesToBeSent[0]);

      setVideoPreview(newVideo);
    }
  };

  const inputs = Array.from(document.getElementsByClassName(template?.id));

  async function handleWithSendTemplateButton(template) {
    const inputs = Array.from(document.getElementsByClassName(template?.id));

    setSendingTemplate(true);
    let variables = await extractVariblesFromInputs(inputs);

    let clientFieldsArray = [];

    if (clientFields) {
      clientFieldsArray = clientFieldsArray.push({
        clientFields: clientFields
      });
    }

    if (!queuesSelected) {
      toast.error('Selecione a fila do contato ativo.');
      setSendingTemplate(false);
      return;
    }

    if (!variables) {
      setSendingTemplate(false);
      return;
    }

    if (inputs.length && variables?.length === 0) {
      toast.error('Por favor, reinicie a página e tente novamente.');
      setSendingTemplate(false);
      return;
    }

    if (variables) {
      for (let i = 0; i < variables.length; i++) {
        variables[i] = variables[i].replace(/#Contact_Name/g, '#Contact_Name');
      }
    }

    if (variables) {
      for (let i = 0; i < variables.length; i++) {
        variables[i] = variables[i].replace(
          /#Contact_Phone/g,
          '#Contact_Phone'
        );
      }
    }

    const message = await completeMessageWithVariables(
      template.description,
      variables
    );

    if (template.type === 'IMAGE') {
      if (files.length === 0) {
        toast.error('Selecione uma imagem');
        setSendingTemplate(false);
        return;
      }
      if (isMultiContact) {
        selectedContacts.length &&
          selectedContacts.forEach(async (contact) => {
            const fileExtension = files[0].name.split('.').pop();
            const formatedImage = new FormData();
            formatedImage.append('files', files[0]);
            if (imageExtensions.includes(fileExtension)) {
              formatedImage.append('response_type', 'image');
            }
            formatedImage.append('contactId', contact.id);
            formatedImage.append('clientId', contact.client_id);
            formatedImage.append('usrSocketId', socketConversation.id);
            formatedImage.append('message', message);
            formatedImage.append('queue', queuesSelected);
            formatedImage.append('type', template.type);
            formatedImage.append('templateId', template.id);
            formatedImage.append('idSourcePhone', channelId);
            formatedImage.append('clientFields', JSON.stringify(clientFields));
            formatedImage.append('params', JSON.stringify([...variables]));
            formatedImage.append('userLogin', localStorage.getItem('login'));
            await sendTemplateMessageFile(formatedImage);
          });
        setSelectedContacts([]);
      } else {
        const fileExtension = files[0].name.split('.').pop();
        const formatedImage = new FormData();
        formatedImage.append('files', files[0]);
        if (imageExtensions.includes(fileExtension)) {
          formatedImage.append('response_type', 'image');
        }
        formatedImage.append('contactId', contactId);
        formatedImage.append('clientId', clientId);
        formatedImage.append('usrSocketId', socketConversation.id);
        formatedImage.append('message', message);
        formatedImage.append('queue', queuesSelected);
        formatedImage.append('type', template.type);
        formatedImage.append('templateId', template.id);
        formatedImage.append('idSourcePhone', channelId);
        formatedImage.append('clientFields', JSON.stringify(clientFields));
        formatedImage.append('params', JSON.stringify([...variables]));
        formatedImage.append('userLogin', localStorage.getItem('login'));
        if (isIsolated) {
          formatedImage.append('phoneClient', clientNumber);
          formatedImage.append('nameClient', clientName);
          await sendTemplateMessageFileIsolated(formatedImage);
          return;
        }
        await sendTemplateMessageFile(formatedImage);
      }
    } else if (template.type === 'TEXT') {
      if (isMultiContact) {
        selectedContacts.length &&
          selectedContacts.forEach(async (contact) => {
            await sendTemplateMessage(
              variables,
              template.id,
              message,
              queuesSelected,
              channelId,
              contact.id,
              contact.client_id,
              isIsolated,
              contact.client_phone,
              contact.client_name
            );
          });
        setSelectedContacts([]);
      } else {
        await sendTemplateMessage(
          variables,
          template.id,
          message,
          queuesSelected,
          channelId,
          contactId,
          clientId,
          isIsolated,
          clientNumber,
          clientName
        );
      }
    } else if (template.type === 'DOCUMENT') {
      if (files.length === 0) {
        toast.error('Selecione um arquivo');
        setSendingTemplate(false);
        return;
      }

      if (isMultiContact) {
        selectedContacts.length &&
          selectedContacts.forEach(async (contact) => {
            const fileExtension = files[0].name.split('.').pop();
            const formatedImage = new FormData();
            formatedImage.append('files', files[0]);

            if (imageExtensions.includes(fileExtension)) {
              formatedImage.append('response_type', 'document');
            }
            formatedImage.append('contactId', contact.id);
            formatedImage.append('clientId', contact.client_id);
            formatedImage.append('usrSocketId', socketConversation.id);
            formatedImage.append('message', message);
            formatedImage.append('queue', queuesSelected);
            formatedImage.append('type', template.type);
            formatedImage.append('templateId', template.id);
            formatedImage.append('clientFields', JSON.stringify(clientFields));
            formatedImage.append('params', JSON.stringify([...variables]));
            formatedImage.append('idSourcePhone', channelId);
            formatedImage.append('userLogin', localStorage.getItem('login'));
            await sendTemplateMessageFile(formatedImage);
          });
        setSelectedContacts([]);
      } else {
        const fileExtension = files[0].name.split('.').pop();
        const formatedImage = new FormData();
        formatedImage.append('files', files[0]);

        if (imageExtensions.includes(fileExtension)) {
          formatedImage.append('response_type', 'document');
        }
        formatedImage.append('contactId', contactId);
        formatedImage.append('clientId', clientId);
        formatedImage.append('usrSocketId', socketConversation.id);
        formatedImage.append('message', message);
        formatedImage.append('queue', queuesSelected);
        formatedImage.append('type', template.type);
        formatedImage.append('templateId', template.id);
        formatedImage.append('clientFields', JSON.stringify(clientFields));
        formatedImage.append('params', JSON.stringify([...variables]));
        formatedImage.append('idSourcePhone', channelId);
        formatedImage.append('userLogin', localStorage.getItem('login'));

        if (isIsolated) {
          formatedImage.append('phoneClient', clientNumber);
          formatedImage.append('nameClient', clientName);
          await sendTemplateMessageFileIsolated(formatedImage);
          return;
        }
        await sendTemplateMessageFile(formatedImage);
      }
    }
    if (template.type === 'VIDEO') {
      if (files.length === 0) {
        toast.error('Selecione um video');
        setSendingTemplate(false);
        return;
      }

      if (isMultiContact) {
        selectedContacts.length &&
          selectedContacts.forEach(async (contact) => {
            const fileExtension = files[0].name.split('.').pop();
            const formatedImage = new FormData();
            formatedImage.append('files', files[0]);
            if (videoExtensions.includes(fileExtension)) {
              formatedImage.append('response_type', 'video');
            }
            formatedImage.append('contactId', contact.id);
            formatedImage.append('clientId', contact.client_id);
            formatedImage.append('usrSocketId', socketConversation.id);
            formatedImage.append('message', message);
            formatedImage.append('queue', queuesSelected);
            formatedImage.append('type', template.type);
            formatedImage.append('templateId', template.id);
            formatedImage.append('clientFields', JSON.stringify(clientFields));
            formatedImage.append('params', JSON.stringify([...variables]));
            formatedImage.append('idSourcePhone', channelId);
            formatedImage.append('userLogin', localStorage.getItem('login'));
            await sendTemplateMessageFile(formatedImage);
          });
        setSelectedContacts([]);
      } else {
        const fileExtension = files[0].name.split('.').pop();
        const formatedImage = new FormData();
        formatedImage.append('files', files[0]);
        if (videoExtensions.includes(fileExtension)) {
          formatedImage.append('response_type', 'video');
        }
        formatedImage.append('contactId', contactId);
        formatedImage.append('clientId', clientId);
        formatedImage.append('usrSocketId', socketConversation.id);
        formatedImage.append('message', message);
        formatedImage.append('queue', queuesSelected);
        formatedImage.append('type', template.type);
        formatedImage.append('templateId', template.id);
        formatedImage.append('clientFields', JSON.stringify(clientFields));
        formatedImage.append('params', JSON.stringify([...variables]));
        formatedImage.append('idSourcePhone', channelId);
        formatedImage.append('userLogin', localStorage.getItem('login'));

        if (isIsolated) {
          formatedImage.append('phoneClient', clientNumber);
          formatedImage.append('nameClient', clientName);
          await sendTemplateMessageFileIsolated(formatedImage);
          return;
        }
        await sendTemplateMessageFile(formatedImage);
      }
    }

    setSendingTemplate(false);
  }

  async function extractVariblesFromInputs(inputs) {
    const variables = [];
    for (let i = 0; i < inputs.length; i++) {
      const value = inputs[i].value;
      if (value.trim() === '') {
        toast.warning('Preencha todos os campos');
        return null;
      }
      variables.push(value);
    }
    return variables;
  }

  async function completeMessageWithVariables(message, variables) {
    function toReplace(match, offset, string) {
      const variableNumber = parseInt(string[offset + 2] - 1);
      return variables[variableNumber] ?? '';
    }

    return message.replace(templateParamRegExp, toReplace);
  }

  async function sendTemplateMessageFile(formatedImage) {
    await FlexChatIntegration.startActiveContactConversationMedia(formatedImage)
      .then(() => {
        setIsModalOpen(false);
        toast.success('Mensagem enviada');
      })
      .catch((error) => {
        const msg =
          error.response && error.response.data && error.response.data.message;
        toast.error(msg || 'Erro ao enviar mensagem');
      });
  }

  async function sendTemplateMessageFileIsolated(formatedImage) {
    await FlexChatIntegration.startActiveConversationWithoutContactMedia(
      formatedImage
    )
      .then(() => {
        setIsModalOpen(false);
        toast.success('Mensagem enviada');
      })
      .catch((error) => {
        const msg =
          error.response && error.response.data && error.response.data.message;
        toast.error(msg || 'Erro ao enviar mensagem');
      });
  }

  async function sendTemplateMessage(
    variables,
    templateId,
    message,
    queuesSelected,
    channelId,
    contactId,
    clientId,
    isIsolated,
    clientNumber,
    clientName
  ) {
    const usrSocketId = socketConversation.id;
    const messageData = {
      id: templateId,
      message: message,
      params: variables
    };
    const queue = queuesSelected;

    if (!isIsolated) {
      await FlexChatIntegration.startActiveContactConversation(
        contactId,
        usrSocketId,
        messageData,
        queue,
        clientId,
        channelId,
        storeValuesContact,
        template,
        clientFields
      )
        .then(() => {
          setIsModalOpen(false);
          toast.success('Mensagem enviada');
        })
        .catch((error) => {
          const msg =
            error.response &&
            error.response.data &&
            error.response.data.message;
          toast.error(msg || 'Erro ao enviar mensagem');
        });
    } else {
      const userLogin = localStorage.getItem('login') || '';

      await FlexChatIntegration.sendIsolatedMessage({
        contactId,
        clientId,
        phoneNumber: clientNumber,
        clientName,
        usrSocketId,
        messageData,
        queue,
        channelId,
        userLogin,
        storeValuesContact,
        template,
        clientFields
      })
        .then((res) => {
          setIsModalOpen(false);
          if (isTryingToCreateAContactAttempt) {
            setChatCreatedOnIsolatedMessageId(res.data.data.chatId);
          }
          toast.success('Mensagem enviada');
        })
        .catch((error) => {
          const msg =
            error.response &&
            error.response.data &&
            error.response.data.message;
          toast.error(msg || 'Erro ao enviar mensagem');
        });
    }
  }

  return (
    <>
      {template?.id && (
        <TempleteModalSelected
          isModalOpen={openTempleteModal}
          template={template}
          templateParamRegExp={templateParamRegExp}
          setSelectedData={setSelectedData}
          onModalToggle={setOpenTempleteModal}
          setStoreValuesContact={setStoreValuesContact}
          storeValuesContact={storeValuesContact}
          selectedData={selectedData}
          inputs={inputs}
          clientFields={clientFields}
          setClientFields={setClientFields}
        />
      )}

      <DefaultModal
        show={isModalOpen}
        onModalToggle={setIsModalOpen}
        title={template && template.vertical ? template.vertical : 'Template'}
        showCloseButton={false}
        modalContainerClasses="modal-md"
      >
        {queues && (
          <Row
            style={{
              justifyContent: 'center',
              marginTop: '-1.5rem',
              marginBottom: '2rem'
            }}
          >
            <Col md="6">
              <label className="form-control-label">Fila*</label>
              <Input
                type="select"
                onChange={(e) => {
                  setQueueSelected(e.target.value);
                  localStorage.setItem('activeContactQueue', e.target.value);
                  setPreviousSelectedQueue(e.target.value);
                }}
                name="fila"
                defaultValue={localStorage.getItem('activeContactQueue') || ''}
              >
                <option value="">Selecione uma fila...</option>
                {queues
                  .filter((queue) => queue.is_chat === true)
                  .map((queue) => (
                    <option key={queue.value} value={queue.label}>
                      {queue.label}
                    </option>
                  ))}
              </Input>
            </Col>
          </Row>
        )}
        {template ? (
          <>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '0.5rem'
              }}
            >
              <div
                style={{
                  alignSelf: 'center',
                  padding: '1rem'
                }}
                className="text-justify"
                dangerouslySetInnerHTML={{
                  __html: template?.description?.replace(
                    templateParamRegExp,
                    inputParam
                  )
                }}
              ></div>
              {template.type !== 'TEXT' ? (
                <>
                  <Row
                    style={{ justifyContent: 'center' }}
                    className="mt-2 mb-2"
                  >
                    <Col className="d-flex justify-content-center">
                      <Preview>
                        {!!Object.keys(previewImages).length &&
                          previewImages.map((previewImage, index) => {
                            return (
                              <PreviewImage
                                key={index}
                                previewImage={previewImage}
                                title={false}
                              />
                            );
                          })}
                        {videoPreview && (
                          <VideoPreview title={false} video={videoPreview} />
                        )}

                        {!!Object.keys(pdfPreview).length &&
                          pdfPreview.map((filePreview, index) => {
                            if (filePreview) {
                              return (
                                <PreviewPdf
                                  key={index}
                                  title={false}
                                  filePreview={filePreview}
                                />
                              );
                            }
                          })}
                      </Preview>
                    </Col>
                  </Row>
                </>
              ) : null}
              <Row style={{ justifyContent: 'center', marginTop: '0.5rem' }}>
                {template.type === 'IMAGE' ? (
                  <>
                    <label
                      htmlFor={`uploadFileConversationImage`}
                      className="btn mt-2"
                      style={{
                        ...buttonStyle(
                          systemConfiguration.confirmation_button_color
                        ),
                        marginLeft: '0.7rem'
                      }}
                    >
                      <BsFillImageFill />
                    </label>
                    <Input
                      style={{ display: 'none' }}
                      type="file"
                      className="inputFile"
                      name="uploadFile"
                      id={`uploadFileConversationImage`}
                      onClick={onInputClick}
                      onChange={(e) => {
                        handleOnChangeFileToTarget(e, 'image');
                      }}
                      accept="image/*"
                    />
                  </>
                ) : null}
                {template.type === 'VIDEO' ? (
                  <>
                    <label
                      htmlFor={`uploadFileConversationVideo`}
                      className="btn mt-2"
                      style={{
                        ...buttonStyle(
                          systemConfiguration.confirmation_button_color
                        ),
                        marginLeft: '0.7rem'
                      }}
                    >
                      <>
                        <BsCameraVideoFill />
                      </>
                    </label>
                    <Input
                      style={{ display: 'none' }}
                      type="file"
                      className="inputFile"
                      name="uploadFile"
                      id={`uploadFileConversationVideo`}
                      onClick={onInputClick}
                      onChange={(e) => {
                        handleOnChangeFileToTarget(e, 'video');
                      }}
                      accept="video/*"
                    />
                  </>
                ) : null}
                {template.type === 'DOCUMENT' ? (
                  <Row>
                    <label
                      htmlFor={`uploadFileConversationDocument`}
                      className="btn mt-2"
                      style={{
                        ...buttonStyle(
                          systemConfiguration.confirmation_button_color
                        ),
                        marginLeft: '0.7rem'
                      }}
                    >
                      <AiFillFilePdf />
                    </label>
                    <Input
                      style={{ display: 'none' }}
                      type="file"
                      className="inputFile"
                      name="uploadFile"
                      id={`uploadFileConversationDocument`}
                      onClick={onInputClick}
                      onChange={(e) => {
                        handleOnChangeFileToTarget(e, 'file');
                      }}
                      accept=".pdf"
                    />
                  </Row>
                ) : null}
                {template.description &&
                templateParamRegExp.test(template.description) ? (
                  <div className="text-right mt-2">
                    <ReactButton
                      color="primary"
                      disabled={sendingTemplate}
                      style={{ marginLeft: '0.7rem' }}
                      onClick={() => setOpenTempleteModal(true)}
                    >
                      Preencher campos
                    </ReactButton>
                  </div>
                ) : (
                  []
                )}

                <div className="text-right mt-2">
                  <ReactButton
                    color="primary"
                    disabled={sendingTemplate}
                    class="btn btn-secondary"
                    style={{
                      marginLeft: '0.7rem',
                      border: '1px solid rgb(16, 185, 129)',
                      backgroundColor: 'rgb(16, 185, 129)',
                      color: 'white'
                    }}
                    onClick={() => handleWithSendTemplateButton(template)}
                  >
                    Enviar mensagem
                  </ReactButton>
                </div>
              </Row>
            </Row>
          </>
        ) : (
          <p>template invalido</p>
        )}
      </DefaultModal>
    </>
  );
};
