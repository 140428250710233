import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';

import FlexSelect from 'components/Inputs/FlexSelect';
import PauseServiceCrud from '../../../../../../views/Pause/service';
import PauseService from '../../service/pause';
import { useServiceBlockServicePainelContext } from '../../../../../../hooks/useBlockAcessServicePainel';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { UserInfoWrapper, Button, Label } from './styled';

export function EnterToPause() {
    //aqui vai ter uma validação escolhendo qual componente chamar
    const { handleModifieldIsBlockServicePainel, handleModifielTypeOfPause } =
        useServiceBlockServicePainelContext();
    const { control, handleSubmit, errors, setValue } = useForm();
    const [pauses, setPauses] = useState([]);
    const systemConfiguration = useSelector(
        (state) => state.systemConfiguration
    );
    const loggedInUser = useMemo(() => {
        const nomeUser = localStorage.getItem('name');
        const idUser = localStorage.getItem('login');
        return {
            nomeUser,
            idUser
        };
    }, []);

    const getPauses = useCallback(async () => {
        try {
            const response = await PauseServiceCrud.getAll();
            const { data } = response?.data;
            setPauses([...data]);
        } catch (err) {
            toast.error('Houve um erro ao buscar as filas');
            console.log('get pause error', err);
        }
    }, []);

    useEffect(() => {
        getPauses();
    }, [getPauses]);

    const onSubmit = async (data) => {
        try {
            const { name } = pauses.find((pause) => pause.id === data.idPausa);

            const dataToSubmit = {
                nomePausa: name,
                ...data,
                ...loggedInUser
            };

            await PauseService.enterToPause(dataToSubmit);

            handleModifieldIsBlockServicePainel(true);
            handleModifielTypeOfPause(name);
            toast.success(
                `O atendente ${loggedInUser.nomeUser} entrou em pausa ${name} com sucesso`
            );
        } catch (err) {
            toast.error('Ocorreu algum erro ao tentar entrar em pausa');
            console.log('err', err);
        }
    };

    const errorFormMessage = (message) => (
        <p style={{ color: 'red' }}>{message}</p>
    );

    return (
        <>
            <UserInfoWrapper title="Pausa do atendimento">
                Entrar em pausa no painel de atendimento
            </UserInfoWrapper>
            <Form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Label>Pausa*</Label>
                <Controller
                    render={(props) => (
                        <FlexSelect
                            dataOptions={pauses || []}
                            isMulti={false}
                            value={props.value}
                            placeholder="Selecione uma pausa"
                            isClearable={true}
                            valueController={setValue}
                            closeMenuOnSelect={true}
                            fieldName="idPausa"
                            labelName="name"
                            valueName="id"
                        />
                    )}
                    defaultValue=""
                    control={control}
                    rules={{ required: 'Campo obrigatório!' }}
                    name="idPausa"
                />
                <ErrorMessage
                    errors={errors}
                    name="idPausa"
                    render={({ message }) => errorFormMessage(message)}
                />
                <Button
                    type="submit"
                    title="Entrar em Pausa"
                    style={buttonStyle(systemConfiguration.primary_color)}
                >
                    Entrar em Pausa
                </Button>
            </Form>
            {/* )} */}
        </>
    );
}
