import React from 'react';
import PaginationReact from 'react-js-pagination';
import PaginationContainerSticky from 'components/Containers/PaginationContainerSticky';

const PaginationSticker = (props) => {
  const activePage = props.activePage || 1;
  const itemsCountPerPage = props.itemsCountPerPage || 10;
  const totalItemsCount = props.totalItemsCount || 10;
  const pageRangeDisplayed = 10;
  const onChange = props.onChange;

  return (
    <PaginationContainerSticky scroll={props.scroll}>
      <PaginationReact
        activePage={activePage}
        itemClass="page-item"
        linkClass="page-link"
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={onChange}
        style={{
          marginBottom: 0
        }}
      />
    </PaginationContainerSticky>
  );
};
export default PaginationSticker;
