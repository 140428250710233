import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { sheetReader } from 'utils/fuctions/sheetReader.js';
import { generateSheetHeaders } from 'utils/fuctions/generateSheetHeaders.js';

import ContactService from '../../../services/contactService';

const initialState = {
  file: '',
  data: [],
  hasHeader: true,
  columns: []
};

const defaultRequiredFields = [
  {
    id: 'name',
    name: 'name',
    label: 'Nome',
    isRequired: true
  }
];
const defaultOptionalFields = [
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    isRequired: false
  },
  {
    id: 'phone',
    name: 'phone',
    label: 'Telefone',
    isRequired: false
  }
];

const ImportContactsModal = ({ isOpen, setIsOpen, recordTypeId }) => {
  const [state, setState] = useState(initialState);
  const [fields, setFields] = useState(defaultRequiredFields);
  const [optionalFields, setOptionalFields] = useState([]);
  const [option, setOption] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleSubmit, control, setValue } = useForm();
  const systemConfiguration = useSelector((stt) => stt.systemConfiguration);

  const { getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.CONTACT,
    recordTypeId
  );

  useEffect(() => {
    if (isOpen) {
      const getCtFields = async () => {
        const custFilds = await getCustomFields();
        const fieldsDTO = custFilds.map((field) => ({
          id: field.id,
          name: field.name,
          label: field.label,
          isRequired: field.recordTypesConfig.isRequired
        }));
        setOptionalFields([...defaultOptionalFields, ...fieldsDTO]);
      };
      getCtFields();
    }
  }, [getCustomFields, isOpen]);

  useEffect(() => {
    const [headers] = state.data;
    if (!headers) return;
    const columns = headers.map((e) => ({ col: e }));
    setState((s) => ({ ...s, columns }));
  }, [state.data]);

  const changeFile = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const result = await sheetReader(file);

    const data = result
      .split(/\r?\n|\r/)
      .map((item) => item.split(/,|\t/))
      .filter((item) => item.length > 1);

    const maxLimit = state.hasHeader ? 50001 : 50000;
    if (data.length > maxLimit) {
      toast.error(`A importação deve ter no máximo 50.000 registros`);
      return;
    }

    setState((s) => ({ ...s, data, file }));
  };

  const changeHasHeader = (e) => {
    const hasHeader = e.target.checked;
    if (!hasHeader) {
      const headers = generateSheetHeaders(state.columns.length);
      setState((s) => ({ ...s, hasHeader, data: [headers, ...s.data] }));
    } else {
      setState((s) => {
        s.data.shift();
        return { ...s, hasHeader, data: [...s.data] };
      });
    }
  };

  const filterMatrixByColumns = (columns, matrix) => {
    const filteredMatrix = [];
    for (let i = 0; i < matrix.length; i++) {
      const row = [];
      for (let j = 0; j < columns.length; j++) {
        row.push(matrix[i][columns[j]]);
      }
      filteredMatrix.push(row);
    }
    return filteredMatrix;
  };

  const onAddField = () => {
    if (optionalFields.length === 0 || option === '') return;

    const [field] = optionalFields.filter((f) => f.name === option);

    setOptionalFields(optionalFields.filter((f) => f.name !== option));
    setFields([...fields, field]);
    setOption('');
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      for (const key in data) {
        if (!data[key]) {
          toast.info(`Preencha todos os campos selecionados`);
          return;
        }
      }
      for (const f of optionalFields) {
        if (
          f.isRequired &&
          (!Object.keys(data).includes(f.name) || !data[f.name])
        ) {
          toast.info(`Preencha todos os campos marcados com "*"`);
          return;
        }
      }

      const columns = Object.values(data).map((h) => state.data[0].indexOf(h));
      const filteredData = filterMatrixByColumns(columns, state.data);
      const dataEntries = Object.entries(data);
      const headers = filteredData.shift().map((h) => {
        for (let i = 0; i < dataEntries.length; i++) {
          const [key, value] = dataEntries[i];

          if (h === value) {
            dataEntries.splice(i, 1);

            return key;
          }
        }
      });

      const dataDTO = [];
      for (const fieldData of filteredData) {
        const item = {};
        for (const header of headers) {
          item[header] = fieldData[headers.indexOf(header)];
        }
        dataDTO.push(item);
      }

      const response = await ContactService.importContacts(recordTypeId, {
        data: dataDTO
      });
      const importedContacts = response?.data?.data?.contacts;
      const { existingContacts, createdContacts } = importedContacts;

      const msg = `${existingContacts.length} contato(s) já existente(s) e ${createdContacts.length} contato(s) criado(s)`;
      toast.success(msg);

      setState(initialState);
    } catch (error) {
      const msg = error?.response?.data?.message || 'Erro ao importar contatos';
      toast.error(msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {!state.file ? (
        <ModalBody>
          <Form>
            <FormGroup>
              <label className="form-control-label d-block mb-3">
                Insira um arquivo do tipo CSV, XLS ou XLSX
              </label>
              <label
                htmlFor="import_contacts_list"
                className="btn btn-primary btn-sm"
                style={buttonStyle(systemConfiguration.primary_color)}
              >
                Selecionar um arquivo
              </label>
              <Input
                style={{ display: 'none' }}
                type="file"
                id="import_contacts_list"
                name="import_contacts_list"
                accept=".csv, .xls, .xlsx"
                value={state.file}
                onChange={changeFile}
              />
            </FormGroup>
          </Form>
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <div style={{ marginBottom: '24px' }}>
              <div style={{ marginBottom: '8px', fontSize: '0.875rem' }}>
                Arquivo
              </div>
              <div
                style={{
                  color: '#525f7f',
                  fontSize: '1rem',
                  fontWeight: '600'
                }}
              >
                {state.file.name}
              </div>
            </div>
            <Form id="import-contacts-form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup check style={{ marginBottom: '24px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    name="hasHeader"
                    checked={state.hasHeader}
                    onChange={changeHasHeader}
                  />
                  Utilizar primeira linha como cabeçalho
                </Label>
              </FormGroup>
              {fields.map((field) => (
                <Controller
                  key={field.id}
                  control={control}
                  name={field.name}
                  defaultValue=""
                  render={(props) => (
                    <FormGroup>
                      <Label className="form-control-label text-capitalize">
                        {`${field.label}${field.isRequired ? '*' : ''}`}
                      </Label>
                      <FlexSelect
                        isClearable={true}
                        dataOptions={state.columns}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={props.value}
                        valueController={setValue}
                        fieldName={field.name}
                        labelName="col"
                        valueName="col"
                      />
                    </FormGroup>
                  )}
                />
              ))}
              <div>
                <div style={{ marginBottom: '8px', fontSize: '0.875rem' }}>
                  Adicionar coluna
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ paddingRight: '8px', width: '100%' }}>
                    <FlexSelect
                      isClearable={true}
                      isDisabled={optionalFields.length === 0 ? true : false}
                      dataOptions={optionalFields.map(
                        ({ label, name, isRequired }) => ({
                          label: `${label}${isRequired ? '*' : ''}`,
                          value: name
                        })
                      )}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      value={option}
                      valueController={(_, value) => {
                        setOption(value);
                      }}
                      fieldName="option"
                      labelName="label"
                      valueName="value"
                    />
                  </div>
                  <Button
                    type="button"
                    color="default"
                    disabled={option === '' ? true : false}
                    onClick={onAddField}
                  >
                    <li className="fas fa-plus"></li>
                  </Button>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <ReactButton
              btnColor="confirmation"
              type="submit"
              form="import-contacts-form"
              disabled={!state.file || isSubmitting}
            >
              Importar
            </ReactButton>
            <ReactButton
              btnColor="cancelation"
              onClick={() => {
                setState(initialState);
                setOption('');
                setIsOpen(!isOpen);
              }}
            >
              Cancelar
            </ReactButton>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default ImportContactsModal;
