import React, { useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import Tour from 'reactour';
import {
  Row,
  Card,
  Col,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledDropdown,
  Button
} from 'reactstrap';
import { TotalAttendanceData } from '../assets/styleds';
import { BiHappyHeartEyes, BiMeh } from 'react-icons/bi';
import { HiEmojiSad } from 'react-icons/hi';
import { colorsChars, stepsNPS, stepsSatisfaction } from '../assets/types';

const Indicators = ({ dataIndicators, typeSurvey }) => {
  const [tourOpen, setTourOpen] = useState(false);

  const openTour = () => {
    setTourOpen(true);
  };

  const closeTour = () => {
    setTourOpen(false);
  };

  const calculateDataGraph = (value) => {
    const result = value.toFixed(2) / 100;
    if (result < 0) {
      return 0;
    } else {
      return result;
    }
  };

  return (
    <Card id="searchIndicators">
      <CardHeader className="introduct">
        <h6 className="surtitle">Visão Geral</h6>
        <h5 className="h3 mb-0">Indicadores de Pesquisa</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="8">
            <Row>
              <Col md="6" className="tour">
                <Card className="bg-success border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          porcentagem de Promotor
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataIndicators?.indicadores?.promotor?.toFixed(2) +
                            ' %'}
                        </TotalAttendanceData>
                      </div>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <BiHappyHeartEyes size={35} color="white" />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="bg-success border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          Quantidade de Promotor(es)
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataIndicators?.indicadores?.qtdePromotor || 0}
                        </TotalAttendanceData>
                      </div>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <BiHappyHeartEyes size={35} color="white" />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="tour2">
                <Card
                  className="border-0"
                  style={{
                    backgroundColor: '#FFAD00'
                  }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          porcentagem de neutro
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataIndicators?.indicadores?.passivo?.toFixed(2) +
                            ' %' || 0}
                        </TotalAttendanceData>
                      </div>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <BiMeh size={35} color="white" />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card
                  className="border-0"
                  style={{ backgroundColor: '#FFAD00' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          Quantidade de neutro(s)
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataIndicators?.indicadores?.qtdePassivo || 0}
                        </TotalAttendanceData>
                      </div>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <BiMeh size={35} color="white" />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="tour3">
                <Card className="bg-danger border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          porcentagem de detrator
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataIndicators?.indicadores?.detrator?.toFixed(2) +
                            ' %' || 0}
                        </TotalAttendanceData>
                      </div>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <HiEmojiSad size={35} color="white" />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="bg-danger border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white"
                        >
                          Quantidade de detrator(es)
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataIndicators?.indicadores?.qtdeDetrator || 0}
                        </TotalAttendanceData>
                      </div>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <HiEmojiSad size={35} color="white" />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12">
                <h6 className="h3 mb-0 text-left">
                  Quantidade de registros: {dataIndicators?.count || 0}
                </h6>
              </Col>
              <Col>
                <Button
                  outline
                  color="info"
                  size="sm"
                  onClick={openTour}
                  title="Saiba mais sobre os indicadores"
                >
                  Tour guiado
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <Row>
              <Col md="12" className="tour4">
                <Card style={{ backgroundColor: '#FAFAFB' }}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white text-center"
                          style={{
                            backgroundColor: '#5E72E4',
                            borderRadius: '4px'
                          }}
                        >
                          {typeSurvey === 'nps' ? 'NPS' : 'Satisfação'}
                        </CardTitle>
                        <p className="h3 font-weight-bold mb-0 text-black text-center">
                          {dataIndicators?.indicadores?.resultado?.toFixed(2) +
                            ' %' || 0}
                        </p>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Col md="12">
              <GaugeChart
                style={{
                  width: '100%',
                  alignItems: 'center'
                }}
                id="gauge-chart2"
                nrOfLevels={20}
                needleBaseColor="#00A17C"
                needleColor="#2DCE89"
                colors={colorsChars}
                hideText={true}
                percent={calculateDataGraph(
                  dataIndicators?.indicadores?.resultado || 0
                )}
              />
            </Col>

            <Col style={{ paddingTop: '1.4rem' }} className="tour5">
              <h5 className="h3 mb-0 text-center">
                Zona: {dataIndicators?.indicadores?.zona || 'Não informado'}
              </h5>
            </Col>
            <Row>
              <Col md="12">
                <Card style={{ backgroundColor: '#FAFAFB' }}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 text-white text-center"
                          style={{
                            backgroundColor: '#5E72E4',
                            borderRadius: '4px'
                          }}
                        >
                          Média
                        </CardTitle>
                        <p className="h3 font-weight-bold mb-0 text-black text-center">
                          {dataIndicators?.indicadores?.media?.toFixed(2) ||
                            'Não informado'}
                        </p>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
      <Tour
        steps={typeSurvey === 'nps' ? stepsNPS : stepsSatisfaction}
        isOpen={tourOpen}
        onRequestClose={closeTour}
      />
    </Card>
  );
};

export default Indicators;
