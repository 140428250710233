import { useState } from 'react';
import EditModal from '../EditModal';
import ConversationDetails from 'components/Conversation/ConversationDetails';
import { apiGeneric } from '../../../../../../../http';
import React from 'react';
import { Button } from 'reactstrap';
import ReactLoader from 'components/Loader';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import { toast } from 'react-toastify';

export const ReportButtonContainer = ({ id }) => {
  const [conversation, setConversation] = useState(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const getConversation = (id, buttonIdentifier) => {
    if (!id) return;
    setIsLoading(true);
    apiGeneric
      .get(`flex-chat-integration/api/chat/finish/${id}`)
      .then((res) => {
        setTimeout(() => {
          setConversation(res.data.data);
          setIsLoading(false);
          if (buttonIdentifier === 0) {
            document.getElementById(`${id}-conversation-details`).click();
          } else {
            document.getElementById(`${id}-edit-modal`).click();
          }
        }, 800);
      })
      .catch((err) => {
        console.log('err', err);
        toast.error('Ocorreu um erro ao buscar a conversa');
        setIsLoading(false);
      });
  };

  return (
    <>
      {conversation !== undefined && conversation !== null && (
        <>
          <ConversationDetails id={id} conversationData={conversation} />
          <EditModal id={id} conversationData={conversation} />
        </>
      )}

      {!conversation && (
        <div style={{ position: 'relative', width: 'min-content' }}>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                left: '4px',
                top: '3px',
                zIndex: '10'
              }}
            >
              <ReactLoader width={20} height={20}></ReactLoader>
            </div>
          )}
          <Button
            onClick={(e) => {
              e.preventDefault();
              getConversation(id, 0);
            }}
            color="success"
            size="sm"
            title={'Detalhes'}
            disabled={isLoading || !id}
          >
            {<i className={'fas fa-info-circle'}></i>}
          </Button>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                left: '41px',
                top: '3px',
                zIndex: '10'
              }}
            >
              <ReactLoader width={20} height={20}></ReactLoader>
            </div>
          )}
          <Button
            onClick={() => getConversation(id, 1)}
            size="sm"
            color="info"
            title="Editar encerramento"
            disabled={isLoading || !id || verifyRoles(['ATTENDANT', 'TRAINNE'])}
          >
            <i className="fas fa-pen"></i>
          </Button>
        </div>
      )}
    </>
  );
};
