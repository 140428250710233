import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import io from 'socket.io-client';
import { Col, Row } from 'reactstrap';
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import { isExpired } from 'react-jwt';
import { getLogoURL } from '../../views/SystemParams/services/getLogoURL';
import ServiceAuth from '../../services/ServiceAUTH';
import { SocketAdmConversationProvider } from '../../hooks/useSocketAdmConversations';
import { SocketInternalChatProvider } from '../../hooks/useInternalChatSocket';
import { RequestUserProvider } from 'hooks/useUserContext';
import { ConversationInstagramConnectionSocketForMonitoringProvider } from 'hooks/useSocketConversationInstagram';
import { FlexCaseBackEndConnectionSocketProvider } from 'hooks/useSocketFlexCaseBackend';
import { ConversationConnectionSocketForMonitoringProvider } from '../../hooks/useConversationConnectionSocketForMonitoring';
import routes from 'routes';
import { connect } from 'react-redux';
import SideDrawer from './SideDrawer';
import {
  toggleSideDrawer,
  openSideDrawer
} from 'store/action/sideDrawerActions';
import { CustomFieldsContextProvider } from 'views/Protocol/form/context/CustomFieldsContext';

class Admin extends React.Component {
  state = {
    sidenavOpen: true,
    rolesUser: JSON.parse(localStorage.getItem('roles')),
    image: null,
    answeringDialerCallData: null
  };

  socket = io(process.env.REACT_APP_PROXY_URL, {
    path: process.env.REACT_APP_CASE_SOCKET_PATH,
    transports: ['websocket'],
    upgrade: false
  });

  componentDidMount() {
    getLogoURL().then((source) => {
      this.setState({
        image: source
      });
    });

    this.socket.on('client_dialer_call_answered', (eventData) => {
      if (!eventData?.params) return;
      if (Object.keys(eventData.params).length <= 0) return;

      const extension = localStorage.getItem('currentLoggedInExtension');
      const hasExtension = !!extension;
      const isAdifferentExtension = eventData.extension !== extension;

      if (!hasExtension || isAdifferentExtension) return;

      this.setState({ answeringDialerCallData: null });
      this.setState({
        answeringDialerCallData: eventData
      });
      this.props.openSideDrawer({
        answeringDialerCallData: eventData
      });
    });
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    this.socket.disconnect();
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }

      let permission = false;

      if (prop.roles) {
        for (let i = 0; i < prop.roles.length; i++) {
          if (permission === true) break;

          for (let j = 0; j < this.state.rolesUser.length; j++) {
            if (this.state.rolesUser[j]) {
              if (
                prop.roles[i] === this.state.rolesUser[j].role.toUpperCase() ||
                prop.roles[i] === 'ALL'
              ) {
                permission = true;
                break;
              }
            } else break;
          }
        }
      }

      if (prop.layout === '/admin' && permission === true) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      'admin/alternative-dashboard'
    ) === -1
      ? 'dark'
      : 'light';
  };

  registerLogoutByTokenExpired = async () => {
    const service = new ServiceAuth('logout');
    const userLogin = localStorage.getItem('login');

    try {
      const data = {
        logoutType: 'logout_por_token_expirado',
        login: userLogin
      };
      await service.create({ ...data });
      window.location.href = '/activelogout';
    } catch (err) {
      console.error('Error register logout by token expired', err);
    }
  };

  getMainContentColumnSize() {
    return this.props.isDrawerCollapsed ? '12' : '7';
  }

  getRedirect() {
    const roles = this.state.rolesUser?.map(({ role }) => role);

    if (roles.includes('AGENT_DASHBOARD') && !roles.includes('DASHBOARD')) {
      return <Redirect from="*" to="/admin/dashboard/agent" />;
    }

    return <Redirect from="*" to="/admin/dashboard" />;
  }

  render() {
    const expired = isExpired(localStorage.getItem('token'));
    if (expired) {
      this.registerLogoutByTokenExpired();
    }
    return (
      <>
        <FlexCaseBackEndConnectionSocketProvider
          socketFlexCaseBackEnd={this.socket}
        >
            <ConversationInstagramConnectionSocketForMonitoringProvider>
              <ConversationConnectionSocketForMonitoringProvider>
                <SocketAdmConversationProvider>
                  <SocketInternalChatProvider>
                    <RequestUserProvider>
                      <CustomFieldsContextProvider>
                        <Sidebar
                          {...this.props}
                          routes={routes}
                          toggleSidenav={this.toggleSidenav}
                          sidenavOpen={this.state.sidenavOpen}
                          logo={{
                            innerLink: '/',
                            imgSrc: this.state.image,
                            imgAlt: '...'
                          }}
                        />
                        <div
                          className="main-content"
                          ref="mainContent"
                          onClick={this.closeSidenav}
                        >
                          <Row className="p-0 m-0">
                            <Col
                              md={this.getMainContentColumnSize()}
                              className={`p-0 m-0`}
                              style={{ transition: '0.2s' }}
                            >
                              <AdminNavbar
                                {...this.props}
                                theme={this.getNavbarTheme()}
                                toggleSidenav={this.toggleSidenav}
                                sidenavOpen={this.state.sidenavOpen}
                                brandText={this.getBrandText(
                                  this.props.location.pathname
                                )}
                              />
                              <Switch>
                                {this.getRoutes(routes)}

                                {this.getRedirect()}
                              </Switch>
                            </Col>
                            <Col className="m-0 p-0">
                              <SideDrawer />
                            </Col>
                            <AdminFooter />
                          </Row>
                        </div>
                        {this.state.sidenavOpen ? (
                          <div
                            className="backdrop d-xl-none"
                            onClick={this.toggleSidenav}
                          />
                        ) : null}
                      </CustomFieldsContextProvider>
                    </RequestUserProvider>
                  </SocketInternalChatProvider>
                </SocketAdmConversationProvider>
              </ConversationConnectionSocketForMonitoringProvider>
            </ConversationInstagramConnectionSocketForMonitoringProvider>
        </FlexCaseBackEndConnectionSocketProvider>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDrawerCollapsed: state.sideDrawer.isDrawerCollapsed
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openSideDrawer: (data) =>
      dispatch(
        openSideDrawer({
          tabId: 1,
          ...data
        })
      ),
    toggleSideDrawer: (value) => dispatch(toggleSideDrawer())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
