import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import ConversationDetails from 'components/Conversation/ConversationDetails';
import ConversaService from '../../../../../Conversation/service';
import { toast } from 'react-toastify';

import {
  ConversationContainer,
  RelationsWrapper,
  CallContainer
} from '../../../../../Protocol/updateForm/components/Relations/styled';
import { handleCpfCnpj } from 'views/Protocol/updateForm/components/Relations';
import { handleFormatConversationData } from 'views/Protocol/updateForm/components/Relations';
import Service from '../../../../../../components/Survey/SurveyDetailsTelephony/service';
import { AudioStreamReportTelephony as AudioStream } from '../../../../../../components/AudioController/AudioStream';
import ReportService from '../../../../../Report/Callcenter/ReceivedCall/service';
import { handleTimeWaitingCall } from 'views/Protocol/updateForm/components/Relations';
import { handleTimeCall } from 'views/Protocol/updateForm/components/Relations';

const ModalContactAttemptDetails = ({
  isModalOpen,
  onModalToggle,
  contactAttemptInfo,
  setLoading,
  ...props
}) => {
  const [conversation, setConversation] = useState(null);
  const [callInfo, setCallInfo] = useState(null);

  useEffect(() => {
    if (contactAttemptInfo) {
      if (contactAttemptInfo?.chatId !== null) {
        getConversationById(contactAttemptInfo?.chatId);
      } else if (contactAttemptInfo?.callId !== null) {
        getCallInfoById(contactAttemptInfo?.callId);
      }
    }
  }, [getConversationById, isModalOpen, contactAttemptInfo, getCallInfoById]);

  const getConversationById = useCallback(
    (conversationId) => {
      if (conversationId && isModalOpen) {
        ConversaService.getClosedOrInAttendance(conversationId)
          .then((res) => {
            setConversation(res.data.conversa);
            setLoading(false);
          })
          .catch(() => {
            toast.error('Erro ao carregar a conversa', {
              autoClose: 3000,
              closeOnClick: true
            });
          });
      }
    },
    [isModalOpen, setLoading]
  );

  const getCallInfoById = useCallback(
    (callId) => {
      if (callId && isModalOpen) {
        Service.getReportById(callId)
          .then((res) => {
            setCallInfo(res.data.data);
            setLoading(false);
          })
          .catch(() => {
            toast.error('Erro ao carregar a chamada', {
              autoClose: 3000,
              closeOnClick: true
            });
          });
      }
    },
    [isModalOpen, setLoading]
  );

  return (
    <Modal
      backdrop={true}
      className={''}
      isOpen={isModalOpen}
      toggle={() => onModalToggle(!isModalOpen)}
      centered={true}
      {...props}
    >
      <div className={`modal-header`}>
        <h6 className="modal-title" id="modal-title-default">
          {contactAttemptInfo?.chatId !== null ? (
            <>Resumo das informações do chat</>
          ) : (
            <>Detalhes da Ligação</>
          )}
        </h6>

        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onModalToggle(!isModalOpen)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div
        className={'modal-body'}
        style={{
          paddingTop: '0px'
        }}
      >
        {(contactAttemptInfo?.chatId === null &&
          contactAttemptInfo?.callId === null) ||
        (conversation === null && callInfo === null) ? (
          <p>
            Não foi possivel encontrar as informações da tentativa de contato...
          </p>
        ) : (
          <RelationsWrapper>
            {conversation !== null ? (
              <ConversationContainer>
                <Row>
                  <Col md="6" title="Usuário">
                    <span>Atendente: </span>
                    <p>{conversation?.atendente?.name || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="Cliente">
                    <span>Cliente: </span>
                    <p>{conversation?.cliente?.nome || 'Não informado'}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Telefone do cliente">
                    <span>Telefone: </span>
                    <p>{conversation?.cliente?.telefone || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="CPF/CNPJ">
                    <span>CPF/CNPJ: </span>
                    <br />
                    {handleCpfCnpj(conversation?.resumoBot) === '' ? (
                      <p>Não informado</p>
                    ) : (
                      handleCpfCnpj(conversation?.resumoBot)
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Fila">
                    <span>Fila: </span>
                    <p>{conversation?.fila || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="Início da Conversa">
                    <span>Início da Conversa: </span>
                    {handleFormatConversationData(conversation?.hora_criacao)}
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Canal">
                    <span>Canal: </span>
                    <p>{conversation?.canal || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="Detalhes da Conversa">
                    <span>Detalhes da Conversa: </span>
                    <br />
                    <ConversationDetails conversationData={conversation} />
                  </Col>
                </Row>
              </ConversationContainer>
            ) : (
              <CallContainer>
                <Row>
                  <Col md="6" title="Usuário">
                    <span>Atendente: </span>
                    <p>{callInfo?.usr?.name || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="Fila">
                    <span>Fila: </span>
                    <p>{callInfo?.queue?.name || 'Não informado'}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Tempo em ligação">
                    <span>Tempo em ligação: </span>
                    {handleTimeCall(callInfo)}
                  </Col>
                  <Col md="6" title="Tempo em espera">
                    <span>Tempo em espera: </span>
                    <br />
                    {handleTimeWaitingCall(callInfo)}
                  </Col>
                </Row>

                <Row>
                  <Col md="6" title="Data de Início">
                    <span>Data de Início: </span>
                    {handleFormatConversationData(callInfo?.calldate_start)}
                  </Col>
                  <Col md="6" title="Origem">
                    <span>Origem: </span>
                    <p>{callInfo?.clid_num || 'Não informado'}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Destino">
                    <span>Destino: </span>
                    <p>{callInfo?.dst || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="Audio">
                    <span>Audio: </span>
                    <br />
                    {callInfo !== null ? (
                      <AudioStream
                        ReportService={ReportService}
                        uniqueid={callInfo?.uniqueid}
                        disabled={
                          callInfo?.disposition === 'ANSWERED' ? false : true
                        }
                        reasonDisabled={
                          callInfo?.disposition === 'ANSWERED'
                            ? ''
                            : 'Não atendida'
                        }
                      />
                    ) : (
                      <p>Não possui audio</p>
                    )}
                  </Col>
                </Row>
              </CallContainer>
            )}
          </RelationsWrapper>
        )}
      </div>
    </Modal>
  );
};

export default ModalContactAttemptDetails;
