import React from 'react';
import './style.css';

import { individual_column_style } from 'assets/styles/table_custom_style';
import { Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { IconsChannel } from 'components/IconsChannel/IconsChannel';
import { useHistory } from 'react-router-dom';
import { HistoryChatBotMOdal } from '../modal/HistoryModal';
import { TotalRegister } from 'components/TotalRegister';

const Analytical = ({ data, watson, queues, totalRegister = 0 }) => {
  const history = useHistory();

  return (
    <Card className="mt-5">
      <TotalRegister totalRegister={totalRegister} />

      <ToolkitProvider
        data={data || []}
        keyField="_id"
        columns={[
          {
            text: 'Watson',
            formatter: (_, row) => {
              return watson[row?.watsonId];
            },
            sort: true,
            style: individual_column_style
            //headerStyle: individual_header_style
          },

          {
            text: 'Canal',
            formatter: (_, row) => {
              return <IconsChannel channel={row?.channel} />;
            },
            sort: true,
            style: individual_column_style
            //headerStyle: individual_header_style
          },

          {
            text: 'Fila',
            formatter: (_, row) => {
              return queues[row?.queue];
            },
            sort: true,
            style: individual_column_style
            //headerStyle: individual_header_style
          },

          {
            text: 'Cliente',
            formatter: (_, row) => {
              return (
                <div
                  style={{
                    cursor: 'pointer'
                  }}
                  className="mouse-link-contact"
                  onClick={() => {
                    history.push(
                      `/admin/crm/contactForm/${row?.clientRecordType}/${row?.clientId}`
                    );
                  }}
                >
                  {row?.clientName}
                </div>
              );
            },
            sort: true,
            style: individual_column_style
            //headerStyle: individual_header_style
          },

          {
            text: 'Data de inicio',
            formatter: (_, row) => {
              return new Date(row?.createdAt).toLocaleString();
            },
            sort: true,
            style: individual_column_style
            //headerStyle: individual_header_style
          },

          {
            text: 'Última interação',
            formatter: (_, row) => {
              return row?.options[row?.options.length - 1]?.value;
            },
            sort: true,
            style: individual_column_style
            //headerStyle: individual_header_style
          },

          {
            text: 'Fluxo',
            formatter: (_, row) => {
              return <HistoryChatBotMOdal options={row?.options} />;
            },
            sort: true,
            style: individual_column_style
            //headerStyle: individual_header_style
          }
        ]}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
            />
          </div>
        )}
      </ToolkitProvider>
    </Card>
  );
};
export default Analytical;
