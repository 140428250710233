import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from 'reactstrap';

import ReactLoader from 'components/Loader';
import Pagination from 'components/Pagination';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import HandleContactsModal from './components/HandleContactsModal';
import HandleAllContactsModal from './components/HandleAllContactsModal';
import ChatBroadcastListService from '../service';
import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import useDebounce from '../../ServicePanel/components/QueueContent/components/Contact/ContactActiveModal/hooks/useDebaunce';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import { useSocketConversationInstagram } from 'hooks/useSocketConversationInstagram';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import { format, parseISO } from 'date-fns';
import ModalTextWhats from './components/ModalTextWhats';
import TimelineFiltroHeader from './components/Header';
export const ChatBroadcastListList = () => {
  const [chatBroadcastList, setChatBroadcastList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [idChangeStatus, setIdChangeStatus] = useState();
  const [idResource, setidResource] = useState();
  const [idExecute, setIdExecute] = useState();

  const [
    openModalConfirmationToggleStatus,
    setOpenModalConfirmationToggleStatus
  ] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isAllContactModalOpen, setIsAllContactModalOpen] = useState(false);
  const [executingBroadcastList, setExecutingBroadcastList] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [openModalExecuteConfirmation, setOpenModalExecuteConfirmation] =
    useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const { socketInstagramConversation } = useSocketConversationInstagram();

  useEffect(() => {
    return () => {
      socketInstagramConversation.close();
    };
  }, [socketInstagramConversation]);

  const handleGetChatBroadcastList = useCallback(
    (selectedPage = 1) => {
      FlexChatIntegration.getBroadcastListPagination(selectedPage, showDisable)
        .then((res) => {
          const data = res.data.registers;
          const quantity = res.data.count;
          if (quantity) {
            setChatBroadcastList([...data]);
            setQuantity(quantity);
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response &&
            error.response.data &&
            error.response.data.message;
          toast.error(errorMessage || 'Erro ao listar listas de transmissão');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [showDisable]
  );

  const setListStatusSocket = useCallback(
    (id, status) => {
      if (chatBroadcastList.length === 0) {
        return;
      }
      let copyList = [...chatBroadcastList];

      let listaIndex = copyList.findIndex((lista) => {
        return lista.id === +id;
      });
      if (listaIndex >= 0) {
        if (copyList[listaIndex].status === status) {
          return;
        }
        copyList[listaIndex].status = status;

        setChatBroadcastList([]);
        setChatBroadcastList([...copyList]);
      }
    },
    [chatBroadcastList, setChatBroadcastList]
  );

  const executeBroadcastList = useCallback((broadcastListId) => {
    if (executingBroadcastList) return;
    setExecutingBroadcastList(true);

    FlexChatIntegration.executeBroadcastList({
      idBroadcastList: broadcastListId
    })
      .then(() => {
        toast.success('Lista de transmissão iniciada com sucesso');
      })
      .catch((err) => {
        toast.error(
          err.response.data.message ?? 'Erro ao inicar lista de transmissão'
        );
      })
      .finally(() => {
        setExecutingBroadcastList(false);
        setOpenModalExecuteConfirmation(false);
      });
  }, []);

  const pauseBroadcastList = useCallback((broadcastListId) => {
    setExecutingBroadcastList(true);

    ChatBroadcastListService.pauseBroadcastList({
      broadcastListId
    })
      .then(() => {
        toast.success('Lista de transmissão pausada com sucesso');
      })
      .catch((err) => {
        toast.error(
          err.response.data.message ?? 'Erro ao inicar lista de transmissão'
        );
      })
      .finally(() => {
        setExecutingBroadcastList(false);
      });
  }, []);

  const resumeBroadcastList = useCallback((broadcastListId) => {
    setExecutingBroadcastList(true);

    ChatBroadcastListService.resumeBroadcastList({
      broadcastListId
    })
      .then(() => {
        toast.success('Lista de transmissão despausada com sucesso');
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || 'Erro ao despausar lista de transmissão'
        );
      });

    setExecutingBroadcastList(false);
  }, []);

  useEffect(() => {
    handleGetChatBroadcastList();
  }, [handleGetChatBroadcastList]);

  useEffect(() => {
    socketInstagramConversation.on('connect', () => {
      console.log('conectou');
    });
    socketInstagramConversation.on('lista_de_transmissao', async (data) => {
      setListStatusSocket(data.id, data.status);
    });
  }, [socketInstagramConversation, chatBroadcastList, setListStatusSocket]);

  const handlePageChange = (Page) => {
    setActivePage(Page);
    handleGetChatBroadcastList(Page, showDisable);
  };

  // const handleDeleteChatBroadcastList = useCallback(
  //   (id) => {
  //     ChatBroadcastListService.delete(id)
  //       .then(() => {
  //         toast.success('Campanha Ativa Whatsapp excluída');
  //       })
  //       .catch((error) => {
  //         const errorMessage =
  //           error.response &&
  //           error.response.data &&
  //           error.response.data.message;
  //         toast.error(errorMessage || 'Erro ao exluir campanha');
  //       })
  //       .finally(() => {
  //         setOpenModalConfirmation(false);
  //         handleGetChatBroadcastList();
  //       });
  //   },
  //   [handleGetChatBroadcastList]
  // );

  // const controlDelete = (id) => {
  //   setidResource(id);
  //   setOpenModalConfirmation(true);
  // };

  const controlExecute = (id) => {
    setIdExecute(id);
    setOpenModalExecuteConfirmation(true);
  };

  const handleOpenDashboard = (id) => {
    window.open(
      `${window.location.origin}/callback/broadcast-list/${id}/dashboard`,
      '_blank',
      'width=' +
        window.screen.availWidth +
        ',height=' +
        window.screen.availHeight
    );
  };

  const handleGetChatBroadcastListFiltered = useCallback(
    (selectedPage = 1) => {
      setLoading(true);
      if (debouncedSearchValue === '') {
        setActivePage(1);
        handleGetChatBroadcastList((selectedPage = 1), showDisable);
        return;
      }
      FlexChatIntegration.getBroadcastListPagination(
        selectedPage,
        showDisable,
        debouncedSearchValue
      )
        .then((res) => {
          const data = res.data.registers;
          const quantity = res.data.count;
          if (quantity) {
            setChatBroadcastList([...data]);
            setQuantity(quantity);
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response &&
            error.response.data &&
            error.response.data.message;
          toast.error(errorMessage || 'Erro ao listar listas de transmissão');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [debouncedSearchValue, handleGetChatBroadcastList]
  );

  useEffect(() => {
    handleGetChatBroadcastListFiltered();
  }, [handleGetChatBroadcastListFiltered]);

  const controlToggleStatus = (row) => {
    setIdChangeStatus(row);
    setOpenModalConfirmationToggleStatus(true);
  };

  const changeBroadcastListStatus = (row) => {
    FlexChatIntegration.changeBroadcastListStatus(row?.id)
      .then(() => {
        !row.is_disabled
          ? toast.success('Campanha Ativa Whatsapp Desabilitada com Sucesso')
          : toast.success('Campanha Ativa Whatsapp Habilitada com Sucesso');

        handleGetChatBroadcastList(activePage, showDisable);
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || 'Ops, algo errado aconteceu!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      })
      .finally(() => {
        setOpenModalConfirmationToggleStatus(false);
      });
  };

  const handleShowDisableds = (value) => {
    setShowDisable(value);
    setActivePage(1);
    handleGetChatBroadcastList(1, value);
  };

  return (
    <>
      <TimelineFiltroHeader
        setCreate="/admin/chat/broadcast-list/form"
        name="Form validation"
        parentName="Forms"
        setIsAllContactModalOpen={setIsAllContactModalOpen}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Campanha Ativa Whatsapp</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Lista de Transmissão"
                          type="text"
                          onChange={(event) =>
                            setSearchValue(event.target.value)
                          }
                        />
                      </InputGroup>
                    </Col>
                    <Col md="4" className="text-right mt-2">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisable}
                            onChange={() => handleShowDisableds(!showDisable)}
                          />
                          <span>Mostrar desabilitados</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={chatBroadcastList || []}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'title',
                      text: 'Título',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style
                    },
                    {
                      dataField: 'created_at',
                      text: 'Data de criação',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        if (!row.created_at) return '-';

                        const inputDateString = row.created_at;

                        const dateObject = parseISO(inputDateString);

                        return format(dateObject, 'dd/MM/yyyy');
                      }
                    },
                    {
                      dataField: 'message',
                      text: 'Informações',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        if (
                          !chatBroadcastList.some((item) => item.isAutomatic)
                        ) {
                          row.isAutomatic = undefined;
                        }
                        return <ModalTextWhats row={row}></ModalTextWhats>;
                      }
                    },
                    {
                      dataField: 'execute',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Executar',
                      formatExtraData: executingBroadcastList,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        if (row.status === 'livre') {
                          return (
                            <Button
                              color="success"
                              size="sm"
                              title="Executar"
                              disabled={formatExtraData}
                              onClick={() => controlExecute(row.id)}
                            >
                              <i className="fas fa-play-circle"></i>
                            </Button>
                          );
                        }
                        if (row.status === 'pausado') {
                          return (
                            <Button
                              color="primary"
                              size="sm"
                              title="Despausar"
                              disabled={formatExtraData}
                              onClick={() => {
                                resumeBroadcastList(row.id);
                              }}
                            >
                              <i className="fas fa-play-circle"></i>
                            </Button>
                          );
                        }
                        if (row.status === 'executando') {
                          return (
                            <Button
                              color="danger"
                              size="sm"
                              title="Pausar"
                              disabled={formatExtraData}
                              onClick={() => {
                                pauseBroadcastList(row.id);
                              }}
                            >
                              <i className="fas fas fa-stop-circle"></i>
                            </Button>
                          );
                        }
                      }
                    },
                    {
                      text: 'Dashboard',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="success"
                            title="Dashboard"
                            size="sm"
                            onClick={() =>
                              handleOpenDashboard(row.id, row.title)
                            }
                          >
                            <i className="fas fa-chart-pie"></i>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      formatter: (_, row) => {
                        return (
                          <Link
                            to={`/admin/chat/broadcast-list/form/${row.id}`}
                          >
                            <Button title="Editar" color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },
                    {
                      dataField: 'contacts',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Contatos',
                      formatter: (_, row) => {
                        return (
                          <>
                            <Button
                              color="info"
                              size="sm"
                              title="Contatos"
                              onClick={() => {
                                setidResource(row.id);
                                setIsContactModalOpen(true);
                              }}
                            >
                              <li className="fas fa-address-card"></li>
                            </Button>
                          </>
                        );
                      }
                    },
                    {
                      dataField: 'is_disabled',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Habilitar / Desabilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={!row.is_disabled}
                            id={row.title}
                            onChange={() => controlToggleStatus(row)}
                          />
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmationToggleStatus}
        onModalToggle={setOpenModalConfirmationToggleStatus}
        unmountOnClose={true}
        idResource={idChangeStatus}
        deleteResource={changeBroadcastListStatus}
        message={
          idChangeStatus?.is_disabled
            ? 'Tem certeza que deseja habilitar essa Campanha Ativa Whatsapp?'
            : 'Tem certeza que deseja desabilitar essa Campanha Ativa Whatsapp?'
        }
      />
      <ModalConfirmation
        isModalOpen={openModalExecuteConfirmation}
        onModalToggle={setOpenModalExecuteConfirmation}
        unmountOnClose={true}
        idResource={idExecute}
        deleteResource={executeBroadcastList}
        executingBroadcastList={executingBroadcastList}
        message={'Executar campanha ativa Whatsapp?'}
      />
      <HandleContactsModal
        isModalOpen={isContactModalOpen}
        onModalToggle={setIsContactModalOpen}
        unmountOnClose={true}
        broadcastListId={idResource}
      />
      <HandleAllContactsModal
        isModalOpen={isAllContactModalOpen}
        onModalToggle={setIsAllContactModalOpen}
        unmountOnClose={true}
        broadcastListId={idResource}
      />
    </>
  );
};
