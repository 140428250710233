import React from 'react';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style,
  individual_icon_header_style,
  icon_column_style
} from 'assets/styles/table_custom_style';

import { TotalRegister } from '../../../../../components/TotalRegister';
import EditModal from '../assets/components/EditModal';
import ConversationDetails from 'components/Conversation/ConversationDetails';
import { TagChatClient } from 'components/TagChatClient';
import { IconsChannel } from 'components/IconsChannel/IconsChannel';
const Analytical = ({ dataReport, totalRegister }) => {
  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={dataReport.conversas || []}
                keyField="_id"
                columns={[
                  {
                    dataField: 'atendente.name',
                    text: 'Usuário',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'atendimentoBot',
                    text: 'CPF/CNPJ',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (_, row) => {
                      if (row.resumoBot) {
                        const resumos = row.resumoBot;
                        const KEYCPF = 'CPF';
                        const KEYCPFANDCNPF = 'CPF/CNPJ';
                        let cpfCnpj = '';

                        for (const resumo of resumos) {
                          if (
                            resumo.id === KEYCPF ||
                            resumo.id === KEYCPFANDCNPF
                          ) {
                            cpfCnpj = resumo.value;
                          }
                        }

                        return cpfCnpj;
                      } else {
                        return '';
                      }
                    }
                  },

                  {
                    dataField: 'cliente.nome',
                    text: 'Cliente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tags',
                    text: 'Tags do Cliente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (cell) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            maxWidth: '13.75rem',
                            width: '13.75rem',
                            wordWrap: 'break-word',
                            paddingTop: '0.6rem',
                            backgroundColor: '#fff'
                          }}
                        >
                          {cell?.length > 0 &&
                            cell.map((tag, index) => {
                              return (
                                <TagChatClient
                                  key={index}
                                  name={tag?.name}
                                  isMore={false}
                                  color={tag?.color}
                                  disabled={false}
                                  index={index}
                                  sizeText="auto"
                                  inPopover={true}
                                />
                              );
                            })}
                        </div>
                      );
                    }
                  },
                  {
                    dataField: 'cliente.telefone',
                    text: 'Telefone',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'fila',
                    text: 'Fila',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'hora_criacao',
                    text: 'Início da conversa',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (rowContent) => {
                      const data = new Date(rowContent);
                      return `${data.toLocaleDateString(
                        'pt-br'
                      )} - ${data.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'canal',
                    text: 'Canal',
                    formatter: (_, row) => {
                      return <IconsChannel channel={row?.canal} />;
                    },
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'apelidoCanal',
                    text: 'Nome do Canal',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'observacao',
                    text: 'Observação',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (cell) => (
                      <div
                        style={{
                          maxWidth: '500px',
                          overflowX: 'auto',
                          scrollbarWidth: 'thin'
                        }}
                      >
                        {cell}
                      </div>
                    )
                  },
                  {
                    dataField: 'actions',
                    text: 'Ações',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          <ConversationDetails conversationData={row} />
                          <EditModal conversationData={row} />
                        </>
                      );
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};

export default Analytical;
