import React, { useState, useEffect } from 'react';
import DefaultModal from 'components/Modal/DefaultModal';
import { Row, Input, Col } from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import ReactLoader from 'components/Loader';
import ContactService from '../service/index';
import FlexChatIntegrationService from '../service/FlexChatIntegration/index';

import QueueService from '../../../../../../../../src/views/Queue/service/index';

import { toast } from 'react-toastify';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';

import {
  BsFillImageFill,
  BsCameraVideoFill,
  BsChatDotsFill
} from 'react-icons/bs';

import { ColTemplate } from './style';

import { SingleTemplateModal } from '../SingleTemplateModal';
import { AiFillFilePdf } from 'react-icons/ai';
import { useCallback } from 'react';
import FlexSelect from 'components/Inputs/FlexSelect';
import { SearchTemplateWhatsapp } from '../Function';

const TemplatesModal = ({
  contactId,
  isModalOpen,
  onModalToggle,
  clientNumber,
  clientName,
  clientId,
  isIsolated = false,
  isTryingToCreateAContactAttempt = false,
  setChatCreatedOnIsolatedMessageId,
  isMultiContact,
  selectedContacts,
  setSelectedContacts,
  shouldGetData = true,
  ...props
}) => {
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const [templates, setTemplates] = useState([]);
  const [filtertemplate, setfiltertemplate] = useState('');

  const filterarray = templates.filter((template) =>
    SearchTemplateWhatsapp({ inputValue: filtertemplate, template })
  );

  const [clientActiveSession, setClientActiveSession] = useState(false);
  const loading = false;
  const setLoading = () => {};
  const [literealMessage, setLiterealMessage] = useState('');
  const [queues, setQueues] = useState();
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [whatsappList, setWhatsappList] = useState([]);
  const whatsappOptions = whatsappList.map((item) => {
    return { value: item.id, label: item.nome_canal };
  });

  const [selectedWhatsappId, setSelectedWhatsappId] = useState('');

  async function sendSessionMessage() {
    try {
      const usrSocketId = socketConversation.id;
      const messageData = {
        message: literealMessage
      };

      await ContactService.startActiveContactConversation(
        contactId,
        usrSocketId,
        messageData
      );
      onModalToggle(false);
      toast.success('Mensagem enviada');
    } catch (err) {
      toast.error('Erro ao enviar mensagem');
    }
  }

  const getQueues = async () => {
    QueueService.getAll()
      .then((response) => {
        const newQueues = response.data.data.map((queue) => {
          return {
            label: queue.name,
            value: queue.id,
            is_chat: queue.is_chat
          };
        });

        setQueues(newQueues);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    if (clientNumber) {
      setLoading(true);
      // getStatusClientSession();
    }
  }, [clientNumber]);

  const getTemplates = useCallback(() => {
    if (!selectedWhatsappId) return;
    setLoading(true);
    FlexChatIntegrationService.getContatoAtivo(selectedWhatsappId)
      .then((response) => {
        const templatesData = response.data.data;
        if (templatesData) {
          setTemplates(templatesData);
        }
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao carregar os templates');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isIsolated, selectedWhatsappId]);

  const getWhatsapp = () => {
    FlexChatIntegrationService.getWhatsapp()
      .then((res) => {
        setWhatsappList(res?.data?.data ?? []);
      })
      .catch(() => {
        toast.error('Erro ao buscar whatsapp');
      });
  };

  const getSynchronizedTemplates = async () => {
    setLoading(true);
    FlexChatIntegrationService.syncTemplates()
      .then(() => {
        getTemplates();
      })
      .catch(() => {
        // toast.error('Ocorreu um erro ao sincronizar os templates');
      });
  };

  useEffect(() => {
    if (!shouldGetData) return;
    getSynchronizedTemplates();
    getWhatsapp();
    getQueues();
  }, [shouldGetData]);

  useEffect(() => {
    getTemplates();
  }, [selectedWhatsappId]);

  const handleOnClick = (value) => {
    setSelectedTemplate(value);
    setIsTemplateModalOpen(true);
  };

  const Icons = {
    IMAGE: <BsFillImageFill />,
    VIDEO: <BsCameraVideoFill />,
    DOCUMENT: <AiFillFilePdf />,
    TEXT: <BsChatDotsFill />
  };

  const Colors = {
    DOCUMENT: '#d92222d1',
    IMAGE: '#2dce89',
    VIDEO: '#11cdef',
    TEXT: 'rgb(54 54 96)'
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Mensagens predefinidas"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      {loading ? (
        <Row className="justify-content-md-center py-3">
          <ReactLoader />
        </Row>
      ) : (
        <>
          <Row>
            <Col className="mb-5">
              <FlexSelect
                isClearable={true}
                dataOptions={whatsappOptions}
                isMulti={false}
                closeMenuOnSelect={true}
                value={selectedWhatsappId}
                valueController={(_, value) => {
                  setLoading(true);
                  setSelectedWhatsappId(value);
                }}
                fieldName="queueIds"
                labelName="label"
                valueName="value"
                minHeight="1rem"
              />
            </Col>
            {templates && templates.length ? (
              <Col>
                <Input
                  onChange={(event) => {
                    setfiltertemplate(event.target.value);
                  }}
                  placeholder="Buscar Template..."
                  style={{ height: '40px' }}
                />
              </Col>
            ) : null}
            {clientActiveSession ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Input
                  value={literealMessage}
                  onChange={(e) => setLiterealMessage(e.target.value)}
                  placeholder="Digite a mensagem"
                  style={{ fontSize: 25, margin: 20 }}
                ></Input>
                <Input
                  value={literealMessage}
                  onChange={(e) => setLiterealMessage(e.target.value)}
                  placeholder="Digite a mensagem"
                  style={{ fontSize: 25, margin: 20 }}
                ></Input>
                <ReactButton
                  color="primary"
                  onClick={() => sendSessionMessage()}
                >
                  Enviar mensagem
                </ReactButton>
              </div>
            ) : templates && templates.length ? (
              <>
                {filterarray.map((template, templateIndex) => {
                  return (
                    <>
                      <ColTemplate
                        sm="12"
                        onClick={() => {
                          handleOnClick(template);
                        }}
                        color={Colors[template.type]}
                        style={{ cursor: 'pointer' }}
                        key={templateIndex}
                        className={`colTemplateMessageHover${templateIndex}`}
                        title={template.description}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between'
                          }}
                        >
                          <h4>{template.name}</h4>

                          {Icons[template.type] ?? '!'}
                        </div>
                      </ColTemplate>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  Selecione um canal para visualizar os templates.
                </div>
              </>
            )}
          </Row>
        </>
      )}

      <SingleTemplateModal
        isModalOpen={isTemplateModalOpen}
        setIsModalOpen={setIsTemplateModalOpen}
        template={selectedTemplate}
        queues={queues}
        contactId={contactId}
        clientId={clientId}
        isIsolated={isIsolated}
        clientNumber={clientNumber}
        clientName={clientName}
        channelId={selectedWhatsappId}
        setChatCreatedOnIsolatedMessageId={setChatCreatedOnIsolatedMessageId}
        isTryingToCreateAContactAttempt={isTryingToCreateAContactAttempt}
        isMultiContact={isMultiContact}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
      />
    </DefaultModal>
  );
};

export default TemplatesModal;
