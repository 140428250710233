/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from 'react';
import makeAnimated from 'react-select/animated';

import { Chip } from '@material-ui/core';
import Select from 'react-select';
import { MdAccessAlarms } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Col, Input, Row } from 'reactstrap';
import { ChipTime, ContentCheckbox } from '../style';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import QueueService from '../../../../Queue/service';
import { Form } from '../../channels/modals/facebook/style';
import { ButtonChat } from 'views/ChatConfiguration/components/buttons';
import { toast } from 'react-toastify';
import FlexChatIntegrationService from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration/index';
import NewService from '../../../service/newService';

const ModalCreate = ({ info, modifyStateModal, setTimeoutConfigs }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const dados = useSelector((state) => state.createObject.data);
  const [initialState, setInitialState] = useState(info);
  const [Config, setConfig] = useState([]);
  const [queues, setQueues] = useState([]);
  const [channels, setChannels] = useState([]);
  const animatedComponents = makeAnimated();
  useEffect(() => {
    QueueService.getAll()
      .then((response) => {
        const newQueues = response.data.data.map((queue) => {
          return {
            label: queue.name,
            value: queue.id
          };
        });
        setQueues(newQueues);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });

    FlexChatIntegrationService.getAllChannels()
      .then((result) => {
        const dataChannels = [];
        const dataResponse = result.data.data;
        Object.keys(dataResponse).map((channelName) => {
          if (channelName === 'facebook' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].descricao,
              value: result.data.data[channelName].id
            });
          }
          if (channelName === 'sms' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].descricao,
              value: result.data.data[channelName].id
            });
          }

          if (channelName === 'instagram' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].descricao,
              value: result.data.data[channelName].id
            });
          }

          if (channelName === 'whatsapp') {
            result.data.data[channelName].map((channelWpp) => {
              if (channelWpp.id)
                dataChannels.push({
                  label: channelWpp.descricao,
                  value: channelWpp.id
                });
            });
          }

          if (channelName === 'telegram' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].descricao,
              value: result.data.data[channelName].id
            });
          }

          if (channelName === 'webchat' && dataResponse[channelName].id) {
            dataChannels.push({
              label:
                result.data.data[channelName].descricao ||
                result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].id
            });
          }
        });
        setChannels(dataChannels);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    setInitialState(info);
    setConfig([dados]);
  }, [info, dados]);

  function setStateModal(event) {
    event.preventDefault();
    modifyStateModal(false);
  }

  function controlToggleActive(control) {
    setInitialState({ ...initialState, ignoraFinalDeSemana: control });
  }

  function sendObject(event) {
    event.preventDefault();
    NewService.createTimeout(initialState)
      .then((response) => {
        setStateModal(event);
        if (response.data) {
          setTimeoutConfigs(response.data.data);
          toast.success('Timeout criado  com sucesso!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          toast.error(error.response.data.message || 'Ops, ocorreu um erro', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  }

  function setDataModal(event, option, name) {
    if (name === 'inTheQueues') {
      const labels = event ? event.map((option) => option.label) : [];
      setInitialState({
        ...initialState,
        inTheQueues: labels
      });
    } else if (name === 'channels') {
      const labels = event ? event.map((option) => option.label) : [];
      setInitialState({
        ...initialState,
        channels: labels
      });
    } else if (event?.target?.name === 'ignoraFinalDeSemana') {
      setInitialState({
        ...initialState,
        [event.target.name]: event.target.checked
      });
    } else if (
      event?.target?.name === 'limit' ||
      event?.target?.name === 'timeout'
    ) {
      setInitialState({
        ...initialState,
        [event.target.name]:
          parseInt(event.target.value) > 0 ? parseInt(event.target.value) : ''
      });
    } else {
      setInitialState({
        ...initialState,
        [event.target.name]: event.target.value
      });
    }
  }
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 46,
      color: '#8898aa',
      backgroundColor: '#fff',
      border: '1px solid #dee2e6'
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#8898aa',
        fontSize: '0.875rem'
      };
    }
  };
  return (
    <>
      <Form>
        <Row className="row">
          <Col md="4">
            <label>Tipo:</label>
            <Input
              type="select"
              onChange={setDataModal}
              name="typeTimeout"
              //defaultValue={initialState.tipoTimeout}
              value={initialState.typeTimeout}
            >
              <option value="SucessoAtendimento">Sucesso de Atendimento</option>
              <option value="Ociosidade">Ociosidade</option>
            </Input>
          </Col>
          <Col md="4">
            <label>Regra:</label>
            <Input
              onChange={setDataModal}
              type="select"
              name="typeOfAttendant"
              value={initialState.typeOfAttendant}
            >
              <option value="Atendente">Atendente</option>
              <option value="Chatbot">Chatbot</option>
            </Input>
          </Col>
          <Col md="4">
            <label>Canais: </label>
            <Select
              placeholder="Selecione um canal..."
              components={animatedComponents}
              styles={customStyles}
              options={channels?.map((option) => {
                return {
                  label: option?.label,
                  value: option?.label
                };
              })}
              isMulti={true}
              name="channels"
              onChange={(event, option) =>
                setDataModal(event, option, 'channels')
              }
              defaultValue={initialState?.channels?.map((value) => {
                return { label: value, value };
              })}
              isClearable={true}
              isSearchable={true}
              isLoading={false}
              isRtl={false}
              closeMenuOnSelect={false}
            ></Select>
          </Col>
        </Row>

        <Row>
          <Col md="8">
            <label>Fila(s) de Origem</label>
            <Select
              placeholder="Selecione ..."
              components={animatedComponents}
              styles={customStyles}
              //className="select"
              options={queues.map((option) => {
                return {
                  label: option.label,
                  value: option.label
                };
              })}
              isMulti
              name="inTheQueues"
              onChange={(event, option) =>
                setDataModal(event, option, 'inTheQueues')
              }
              defaultValue={initialState?.inTheQueues.map((value) => {
                return { label: value, value };
              })}
              isClearable={true}
              isSearchable={true}
              isLoading={false}
              isRtl={false}
              closeMenuOnSelect={false}
            ></Select>
          </Col>
          <Col
            md={`${initialState.action !== 'encerrar' ? 2 : 4}`}
            style={{
              marginBottom: '10px'
            }}
          >
            <label>Ação:</label>
            <Input
              type="select"
              onChange={setDataModal}
              name="action"
              value={initialState.action}
            >
              <option value="encerrar">Encerrar</option>
              <option value="transferir">Transferir</option>
            </Input>
          </Col>
          {initialState.action !== 'encerrar' && (
            <Col md="2">
              <label>Transferir para:</label>
              <Input
                type="select"
                onChange={setDataModal}
                name="transferQueue"
                value={initialState.transferQueue}
              >
                <option value="">Selecione uma transferência</option>5
                {queues &&
                  queues.map((queue) => (
                    <option key={queue.value} value={queue.label}>
                      {queue.label}
                    </option>
                  ))}
              </Input>
            </Col>
          )}
        </Row>
        <Row
          style={{
            marginTop: '20px'
          }}
        >
          <Col
            md="9"
            style={{
              marginBottom: '20px'
            }}
          >
            <label>{`Lembrete de ${
              initialState.action === 'encerrar'
                ? 'Encerramento'
                : 'Transferência'
            }:`}</label>
            <Input
              type="textarea"
              onChange={setDataModal}
              value={initialState.reminderMessage}
              defaultValue={initialState.reminderMessage}
              name="reminderMessage"
            />
          </Col>
          {initialState.typeOfAttendant === 'Chatbot' ? (
            <ChipTime>
              <Col>
                <Chip
                  avatar={<MdAccessAlarms color="white" />}
                  size="small"
                  className="chipTime"
                  label="TEMPO EM MINUTOS"
                />
                <Input
                  defaultValue={initialState.limit}
                  type="number"
                  name="limit"
                  min={1}
                  max={1440}
                  value={initialState.limit}
                  onChange={(e) => {
                    setDataModal(e, null, 'limit');
                  }}
                />
              </Col>
            </ChipTime>
          ) : (
            <ChipTime>
              <Col>
                <Chip
                  avatar={<MdAccessAlarms color="white" />}
                  size="small"
                  className="chipTime"
                  label="TEMPO EM MINUTOS"
                />
                <Input
                  defaultValue={initialState.limit}
                  type="number"
                  name="limit"
                  min={1}
                  value={initialState.limit}
                  onChange={(e) => {
                    setDataModal(e, null, 'limit');
                  }}
                />
              </Col>
            </ChipTime>
          )}
        </Row>
        <Row
          style={{
            marginTop: '20px',
            marginBottom: '20px'
          }}
        >
          <Col
            md="9"
            style={{
              marginBottom: '20px'
            }}
          >
            <label>{`Mensagem de ${
              initialState.action === 'encerrar'
                ? 'Encerramento'
                : 'Transferência'
            }:`}</label>

            <Input
              type="textarea"
              onChange={setDataModal}
              defaultValue={initialState.closingMessage}
              name="closingMessage"
            />
          </Col>
          {initialState.typeOfAttendant === 'Chatbot' ? (
            <ChipTime>
              <Col>
                <Chip
                  avatar={<MdAccessAlarms color="white" />}
                  size="small"
                  className="chipTime"
                  label="TEMPO EM MINUTOS"
                />
                <Input
                  defaultValue={initialState.timeout}
                  type="number"
                  name="timeout"
                  min={1}
                  max={1440}
                  onChange={setDataModal}
                />
              </Col>
            </ChipTime>
          ) : (
            <ChipTime>
              <Col>
                <Chip
                  avatar={<MdAccessAlarms color="white" />}
                  size="small"
                  className="chipTime"
                  label="TEMPO EM MINUTOS"
                />
                <Input
                  defaultValue={initialState.timeout}
                  type="number"
                  name="timeout"
                  min={1}
                  onChange={setDataModal}
                />
              </Col>
            </ChipTime>
          )}
        </Row>

        <Row>
          <ContentCheckbox color={systemConfiguration.primary_color}>
            <Col>
              <label className="titleMsg">IGNORAR FINAL DE SEMANA</label>
              {initialState.ignoreWeekend === true ? (
                <Chip size="small" className="chipTrue" label="HABILITADO" />
              ) : (
                <Chip size="small" className="chipFalse" label="DESABILITADO" />
              )}
            </Col>

            <ToggleSwitch
              checked={initialState.ignoreWeekend}
              id={'ativado'}
              onChange={() => controlToggleActive(!initialState.ignoreWeekend)}
            />
          </ContentCheckbox>
        </Row>
        <Row className="row">
          <Col md="12" className="button">
            <ButtonChat
              action={(event) => sendObject(event)}
              name={'Salvar'}
              color={systemConfiguration.confirmation_button_color}
            />
            <ButtonChat
              action={(event) => setStateModal(event)}
              name={'Fechar'}
              color={systemConfiguration.cancelation_button_color}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export { ModalCreate };
