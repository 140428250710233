import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { dateRules } from './assets';
import { Alert } from 'reactstrap';
import { CardCabecalho } from './styled';
import ScoreboardService from './service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Loader from 'react-loader-spinner';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody
} from 'reactstrap';

import { format } from 'date-fns';

import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import CardItem from './components/CardItem';
import { Line } from 'react-chartjs-2';
import Service from '../../../../views/ChatConfiguration/service';
import FlexSelect from 'components/Inputs/FlexSelect';

const config = {
  type: 'line',
  options: {
    scales: {
      xAxis: {
        type: 'time'
      }
    }
  }
};

const ConversationReport = () => {
  const { control, handleSubmit, errors, setValue, watch, reset, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [imageMediaScoreUsoIntencao, setImageMediaScoreUsoIntencao] =
    useState();
  const [imageUsoIntencao, setImageUsoIntencao] = useState();
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState();
  const showAllConversations = localStorage.getItem('show_all_conversations');
  const login = localStorage.getItem('login');
  const [dataChart, setDataChart] = useState({});
  const [custoPorConversa, setCustoPorConversa] = useState(0);
  const [cotacaoDolar, setCotacaoDolar] = useState(0);
  const [channels, setChannels] = useState([]);
  const [valorEmDolar, setValorEmDolar] = useState(0);
  const [valorEmReal, setValorEmReal] = useState(0);

  const [messageAlert, setShowAlert] = useState({
    active: false,
    message: ''
  });

  async function loadDashboard(data) {
    setIsLoadingDashboard(true);

    function formatLabels(labels) {
      return labels.map((day) => format(new Date(day), 'dd/MM'));
    }

    await ScoreboardService.getScoreboardFastApi({
      dataFinal: data.dataFinal,
      dataInicial: data.dataInicial
    })
      .then((res) => {
        const imgMediaScoreUsoIntencaoBase64 =
          res.data.mediaScoreUsoIntencaoBase64;
        const imgUsoIntencaoBase64 = res.data.usoIntencaoBase64;
        setImageMediaScoreUsoIntencao(
          'data:image/png;base64,' + imgMediaScoreUsoIntencaoBase64
        );
        setImageUsoIntencao('data:image/png;base64,' + imgUsoIntencaoBase64);
      })
      .catch((err) => {
        setIsLoadingDashboard(false);
        console.log(err);
      });

    await ScoreboardService.getScoreboard({
      dataFinal: data.dataFinal,
      dataInicial: data.dataInicial,
      idChannel: data.idChannel
    })
      .then((res) => {
        let conversasByChannel = {};

        res.data.conversas.map((conversa) => {
          conversasByChannel[
            conversa.idCanal ? conversa.idCanal : conversa.id_canal
          ] =
            (conversasByChannel[
              conversa.idCanal ? conversa.idCanal : conversa.id_canal
            ] || 0) + 1;
        });

        let valuesByChannel = {};

        channels.map((channel) => {
          Object.keys(conversasByChannel).map((key) => {
            if (channel.id === key) {
              valuesByChannel[key] =
                conversasByChannel[key] * channel.custoPorConversa || 0;
            }
          });
        });

        let somaValorEmDolar = 0;
        let somaValorEmReal = 0;

        Object.keys(valuesByChannel).map((channelId) => {
          channels.map((channel) => {
            if (channel.id === channelId) {
              somaValorEmDolar += valuesByChannel[channelId];
              somaValorEmReal +=
                valuesByChannel[channelId] * channel.cotacaoDolar;
            }
          });
        });

        setValorEmDolar(somaValorEmDolar);
        setValorEmReal(somaValorEmReal);

        //Calcular por cada canal
        let dataDay = [];
        let dataLabel = [];

        res.data.conversasPorDia.map((conversa) => {
          dataLabel.push(conversa.slice(0, 11));
          dataDay.push(conversa.slice(12, conversa.length).split(' '));
        });
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];

        let formatedLabels = formatLabels(dataLabel);

        dataDay.map((data) => {
          arr1.push(data[0]);
          arr2.push(data[1]);
          arr3.push(data[2]);
        });

        setDataChart({
          mainBoard: {
            quantidadeDeConversas: res.data.quantidadeDeConversas,
            quantidadeDeMensagens: res.data.quantidadeMensagens,
            mediaMensagensPorConversa: res.data.mediaMsgPorConversa,
            maximoConversas: res.data.maxConversasDia.numConversas,
            data:
              res.data.maxConversasDia.data?.length > 0
                ? format(new Date(res.data.maxConversasDia.data), 'dd/MM/yyyy')
                : '',
            compreensaoFraca: res.data.msgsScoreBaixo
          },
          totalMensagens: {
            labels: formatedLabels,
            datasets: [
              {
                label: 'Mensagens',
                data: arr2,
                fill: false,
                backgroundColor: ['rgb(54, 162, 235)'],
                tension: 0
                // borderDash: [4] // <- Linha tracejada
              }
            ]
          },
          mediaMensagensPorConversa: {
            labels: formatedLabels,
            datasets: [
              {
                label: 'Mensagens',
                data: arr3,
                fill: false,
                backgroundColor: ['rgb(54, 162, 235)'],
                tension: 0
                // borderDash: [4] // <- Linha tracejada
              }
            ]
          },
          totalConversas: {
            labels: formatedLabels,
            datasets: [
              {
                label: 'Conversas',
                data: arr1,
                fill: false,
                backgroundColor: ['rgb(54, 162, 235)'],
                tension: 0
                // borderDash: [4] // <- Linha tracejada
              }
            ]
          }
        });

        setIsLoadingDashboard(false);
        setDashboard(true);
      })
      .catch((err) => {
        setIsLoadingDashboard(false);
        console.log(err);
      });
  }
  useEffect(() => {
    Service.getAll()
      .then((response) => {
        if (response.data) {
          let semCotacao = true;
          let semCustoPorConversa = true;

          response.data.channels.whatsapp.forEach((item) => {
            if (item.cotacaoDolar) semCotacao = false;
            if (item.custoPorConversa) semCustoPorConversa = false;
          });

          setChannels(response.data.channels.whatsapp);
          if (semCotacao || semCustoPorConversa) {
            setShowAlert({
              active: true,
              message:
                'Para visualizar os dados monetários do chatbot, é necessário configurar a cotação do dólar e o custo por conversa.'
            });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar as respostas do bot!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleAlert = () => {
    setShowAlert({ active: false, message: '' });
  };

  const clearData = () => {
    setDashboard('');
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${YYYY}-${MM}-${DD}`;
  };

  const getDataDTO = (data) => {
    let formatedData;

    formatedData = {
      ...data,
      dataInicial: `${toISOFormat(data.dataInicial)}`,
      dataFinal: `${toISOFormat(data.dataFinal)}`,
      idChannel: data.channel
    };
    return formatedData;
  };

  const getValuesFromMoneyReport = (idChannel = null, channels) => {
    if (idChannel) {
      const channel = channels.find((channel) => channel.id === idChannel);
      setCustoPorConversa(Number(channel.custoPorConversa));
      setCotacaoDolar(Number(channel?.cotacaoDolar));
      return;
    }
    setCustoPorConversa(0);
    setCotacaoDolar(0);
  };

  const onSubmit = (data) => {
    //setCustoPorConversa(dados.whatsapp.custoPorConversa);
    //setCotacaoDolar(dados.whatsapp.cotacaoDolar);
    clearData();
    try {
      const formatedData = getDataDTO(data);
      getValuesFromMoneyReport(data.channel, channels);
      loadDashboard(formatedData);
      //const formatedType = getFormatedType(reportType.value);
      //getReports(formatedType, formatedData, 1, true);
    } catch (err) {
      console.log(err);
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const cleanFilter = () => {
    reset({
      dataInicial: new Date().toLocaleDateString('pt-br'),
      dataFinal: new Date().toLocaleDateString('pt-br'),
      fila: '',
      encerradaPor: '',
      group: '',
      atendente: showAllConversations === 'true' ? '' : login,
      nome: '',
      situacao: '',
      atendimentoBot: '',
      status: '',
      sucessoAtendimento: '',
      isMobile: '',
      satisfacao: '',
      canal: '',
      conteudoDaMensagem: ''
    });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Dados do Chatbot - Whatsapp</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row className="mb-3"></Row>

                        <Row className="mb-3">
                          <Col md="4">
                            <label className="form-control-label">
                              Data inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataInicial"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataInicial"
                              rules={dateRules}
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataInicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="4">
                            <label className="form-control-label">
                              Data final*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataFinal"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataFinal"
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                              rules={{
                                ...dateRules,
                                validate: (value) => {
                                  if (
                                    new Date(toISOFormat(value, true)) <
                                    new Date(
                                      toISOFormat(
                                        getValues()['dataInicial'],
                                        true
                                      )
                                    )
                                  ) {
                                    return 'A data final não pode ser anterior à inicial!';
                                  } else {
                                    return true;
                                  }
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataFinal"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="4">
                            <label className="form-control-label">Canal</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={true}
                                  dataOptions={channels}
                                  isMulti={false}
                                  closeMenuOnSelect={false}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="channel"
                                  labelName="nome_canal"
                                  valueName="id"
                                  minHeight="1rem"
                                />
                              )}
                              control={control}
                              name="channel"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="channel"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Button
                          style={buttonStyle(systemConfiguration.primary_color)}
                          type="submit"
                          disabled={isLoadingDashboard}
                          //onClick={() => loadWordCloud()}
                        >
                          {isLoadingDashboard ? (
                            <Row className="justify-content-md-center">
                              <Loader
                                type="ThreeDots"
                                color={'white'}
                                height={18}
                                width={48}
                              />
                            </Row>
                          ) : (
                            'Buscar'
                          )}
                        </Button>
                        <Button
                          onClick={cleanFilter}
                          style={buttonStyle(
                            systemConfiguration.cancelation_button_color
                          )}
                        >
                          Limpar
                        </Button>
                      </Form>
                      {isLoadingDashboard ? (
                        <Row className="justify-content-md-center">
                          <Loader
                            type="TailSpin"
                            color={systemConfiguration.primary_color}
                            height={100}
                            width={100}
                          />
                        </Row>
                      ) : (
                        dashboard && (
                          <>
                            <Row>
                              <Col md="12">
                                <Alert
                                  color="info"
                                  isOpen={messageAlert.active}
                                  toggle={toggleAlert}
                                >
                                  {messageAlert.message}
                                </Alert>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="6" xl="4">
                                <CardItem
                                  title="Total de conversas"
                                  text={
                                    dataChart.mainBoard.quantidadeDeConversas
                                  }
                                  subText={`
                                  US$
                                    ${valorEmDolar.toFixed(2)}
                                     - R$
                                    ${valorEmReal.toFixed(2)} `}
                                  tooltip="O número total de conversas entre usuários ativos e seu aplicativo, somado as conversas de campanhas, durante o período selecionado."
                                  icon="fa fa-info-circle"
                                />
                              </Col>
                              <Col md="6" xl="4">
                                <CardItem
                                  title="Média de msg por conversa"
                                  text={
                                    dataChart.mainBoard
                                      .mediaMensagensPorConversa
                                  }
                                  tooltip="A média de mensagens por conversa é o total de mensagens recebidas durante o intervalo de datas selecionado dividido pelo total de conversas durante o intervalo de datas selecionado."
                                  icon="fa fa-info-circle"
                                />
                              </Col>
                              <Col md="6" xl="4">
                                <CardItem
                                  title="Máximo de conversas"
                                  text={dataChart.mainBoard.maximoConversas}
                                  subText={dataChart.mainBoard.data}
                                  tooltip="Máx. de conversas é o número máximo de conversas que ocorreram durante um único intervalo de tempo dentro do intervalo de datas selecionado."
                                  icon="fa fa-info-circle"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12" xl="12">
                                <Card>
                                  <CardHeader>
                                    <CardCabecalho>
                                      <div>
                                        <h5 className="h3 mb-0">
                                          Total de conversas
                                        </h5>
                                        <h6 className="surtitle">POR: Dia</h6>
                                      </div>
                                      <h5 className="h3 mb-0">
                                        Total:{' '}
                                        {
                                          dataChart.mainBoard
                                            .quantidadeDeConversas
                                        }
                                      </h5>
                                    </CardCabecalho>
                                  </CardHeader>
                                  <CardBody>
                                    <div className="chart">
                                      <Line
                                        data={dataChart.totalConversas}
                                        options={config}
                                        className="chart-canvas"
                                        id="chart-bars"
                                      />
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6" xl="6">
                                <Card>
                                  <CardHeader>
                                    <CardCabecalho>
                                      <div>
                                        <h5 className="h3 mb-0">
                                          Média mensagem por conversa
                                        </h5>
                                        <h6 className="surtitle">POR: Dia</h6>
                                      </div>
                                      <h5 className="h3 mb-0">
                                        Média:{' '}
                                        {
                                          dataChart.mainBoard
                                            .mediaMensagensPorConversa
                                        }
                                      </h5>
                                    </CardCabecalho>
                                  </CardHeader>
                                  <CardBody>
                                    <div className="chart">
                                      <Line
                                        data={
                                          dataChart.mediaMensagensPorConversa
                                        }
                                        options={config}
                                        className="chart-canvas"
                                        id="chart-bars"
                                      />
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md="6" xl="6">
                                <Card>
                                  <CardHeader>
                                    <CardCabecalho>
                                      <div>
                                        <h5 className="h3 mb-0">
                                          Total de mensagens
                                        </h5>
                                        <h6 className="surtitle">POR: Dia</h6>
                                      </div>
                                      <h5 className="h3 mb-0">
                                        Total:{' '}
                                        {
                                          dataChart.mainBoard
                                            .quantidadeDeMensagens
                                        }
                                      </h5>
                                    </CardCabecalho>
                                  </CardHeader>

                                  <CardBody>
                                    <div className="chart">
                                      <Line
                                        data={dataChart.totalMensagens}
                                        options={config}
                                        className="chart-canvas"
                                        id="chart-bars"
                                      />
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            {imageMediaScoreUsoIntencao && (
                              <Row>
                                <Col md="6" xl="6">
                                  <Card>
                                    <img
                                      alt="imageMediaScoreUsoIntencao"
                                      src={imageMediaScoreUsoIntencao}
                                    />
                                  </Card>
                                </Col>
                                <Col md="6" xl="6">
                                  <Card>
                                    <img
                                      alt="imageUsoIntencao"
                                      src={imageUsoIntencao}
                                    />
                                  </Card>
                                </Col>
                              </Row>
                            )}
                          </>
                        )
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConversationReport;
