import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service';
import ContextService from '../service/contextService';
import PjsipService from '../service/pjsipService';
import { toast } from 'react-toastify';
import { ddrRegex } from './regex';

import { CodecValues } from './codecs';
import FlexSelect from '../../../components/Inputs/FlexSelect';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import { ScreenPrompt } from 'components/ScreenPrompt';

const Iax2Form = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [context, setContext] = useState([]);
  const [pjsip, setPjsip] = useState([]);

  const { id } = props.match.params;

  //set to select context
  useEffect(() => {
    ContextService.getAll()
      .then((res) => {
        setContext(res.data.data);
      })
      .catch(() => {
        toast.error('Erro ao tentar carregar os contextos', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, []);

  //set to select pjsip
  useEffect(() => {
    PjsipService.getAll()
      .then((res) => {
        setPjsip(res.data.data);
      })
      .catch(() => {
        toast.error('Erro ao tentar carregar os ramais', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          let getTronco = res.data.data;
          const getCodec = getTronco.codec;
          const codecsUpdate = CodecValues.filter((item) => {
            return getCodec.includes(item.name);
          });
          const codecsUpdateId = codecsUpdate.map((code) => {
            return code.id;
          });

          getTronco = { ...getTronco, codec: codecsUpdateId };

          reset(getTronco);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Tronco', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const dataCodec = data.codec;
      const codecsSubmit = CodecValues.filter((item) => {
        return dataCodec.includes(item.id);
      });

      const dataSubmit = { ...data, codec: codecsSubmit, type: 'PJSIP' };
      setLoading(true);
      isUpdate
        ? await Service.update(id, dataSubmit)
        : await Service.create(dataSubmit);
      history.push('/admin/trunks');
      if (isUpdate) {
        toast.info('Tronco foi atualizado com sucesso!');
      } else {
        toast.success('Tronco foi cadastrado com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/trunks');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/trunks');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/trunks"
        buttonTitle="Voltar para a pagina de troncos"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Tronco PJSIP</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Nome*</label>

                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'Este campo é obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            DDR inicial
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="initial_ddr"
                            rules={{
                              // required: 'Email é um campo obrigatório',
                              pattern: {
                                value: ddrRegex,
                                message: 'DDR deve possuir 4, 8 ou 10 números'
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="initial_ddr"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Usuário</label>

                          <Controller
                            as={Input}
                            control={control}
                            name="username"
                            rules={{
                              // required: 'Nome é um campo obrigatório',
                              validate: (value) => {
                                if (String(value).length > 50)
                                  return 'Usuário deve possuir no máximo 50 caracteres';
                                return true;
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="username"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            DDR final
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="final_ddr"
                            rules={{
                              // required: 'Email é um campo obrigatório',
                              pattern: {
                                value: ddrRegex,
                                message: 'DDR deve possuir 4, 8 ou 10 números'
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="final_ddr"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Senha</label>

                          <Controller
                            as={Input}
                            type="password"
                            control={control}
                            name="secret"
                            rules={{
                              validate: (value) => {
                                if (String(value).length > 30)
                                  return 'Senha deve possuir no máximo 30 caracteres';
                                return true;
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="secret"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Servidor*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="trunk_server"
                            rules={{
                              required: 'Este campo é obrigatório',
                              validate: (value) => {
                                const regex =
                                  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):[0-9]+$/g;
                                return regex.test(value)
                                  ? true
                                  : 'O IP precisa estar no padrão IPv4 com a porta separada por :';
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="trunk_server"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Número da chave*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="key_number"
                            rules={{
                              required: 'Este campo é obrigatório',
                              validate: (value) => {
                                if (String(value).length > 11)
                                  return 'Número da Chave deve possuir no máximo 11 caracteres';
                                return true;
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="key_number"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Contexto*
                          </label>

                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {context.map((contextData, index) => (
                                  <option key={index} value={contextData.id}>
                                    {contextData.name}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="context"
                            rules={{
                              required: 'Selecione um contexto'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="context"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">Codecs*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={CodecValues}
                                isMulti={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="codec"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            rules={{
                              required: 'Este campo é obrigátorio'
                            }}
                            control={control}
                            name="codec"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="codec"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Configuração*
                          </label>

                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {pjsip.map((configPjsip, index) => (
                                  <option key={index} value={configPjsip.id}>
                                    {configPjsip.name}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="pjsip_configuration_id"
                            rules={{
                              required: 'Selecione uma configuração'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="pjsip_configuration_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Iax2Form;
