/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import HourMaskCenter from '../../../components/Inputs/HourMaskCenter.js';
import ReactButton from 'components/Buttons/ReactButton';
import UserService from '../../User/service';
import ReactLoader from 'components/Loader';
import pauseOptions from '../../../variables/pause';
import ScaleService from '../service';
import PauseService from '../../Pause/service/index';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  Col,
  Input,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorFormMessage } from './components/ErrorFormMessage';
import { useRequestUsers } from 'hooks/useUserContext.js';
import { IconLogoHeader } from '../style/style';
import { BsFillCalendarFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { ScreenPrompt } from 'components/ScreenPrompt/index.js';

export default function ScaleForm(props) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_PROXY_URL, {
        path: process.env.REACT_APP_SCALE_PATH_SOCKET
      }),
    []
  );
  const [showDisabledsun, setShowDisablesun] = useState(false);
  const [showDisabledmon, setShowDisablemon] = useState(false);
  const [showDisabledtue, setShowDisabletue] = useState(false);
  const [showDisabledwed, setShowDisablewed] = useState(false);
  const [showDisabledthu, setShowDisablethu] = useState(false);
  const [showDisabledfri, setShowDisablefri] = useState(false);
  const [showDisabledsat, setShowDisablesat] = useState(false);
  const [exceptions, setExceptions] = useState([]);

  const [exceptionErrors, setExceptionErrors] = useState([]);
  const [pauses, setPauses] = useState([]);
  const [pausesList, setPausesList] = useState([]);
  const [reference, setReference] = useState('ENTRY');
  const [duration, setDuration] = useState([]);
  const [interval, setInterval] = useState([]);
  const [toleranceTime, setToleranceTime] = useState(null);
  const [enableUnpauseCheck, setEnableUnpauseCheck] = useState(false);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const columns = [
    'Pausa',
    'Referência',
    'Intervalo para acionamento(Minutos)',
    'Duração(Minutos)',
    'Tempo de Tolerância(Segundos)',
    'Remover'
  ];

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const { id } = props.match.params;

  const loadAPI = useCallback(async () => {
    ScaleService.get(id).then((response) => {
      const getScale = response.data.data;
      const arraytest = [];
      const formatgetScale = response.data.data.users.forEach((response) => {
        arraytest.push(response.login);
      });
      reset({ ...getScale, userLogins: arraytest });
      setExceptions(getScale.automaticPauses);
      setShowDisablesun(getScale.sunday);
      setShowDisablemon(getScale.monday);
      setShowDisabletue(getScale.tuesday);
      setShowDisablewed(getScale.wednesday);
      setShowDisablethu(getScale.thursday);
      setShowDisablefri(getScale.friday);
      setShowDisablesat(getScale.saturday);
      setIsUpdate(true);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reset]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      loadAPI();
    }
  }, [id, loadAPI]);

  useEffect(() => {
    const Params = {
      page: 0,
      size: 0,
      showDisable: true,
      query: ''
    };

    PauseService.getAllSeach(
      Params.page,
      Params.size,
      Params.query,
      Params.showDisable
    )
      .then((response) => {
        const listPause = response.data.data.map((pause) => {
          return {
            label: pause.name,
            value: pause.id
          };
        });
        setPauses(listPause);
      })
      .catch((error) => {
        toast.error(error.response?.data.error[0]);
      });
  }, []);
  const errorFormMessage = (message) => <ErrorFormMessage message={message} />;
  const history = useHistory();

  const hourRules = {
    pattern: {
      value: /^[0-2][0-9]:[0-5][0-9]$/,
      message: 'Hora inválida!'
    }
  };
  const cancelAction = () => {
    history.push('/admin/Scales');
  };

  const onSubmit = async (data) => {
    try {
      const FormatData = {
        ...data,
        sunday: showDisabledsun,
        monday: showDisabledmon,
        tuesday: showDisabledtue,
        wednesday: showDisabledwed,
        thursday: showDisabledthu,
        friday: showDisabledfri,
        saturday: showDisabledsat,
        automaticPauses: exceptions
      };
      const dataField = FormatData;

      if (id) {
        await ScaleService.put(id, dataField).then(() => {
          toast.info('Escala foi atualizada com sucesso!');
          history.push('/admin/Scales');
        });
      } else {
        await ScaleService.post(dataField).then(() => {
          toast.success('Escala foi cadastrada com sucesso!');
          history.push('/admin/Scales');
        });
      }
    } catch (err) {
      toast.error(err.response?.data?.errors[0]);
    }
  };
  const removeHoliday = (event, index) => {
    event.preventDefault();
    let array = exceptions;
    array.splice(index, 1);
    setExceptions([...array]);
  };
  const toggle = () => {
    setModal(!modal);
    setExceptionErrors([]);
  };

  const addPause = async (event) => {
    event.preventDefault();
    if (pausesList.length === 0) {
      const data = {
        pauseId: pauses[0].value,
        pauseName: pauses[0].label,
        intervalReference: reference,
        triggerInterval: interval,
        duration: duration,
        toleranceTime,
        enableUnpauseCheck
      };

      try {
        const copy = [...exceptions];
        copy.push(data);
        setExceptions(copy);
        toggle();
      } catch (error) {
        toast.error(error);
      }
    } else {
      const data = {
        pauseId: pausesList.pauseValue,
        pauseName: pausesList.pauseName,
        intervalReference: reference,
        triggerInterval: interval,
        duration: duration,
        toleranceTime,
        enableUnpauseCheck
      };
      try {
        exceptions.push(data);
        toggle();
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  <IconLogoHeader
                    backgroundColor={systemConfiguration.primary_color}
                  >
                    <BsFillCalendarFill color="white" size={20} />
                  </IconLogoHeader>
                  <h3>Escalas</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{ required: 'Nome é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Tolerância de Login (Minutos)*
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="loginTolerance"
                            rules={{ required: 'Tolerância de Login!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="loginToleance"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <h4 className="mb-3">Dias Trabalhados</h4>
                        </Col>
                      </Row>
                      <Row className="mb-5" style={{ textAlign: 'center' }}>
                        <Col className="col-sm">
                          <h5
                            style={{
                              marginTop: '2.7rem',
                              fontSize: '0.800rem',
                              fontWeight: '600',
                              color: '#525f7f'
                            }}
                          >
                            Horário inicial:
                          </h5>
                          <h5
                            style={{
                              marginTop: '3rem',
                              fontSize: '0.800rem',
                              fontWeight: '600',
                              color: '#525f7f'
                            }}
                          >
                            Horário final:
                          </h5>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>
                              <input
                                type="checkbox"
                                checked={showDisabledmon}
                                className="checkbox-round"
                                onChange={() =>
                                  setShowDisablemon(!showDisabledmon)
                                }
                                style={{
                                  marginRight: '0.5rem'
                                }}
                              />
                              Segunda
                            </h5>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="beginTimeMonday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="beginTimeMonday"
                              rules={hourRules}
                              defaultValue="08:00"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="beginTimeMonday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="endTimeMonday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="endTimeMonday"
                              rules={hourRules}
                              defaultValue="23:00" //
                            />
                            <ErrorMessage
                              errors={errors}
                              name="endTimeMonday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>
                              <input
                                type="checkbox"
                                checked={showDisabledtue}
                                className="checkbox-round"
                                onChange={() =>
                                  setShowDisabletue(!showDisabledtue)
                                }
                                style={{ marginRight: '0.5rem' }}
                              />
                              Terça
                            </h5>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="beginTimeTuesday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="beginTimeTuesday"
                              rules={hourRules}
                              defaultValue="08:00" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="beginTimeTuesday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="endTimeTuesday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="endTimeTuesday"
                              rules={hourRules}
                              defaultValue="23:00" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="endTimeTuesday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>
                              <input
                                type="checkbox"
                                checked={showDisabledwed}
                                className="checkbox-round"
                                onChange={() =>
                                  setShowDisablewed(!showDisabledwed)
                                }
                                style={{ marginRight: '0.5rem' }}
                              />
                              Quarta
                            </h5>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="beginTimeWednesday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="beginTimeWednesday"
                              rules={hourRules}
                              defaultValue="08:00" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="beginTimeWednesday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="endTimeWednesday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="endTimeWednesday"
                              rules={hourRules}
                              defaultValue="23:00" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="endTimeWednesday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>
                              <input
                                type="checkbox"
                                checked={showDisabledthu}
                                className="checkbox-round"
                                onChange={() =>
                                  setShowDisablethu(!showDisabledthu)
                                }
                                style={{ marginRight: '0.5rem' }}
                              />
                              Quinta
                            </h5>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="beginTimeThursday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="beginTimeThursday"
                              rules={hourRules}
                              defaultValue="08:00" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="beginTimeThursday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="endTimeThursday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="endTimeThursday"
                              rules={hourRules}
                              defaultValue="23:00" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="endTimeThursday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>
                              <input
                                type="checkbox"
                                checked={showDisabledfri}
                                className="checkbox-round"
                                onChange={() =>
                                  setShowDisablefri(!showDisabledfri)
                                }
                                style={{ marginRight: '0.5rem' }}
                              />
                              Sexta
                            </h5>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="beginTimeFriday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="beginTimeFriday"
                              rules={hourRules}
                              defaultValue="08:00" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="beginTimeFriday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="endTimeFriday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="endTimeFriday"
                              rules={hourRules}
                              defaultValue="23:00" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="endTimeFriday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>
                              <input
                                type="checkbox"
                                checked={showDisabledsat}
                                className="checkbox-round"
                                onChange={() =>
                                  setShowDisablesat(!showDisabledsat)
                                }
                                style={{ marginRight: '0.5rem' }}
                              />
                              Sabado
                            </h5>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="beginTimeSaturday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="beginTimeSaturday"
                              rules={hourRules}
                              defaultValue="08:00" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="beginTimeSaturday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="endTimeSaturday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="endTimeSaturday"
                              rules={hourRules}
                              defaultValue="23:00" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="endTimeSaturday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>
                              <input
                                type="checkbox"
                                checked={showDisabledsun}
                                className="checkbox-round"
                                onChange={() =>
                                  setShowDisablesun(!showDisabledsun)
                                }
                                style={{ marginRight: '0.5rem' }}
                              />
                              Domingo
                            </h5>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="beginTimeSunday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="beginTimeSunday"
                              rules={hourRules}
                              defaultValue="08:00" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="beginTimeSunday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMaskCenter
                                  valueController={setValue}
                                  fieldName="endTimeSunday"
                                  value={props.value}
                                  placeholder=""
                                />
                              )}
                              control={control}
                              name="endTimeSunday"
                              rules={hourRules}
                              defaultValue="23:00" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="endTimeSunday"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <h3 className="mb-1">Pausas</h3>
                        </Col>
                        <Col style={{ marginLeft: '50rem' }}>
                          <ReactButton
                            btnColor="confirmation"
                            size="sm"
                            onClick={toggle}
                          >
                            Adicionar
                          </ReactButton>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="col-md-12">
                          <Table>
                            <thead>
                              <tr>
                                {columns.map((column, index) => (
                                  <th key={index}>{column}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {exceptions.map((exception, index) => {
                                return (
                                  <tr key={index}>
                                    {exception.pause?.name ? (
                                      <td>{exception.pause.name}</td>
                                    ) : (
                                      <td>{exception.pauseName}</td>
                                    )}
                                    <td>
                                      {exception.intervalReference === 'ENTRY'
                                        ? 'Após a entrada'
                                        : 'Antes da saída'}
                                    </td>
                                    <td>{exception.triggerInterval}</td>
                                    <td>{exception.duration}</td>
                                    <td>{exception.toleranceTime}</td>
                                    <td>
                                      <Button
                                        size="sm"
                                        color="danger"
                                        onClick={(event) => {
                                          removeHoliday(event, index);
                                        }}
                                      >
                                        <li className="fas fa-trash"></li>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '3rem', marginLeft: 'auto' }}>
                        <ReactButton btnColor="confirmation" type="submit">
                          Salvar
                        </ReactButton>
                        <ReactButton
                          btnColor="cancelation"
                          onClick={() => cancelAction()}
                        >
                          Cancelar
                        </ReactButton>
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle}>Pausa automática</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <h5>Pausa</h5>
              <Input
                type="select"
                name="pauseId"
                onChange={(e) => {
                  const newPause = JSON.parse(e.target.value);
                  setPausesList(newPause);
                }}
              >
                {pauses.map((pause, key) => {
                  return (
                    <option
                      key={key}
                      value={JSON.stringify({
                        pauseName: pause.label,
                        pauseValue: pause.value
                      })}
                    >
                      {pause.label}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Col md="8">
              <h5>Referência</h5>
              <Input
                type="select"
                name="intervalReference"
                onChange={(e) => {
                  setReference(e.target.value);
                }}
              >
                {pauseOptions.map((pause, index) => {
                  return (
                    <option key={index} value={pause.value}>
                      {pause.label}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Col md="12">
              <h5>Intervalo para acionamento(Minutos)</h5>
              <Input
                name="intervalReference"
                onChange={(e) => setInterval(e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Col md="12">
              <h5>Duração(Minutos)</h5>
              <Input
                name="duration"
                onChange={(e) => setDuration(e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Col md="12">
              <label className="form-control-label">
                Tempo de Tolerância* (Segundos)
              </label>
              <Input
                type="number"
                disabled={!enableUnpauseCheck}
                name="toleranceTime"
                onChange={(e) => setToleranceTime(e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem' }} className="ml-1">
            <Col md="12">
              <label className="form-control-label">
                <Input
                  type="checkbox"
                  onChange={(e) => {
                    setEnableUnpauseCheck(e.target.checked);
                  }}
                  checked={enableUnpauseCheck}
                />
                Habilitar bloqueio da remoção da pausa após horario
              </label>
            </Col>
          </Row>
          <Row className="mt-3 ml-1">
            <ReactButton btnColor="confirmation" onClick={addPause}>
              Salvar
            </ReactButton>

            <ReactButton btnColor="cancelation" onClick={toggle}>
              Cancelar
            </ReactButton>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
