/* eslint-disable react/display-name */
import { ErrorMessage } from '@hookform/error-message';
import { Tooltip } from '@material-ui/core';
import Select from 'react-select';
import { custom_select } from 'assets/styles/multiple_select';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Col, Form, Input, Row } from 'reactstrap';
import xlsxImage from '../../../../../assets/img/xlsx.png';
import pdfImage from '../../../../../assets/img/pdf.png';
import {
  ImageButton,
  ImageButtonConteinerProtocol,
  ImageSize
} from '../../../../../assets/styles/image_style';
import DatePicker from '../../../../../components/Inputs/DatePicker';
import { useListReasonByRecordType } from '../../../../reason/list/hooks/useListReasonByRecordType';
import { useUsers } from '../../../../Report/Protocol/hooks/useUsers';
import { useListStatusByRecordType } from '../../../../status/list/hooks/useListStatusByRecordType';
import { functionsUseFul } from '../../assets/functionsUseFul';
import { ErrorFormMessage } from '../ErrorFormMessage';
import { useMemorizeProtocolsFilters } from './useMemorizeProtocolsFilters';
import FlexSelect from 'components/Inputs/FlexSelect';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import AdvancedSearch from 'views/Report/Protocol/Analytical/components/AdvancedSearchForm';
import { useProtocolFieldsByRecordType } from 'views/fieldConfigs/hooks/useProtocolFieldsByRecordType';
import { UserFlexSelect } from '../../../../../components/UserFlexSelect';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { ItemPerPageSelect } from 'components/Inputs/ItemPerPageSelect';
import { useMultipleSubReason } from 'views/Report/Protocol/hooks/useMultipleSubReason';
import { useSearchAccounts } from 'views/CRM/Account/hooks/useSearchAccounts';
import SelectAccount from 'react-select/async';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import channels from 'variables/channels';
import { toast } from 'react-toastify';
import ProtocolService from '../../../../Protocol/service';
import ModelUpdateMultProtocols from 'views/Protocol/updateForm/components/ModalUpdateMultProtocols';

// eslint-disable-next-line react/display-name
const FiltersForm = React.forwardRef(
  (
    {
      defaultFields,
      getProtocols,
      canSeeAllProtocols,
      dateRules,
      getMediaPath,
      quantity,
      isDisableClickToCall,
      recordTypes,
      selectedRecordType,
      handleSelectedRecordTypeChange,
      setShowTable = () => {},
      showTable = 'TABLE',
      setActivePage = () => {},
      sortOrder,
      orderByFilter,
      activePage,
      itemsPerPage,
      setItemsPerPage,
      typeReportSelected,
      setTypeReportSelected,
      setDataGrafics,
      setLoaderGrafics,
      ids,
      hasChangedRecordType,
      setHasChangedRecordType,
      callBackShowAdvancedFilters = () => {}
    },
    ref
  ) => {
    const {
      control,
      handleSubmit,
      getValues,
      setValue,
      errors,
      register,
      reset,
      watch
    } = useForm();
    const typesReports = ['Analítico', 'Sintético'];
    const modifyReportSelected = (param) => {
      setTypeReportSelected(param.target.value);
    };
    const { getMemorizedFilters, memorizeFilters, clearMemorizedFilters } =
      useMemorizeProtocolsFilters();
    const [usersShowDisable, setUsersShowDisable] = useState(true);
    const { userList } = useUsers(usersShowDisable);
    const { status, getStatusByRecordType } = useListStatusByRecordType(
      selectedRecordType.value
    );

    const { reasons, getReasonsByRecordType } = useListReasonByRecordType(
      selectedRecordType.value
    );
    const { subreasons, loadingSubreasons, handleGetSubReasons } =
      useMultipleSubReason();

    const getSelectedRecordTypeId = () => {
      return selectedRecordType.value;
    };
    const { protocolCustomFields } = useProtocolFieldsByRecordType(
      getSelectedRecordTypeId()
    );

    const [filtersProtocol, setFiltersProtocol] = useState({});
    const [openModalUpdateMultProtocols, setOpenModalUpdateMultProtocols] =
      useState(false);
    const [showAdvancedSearchForm, setShowAdvancedSearchForm] = useState(false);
    const systemConfiguration = useSelector(
      (state) => state.systemConfiguration
    );
    const {
      getDateBegin,
      disabledDownloadButtonCsvAndPdf,
      definedMessageGeneratedInPdfCsvDownloads
    } = functionsUseFul();
    const limitGenerationPDF = parseInt(
      process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
    );
    const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
    const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
    const [areDefaultFiltersSet, setAreDefaultFiltersSet] = useState(false);

    const defaultPageAndSize = { page: 1, size: itemsPerPage };
    const { isSearching, filterAccounts } = useSearchAccounts();
    const [account, setAccount] = useState();

    const [canalOptions, setCanalOptions] = useState([]);

    const limitGenerationXLSX = 40000;

    const getChannels = useCallback(() => {
      FlexChatIntegration.getAllChannels().then((res) => {
        const info = res.data.data;

        let array = [];

        channels.forEach((nome) => {
          if (!info[nome]) return;
          if (nome === 'whatsapp') {
            info[nome].forEach((item, index) => {
              array.push(info[nome][index] ?? []);
            });
            return;
          }
          array.push(info[nome] ?? []);
        });

        const list = array.map((canal) => {
          return { label: canal?.nome_canal, value: canal?.id };
        });

        setCanalOptions(list);
      });
    }, [channels]);

    useEffect(() => {
      getStatusByRecordType();
    }, [getStatusByRecordType]);

    useEffect(() => {
      getReasonsByRecordType();
    }, [getReasonsByRecordType]);

    useEffect(() => {
      getChannels();
    }, [getChannels]);

    useEffect(() => {
      const filters = getMemorizedFilters();
      if (filters?.selectedRecordType) {
        handleSelectedRecordTypeChange(filters.selectedRecordType);
      }
      if (filters?.reason_id) {
        handleGetSubReasons(filters.reason_id);
      }
      if (filters) {
        reset(filters);
      }
      setAreDefaultFiltersSet(true);
    }, [getMemorizedFilters, reset]);

    useEffect(() => {
      const message = definedMessageGeneratedInPdfCsvDownloads(
        quantity,
        limitGenerationPDF,
        limitGenerationXLSX,
        filtersProtocol
      );

      setMessagemGeneratePdf(message.pdfMessage);
      setMessagemGenerateCsv(message.xlsxMessage);
    }, [
      quantity,
      limitGenerationPDF,
      definedMessageGeneratedInPdfCsvDownloads,
      filtersProtocol
    ]);

    const handleSaveFilters = useCallback(() => {
      setActivePage(1);
      if (areDefaultFiltersSet) {
        memorizeFilters({ ...getValues(), selectedRecordType });
      }
    }, [
      setActivePage,
      areDefaultFiltersSet,
      memorizeFilters,
      getValues,
      selectedRecordType
    ]);

    const onSubmit = useCallback(
      async (dataSubmit) => {
        if (account?.value) {
          dataSubmit.account_id = account?.value;
        }
        const dataDTO = formatCustomFieldsToSubmit(
          dataSubmit,
          protocolCustomFields ?? []
        );

        if (typeReportSelected === 'Analítico') {
          setFiltersProtocol(dataDTO);

          setActivePage(activePage);
          const dataToSubmitWithOrder = {
            ...dataSubmit,
            order_by: orderByFilter,
            order_type: sortOrder
          };
          if (showTable === 'TABLE' && !hasChangedRecordType) {
            if (Object.keys(dataSubmit).length > 0) {
              getProtocols({
                ...dataToSubmitWithOrder,
                ...defaultPageAndSize
              });
            }
            return;
          }
          getProtocols({ ...dataSubmit, size: itemsPerPage }, false);
          return;
        } else {
          try {
            setLoaderGrafics(true);

            const dataToSend = {};
            Object.keys(dataDTO).forEach((key) => {
              if (dataDTO[key] === 0 || !!dataDTO[key]) {
                dataToSend[key] = dataDTO[key];
              }
            });

            const response = await ProtocolService.getReportProtocol(
              { ...dataToSend, recordType: selectedRecordType.value },
              'synthetic'
            );
            const responseData = response?.data.content;
            setDataGrafics({ ...responseData });

            setLoaderGrafics(false);
            //totalProtocolsPerStatus vai vir nulo quando o campo "status" do protocolo estiver desaivado
          } catch (err) {
            setLoaderGrafics(false);
            toast.error('Erro desconhecido por favor, informar o suporte!', {
              autoClose: 3000,
              closeOnClick: true
            });
          }
        }

        return;
      },
      [
        typeReportSelected,
        getProtocols,
        orderByFilter,
        setActivePage,
        showTable,
        sortOrder,
        itemsPerPage,
        setDataGrafics,
        setLoaderGrafics,
        account?.value,
        hasChangedRecordType
      ]
    );

    useEffect(() => {
      handleSubmit(onSubmit)();
    }, [handleSubmit, onSubmit, isDisableClickToCall]);

    const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
      const [date] = dateTimeString.split(' ');
      const [DD, MM, YYYY] = date.split('/');
      if (bar) return `${YYYY}/${MM}/${DD}`;
      return `${YYYY}-${MM}-${DD}`;
    }, []);

    const cleanFilters = () => {
      const dateBegin = getDateBegin();
      const dateEnd = new Date().toLocaleDateString('pt-br');

      reset({
        dateBegin,
        dateEnd,
        current_responsible: '',
        reason_id: '',
        subreason_id: '',
        status_id: '',
        current_status: '',
        protocolTextQuery: '',
        clientTextQuery: ''
      });
      clearMemorizedFilters();

      getProtocols({ dateBegin, dateEnd });
    };

    useImperativeHandle(ref, () => ({
      cleanFilters() {
        cleanFilters();
      }
    }));

    const handleDownloadClick = (fileType) => {
      const url = getMediaPath(fileType);
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };

      fetch(url, {
        method: 'GET',
        headers: headers
      })
        .then((res) => {
          if (!res.ok) throw new Error('Ocorreu um erro ao gerar o arquivo.');

          return res.blob();
        })
        .then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `relatorio-protocolos-${new Date().toLocaleDateString(
            'pt-br'
          )}.${fileType}`;
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const getSelectOptionsDTO = () => {
      if (!recordTypes) return [];
      return recordTypes.map((group) => ({
        label: group.name,
        value: group.id
      }));
    };

    return (
      defaultFields &&
      !!Object.keys(defaultFields).length && (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col md="6">
              <label className="form-control-label">Tipo de registro</label>
              <Select
                styles={custom_select}
                placeholder="Selecione..."
                options={getSelectOptionsDTO() || []}
                isSearchable={false}
                isClearable={false}
                name="recordType"
                defaultValue=""
                value={selectedRecordType}
                onChange={handleSelectedRecordTypeChange}
              />
            </Col>
            <Col md="6">
              <label className="form-control-label">Responsável atual</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={userList}
                    isMulti={true}
                    isClearable={true}
                    value={props.value}
                    closeMenuOnSelect={true}
                    valueController={setValue}
                    fieldName="current_responsible"
                    labelName="name"
                    valueName="login"
                  />
                )}
                disabled={!canSeeAllProtocols}
                control={control}
                name="current_responsible"
                defaultValue=""
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6">
              <label className="form-control-label">Data inicial</label>
              <Controller
                render={(props) => (
                  <DatePicker
                    fieldName="dateBegin"
                    valueController={setValue}
                    defaultValue={props.value}
                  />
                )}
                control={control}
                name="dateBegin"
                rules={dateRules}
                defaultValue={getDateBegin()}
              />
              <ErrorMessage
                errors={errors}
                name="dateBegin"
                render={({ message }) => <ErrorFormMessage message={message} />}
              />
            </Col>
            <Col md="6">
              <label className="form-control-label">Data final</label>
              <Controller
                render={(props) => (
                  <DatePicker
                    fieldName="dateEnd"
                    valueController={setValue}
                    defaultValue={props.value}
                  />
                )}
                control={control}
                name="dateEnd"
                defaultValue={new Date().toLocaleDateString('pt-br')}
                rules={{
                  ...dateRules,
                  validate: async (value) => {
                    if (
                      new Date(await toISOFormat(value, true)) <
                      new Date(
                        await toISOFormat(getValues()['dateBegin'], true)
                      )
                    ) {
                      return 'A data final não pode ser anterior à inicial!';
                    } else {
                      return true;
                    }
                  }
                }}
              />
              <ErrorMessage
                errors={errors}
                name="dateEnd"
                render={({ message }) => <ErrorFormMessage message={message} />}
              />
            </Col>
            <Col className="mt-2" md="6">
              <label
                className="form-control-label"
                style={{ marginRight: '20px', fontWeight: '400' }}
              >
                <input
                  type="radio"
                  name="typeDateFilter"
                  ref={register}
                  value="creation"
                  defaultChecked={true}
                  //disabled={isUpdate}
                />{' '}
                Data de criação
              </label>
              <label
                className="form-control-label"
                style={{ fontWeight: '400' }}
              >
                <input
                  type="radio"
                  name="typeDateFilter"
                  ref={register}
                  value="lastChange"
                  defaultChecked={false}
                  //disabled={isUpdate}
                />{' '}
                Data de última alteração
              </label>
            </Col>
          </Row>
          {typeReportSelected === 'Analítico' && (
            <Row className="mb-3">
              <Col
                md={
                  defaultFields?.client_id?.isActive
                    ? { size: 6 }
                    : { size: 12 }
                }
              >
                <label className="form-control-label">
                  Número do protocolo, email, título, descrição ou telefone
                </label>
                <Controller
                  as={
                    <Input
                      type="text"
                      name="protocolTextQuery"
                      placeholder="Buscar por número do protocolo, título, descrição e telefone"
                    />
                  }
                  control={control}
                  name="protocolTextQuery"
                  defaultValue=""
                />
              </Col>
              {defaultFields?.client_id?.isActive && (
                <Col md="6">
                  <label className="form-control-label">
                    Nome, email ou telefone do contato
                  </label>
                  <Controller
                    as={
                      <Input
                        type="text"
                        name="clientTextQuery"
                        placeholder="Buscar por Nome, email e telefone do contato"
                      />
                    }
                    control={control}
                    name="clientTextQuery"
                    defaultValue=""
                  />
                </Col>
              )}
            </Row>
          )}

          <Row>
            {defaultFields?.reason_id?.isActive && (
              <Col className="mb-3" md="6">
                <label className="form-control-label">Motivo</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={
                        reasons
                          ? reasons.map(({ id, name }) => ({
                              label: name,
                              value: id
                            }))
                          : []
                      }
                      isMulti
                      value={props.value}
                      valueController={setValue}
                      fieldName="reason_id"
                      labelName="label"
                      valueName="value"
                      isClearable={true}
                      onChange={(e) => {
                        handleGetSubReasons(e);

                        if (e.length === 0) {
                          setValue('subreason_id', []);
                        }
                      }}
                    />
                  )}
                  control={control}
                  name="reason_id"
                  defaultValue={[]}
                />
              </Col>
            )}
            {defaultFields?.subreason_id?.isActive && (
              <Col className="mb-3" md="6">
                <label className="form-control-label">Submotivo</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={
                        subreasons
                          ? subreasons.map(({ id, name }) => ({
                              label: name,
                              value: id
                            }))
                          : []
                      }
                      isMulti
                      value={props.value}
                      valueController={setValue}
                      fieldName="subreason_id"
                      labelName="label"
                      valueName="value"
                      isClearable={true}
                    />
                  )}
                  disabled={loadingSubreasons}
                  control={control}
                  name="subreason_id"
                  defaultValue={[]}
                />
              </Col>
            )}
            {defaultFields?.current_status?.isActive && (
              <Col className="mb-3" md="6">
                <label className="form-control-label">Status/Fase</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={
                        status
                          ? status.map(({ id, name }) => ({
                              label: name,
                              value: id
                            }))
                          : []
                      }
                      isMulti
                      value={props.value}
                      valueController={setValue}
                      fieldName="current_status"
                      labelName="label"
                      valueName="value"
                    />
                  )}
                  control={control}
                  name="current_status"
                  defaultValue={[]}
                />
              </Col>
            )}
            {defaultFields?.service_channel?.isActive && (
              <Col className="mb-3" md="6">
                <label className="form-control-label">
                  Canal de atendimento
                </label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={[
                        { label: 'Email', value: 'EMAIL' },
                        { label: 'Facebook', value: 'FACEBOOK' },
                        { label: 'Telegram', value: 'TELEGRAM' },
                        { label: 'Telefonia', value: 'TELEFONIA' },
                        { label: 'Whatsapp', value: 'WHATSAPP' },
                        { label: 'Chat', value: 'CHAT' },
                        { label: 'Instagram', value: 'INSTAGRAM' },
                        { label: 'Flex4U', value: 'FLEX4U' }
                      ]}
                      value={props.value}
                      valueController={setValue}
                      fieldName="service_channel"
                      labelName="label"
                      valueName="value"
                      placeholder="Selecione o canal"
                      isSearchable={false}
                      isMulti
                      isClearable
                    />
                  )}
                  control={control}
                  name="service_channel"
                  defaultValue=""
                />
              </Col>
            )}
          </Row>
          <Row className="mb-3">
            <Col md="6">
              <label className="form-control-label">Canal</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={canalOptions}
                    isMulti={false}
                    isClearable={true}
                    value={props.value}
                    valueController={setValue}
                    closeMenuOnSelect={true}
                    fieldName="idChannel"
                    labelName="label"
                    valueName="value"
                  />
                )}
                control={control}
                name="idChannel"
                defaultValue=""
              />
            </Col>

            <Col md="6">
              <label className="form-control-label">Conta</label>
              <SelectAccount
                styles={custom_select}
                placeholder="Buscar por conta"
                isClearable={true}
                name="account_id"
                isLoading={isSearching}
                loadingMessage={() => 'Buscando...'}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue
                    ? 'Digite para pesquisar'
                    : 'Nenhum resultado encontrado'
                }
                loadOptions={async (inputValue) => {
                  const res = await filterAccounts(inputValue);
                  return (
                    res &&
                    res.map((account) => ({
                      label: account.name,
                      value: account.id
                    }))
                  );
                }}
                onChange={(data) => {
                  if (data) {
                    const { value, label } = data;
                    setAccount({
                      label,
                      value
                    });
                  } else {
                    setAccount(null);
                  }
                }}
                value={
                  account
                    ? {
                        label: account.label,
                        value: account.value
                      }
                    : ''
                }
                defaultValue={
                  account
                    ? {
                        label: account.label,
                        value: account.value
                      }
                    : ''
                }
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6">
              <label className="form-control-label">Tipo de visualização</label>
              <Input
                type="select"
                onChange={(e) => modifyReportSelected(e)}
                name="select"
                defaultValue={'Analítico'}
              >
                {typesReports.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>

          <div style={{ display: 'flex', gap: '20px', marginTop: '10px' }}>
            <UserFlexSelect
              usersShowDisable={usersShowDisable}
              setUsersShowDisable={setUsersShowDisable}
              name={'Responsável Atual'}
            />
            {typeReportSelected !== 'Analítico' && (
              <>
                <Row className="mb-12">
                  <Col className="md-6 mx-4">
                    <Controller
                      name="enableCustomFields"
                      control={control}
                      render={(props) => (
                        <>
                          <label className="form-control-label">
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            Mostrar gráficos de campos customizados
                          </label>
                        </>
                      )}
                      defaultValue={false}
                    />
                  </Col>
                </Row>
              </>
            )}
          </div>
          {typeReportSelected === 'Analítico' && (
            <Row className="mb-12">
              <Col className="md-12">
                <label
                  className="form-control-label"
                  style={{ marginLeft: '-15px' }}
                >
                  <ToggleSwitch
                    checked={showAdvancedSearchForm}
                    id="customFieldsAdvancedSearchForm"
                    onChange={() => {
                      setShowAdvancedSearchForm(!showAdvancedSearchForm);
                      callBackShowAdvancedFilters(!showAdvancedSearchForm);
                    }}
                  />
                  Busca avançada
                </label>
              </Col>
            </Row>
          )}
          {showAdvancedSearchForm && typeReportSelected === 'Analítico' && (
            <Row>
              <AdvancedSearch
                customFields={protocolCustomFields}
                control={control}
                setValue={setValue}
                error={errors}
                getValues={getValues}
                watch={watch}
                toISOFormat={toISOFormat}
              />
            </Row>
          )}

          <div className="mt-3" style={ImageButtonConteinerProtocol}>
            <Row>
              <Button
                style={buttonStyle(systemConfiguration.primary_color)}
                type="submit"
                size="sm"
                onClick={() => {
                  handleSaveFilters();
                  setHasChangedRecordType(false);
                }}
              >
                Filtrar protocolos
              </Button>
              <Col>
                <ItemPerPageSelect
                  valueController={setItemsPerPage}
                ></ItemPerPageSelect>
              </Col>
              <Button
                style={{
                  backgroundColor: ids.length > 0 ? null : 'rgba(0, 0, 0, 0.5)',
                  opacity: ids.length > 0 ? null : '0.5',
                  cursor: ids.length > 0 ? 'pointer' : 'not-allowed'
                }}
                title="Atualizar protocolos em lote"
                size="md"
                color="info"
                disabled={ids.length > 0 ? false : true}
                onClick={() => setOpenModalUpdateMultProtocols(true)}
              >
                <li className="fas fa-user-edit"></li>
              </Button>
            </Row>

            {typeReportSelected === 'Analítico' && (
              <div>
                <label
                  style={{ fontWeight: '400' }}
                  className="form-control-label"
                >
                  <input
                    style={{ marginLeft: '11px' }}
                    checked={showTable === 'KANBAN'}
                    onChange={() => {
                      setShowTable((prev) => {
                        const formData = getValues();

                        if (prev === 'SPLIT') {
                          return 'KANBAN';
                        }

                        if (prev === 'KANBAN') {
                          getProtocols({
                            ...formData
                          });
                          return 'TABLE';
                        } else {
                          getProtocols({ ...formData, size: 15000 });
                        }

                        return 'KANBAN';
                      });
                    }}
                    defaultValue={false}
                    type="checkbox"
                  ></input>{' '}
                  Kanban
                </label>
                <label
                  style={{ fontWeight: '400' }}
                  className="form-control-label"
                >
                  <input
                    style={{ marginLeft: '11px' }}
                    checked={showTable === 'SPLIT'}
                    onChange={() => {
                      setShowTable((prev) => {
                        const formData = getValues();
                        if (prev === 'KANBAN') {
                          return 'SPLIT';
                        }
                        if (prev === 'SPLIT') {
                          getProtocols({
                            ...formData
                          });
                          return 'TABLE';
                        } else {
                          getProtocols({ ...formData, size: 15000 });
                        }
                        return 'SPLIT';
                      });
                    }}
                    defaultValue={false}
                    type="checkbox"
                  ></input>{' '}
                  Separar por status
                </label>
                <a
                  type="button"
                  disabled={disabledDownloadButtonCsvAndPdf(
                    'xlsx',
                    quantity,
                    limitGenerationPDF,
                    filtersProtocol
                  )}
                  style={ImageButton}
                  onClick={() => {
                    if (quantity <= limitGenerationXLSX) {
                      handleDownloadClick('xlsx');
                    } else {
                      handleDownloadClick('csv');
                    }
                  }}
                >
                  <Tooltip
                    title={
                      <p style={{ fontSize: '14px' }}>{messagemGenerateCsv}</p>
                    }
                    arrow
                  >
                    <img src={xlsxImage} alt="csv" style={ImageSize} />
                  </Tooltip>
                </a>
                <ModelUpdateMultProtocols
                  getProtocols={getProtocols}
                  isModalOpen={openModalUpdateMultProtocols}
                  selectedRecordType={selectedRecordType}
                  onModalToggle={setOpenModalUpdateMultProtocols}
                  ids={ids}
                  selectedRecordTypeId={selectedRecordType}
                />
                <button
                  type="button"
                  disabled={disabledDownloadButtonCsvAndPdf(
                    'pdf',
                    quantity,
                    limitGenerationPDF,
                    filtersProtocol
                  )}
                  style={ImageButton}
                  onClick={() => handleDownloadClick('pdf')}
                >
                  <Tooltip
                    title={
                      <p style={{ fontSize: '14px' }}>{messagemGeneratePdf}</p>
                    }
                    arrow
                  >
                    <img src={pdfImage} alt="pdf" style={ImageSize} />
                  </Tooltip>
                </button>
              </div>
            )}
          </div>
        </Form>
      )
    );
  }
);

export default FiltersForm;
