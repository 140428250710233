import DefaultModal from '../../../../../../components/Modal/DefaultModal';
import React, { useCallback, useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useEntityCustomFieldsByRecordType } from '../../../../../../views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from '../../../../../../views/CRM/shared/utils/entitiesName';
import { customFieldTypes } from '../../../../../../views/fieldConfigs/utils/customFieldTypes';

const TemplateModalSelected = ({
  isModalOpen,
  onModalToggle,
  template,
  templateParamRegExp,
  setStoreValuesContact,
  storeValuesContact,
  inputs,
  clientFields,
  setClientFields
}) => {
  const [optionSelects, setOptionSelects] = useState([]);
  const defaultContactRecordType = 301;
  const { customFields, getCustomFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(
      entitiesName.CONTACT,
      defaultContactRecordType
    );

  useEffect(() => {
    if (template) {
      const matches = template?.description?.match(templateParamRegExp);
      if (matches) {
        const newOptions = matches?.map((match, index) => {
          return optionSelects[index] || 'Texto Livre';
        });
        setOptionSelects(newOptions);
      }
    }
  }, [template, templateParamRegExp]);

  const getClientFields = useCallback(() => {
    if (isLoadingCustomFields) return;
    const defaultFields = [
      { label: 'Nome', value: '#nome' },
      { label: 'Telefone', value: '#telefone' }
    ];

    const customizedFields = customFields
      .filter((field) =>
        [
          customFieldTypes.TEXT,
          customFieldTypes.LIST,
          customFieldTypes.NUMERIC,
          customFieldTypes.DATE,
          customFieldTypes.CURRENCY,
          customFieldTypes.HOUR,
          customFieldTypes.TELEPHONE,
          customFieldTypes.CPF,
          customFieldTypes.CNPJ,
          customFieldTypes.MULTIPLE,
          customFieldTypes.LINK
        ].includes(field.type)
      )
      .map((field) => ({
        label: field.label,
        value: `#${field.name}`
      }));

    setClientFields(defaultFields.concat(customizedFields || []));
  }, [customFields, isLoadingCustomFields]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOptionSelects((prevOptions) => prevOptions);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [optionSelects]);

  useEffect(() => {
    getClientFields();
  }, [getClientFields]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  const saveModal = useCallback(() => {
    if (Object.keys(storeValuesContact).length === 0) {
      toast.error('Não há nenhum valor preenchido');
    } else {
      const hasEmptyValue = Object.values(storeValuesContact).some(
        (value) => value === ''
      );
      if (hasEmptyValue) {
        toast.error('Há valor vazio');
      } else {
        onModalToggle(false);
      }
      for (let i = 0; i < Object.keys(storeValuesContact).length; i++) {
        inputs[i].value = storeValuesContact[i].value;
      }
    }
  }, [onModalToggle, storeValuesContact]);

  const closeModal = useCallback(() => {
    onModalToggle(false);
  }, [onModalToggle]);

  const handleOptionChange = (value, index) => {
    const newOptions = [...optionSelects];
    newOptions[index] = value;
    setOptionSelects(newOptions);

    const updatedStoreValuesContact = { ...storeValuesContact };
    if (value !== 'Campo do cliente') {
      updatedStoreValuesContact[index] = { value, type: 'Text' };
    } else {
      updatedStoreValuesContact[index] = '';
    }
    setStoreValuesContact(updatedStoreValuesContact);
  };

  const handleWrite = (value, index) => {
    const updatedStoreValuesContact = { ...storeValuesContact };
    if (optionSelects[index] === 'Texto Livre') {
      updatedStoreValuesContact[index] = { value, type: 'Text' };
    } else {
      updatedStoreValuesContact[index] = { value, type: 'Client_field' };
    }
    setStoreValuesContact(updatedStoreValuesContact);
  };

  const getSelectDefaultValue = () => {
    for (let i = 0; i < storeValuesContact.length; i++) {
      const key = storeValuesContact[i]?.value;
      return clientFields && clientFields.find((field) => field.value === key);
    }
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Selecione dados do template"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      closeModal={closeModal}
    >
      {template && (
        <>
          <h2>{template?.description}</h2>
          {template?.description
            ?.match(templateParamRegExp)
            ?.map((match, index) => (
              <div
                key={index}
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2em'
                }}
              >
                <Select
                  options={[
                    { value: 'Texto Livre', label: 'Texto Livre' },
                    { value: 'Campo do cliente', label: 'Campo do cliente' }
                  ]}
                  onChange={(e) => handleOptionChange(e.value, index)}
                  value={{
                    value: optionSelects[index],
                    label: optionSelects[index]
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '200px',
                      marginTop: '10px'
                    })
                  }}
                />
                {optionSelects[index] === 'Texto Livre' ? (
                  <Input
                    placeholder=""
                    value={storeValuesContact[index]?.value || ''}
                    onChange={(e) => {
                      handleWrite(e.target.value, index);
                    }}
                    style={{ marginTop: '10px', height: '37px' }}
                  />
                ) : (
                  <Select
                    options={clientFields || []}
                    value={clientFields.find(
                      (option) => option.value === storeValuesContact[index]
                    )}
                    defaultValue={getSelectDefaultValue()}
                    placeholder="Selecione um campo"
                    onChange={(e) => {
                      handleWrite(e.value, index);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '520px',
                        marginTop: '10px'
                      })
                    }}
                  />
                )}
              </div>
            ))}
          <div style={{ marginTop: '20px' }}>
            <ReactButton
              color="primary"
              type="submit"
              style={{ marginRight: '10px' }}
              onClick={saveModal}
            >
              Salvar
            </ReactButton>
            <ReactButton
              btnColor="cancelation"
              type="submit"
              onClick={closeModal}
            >
              Voltar
            </ReactButton>
          </div>
        </>
      )}
    </DefaultModal>
  );
};

export default TemplateModalSelected;
