import React, { useState, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Row, Form, Col, Input, Button } from 'reactstrap';
import QueueService from 'views/Queue/service';
import { ErrorMessage } from '@hookform/error-message';
import Modal from '../../../../../components/Modal/DefaultModal';
import ChatDialogTreeService from '../../../service';
import { useDialogTree } from '../../tree/context';
import FlexSelect from 'components/Inputs/FlexSelect';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import FormTooltip from 'components/Inputs/FormTooltip';
import Tour from 'reactour';
import { steps } from '../../tree/utils/steps';
import { loopTreeData } from '../../tree/context/helpers';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import WhatsonService from 'views/Chat/service/watson/index';
const DialogTreeModal = ({
  isOpenModal,
  onModalToggle,
  onSubmitModalData,
  selectedNode
}) => {
  const [nodeActions, setNodeActions] = useState([]);
  const [watsonList, setWatsonList] = useState([]);
  const [queues, setQueues] = useState(null);
  // const [selectedNodeAction, setSelectedNodeAction] = useState(null);
  const [tourOpen, setTourOpen] = useState(false);
  const {
    reset,
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    setError
  } = useForm();
  const {
    treeData,
    typeNodeAction,
    setAvailableActions,
    setAvailableQueues
    // setTreeData
  } = useDialogTree();

  const [actionType, setActionType] = useState(null);

  useEffect(() => {
    setAvailableActions(nodeActions);
    setAvailableQueues(queues);
  }, [nodeActions, queues, setAvailableActions, setAvailableQueues]);

  useEffect(() => {
    FlexChatIntegration.getActionDialogTree()
      .then((response) => {
        //console.log('response: ', response);
        setNodeActions(response.data);
      })
      .catch((err) => {
        toast.error('Ocorreu um erro ao carregar as ações do nó');
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    if (selectedNode && typeNodeAction === 'EDIT_NODE') {
      setActionType(selectedNode?.action?.type);
    } else {
      const value = getValues('action');
      setActionType(value);
    }
  }, [selectedNode, getValues, typeNodeAction]);

  const getQueues = useCallback(async () => {
    QueueService.getAll()
      .then((response) => {
        const queuesData = response.data.data;
        if (queuesData) {
          const queues = queuesData.map(({ id, name }) => ({ id, name }));
          setQueues(queues);
        }
      })
      .catch(() => {
        toast('Ocorreu um erro ao carregar as filas');
      });
  }, []);

  useEffect(() => {
    getQueues();
  }, [getQueues]);

  const onSubmit = (data) => {
    if (!data.stringIdentifier) {
      const action = {
        type: data.action,
        message: data.message
      };
      delete data.message;
      if (data.action === 'LINKMENU') {
        action.to = {
          level: data.to
        };
        delete data.to;
      } else if (data.action === 'ET') {
        action.queue = data.queue;
        action.queueName = queues.find((queue) => queue.id === data.queue).name;
        delete data.queue;
      } else if (data.action === 'TTW') {
        action.watsonConfig = {
          id: data.watsonId,
          jumpInitial: data.WatsonJumpInitial === 'sim'
        };
        delete data.watsonId;
        delete data.WatsonJumpInitial;
      }
      data.action = action;
      onSubmitModalData(data);
    } else {
      if (data.action === 'SUBMENU' || data.action === 'SUBMENU_BUTTONS') {
        const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
        if (emojiRegex.exec(data.stringIdentifier)) {
          setError('stringIdentifier', {
            type: 'manual',
            message: 'Não é permitido emojis neste campo'
          });
          return;
        }
      }

      const possibleAnswersFormated = data.possibleAnswers.reduce(
        (acc, { label }) => ({
          ...acc,
          [label.replace(/\s/g, '').toLowerCase().trim()]: {
            label: label
          }
        }),
        {}
      );
      data.possibleAnswers = possibleAnswersFormated;
      const action = {
        type: data.action,
        message: data.message
      };
      delete data.message;
      if (data.action === 'LINKMENU') {
        action.to = {
          level: data.to
        };
        delete data.to;
      } else if (data.action === 'ET') {
        action.queue = data.queue;
        action.queueName = queues.find((queue) => queue.id === data.queue).name;
        delete data.queue;
      } else if (data.action === 'TTW') {
        action.watsonConfig = {
          id: data.watsonId,
          jumpInitial: data.WatsonJumpInitial === 'sim'
        };
        delete data.watsonId;
        delete data.WatsonJumpInitial;
      }

      data.action = action;
      onSubmitModalData(data);
    }
    onModalToggle(false);
    reset();
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const getAvailableLevels = () => {
    const availableSubMenus = [];
    loopTreeData(treeData, (node) => {
      if (node.nodes && !!Object.keys(node.nodes).length) {
        availableSubMenus.push(node);
        return node;
      }
    });

    return availableSubMenus.map((node) => ({
      value: node.id,
      label: node.stringIdentifier || `Nó padrão - level ${node.level}`
    }));
  };

  const handleFilterActions = () => {
    let filteredActions = nodeActions;

    // get the father of this node
    const nodeFatherId = selectedNode?.mac?.idMac;
    let actualFatherNode = null;
    loopTreeData(treeData, (node) => {
      if (node.id === nodeFatherId && nodeFatherId && node.isDefault) {
        actualFatherNode = node;
        return node;
      }
    });

    // if it have a father node, get the father of this node as well
    if (actualFatherNode) {
      const nodeGrandFatherId = actualFatherNode?.mac?.idMac;
      let actualGrandFatherNode = null;
      if (nodeGrandFatherId === null) {
        // if the father of this node doesn't have a father, it means that it is the nodes from level 1
        // if it is, we need to get how mane nodes are in level 1
        if (treeData && Object.keys(treeData).length === 1) {
          filteredActions = filteredActions.filter(
            (action) => action.type !== 'RETURN'
          );
        }
      }
      loopTreeData(treeData, (node) => {
        if (node.id === nodeGrandFatherId && nodeGrandFatherId) {
          actualGrandFatherNode = node;
          return node;
        }
      });

      if (
        actualGrandFatherNode &&
        Object.keys(actualGrandFatherNode.nodes).length === 1
      ) {
        filteredActions = filteredActions.filter(
          (action) => action.type !== 'RETURN'
        );
      }
    }

    if (selectedNode.level === 1) {
      filteredActions = filteredActions.filter(
        (action) => action.type !== 'RETURN'
      );
    }

    // if (selectedNode.nodes && !!Object.keys(selectedNode.nodes).length && typeNodeAction === 'EDIT_NODE') {
    //   filteredActions = filteredActions.filter(
    //     (action) => action.type !== 'LINKMENU'
    //   );
    // }

    if (
      typeNodeAction === 'EDIT_NODE' &&
      selectedNode.nodes &&
      ((Object.keys(selectedNode.nodes).length === 1 &&
        Object.keys(selectedNode.nodes[0].action).length !== 0) ||
        Object.keys(selectedNode.nodes).length > 1)
    ) {
      filteredActions = filteredActions.filter(
        (action) => action.type === selectedNode.action.type
      );
    }

    return filteredActions;
  };

  const openTour = () => {
    setTourOpen(true);
  };

  useEffect(() => {
    WhatsonService.getWhatson()
      .then((response) => {
        setWatsonList(
          (response?.data.data ?? [])
            .map((item) => {
              if (item.ativado)
                return {
                  label: item.nome_canal,
                  value: item?.id
                };
            })
            .filter(Boolean)
        );
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar Watson!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  }, []);

  const closeTour = () => {
    setTourOpen(false);
  };

  // useEffect(() => {
  //   if (selectedNode?.action?.to?.level && typeNodeAction === 'EDIT_NODE') {
  //     if (actionType !== 'LINKMENU' && actionType) {
  //       treeData[selectedNode.action.to.level].mac = {
  //         id: null,
  //         level: selectedNode.level
  //       };
  //     }
  //   }
  // }, [selectedNode, actionType, treeData, typeNodeAction]);

  return (
    <>
      <Modal
        show={isOpenModal}
        onModalToggle={(state) => {
          onModalToggle(state);
        }}
        title="Configuração de Nó"
        showCloseButton={false}
      >
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <>
            {selectedNode?.isDefault &&
            typeNodeAction !== 'ADD_NEW_NODE' &&
            typeNodeAction !== 'ADD_NEW_NODE_CHILD' ? (
              <></>
            ) : (
              <>
                <Row className="mb-3">
                  <Col sm="4" className="tour1-dt">
                    <label className="form-control-label">
                      Número
                      <FormTooltip text="Este número é opcional e representa a opção que será exibida ao usuário." />
                    </label>
                    <Controller
                      as={<Input type="number" min={1} />}
                      control={control}
                      name="numericIdentifier"
                      defaultValue={
                        selectedNode?.numericIdentifier &&
                        typeNodeAction === 'EDIT_NODE'
                          ? selectedNode?.numericIdentifier
                          : ''
                      }
                    />
                    <ErrorMessage
                      errors={errors}
                      name="numericIdentifier"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                  <Col sm="8" className="tour2-dt">
                    <label className="form-control-label">
                      Texto da opção*
                      <FormTooltip text="Este texto representa a descrição da opção que será apresentado ao usuário" />
                    </label>
                    <Controller
                      as={<Input />}
                      control={control}
                      name="stringIdentifier"
                      rules={{
                        required: 'Este campo é obrigatório',
                        validate: (value) => {
                          let selectedNodeFather = {};
                          loopTreeData(treeData, (node) => {
                            if (node.id === selectedNode?.mac?.idMac) {
                              selectedNodeFather = node;
                              return node;
                            }
                          });

                          if (
                            selectedNodeFather &&
                            selectedNodeFather?.nodes &&
                            Object.keys(selectedNodeFather.nodes).length <= 4 &&
                            selectedNodeFather?.action?.type ===
                              'SUBMENU_BUTTONS'
                          ) {
                            //console.log('entrou maior que 16');
                            if (value && value.length > 16) {
                              return 'O texto deve ter no máximo 16 caracteres';
                            }
                          }
                        }
                      }}
                      defaultValue={
                        selectedNode?.stringIdentifier &&
                        typeNodeAction === 'EDIT_NODE'
                          ? selectedNode?.stringIdentifier
                          : ''
                      }
                    />
                    <ErrorMessage
                      errors={errors}
                      name="stringIdentifier"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="12" className="tour3-dt">
                    <Row className="mb-3">
                      <Col sm="12">
                        <label className="form-control-label">
                          Respostas possíveis
                          <FormTooltip text="Este campo representa as possíveis respostas que, ao usuário digitar ativa a opção descrita neste nó." />
                        </label>
                        <Controller
                          render={(props) => (
                            <CreatableSelect
                              isMulti
                              isClearable={true}
                              options={[]}
                              value={props.value}
                              onChange={(value) => {
                                props.onChange(value);
                              }}
                              placeholder="Selecione..."
                              labelName="label"
                              valueName="value"
                            />
                          )}
                          control={control}
                          name="possibleAnswers"
                          rules={{ required: 'Campo obrigatório!' }}
                          defaultValue={() => {
                            if (
                              selectedNode?.possibleAnswers &&
                              typeNodeAction === 'EDIT_NODE'
                            ) {
                              const possibleAnswers = Object.values(
                                selectedNode?.possibleAnswers
                              ).map(({ label }) => ({ label }));
                              return possibleAnswers;
                            }
                            return [];
                          }}
                        />

                        <ErrorMessage
                          errors={errors}
                          name="possibleAnswers"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <Row className="mb-3">
              <Col sm="12" className="tour4-dt">
                <label className="form-control-label">
                  Tipo da ação*
                  <FormTooltip text="Selecione o tipo de ação que será executada quando o usuário selecionar este nó." />
                </label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      isClearable={true}
                      dataOptions={handleFilterActions()}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      value={props.value}
                      valueController={(fieldName, value) => {
                        setValue(fieldName, value);
                        setActionType(value);
                      }}
                      fieldName="action"
                      labelName="description"
                      valueName="type"
                    />
                  )}
                  control={control}
                  name="action"
                  rules={{ required: 'Campo obrigatório!' }}
                  defaultValue={
                    selectedNode?.action?.type && typeNodeAction === 'EDIT_NODE'
                      ? selectedNode?.action?.type
                      : ''
                  }
                />

                <ErrorMessage
                  errors={errors}
                  name="action"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>
            {actionType === 'ET' ? (
              <Row className="mb-3">
                <Col sm="12">
                  <label className="form-control-label">
                    Fila*
                    <FormTooltip text="Selecione a fila para qual o usuário será transferido." />
                  </label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        isClearable={true}
                        dataOptions={queues}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={props.value}
                        valueController={setValue}
                        fieldName="queue"
                        labelName="name"
                        valueName="id"
                      />
                    )}
                    control={control}
                    name="queue"
                    rules={{ required: 'Campo obrigatório!' }}
                    defaultValue={
                      selectedNode?.action?.queue &&
                      typeNodeAction === 'EDIT_NODE'
                        ? selectedNode?.action?.queue
                        : ''
                    }
                  />

                  <ErrorMessage
                    errors={errors}
                    name="queue"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {actionType === 'TTW' ? (
              <>
                <Row className="mb-3">
                  <Col sm="12">
                    <label className="form-control-label">
                      Watson*
                      <FormTooltip text="Selecione para qual watson deseja transferir" />
                    </label>
                    <Controller
                      render={(props) => (
                        <FlexSelect
                          isClearable={true}
                          dataOptions={watsonList}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          value={props.value}
                          valueController={setValue}
                          fieldName="watsonId"
                          labelName="label"
                          valueName="value"
                        />
                      )}
                      control={control}
                      name="watsonId"
                      rules={{ required: 'Campo obrigatório!' }}
                      defaultValue={
                        selectedNode?.action?.watsonConfig?.id &&
                        typeNodeAction === 'EDIT_NODE'
                          ? selectedNode?.action?.watsonConfig?.id
                          : false
                      }
                    />

                    <ErrorMessage
                      errors={errors}
                      name="watsonId"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="12">
                    <label className="form-control-label">
                      Pular Mensagem Inicial*
                      <FormTooltip text="Ao selecionar esta opção a mensagem inicial do watson será ignorada e o valor enviado pelo cliente será repassado como resposta para a segunda pergunta do watson." />
                    </label>
                    <Controller
                      render={(props) => (
                        <FlexSelect
                          isClearable={true}
                          dataOptions={[
                            {
                              label: 'Sim',
                              value: 'sim'
                            },
                            {
                              label: 'Não',
                              value: 'nao'
                            }
                          ]}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          value={props.value}
                          valueController={setValue}
                          fieldName="WatsonJumpInitial"
                          labelName="label"
                          valueName="value"
                        />
                      )}
                      control={control}
                      name="WatsonJumpInitial"
                      rules={{ required: 'Campo obrigatório!' }}
                      defaultValue={
                        selectedNode?.action?.watsonConfig?.id &&
                        typeNodeAction === 'EDIT_NODE'
                          ? selectedNode?.action?.watsonConfig?.jumpInitial
                            ? 'sim'
                            : 'nao'
                          : ''
                      }
                    />

                    <ErrorMessage
                      errors={errors}
                      name="WatsonJumpInitial"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            {actionType === 'LINKMENU' ? (
              <Row className="mb-3">
                <Col sm="12">
                  <label className="form-control-label">
                    Para*
                    <FormTooltip
                      text={`Selecione o nível para qual o usuário será redirecionado dentro desta árvore.`}
                    />
                  </label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        isClearable={true}
                        dataOptions={getAvailableLevels() || []}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={props.value}
                        valueController={setValue}
                        fieldName="to"
                        labelName="label"
                        valueName="value"
                      />
                    )}
                    control={control}
                    name="to"
                    rules={{ required: 'Campo obrigatório!' }}
                    defaultValue={
                      selectedNode?.action?.to && typeNodeAction === 'EDIT_NODE'
                        ? String(selectedNode?.action?.to?.level)
                        : ''
                    }
                  />

                  <ErrorMessage
                    errors={errors}
                    name="to"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="mb-3">
              <Col sm="12" className="tour5-dt">
                <label className="form-control-label">
                  Mensagem após escolha da opção
                  {actionType === 'RP' ||
                  actionType === 'RETURN' ||
                  actionType === 'LINKMENU'
                    ? ''
                    : '*'}
                  <FormTooltip text="Mensagem a ser exibida ao usuário após a seleção da opção." />
                </label>
                <Controller
                  as={
                    <Input
                      type="textarea"
                      rows="3"
                      placeholder="Mensagem a ser exibida ao usuário"
                    />
                  }
                  control={control}
                  name="message"
                  rules={
                    actionType === 'RP' ||
                    actionType === 'RETURN' ||
                    actionType === 'LINKMENU'
                      ? {}
                      : { required: 'Campo obrigatório!' }
                  }
                  defaultValue={
                    selectedNode?.action?.message &&
                    typeNodeAction === 'EDIT_NODE'
                      ? selectedNode?.action?.message
                      : ''
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="message"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>
          </>

          <Button color="primary" type="submit">
            Salvar
          </Button>
          <Button color="danger" onClick={() => onModalToggle(false)}>
            Cancelar
          </Button>
          {!selectedNode.isDefault && (
            <Button
              className="float-right "
              type="button"
              outline
              color="info"
              onClick={openTour}
              title="Saiba mais sobre as configurações do nó"
            >
              Saiba Mais
            </Button>
          )}
        </Form>
      </Modal>
      <Tour steps={steps} isOpen={tourOpen} onRequestClose={closeTour} />
    </>
  );
};

export default DialogTreeModal;
