/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import NewService from '../../../service/newService';
import { Chip } from '@material-ui/core';
import FlexChatIntegrationService from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration/index';
import Select from 'react-select';
import { MdAccessAlarms } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Col, Input, Row } from 'reactstrap';
import { ChipTime, ContentCheckbox } from '../style';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import QueueService from '../../../../Queue/service';
import { Form } from '../../channels/modals/facebook/style';
import { ButtonChat } from 'views/ChatConfiguration/components/buttons';

import { toast } from 'react-toastify';

const ModalUpdate = ({
  info,
  modifyStateModal,
  posicao,
  setTimeoutConfigs
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  //const dados = useSelector((state) => state.createObject.data);
  const [initialState, setInitialState] = useState(null);
  //const [Config, setConfig] = useState([]);
  const [queues, setQueues] = useState([]);
  const [channels, setChannels] = useState([]);

  const animatedComponents = makeAnimated();
  useEffect(() => {
    QueueService.getAll()
      .then((response) => {
        const newQueues = response.data.data.map((queue) => {
          return {
            label: queue.name,
            value: queue.id
          };
        });
        setQueues(newQueues);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
    FlexChatIntegrationService.getAllChannels()
      .then((result) => {
        const dataChannels = [];
        const dataResponse = result.data.data;
        Object.keys(dataResponse).map((channelName) => {
          if (channelName === 'facebook' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }
          if (channelName === 'sms' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }

          if (channelName === 'instagram' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }

          if (channelName === 'whatsapp') {
            result.data.data[channelName].map((channelWpp) => {
              if (channelWpp.id)
                dataChannels.push({
                  label: channelWpp.nome_canal,
                  value: channelWpp.nome_canal
                });
            });
          }

          if (channelName === 'telegram' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }

          if (channelName === 'webchat' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }
        });
        setChannels(dataChannels);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    setInitialState(info);
    //setConfig([dados]);
  }, [info]);

  function setStateModal(event) {
    event.preventDefault();
    modifyStateModal(false);
  }

  function controlToggleActive(control) {
    setInitialState((prev) => ({ ...prev, ignoreWeekend: control }));
  }

  function sendObject(event) {
    event.preventDefault();

    if (initialState.timeout && !initialState.closingMessage) {
      toast.error(
        `Mensagem de ${
          initialState.action === 'encerrar'
            ? 'Encerramento é obrigatória'
            : 'Transferência é obrigatória'
        }`,
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
      return;
    }

    NewService.updateTimeout(initialState)
      .then((response) => {
        setStateModal(event);
        if (response.data) {
          setTimeoutConfigs(response.data.data);
          toast.success('Alterações feitas com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data || 'Ops, ocorreu um erro', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  }

  function setDataModal(event, name) {
    if (name === 'inTheQueues') {
      const labels = event ? event.map((option) => option.label) : [];
      setInitialState((prev) => ({
        ...prev,
        inTheQueues: labels
      }));
      return;
    } else if (name === 'channels') {
      setInitialState((prev) => ({
        ...prev,
        channels: event ? event.map((option) => option.label) : []
      }));
      return;
    } else if (name === 'ignoreWeekend') {
      setInitialState((prev) => ({
        ...prev,
        ignoreWeekend: event.value
      }));
      return;
    } else if (name === 'limit' || name === 'timeout') {
      setInitialState((prev) => ({
        ...prev,
        [name]: Number(event.value) > 0 ? event.value : ''
      }));
      return;
    } else {
      setInitialState((prev) => ({
        ...prev,
        [name]: event.value
      }));
    }
  }
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 46,
      color: '#8898aa',
      backgroundColor: '#fff',
      border: '1px solid #dee2e6'
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#8898aa',
        fontSize: '0.875rem'
      };
    }
  };
  return (
    <>
      {initialState && channels.length && (
        <Form>
          <Row className="row">
            <Col>
              <label>Tipo:</label>
              <Input
                type="select"
                onChange={(event) =>
                  setDataModal({ value: event.target.value }, 'typeTimeout')
                }
                name="typeTimeout"
                //defaultValue={initialState.tipoTimeout}
                value={initialState.typeTimeout}
              >
                <option value="SucessoAtendimento">
                  Sucesso de Atendimento
                </option>
                <option value="Ociosidade">Ociosidade</option>
              </Input>
            </Col>
            <Col md="4">
              <label>Regra:</label>
              <Input
                onChange={(event) =>
                  setDataModal({ value: event.target.value }, 'typeOfAttendant')
                }
                type="select"
                name="typeOfAttendant"
                //defaultValue={initialState.tipoAtendente}
                value={initialState.typeOfAttendant}
              >
                <option value="Atendente">Atendente</option>
                <option value="Chatbot">Chatbot</option>
              </Input>
            </Col>
            <Col md="4">
              <label>Canais: </label>
              <Select
                placeholder="Selecione..."
                components={animatedComponents}
                styles={customStyles}
                options={channels?.map((option) => {
                  return {
                    label: option?.label,
                    value: option?.value
                  };
                })}
                isMulti
                name="channels"
                onChange={(event) => setDataModal(event, 'channels')}
                defaultValue={
                  initialState?.channels?.map((value) => {
                    return { label: value, value };
                  }) || []
                }
                isClearable={true}
                isSearchable={true}
                isLoading={false}
                isRtl={false}
                closeMenuOnSelect={false}
              ></Select>
            </Col>
          </Row>

          <Row>
            <Col md="8">
              <label>Fila(s) de origem:</label>
              <Select
                placeholder="Selecione..."
                components={animatedComponents}
                styles={customStyles}
                //className="select"
                options={queues.map((option) => {
                  return {
                    label: option.label,
                    value: option.label
                  };
                })}
                isMulti
                name="inTheQueues"
                onChange={(event) => setDataModal(event, 'inTheQueues')}
                defaultValue={initialState?.inTheQueues?.map((value) => {
                  return { label: value, value };
                })}
                isClearable={true}
                isSearchable={true}
                isLoading={false}
                isRtl={false}
                closeMenuOnSelect={false}
              ></Select>
            </Col>
            <Col md={`${initialState.action !== 'encerrar' ? '2' : '4'}`}>
              <label>Ação:</label>
              <Input
                type="select"
                onChange={(event) =>
                  setDataModal({ value: event.target.value }, 'action')
                }
                name="action"
                value={initialState.action}
              >
                <option value="encerrar">Encerrar</option>
                <option value="transferir">Transferir</option>
              </Input>
            </Col>
            {initialState.action !== 'encerrar' && (
              <Col md="2">
                <label>Transferir para:</label>
                <Input
                  type="select"
                  onChange={(event) =>
                    setDataModal({ value: event.target.value }, 'transferQueue')
                  }
                  name="transferQueue"
                  value={initialState.transferQueue}
                >
                  {queues &&
                    queues.map((queue) => (
                      <option key={queue.value} value={queue.label}>
                        {queue.label}
                      </option>
                    ))}
                </Input>
              </Col>
            )}
          </Row>
          <Row
            style={{
              marginTop: '20px'
            }}
          >
            <Col>
              <label>{`Lembrete de ${
                initialState.action === 'encerrar'
                  ? 'Encerramento'
                  : 'Transferência'
              }:`}</label>
              <Input
                type="textarea"
                onChange={(event) =>
                  setDataModal({ value: event.target.value }, 'reminderMessage')
                }
                defaultValue={initialState.reminderMessage}
                name="reminderMessage"
              />
            </Col>
            {initialState.tipoAtendente === 'Chatbot' ? (
              <ChipTime>
                <Col md="9">
                  <Chip
                    avatar={<MdAccessAlarms color="white" />}
                    size="small"
                    className="chipTime"
                    label="TEMPO EM MINUTOS"
                  />
                  <Input
                    defaultValue={initialState?.limit}
                    type="number"
                    name="limit"
                    min={1}
                    max={1440}
                    value={initialState?.limit}
                    onChange={(event) =>
                      setDataModal({ value: event.target.value }, 'limit')
                    }
                  />
                </Col>
              </ChipTime>
            ) : (
              <ChipTime>
                <Col md="9">
                  <Chip
                    avatar={<MdAccessAlarms color="white" />}
                    size="small"
                    className="chipTime"
                    label="TEMPO EM MINUTOS"
                  />
                  <Input
                    defaultValue={initialState.limit}
                    type="number"
                    name="limit"
                    min={1}
                    value={initialState?.limit}
                    onChange={(event) =>
                      setDataModal({ value: event.target.value }, 'limit')
                    }
                  />
                </Col>
              </ChipTime>
            )}
          </Row>
          <Row
            style={{
              marginTop: '20px'
            }}
          >
            <Col>
              <label>{`Mensagem de ${
                initialState.action === 'encerrar'
                  ? 'Encerramento'
                  : 'Transferência'
              }:`}</label>
              <Input
                type="textarea"
                onChange={(event) =>
                  setDataModal({ value: event.target.value }, 'closingMessage')
                }
                defaultValue={initialState.closingMessage}
                name="closingMessage"
                required
              />
            </Col>
            {initialState.typeOfAttendant === 'Chatbot' ? (
              <ChipTime>
                <Col>
                  <Chip
                    avatar={<MdAccessAlarms color="white" />}
                    size="small"
                    className="chipTime"
                    label="TEMPO EM MINUTOS"
                  />
                  <Input
                    defaultValue={initialState?.timeout}
                    type="number"
                    name="timeout"
                    min={1}
                    max={1440}
                    value={initialState?.timeout}
                    onChange={(event) =>
                      setDataModal({ value: event.target.value }, 'timeout')
                    }
                  />
                </Col>
              </ChipTime>
            ) : (
              <ChipTime>
                <Col md="9">
                  <Chip
                    avatar={<MdAccessAlarms color="white" />}
                    size="small"
                    className="chipTime"
                    label="TEMPO EM MINUTOS"
                  />
                  <Input
                    defaultValue={initialState?.timeout}
                    value={initialState?.timeout}
                    type="number"
                    name="timeout"
                    min={1}
                    onChange={(event) =>
                      setDataModal({ value: event.target.value }, 'timeout')
                    }
                  />
                </Col>
              </ChipTime>
            )}
          </Row>

          <Row>
            <ContentCheckbox color={systemConfiguration.primary_color}>
              <Col>
                <label className="titleMsg">IGNORAR FINAL DE SEMANA</label>
                {initialState.ignoreWeekend === true ? (
                  <Chip size="small" className="chipTrue" label="HABILITADO" />
                ) : (
                  <Chip
                    size="small"
                    className="chipFalse"
                    label="DESABILITADO"
                  />
                )}
              </Col>
              <ToggleSwitch
                checked={initialState.ignoreWeekend}
                id={'ativado'}
                onChange={() =>
                  controlToggleActive(!initialState.ignoreWeekend)
                }
              />
            </ContentCheckbox>
          </Row>
          <Row className="row">
            <Col md="12" className="button">
              <ButtonChat
                action={(event) => sendObject(event)}
                name={'Salvar'}
                color={systemConfiguration.confirmation_button_color}
              />
              <ButtonChat
                action={(event) => setStateModal(event)}
                name={'Fechar'}
                color={systemConfiguration.cancelation_button_color}
              />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export { ModalUpdate };
