import { useState, useEffect, useCallback } from 'react';
import { useGetUserSectors } from '../../../User/form/hooks/useGetUser';

import CostCenterService from '../../../Extension/service/centr_de_custo';

const useFilteringHandlers = (extensions) => {
  const [textQuery, setTextQuery] = useState('');
  const [hideInactive, setHideInactive] = useState(false);
  const [costsCenter, setCostsCenter] = useState([]);
  const [selectedCostCenters, setSelectedCostCenters] = useState([]);
  const { getUserSectors, userCostCenter } = useGetUserSectors();

  useEffect(() => {
    getUserSectors();
  }, [getUserSectors]);

  useEffect(() => {
    CostCenterService.getAll()
      .then((response) => {
        const backendCostCenter = response.data.data;
        const costCenterInputSelectFormat = backendCostCenter.map((cc) => ({
          value: cc.id,
          label: cc.name
        }));
        setCostsCenter(costCenterInputSelectFormat);
      })
      .catch(() => {
        console.error('Erro ao ler os centros de custo');
      });
  }, []);

  const setSearchText = useCallback((text) => {
    setTextQuery(text);
  }, []);

  const getFilteredExtensions = useCallback(
    (allExtensions, filterText, isHideInactive, costCenters) => {
      let filteredExtensions = [...allExtensions];

      filteredExtensions = allExtensions.filter((ext) =>
        ext.extension.includes(filterText)
      );

      if (isHideInactive) {
        filteredExtensions = filteredExtensions.filter(
          (ext) => ext.state !== 'UNPLACED'
        );
      }

      if (Array.isArray(costCenters) && costCenters.length) {
        const namesCostCenters = costCenters.map((cc) => cc.label);

        filteredExtensions = filteredExtensions.filter((ext) =>
          namesCostCenters.includes(ext.costCenter)
        );
      }
      filteredExtensions = filteredExtensions.filter(
        (ext) => userCostCenter?.includes(ext.costCenter) ?? false
      );

      return filteredExtensions;
    },
    [userCostCenter]
  );

  const setHideInactiveExtensions = useCallback((value) => {
    setHideInactive(() => value);
  }, []);

  const setArraySelectedCostCenter = useCallback((selecteds) => {
    setSelectedCostCenters(selecteds || []);
  }, []);

  return {
    filteredExtensions: getFilteredExtensions(
      extensions,
      textQuery,
      hideInactive,
      selectedCostCenters
    ),
    textQuery,
    setTextQuery: setSearchText,
    hideInactive,
    setHideInactive: setHideInactiveExtensions,
    costsCenter,
    setSelectedCostCenters: setArraySelectedCostCenter
  };
};

export { useFilteringHandlers };
