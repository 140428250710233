import React, { useRef, useState } from 'react';
import { FaCircle, FaPhone, FaPhoneSlash } from 'react-icons/fa';
import './style.css';
import userAgentSingleton from '../userAgent/userAgentSingleton';
import { SessionState } from 'sip.js';
import { MdHeadset, MdHeadsetOff, MdMic, MdMicOff } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { FiMonitor } from 'react-icons/fi';
import { verifyRoles } from 'components/Authentication/VerifyRoles';

const DialpadWebRTC = ({
  ramal,
  onDisconnect,
  callState,
  callStateColor,
  callStateStatus,
  receivedInvitation,
  setOpen
}) => {
  const [currentDialPadInput, setCurrentDialPadInput] = useState(
    userAgentSingleton.dialpadInput ?? ''
  );
  const [defean, setDefean] = useState(userAgentSingleton.defean);
  const [mute, setMute] = useState(userAgentSingleton.mute);
  const elementoLocal = useRef();
  const history = useHistory();

  const typeInDialPad = (value) => {
    setCurrentDialPadInput(currentDialPadInput + value);
    if (userAgentSingleton.session != undefined) {
      userAgentSingleton.sendDTMF(value);
    }
  };

  const getNumber = () => {
    let number =
      userAgentSingleton.session?._dialog?.dialogState?.remoteURI?.normal?.user;

    if (number == undefined) {
      number =
        userAgentSingleton.session?.outgoingRequestMessage?.toURI?.normal?.user;
    }
    return number ?? 'Desconhecido';
  };

  return (
    <div className="dialpadModal">
      <audio ref={elementoLocal} id="mediaElement">
        <p>O seu navegador não suporta áudio</p>
      </audio>
      <div className="ramalRow">
        <p>Ramal: {ramal}</p>
        {userAgentSingleton.session?._state != 'Established' ? (
          <div className="disconnectButton" onClick={onDisconnect}>
            <span>Desconectar</span>
          </div>
        ) : verifyRoles(['MONITORING_PBXIP']) ? (
          <div
            className="transferButton"
            onClick={() => {
              setOpen(false);
              history.push('/admin/monitoring/extensions');
            }}
          >
            <span>Transferir</span>
          </div>
        ) : null}
      </div>

      {receivedInvitation != undefined ? (
        <div className="receivedInvitation">
          <p>{getNumber()}</p>
          <div className="receivedInvitationActions">
            <div
              className="call"
              onClick={() => {
                receivedInvitation.accept();
              }}
            >
              <div>
                <FaPhone />
              </div>
            </div>
            <div
              className="endCall"
              onClick={() => {
                receivedInvitation.reject();
              }}
            >
              <div>
                <FaPhoneSlash />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="numPan">
        <div className="disp">
          <input
            value={currentDialPadInput}
            onChange={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setCurrentDialPadInput(event.target.value);
            }}
            type="text"
          ></input>
        </div>
        <div className="nums">
          <div className="r r1 ">
            <div onClick={() => typeInDialPad(1)}>
              <span>1</span>
            </div>
            <div onClick={() => typeInDialPad(2)}>
              <span>2</span>
            </div>
            <div onClick={() => typeInDialPad(3)}>
              <span>3</span>
            </div>
          </div>
          <div className="r r2 ">
            <div onClick={() => typeInDialPad(4)}>
              <span>4</span>
            </div>
            <div onClick={() => typeInDialPad(5)}>
              <span>5</span>
            </div>
            <div onClick={() => typeInDialPad(6)}>
              <span>6</span>
            </div>
          </div>
          <div className="r r3 ">
            <div onClick={() => typeInDialPad(7)}>
              <span>7</span>
            </div>
            <div onClick={() => typeInDialPad(8)}>
              <span>8</span>
            </div>
            <div onClick={() => typeInDialPad(9)}>
              <span>9</span>
            </div>
          </div>
          <div className="r r4 ">
            <div onClick={() => typeInDialPad('*')}>
              <span>*</span>
            </div>
            <div onClick={() => typeInDialPad(0)}>
              <span>0</span>
            </div>
            <div onClick={() => typeInDialPad('#')}>
              <span>#</span>
            </div>
          </div>
          {callState == SessionState.Initial ? (
            <div className="call">
              <div
                onClick={() => {
                  userAgentSingleton.call(currentDialPadInput);
                }}
              >
                <FaPhone />
              </div>
            </div>
          ) : (
            <div className="endCall">
              <div
                onClick={() => {
                  userAgentSingleton.endCall();
                }}
              >
                <FaPhoneSlash />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="dialpad-toolbar">
        <div style={{ display: 'flex ' }}>
          {verifyRoles(['MONITORING_PBXIP']) ? (
            <div
              className="monitor"
              onClick={() => {
                setOpen(false);
                history.push('/admin/monitoring/extensions');
              }}
            >
              <div>
                <FiMonitor size={22} title="Ir para monitoramento de ramais" />
              </div>
            </div>
          ) : null}

          <div className="statusRow">
            <div
              style={{
                color: callStateColor
              }}
            >
              <FaCircle size={10} />
            </div>
            <p>{`${callStateStatus ?? 'Desconectado'} ${
              userAgentSingleton.session != undefined &&
              userAgentSingleton.session.state != SessionState.Initial
                ? `- ${getNumber()}`
                : ''
            }`}</p>
          </div>
        </div>
        <div className="dialpad-audio-toolbar">
          <div
            className="defean"
            onClick={() => {
              setDefean(!defean);
              setMute(!defean);
              userAgentSingleton.toggleDefean();
              userAgentSingleton.toggleMute(!defean);
            }}
          >
            <div>
              {defean ? <MdHeadsetOff size={22} /> : <MdHeadset size={22} />}
            </div>
          </div>

          <div
            className="mute"
            onClick={() => {
              setMute(!mute);
              userAgentSingleton.toggleMute();
            }}
          >
            <div>{mute ? <MdMicOff size={22} /> : <MdMic size={22} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialpadWebRTC;
