import React, { useEffect, useState, useCallback } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import ReactButton from 'components/Buttons/ReactButton';
import DatePicker from 'components/Inputs/DatePicker.js';
import TelephoneMask from 'components/Inputs/TelephoneMask';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  Col,
  Form,
  Input,
  Row,
  InputGroup
} from 'reactstrap';
import { removeMaskSpecialCharacters } from 'views/CRM/shared/utils/phoneNumberHelpers';
import FlexSelect from 'components/Inputs/FlexSelect';
import ProtocolSchedulingService from '../../../service/ProtocolSchedulingService';
import { toast } from 'react-toastify';
import { maskPhoneNumber } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { typeOptions } from '../../utils/schedulingOptions';

function ModalSchedulingProtocol({
  show,
  onModalToggle,
  setSelectedSchedule,
  schedulingId,
  setschedulingId,
  isToCreateScheduling,
  isToUpdateScheduling,
  protocolId,
  getProtocolById,
  isFromNotification
}) {
  const [schedulingData, setSchedulingData] = useState(null);
  const { control, errors, setValue, handleSubmit, reset } = useForm();
  useEffect(() => {
    if (isToUpdateScheduling && schedulingId) {
      ProtocolSchedulingService.getProtocolScheduleById(schedulingId)
        .then((response) => {
          const schedulingDataDto = response.data.content;
          const [date, fullHour] = schedulingDataDto.schedule_date.split(' ');
          schedulingDataDto.scheduling_date = date;
          schedulingDataDto.scheduling_hour = fullHour;
          schedulingDataDto.destination = maskPhoneNumber(
            schedulingDataDto.destination
          );
          reset(schedulingDataDto);
          setSchedulingData(schedulingDataDto);
        })
        .catch((error) => console.error(error));
    }
  }, [isToUpdateScheduling, reset, schedulingId, setSchedulingData]);

  const cancelAction = () => {
    reset({});
    isToUpdateScheduling && setschedulingId(null);
    onModalToggle(!show);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const onSubmit = (data) => {
    const today = new Date();
    if (isToCreateScheduling) {
      data.protocol_id = protocolId;
    }

    if (isToUpdateScheduling) {
      data.protocol_id = schedulingData.protocol_id;
    }
    const [day, month, year] = data.scheduling_date.toString().split('/');
    const [hour, minute, seconds] = data.scheduling_hour.toString().split(':');
    const schedule_date = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hour),
      Number(minute),
      Number(seconds) || 0
    );

    data.schedule_date = schedule_date;

    if (data.schedule_date < today) {
      toast.error(
        'Horário inválido, defina uma data ou hora maior que a atual',
        {
          autoClose: 3000,
          closeOnClick: 'true'
        }
      );
    } else {
      delete data.scheduling_date;
      delete data.scheduling_hour;
      if (data.destination.length === 0) {
        delete data.destination;
      } else {
        data.destination = removeMaskSpecialCharacters(data.destination);
      }
      isToCreateScheduling
        ? ProtocolSchedulingService.createProtocolSchedule(data)
            .then(() => getProtocolById())
            .catch((error) => {
              toast.error(
                error | 'Erro ao criar um novo agendamento de ligação',
                {
                  autoClose: 3000,
                  closeOnClick: true
                }
              );
            })
        : isToUpdateScheduling
        ? ProtocolSchedulingService.rescheduleProtocolScheduling(
            schedulingId,
            data
          )
            .then(() => {
              !isFromNotification && getProtocolById();
              !isFromNotification && getProtocolById();
            })
            .catch((error) => {
              toast.error(error | 'Erro ao reagendar a ligação', {
                autoClose: 3000,
                closeOnClick: true
              });
            })
        : setSelectedSchedule(data);
      onModalToggle(!show);
      reset({});
      isToUpdateScheduling && setschedulingId(null);
    }
  };

  const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${YYYY}-${MM}-${DD}`;
  }, []);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={show}
      returnFocusAfterClose={false}
      unmountOnClose={true}
      size="lg"
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {schedulingId
            ? 'Reagendar ligação'
            : 'Adicionar agendamento ao protocolo'}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            onModalToggle(!show);
            reset({});
            isToUpdateScheduling && setschedulingId(null);
          }}
        >
          <span aria-hidden={true}>&times;</span>
        </button>
      </div>
      <ModalBody>
        <Form
          className="needs-validation"
          onSubmit={(event) => {
            event.stopPropagation();
            handleSubmit(onSubmit)(event);
          }}
          id="modalForm"
        >
          <Row>
            <Col className="mb-3" sm="6">
              <label className="form-control-label">Data</label>
              <Controller
                render={(props) => (
                  <DatePicker
                    fieldName="scheduling_date"
                    valueController={setValue}
                    defaultValue={props.value}
                  />
                )}
                control={control}
                defaultValue=""
                rules={{
                  required: 'Campo obrigatório',
                  validate: async (value) => {
                    const today = new Date().toLocaleDateString(undefined, {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    });
                    if (
                      new Date(await toISOFormat(value, true)) <
                      new Date(await toISOFormat(today, true))
                    ) {
                      return 'A data não pode ser inferior a de hoje!';
                    } else {
                      return true;
                    }
                  }
                }}
                name="scheduling_date"
              />
              <ErrorMessage
                errors={errors}
                name="scheduling_date"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
            <Col className="mb-3" sm="6">
              <label className="form-control-label">Horário</label>
              <Controller
                render={(props) => (
                  <Input
                    value={props.value}
                    onChange={props.onChange}
                    type="time"
                    step="2"
                    placeholder="scheduling_hour"
                  />
                )}
                control={control}
                defaultValue=""
                rules={{
                  required: 'Campo obrigatório'
                }}
                name="scheduling_hour"
              />
              <ErrorMessage
                errors={errors}
                name="scheduling_hour"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3" sm="6">
              <label className="form-control-label">Descrição</label>
              <Controller
                render={(props) => (
                  <Input
                    value={props.value}
                    onChange={props.onChange}
                    type="text"
                    placeholder="Descrição"
                  />
                )}
                control={control}
                defaultValue=""
                rules={{
                  required: 'Campo obrigatório'
                }}
                name="description"
              />
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
            <Col className="mb-3" sm="6">
              <label className="form-control-label">Tipo</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={typeOptions || []}
                    isMulti={false}
                    isClearable={true}
                    value={props.value}
                    valueController={setValue}
                    closeMenuOnSelect={true}
                    fieldName="type"
                    labelName="label"
                    valueName="value"
                  />
                )}
                control={control}
                defaultValue=""
                rules={{
                  required: 'Campo obrigatório'
                }}
                name="type"
              />
              <ErrorMessage
                errors={errors}
                name="type"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6" className="mb-3">
              <label className="form-control-label">Destino</label>
              <Controller
                render={(props) => (
                  <InputGroup>
                    <TelephoneMask
                      valueController={setValue}
                      fieldName="destination"
                      value={props.value}
                      placeholder="(DDD) 0000-0000"
                    />
                  </InputGroup>
                )}
                control={control}
                defaultValue=""
                rules={{
                  validate: (value) => {
                    if (!value) return true;
                    let phoneFormated = value.replace(/\D/g, '');
                    phoneFormated = phoneFormated.replace(/^0/, '');

                    if (phoneFormated.length < 10 && phoneFormated.length > 0) {
                      return 'Telefone inválido';
                    }
                    return true;
                  }
                }}
                name="destination"
              />
              <ErrorMessage
                errors={errors}
                name="destination"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>

          <ReactButton btnColor="confirmation" type="submit" form="modalForm">
            Salvar
          </ReactButton>

          <ReactButton
            btnColor="cancelation"
            onClick={() => {
              return cancelAction();
            }}
          >
            Cancelar
          </ReactButton>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ModalSchedulingProtocol;
