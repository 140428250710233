import { toast } from 'react-toastify';
import {
  Inviter,
  Registerer,
  RegistererState,
  SessionState,
  UserAgent
} from 'sip.js';
import newCallAudio from '../../../../../assets/audios/newCall.mp3';
import toneCallAudio from '../../../../../assets/audios/toneCall.mp3';
import endCallAudio from '../../../../../assets/audios/endCall.mp3';

class UserAgentSingleton {
  async connect(
    ramal,
    secret,
    setReceivedInvitation,
    updateCallState,
    setDialpadOpen,
    onDisconnect
  ) {
    this.ramal = ramal;
    this.updateCallState = updateCallState;
    this.defean = false;
    this.mute = false;
    this.setDialpadOpen = setDialpadOpen;
    this.onDisconnect = onDisconnect;

    this.userAgent = new UserAgent({
      uri: UserAgent.makeURI(`sip:${ramal}@${this.getDomain()}`),
      authorizationUsername: ramal,
      authorizationPassword: secret,
      displayName: localStorage.getItem('name'),
      delegate: {
        onInvite: (invitation) => {
          invitation.sessionType = 'Invitation';
          this.session = invitation;
          let audio = new Audio(newCallAudio);
          audio.play();
          let intervalId = setInterval(async () => {
            await audio.play();
          }, 3000);

          setDialpadOpen(true);
          setReceivedInvitation(invitation);

          invitation.stateChange.addListener((state) => {
            if (state == SessionState.Terminated) {
              this.clearSession();
            }
            updateCallState(state);
            this.callStateListener(state);

            if (state != SessionState.Initial) {
              clearInterval(intervalId);
              audio.pause();
              setReceivedInvitation(null);
            }
          });
        }
      },
      transportOptions: {
        server: `wss://${this.getDomain()}:8089/ws`
      }
    });

    await this.userAgent.start();

    const registerer = new Registerer(this.userAgent);

    registerer.stateChange.addListener(async (state) => {
      if (state == RegistererState.Registered) {
        this.updateCallState(SessionState.Initial);
      }
      if (state == RegistererState.Unregistered) {
        await this.disconnect();
      }
    });

    await registerer.register();
  }

  async disconnect() {
    try {
      await this.userAgent.stop();
      this.userAgent = undefined;
      this.ramal = undefined;
      this.updateCallState(undefined);
      this.onDisconnect();
    } catch (e) {
      console.log(e);
    }
  }

  getDomain() {
    let url =
      window.location.hostname == 'localhost'
        ? process.env.REACT_APP_PROXY_URL
        : window.location.origin;

    url = url.replace('http://', '');
    url = url.replace('https://', '');
    if (url.indexOf(':') != -1) {
      url = url.substring(0, url.indexOf(':'));
    }
    if (url.indexOf('/') != -1) {
      url = url.substring(0, url.indexOf('/'));
    }
    return url;
  }

  setupRemoteMedia(session) {
    this.mediaElement = document.getElementById('mediaElement');
    const remoteStream = new MediaStream();
    session.sessionDescriptionHandler.peerConnection
      .getReceivers()
      .forEach((receiver) => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });
    this.mediaElement.srcObject = remoteStream;
    this.mediaElement.play();

    this.checkMedia();
  }

  cleanupMedia() {
    if (this.mediaElement) {
      this.mediaElement.srcObject = null;
      this.mediaElement.pause();
    }
  }

  callStateListener(state) {
    console.log(`Session state changed to ${state}`);
    switch (state) {
      case SessionState.Initial:
        break;
      case SessionState.Establishing:
        break;
      case SessionState.Established:
        this.setupRemoteMedia(this.session);
        break;
      case SessionState.Terminating:
      // fall through
      case SessionState.Terminated:
        this.cleanupMedia();
        break;
      default:
        throw new Error('Unknown session state.');
    }
  }

  async clearSession() {
    this.session = undefined;
    this.updateCallState(SessionState.Terminated);
  }

  async call(phone) {
    try {
      const uri = `sip:${phone}@${this.getDomain()}`;
      const inviter = new Inviter(this.userAgent, UserAgent.makeURI(uri));
      inviter.sessionType = 'Inviter';
      this.session = inviter;

      let audio = new Audio(toneCallAudio);
      audio.play();
      let intervalId = setInterval(async () => {
        await audio.play();
      }, 5000);

      inviter.stateChange.addListener((state) => {
        this.callStateListener(state);
        if (state != SessionState.Establishing) {
          clearInterval(intervalId);
          audio.pause();
        }
        if (state == SessionState.Terminated) {
          this.clearSession();

          let audio = new Audio(endCallAudio);
          audio.play();
        }
        this.updateCallState(state);
      });
      await inviter.invite();
    } catch (e) {
      this.clearSession();
      toast.error('Não foi possível realizar a ligação', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }

  async sendDTMF(value) {
    if (this.session.state == 'Established') {
      const options = {
        requestOptions: {
          body: {
            contentDisposition: 'render',
            contentType: 'application/dtmf-relay',
            content: `Signal=${value}\r\nDuration=1000`
          }
        }
      };
      this.session.info(options);
    }
  }

  async blindedTransfer(ramal) {
    this.setDialpadOpen(true);
    const target = UserAgent.makeURI(`sip:${ramal}@${this.getDomain()}`);
    await this.session.refer(target);
    await this.endCall();
    toast.success('Ligação transferida');
  }

  async attendedTransfer(ramal) {
    this.setDialpadOpen(true);
    const transfer = ['*', '2', ...ramal.split('')];

    for (let dtmf of transfer) {
      const options = {
        requestOptions: {
          body: {
            contentDisposition: 'render',
            contentType: 'application/dtmf-relay',
            content: `Signal=${dtmf}\r\nDuration=1000`
          }
        }
      };
      await this.session.info(options);
    }

    //this.currentTransferExtension = ramal;
    toast.info('Transferindo ligação');
  }

  async toggleDefean() {
    const defean = this.defean ?? false;
    this.defean = !defean;
    this.checkMedia();
  }

  async toggleMute(mute) {
    this.mute = mute ?? !this.mute;
    this.checkMedia();
  }

  checkMedia() {
    if (this.session != undefined) {
      if (this.mediaElement != undefined) {
        if (this.defean) {
          this.mediaElement.pause();
        } else {
          this.mediaElement.play();
        }
      }

      const pc = this.session?.sessionDescriptionHandler?.peerConnection;
      if (pc != undefined) {
        pc.getSenders().forEach((stream) => {
          stream.track.enabled = !this.mute;
        });
      }
    }
  }

  async endCall() {
    let audio = new Audio(endCallAudio);
    audio.play();
    switch (this.session.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        await this.session.cancel();
        this.clearSession();
        break;
      case SessionState.Established:
        await this.session.bye();
        this.clearSession();
        break;
      case SessionState.Terminating:
      case SessionState.Terminated:
        break;
    }
  }
}

const userAgentSingleton = new UserAgentSingleton();
export default userAgentSingleton;
