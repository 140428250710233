export const templateParamsRegex = new RegExp('{{\\d+}}', 'gm'); ///{{\d+}}/gm

export const fillTypes = Object.freeze({
  FREE_TEXT: 'free_text',
  CLIENT_FIELD: 'client_field'
});

export const broadcastTypeOfClosingConversationOptions = [
  { label: 'Transferir para fila', value: 1 },
  { label: 'Encerrar na primeira resposta', value: 2 },
  { label: 'Transferir para fila com primeira resposta', value: 3 },
  { label: 'Transferir para a Árvore de Diálogo', value: 4 },
  { label: 'Transferir para o Watson', value: 5 },
  { label: 'Encerrar conversa após envio', value: 6 }
];
