import ServiceCRUD from '../../../services/ServiceCRUDNEWCHANNEL';
import { apiInstagramChat as http } from '../../../http';

class ConversationReportService extends ServiceCRUD {
  constructor() {
    super('/configuration');
  }

  getTimeOut() {
    return http.get(`/configuration/timeout`);
  }

  createTimeout(data) {
    return http.post(`/configuration/timeout`, data);
  }

  updateTimeout(data) {
    console.log(data);
    return http.put(`/configuration/timeout`, data);
  }

  deleteTimeout(id) {
    return http.delete(`/configuration/timeout/${id}`);
  }

  changeStatusTimeout(id) {
    return http.patch(`/configuration/timeout/${id}/status`);
  }
}

export default new ConversationReportService();
