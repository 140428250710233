import ServiceCRUD from '../../../../services/ServiceCRUDFLEXCHAT';
import { apiChat as http } from '../../../../http';
import { apiInstagramChat as httpChatTs } from '../../../../http';

const formatDate = (rangeDate) => {
  return rangeDate.toLocaleDateString('pt-br').split('/').reverse().join('-');
};

class DashboardService extends ServiceCRUD {
  constructor() {
    super('dashboard');
  }

  getTotal(rangeDate) {
    return http.get(
      `${this.path}/total?dataInicial=${formatDate(
        rangeDate.start
      )}&dataFinal=${formatDate(rangeDate.end)}`
    );
  }

  getInAttendance(rangeDate, loginAtendente = null) {
    return http.get(
      `${this.path}/conversa/atendente/ematendimento?dataInicial=${
        rangeDate ? formatDate(rangeDate.start) : ''
      }&dataFinal=${rangeDate ? formatDate(rangeDate.end) : ''}${
        loginAtendente ? `&loginAtendente=${loginAtendente}` : ''
      }`
    );
  }

  getClosed(rangeDate, loginAtendente = null) {
    return http.get(
      `${this.path}/conversa/atendente/encerrada?dataInicial=${formatDate(
        rangeDate.start
      )}&dataFinal=${formatDate(rangeDate.end)}${
        loginAtendente ? `&loginAtendente=${loginAtendente}` : ''
      }`
    );
  }

  getNotAnswered(rangeDate) {
    return http.get(
      `${this.path}/conversa/fila/naoatendida?dataInicial=${formatDate(
        rangeDate.start
      )}&dataFinal=${formatDate(rangeDate.end)}`
    );
  }

  getTmae(rangeDate) {
    return httpChatTs.get(
      `/dashboard/serviceData?startDate=${formatDate(
        rangeDate.startDate
      )}&endDate=${formatDate(rangeDate.endDate)}`
    );
  }
}

export default new DashboardService();
