import React, { useState } from 'react';
import { Container, Row, Card, CardHeader, CardBody } from 'reactstrap';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import FilteringFieldHeader from './components/FilteringFieldHeader';
import ExtensionCardContainer from './components/ExtensionCards/ExtensionCardContainer';
import { useSocketEvents } from './hooks/useSocketEvents';
import { useFilteringHandlers } from './hooks/useFilteringHandlers';
import TransferModal from './components/TransferModal';
// import CostCenterService from '../../Extension/service/centr_de_custo';

const ExtensionMonitoring = () => {
  const { extensions } = useSocketEvents();
  const {
    filteredExtensions,
    costsCenter,
    hideInactive,
    textQuery,
    setSelectedCostCenters,
    setHideInactive,
    setTextQuery
  } = useFilteringHandlers(extensions);
  const [transferModelOpen, setTransferModelOpen] = useState(false);
  const [selectedExtension, setSelectedExtension] = useState(undefined);

  const updateSelectedExension = (extension) => {
    setTransferModelOpen(true);
    setSelectedExtension(extension);
  };

  return (
    <>
      <SimpleHeader showBackArrow={'not'} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Monitoramento de Ramais</h3>
                </CardHeader>
                <CardBody>
                  <FilteringFieldHeader
                    textQuery={textQuery}
                    setTextQuery={setTextQuery}
                    hideInactive={hideInactive}
                    setHideInactive={setHideInactive}
                    costsCenters={costsCenter}
                    setSelectedCostCenters={setSelectedCostCenters}
                  />
                  <ExtensionCardContainer
                    extensions={filteredExtensions}
                    updateSelectedExension={updateSelectedExension}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>

      <TransferModal
        isModalOpen={transferModelOpen}
        onModalToggle={setTransferModelOpen}
        extension={selectedExtension}
      />
    </>
  );
};

export default ExtensionMonitoring;
