import React from 'react';
import { AiFillInstagram } from 'react-icons/ai';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { FaFacebook, FaTelegram } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import { LiaSmsSolid } from 'react-icons/lia';
import LogoFlex4U from 'assets/img/4u_dark.svg';

export const IconsChannel = ({ channel, showName = true }) => {
  switch (channel.toLowerCase()) {
    case 'telegram':
      return (
        <div>
          <FaTelegram
            size="20"
            color="#0088cc"
            style={{ marginRight: '4px' }}
          />
          {showName ? 'Telegram' : ''}
        </div>
      );
    case 'whatsapp':
      return (
        <div>
          <IoLogoWhatsapp
            size="20"
            color="#25D366"
            style={{ marginRight: '4px' }}
          />
          {showName ? 'Whatsapp' : ''}
        </div>
      );
    case 'chat':
      return (
        <div>
          <BsFillChatDotsFill
            size="20"
            color="#fbad50"
            style={{ marginRight: '4px' }}
          />
          {showName ? 'Webchat' : ''}
        </div>
      );
    case 'sms':
      return (
        <div>
          <LiaSmsSolid size="20" color="#000" style={{ marginRight: '4px' }} />
          {showName ? 'SMS' : ''}
        </div>
      );
    case 'instagram':
      return (
        <div>
          <AiFillInstagram
            size="20"
            color="#bc2a8d"
            style={{ marginRight: '4px' }}
          />
          {showName ? 'Instagram' : ''}
        </div>
      );
    case 'facebook':
      return (
        <div>
          <FaFacebook
            size="20"
            color="#3B5998"
            style={{ marginRight: '4px' }}
          />
          {showName ? 'Facebook' : ''}
        </div>
      );
    case 'flex4u':
      return (
        <div>
          <img src={LogoFlex4U} width={22} style={{ marginRight: '4px' }} />
          {showName ? 'Flex4U' : ''}
        </div>
      );
    default:
      return <>Canal: {channel}</>;
  }
};
