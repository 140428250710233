import { apiCrm } from '../../../../http';
import { calculateOffsetFromPageAndLimit } from 'views/CRM/shared/utils/pagination';

class AccountService {
  getAccounts(recordTypeId, params = {}, headers = { page: 1, limit: 10 }) {
    const offset = calculateOffsetFromPageAndLimit(headers.page, headers.limit);
    return apiCrm.get(`/account/${recordTypeId}`, {
      params,
      headers: { ...headers, offset }
    });
  }

  create(recordTypeId, data) {
    return apiCrm.post(`account/${recordTypeId}`, data);
  }

  update(accountId, data) {
    return apiCrm.put(`account/${accountId}`, data);
  }

  getById(recordTypeId, id) {
    return apiCrm.get(`account/${recordTypeId}/${id}`);
  }

  removeContacts(accountId, contactIds = []) {
    return apiCrm.put(`account/${accountId}/remove-contacts`, { contactIds });
  }

  importAccounts(recordTypeId, data) {
    return apiCrm.post(`account/${recordTypeId}/import`, data);
  }

  deleteAccount(accountId) {
    return apiCrm.delete(`account/${accountId}`);
  }
  getAccountsComments(accountId) {
    return apiCrm.get(`accounts-comment/${accountId}`);
  }
  createAccountsComments(accountId, data) {
    return apiCrm.post(`accounts-comment/${accountId}`, data);
  }
  updateAccountsComments(commentId, data) {
    return apiCrm.put(`accounts-comment/${commentId}`, data);
  }
  deleteAccountsComments(commentId) {
    return apiCrm.delete(`accounts-comment/${commentId}`);
  }
}

export default new AccountService();
