import React, { useCallback, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import {
  Row,
  Form,
  Col,
  Input,
  Container,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select/async';
import { LocationOn } from '@material-ui/icons';

import ContactService from '../../../Contact/services/contactService';

import ListComments from '../ContactDetails/Comments/CommentsList';
import CommentModal from '../ContactDetails/Comments/CommentsForm';
import ModalComment from '../ContactDetails/Comments/ModalUpdateComment';
import { FiPlus } from 'react-icons/fi';

import ReactButton from 'components/Buttons/ReactButton';
import TelephoneMask from 'components/Inputs/TelephoneMask';
import contactService from '../../services/contactService';
import CustomFieldsInputsForm from '../../../shared/components/CustomFieldsInputsForm';
import Loader from 'components/Loader';
import { custom_select } from '../../../../../assets/styles/multiple_select';
import { useSearchAccounts } from '../../../Account/hooks/useSearchAccounts';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';
import { maskPhoneNumber } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { formatCustomFieldsToDisplay } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { useClickToCallOnPhoneField } from 'views/CRM/shared/hooks/useClickToCallOnPhoneField';
import ContactProtocols from './ContactProtocols';
import { removeMaskSpecialCharacters } from 'views/CRM/shared/utils/phoneNumberHelpers';
import TemplatesModal from 'views/ServicePanel/components/QueueContent/components/Contact/TemplatesModal';

import { SearchTagClientInput } from 'components/Inputs/SearchTagClientInput';

const sectionStyle = {
  backgroundColor: '#f7f7f7',
  padding: '0.8rem 1rem',
  borderRadius: '0.1rem'
};

const ContactDetails = () => {
  const history = useHistory();
  const { control, handleSubmit, errors, setValue, reset, watch } = useForm();
  const { contactId, recordTypeId, id } = useParams();
  const [contactEdit, setContactEdit] = useState(null);

  const { isSearching, filterAccounts } = useSearchAccounts();
  const [account, setAccount] = useState();
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [
    isModalTemplateMessageContactOpen,
    setIsModalTemplateMessageContactOpen
  ] = useState(false);
  const [whatsAppMessageContactInfo, setWhatsAppMessageContactInfo] = useState(
    {}
  );

  const { getCustomFields, customFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(entitiesName.CONTACT, recordTypeId);
  const { canMakeCall, makeCall, canSendMessage } =
    useClickToCallOnPhoneField();

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  function handleWhatsAppMessage(phone) {
    if (!phone) return;
    const phoneNumberWithoutMask = removeMaskSpecialCharacters(phone);
    let data = {
      nome: contactEdit?.name,
      telefone: phoneNumberWithoutMask !== '' ? phoneNumberWithoutMask : ''
    };

    setWhatsAppMessageContactInfo(data);
    setIsModalTemplateMessageContactOpen(!isModalTemplateMessageContactOpen);
    return whatsAppMessageContactInfo;
  }

  const [listComments, setListComments] = useState([]);

  const [openModalCreateComment, setOpenModalCreateComment] = useState(false);

  const [dataCommentUpdate, setDataCommentUpdate] = useState([]);
  const [openModalUpdateComment, setOpenModalUpdateComment] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (contactId) {
      contactService
        .getContactById(contactId)
        .then(({ data }) => {
          const account = data.data.account;
          setAccount({
            label: account?.name,
            value: account?.id
          });

          const tags = data?.data?.tags?.map((tag) => ({
            label: tag.name,
            value: tag.id
          }));

          setTags(tags);

          const contact = {
            ...data.data,
            phone: maskPhoneNumber(data.data.phone)
          };

          const formattedContact = formatCustomFieldsToDisplay(
            contact,
            customFields
          );

          reset(formattedContact);
          setContactEdit(formattedContact);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Houve um erro ao buscar os dados do contato.', {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoading(false);
        });
    }
  }, [contactId, recordTypeId, reset, customFields]);

  const onSubmit = async (data, tagsToUpdate) => {
    data.accountId = account && account.value ? Number(account.value) : null;

    const dataDTO = formatCustomFieldsToSubmit(data, customFields);
    dataDTO.phone = removeMaskSpecialCharacters(data.phone);

    const tagsDTO = tagsToUpdate?.map((tag) => tag.value);
    dataDTO.tags = tagsDTO;

    contactService
      .updateContact(recordTypeId, contactId, dataDTO)
      .then(() => {
        toast.success('Contato atualizado com successo.', {
          autoClose: 3000,
          closeOnClick: true
        });
        history.push('/admin/crm/contact');
      })
      .catch((error) => {
        const msg =
          error?.response?.data?.message ??
          'Houve um erro ao atualizar o contato';
        toast.error(msg, {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handleUpdateComment = (dataComment) => {
    setOpenModalUpdateComment(true);
    setDataCommentUpdate(dataComment);
  };

  const handleCreateComment = () => {
    setOpenModalCreateComment(true);
  };

  const getCommnents = useCallback(() => {
    if (contactId) {
      ContactService.getContactsComments(contactId)
        .then((res) => {
          setListComments(res.data.data);
        })
        .catch((err) => {
          toast.error('Houve um erro ao buscar os comentários desse contato', {
            closeOnClick: true,
            autoClose: 3000
          });
          console.log(err);
        });
    }
  }, [contactId]);
  useEffect(getCommnents, [getCommnents]);

  const reloadComments = () => {
    ContactService.getContactsComments(contactId).then((res) => {
      setListComments(res.data.data);
    });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  if (isLoadingCustomFields) {
    return (
      <Row className="justify-content-md-center">
        <Loader />
      </Row>
    );
  }

  const openGoogleMaps = () => {
    window.open(
      'https://maps.google.com?q=' +
        contactEdit.latitude +
        ',' +
        contactEdit.longitude
    );
  };

  return (
    <>
      <SimpleHeader
        name="Account edit"
        parentName="Account"
        returnPath="/admin/crm/contact"
        navigatorState={{ recordTypeId }}
      />
      <Container className="configuration-container mt--6" fluid>
        <Card>
          <CardBody>
            {contactEdit && (
              <Row className="mb-3">
                <Col sm={contactEdit.longitude ? '2' : '3'}>
                  <h3 className="font-weight-bold small mb-0">Criado por:</h3>
                  <span>{contactEdit?.createdByName}</span>
                </Col>
                <Col sm="3">
                  <h3 className="font-weight-bold small mb-0">Criado em:</h3>
                  <span>{toBrazilianFullDate(contactEdit?.createdAt)}</span>
                </Col>
                <Col sm="3">
                  <h3 className="font-weight-bold small mb-0">
                    Última modificação por:
                  </h3>
                  <span>{contactEdit?.lastUpdatedByName}</span>
                </Col>
                <Col sm="3">
                  <h3 className="font-weight-bold small mb-0">
                    Última modificação em:
                  </h3>
                  <span>{toBrazilianFullDate(contactEdit?.lastUpdatedAt)}</span>
                </Col>

                {contactEdit.longitude && contactEdit.latitude ? (
                  <Col
                    sm="1"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <h3 className="font-weight-bold small mb-0">
                      Localização:
                    </h3>
                    <Button
                      color="primary"
                      outline
                      size="sm"
                      type="button"
                      title="Abrir endereço do cliente no Google Maps"
                      onClick={() => {
                        openGoogleMaps();
                      }}
                      style={{ maxWidth: '3rem' }}
                    >
                      <LocationOn />
                    </Button>
                  </Col>
                ) : null}
              </Row>
            )}
            <div className="mt-3" style={sectionStyle}>
              <h2>Dados do contato</h2>
              <Form
                className="needs-validation"
                onSubmit={handleSubmit((data) => onSubmit(data, tags))}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <Row>
                      <Col className="mb-3" sm="6">
                        <label className="form-control-label text-capitalize">
                          Nome*
                        </label>
                        <Controller
                          render={(props) => (
                            <Input
                              valueController={setValue}
                              value={props.value}
                              onChange={props.onChange}
                              type="text"
                              placeholder="Nome"
                            />
                          )}
                          control={control}
                          defaultValue=""
                          rules={{ required: 'Campo obrigatório' }}
                          name="name"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col>
                        <label className="form-control-label text-capitalize">
                          Telefone
                        </label>
                        <Controller
                          render={(props) => (
                            <InputGroup>
                              <TelephoneMask
                                valueController={setValue}
                                fieldName="phone"
                                value={props.value}
                                placeholder="(88) 99999-9999"
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  type="button"
                                  color="info"
                                  title="Enviar mensagem"
                                  disabled={!canSendMessage(props.value)}
                                  onClick={() =>
                                    handleWhatsAppMessage(props.value)
                                  }
                                >
                                  <i className="fas fa-comment" />
                                </Button>
                              </InputGroupAddon>
                              <InputGroupAddon addonType="append">
                                <Button
                                  type="button"
                                  color="success"
                                  title="Realizar chamada"
                                  disabled={!canMakeCall(props.value)}
                                  onClick={() => makeCall(props.value)}
                                >
                                  <i className="fas fa-phone" />
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          )}
                          control={control}
                          defaultValue=""
                          rules={{
                            validate: (value) => {
                              if (!value) return true;
                              let phoneFormated = value.replace(/\D/g, '');
                              phoneFormated = phoneFormated.replace(/^0/, '');

                              if (
                                phoneFormated.length < 10 &&
                                phoneFormated.length > 0
                              ) {
                                return 'Telefone inválido';
                              }
                              return true;
                            }
                          }}
                          name="phone"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="phone"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3" sm="6">
                        <label className="form-control-label text-capitalize">
                          Email
                        </label>
                        <Controller
                          render={(props) => (
                            <Input
                              name="email"
                              valueController={setValue}
                              value={props.value}
                              onChange={props.onChange}
                              placeholder="seu-email@mail.com"
                            />
                          )}
                          control={control}
                          defaultValue=""
                          // O campo não é obrigatório mas é necessário validar o email com regex
                          rules={{
                            validate: (value) => {
                              if (!value) return true;

                              const emailRegex =
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

                              return (
                                emailRegex.test(value) ||
                                'Email com formato incorreto'
                              );
                            }
                          }}
                          name="email"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="email"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col>
                        <label className="form-control-label text-capitalize">
                          Conta
                        </label>
                        <Select
                          styles={custom_select}
                          placeholder="Busca rápida"
                          isClearable={true}
                          name="accountId"
                          isLoading={isSearching}
                          loadingMessage={() => 'Buscando...'}
                          noOptionsMessage={({ inputValue }) =>
                            !inputValue
                              ? 'Digite para pesquisar'
                              : 'Nenhum resultado encontrado'
                          }
                          loadOptions={async (inputValue) => {
                            const res = await filterAccounts(inputValue);
                            return (
                              res &&
                              res.map((account) => ({
                                label: account.name,
                                value: account.id
                              }))
                            );
                          }}
                          onChange={(data) => {
                            if (data) {
                              const { value, label } = data;
                              setAccount({
                                label,
                                value
                              });
                            } else {
                              setAccount(null);
                            }
                          }}
                          value={
                            account
                              ? {
                                  label: account.label,
                                  value: account.value
                                }
                              : ''
                          }
                          defaultValue={
                            account
                              ? {
                                  label: account.label,
                                  value: account.value
                                }
                              : ''
                          }
                        />
                      </Col>
                      <Col>
                        <SearchTagClientInput
                          setValueSelected={setTags}
                          defaultValue={tags}
                          isMulti={true}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <CustomFieldsInputsForm
                        fields={customFields}
                        control={control}
                        errors={errors}
                        watch={watch}
                        isOnEditPage={true}
                        setValue={setValue}
                        Controller={Controller}
                        options={{
                          showCallIconOnPhoneField: true,
                          showMessageIconOnPhoneField: true
                        }}
                        contactMessageClientName={contactEdit?.name}
                      />
                    </Row>
                  </>
                )}

                <ReactButton btnColor="confirmation" type="submit">
                  Salvar
                </ReactButton>
              </Form>
            </div>

            {listComments && (
              <ListComments
                listComments={listComments}
                reloadComments={reloadComments}
                handleUpdateComment={handleUpdateComment}
              />
            )}
            <Row style={{ paddingTop: '20px' }}>
              <Col className="text-right">
                <Button
                  title="Adicionar novo comentário"
                  color="success"
                  type="button"
                  onClick={() => handleCreateComment()}
                >
                  <FiPlus size={16} /> Adiciona Comentário
                </Button>
              </Col>
            </Row>

            <CommentModal
              render={getCommnents}
              contactId={contactId}
              isModalOpen={openModalCreateComment}
              onModalToggle={setOpenModalCreateComment}
              unmountOnClose={true}
            />

            <ModalComment
              render={getCommnents}
              dataComment={dataCommentUpdate}
              isModalOpen={openModalUpdateComment}
              onModalToggle={setOpenModalUpdateComment}
              unmountOnClose={true}
            />

            <div className="mt-3" style={sectionStyle}>
              <h2>{`Protocolo (${contactEdit?.protocols?.length || 0})`}</h2>
              <ContactProtocols protocols={contactEdit?.protocols} />
            </div>
          </CardBody>
        </Card>
      </Container>
      <TemplatesModal
        isModalOpen={isModalTemplateMessageContactOpen}
        onModalToggle={setIsModalTemplateMessageContactOpen}
        clientNumber={whatsAppMessageContactInfo.telefone}
        clientName={whatsAppMessageContactInfo.nome}
        clientId={whatsAppMessageContactInfo.clientId ?? ''}
        isIsolated={true}
      />
    </>
  );
};

export default ContactDetails;
