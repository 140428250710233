import React from 'react';
import { HiChat } from 'react-icons/hi';

import AgentIndicatorCard from '../AgentIndicatorCard';

const ChatAnswerIndicator = ({ data }) => {
  return (
    <AgentIndicatorCard icon={HiChat}>
      <AgentIndicatorCard.Header>
        <AgentIndicatorCard.Title>Conversas Atendidas</AgentIndicatorCard.Title>
      </AgentIndicatorCard.Header>
      <AgentIndicatorCard.Body value={data} />
    </AgentIndicatorCard>
  );
};

export default React.memo(ChatAnswerIndicator);
