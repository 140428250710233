import { cloneDeep } from 'lodash';
import { useEffect, useState, useCallback, useMemo } from 'react';
import io from 'socket.io-client';

import { queueAgentEvents } from '../utils/events';
import { queueAgentStatus } from '../utils/states';

const handledEvents = [
  queueAgentEvents.QUEUE_MEMBER,
  queueAgentEvents.QUEUE_MEMBER_ADDED,
  queueAgentEvents.QUEUE_MEMBER_REMOVED,
  queueAgentEvents.QUEUE_MEMBER_UNREGISTERED,
  queueAgentEvents.QUEUE_MEMBER_PAUSE,
  queueAgentEvents.QUEUE_MEMBER_AVAILABLE,
  queueAgentEvents.QUEUE_MEMBER_RING,
  queueAgentEvents.QUEUE_MEMBER_RINGING,
  queueAgentEvents.QUEUE_MEMBER_TABULATION_PAUSE
];

const useAgentEvents = () => {
  const socket = useMemo(() => {
    return io(process.env.REACT_APP_PROXY_URL, {
      path: process.env.REACT_APP_AMI_SOCKET_PATH,
      transports: ['websocket'],
      upgrade: false,
      query: { token: localStorage.getItem('token') }
    });
  }, []);

  const [agents, setAgents] = useState([]);

  const alreadyExists = (allAgents, newAgent) => {
    return allAgents.some(
      (agent) =>
        agent.extension === newAgent.extension &&
        agent.queueName === newAgent.queueName
    );
  };

  const addAgent = useCallback((agent) => {
    setAgents((currentAgents) => {
      if (alreadyExists(currentAgents, agent)) return currentAgents;
      return [...currentAgents, agent];
    });
  }, []);

  const removeAgent = useCallback((agent) => {
    setAgents((currentAgents) => {
      return currentAgents.filter(
        (ag) =>
          ag.extension !== agent.extension || ag.queueName !== agent.queueName
      );
    });
  }, []);

  const changeToUnplaced = useCallback((agent) => {
    setAgents((ag) => {
      return ag.map((item) => {
        if (
          item.extension === agent.extension &&
          item.state !== queueAgentStatus.UNPLACED
        ) {
          item.state = queueAgentStatus.UNPLACED;
          item.date = agent.date;
          item.caller = null;
          item.destCaller = null;
          if (item.agent.pause) {
            item.agent.pause.show_alert = false;
          }
        }

        return item;
      });
    });
  }, []);

  const changeToPause = useCallback((agent) => {
    setAgents((ag) => {
      const currentAgents = cloneDeep(ag);

      return currentAgents.map((item) => {
        if (
          item.extension === agent.extension &&
          item.state !== queueAgentStatus.PAUSE
        ) {
          item.state = queueAgentStatus.PAUSE;
          item.date = agent.date;
          item.caller = null;
          item.destCaller = null;
          item.agent.pause = agent.agent.pause;
          if (item.agent.pause) {
            item.agent.pause.show_alert = false;
          }
        }

        return item;
      });
    });
  }, []);

  const changeToAvailable = useCallback((agent) => {
    setAgents((ag) => {
      return ag.map((item) => {
        if (
          item.extension === agent.extension &&
          item.state !== queueAgentStatus.AVAILABLE
        ) {
          item.state = queueAgentStatus.AVAILABLE;
          item.date = agent.date;
          item.caller = null;
          item.destCaller = null;
          item.agent.pause = null;
          if (item.agent.pause) {
            item.agent.pause.show_alert = false;
          }
        }

        return item;
      });
    });
  }, []);

  const changeToRing = useCallback((agent) => {
    setAgents((ag) => {
      return ag.map((item) => {
        if (
          item.extension === agent.extension &&
          item.state !== queueAgentStatus.RING
        ) {
          item.state = queueAgentStatus.RING;
          item.caller = agent?.caller;
          item.isIncomingCall = agent.isIncomingCall;
          item.date = agent.date;
          if (item.agent.pause) {
            item.agent.pause.show_alert = false;
          }
        }

        return item;
      });
    });
  }, []);

  const changeToRinging = useCallback((agent) => {
    setAgents((ag) => {
      return ag.map((item) => {
        if (
          item.extension === agent.extension &&
          item.state !== queueAgentStatus.RINGING
        ) {
          item.state = queueAgentStatus.RINGING;
          item.isIncomingCall = true;
          item.date = agent.date;
          item.caller = agent.caller;
          if (item.agent.pause) {
            item.agent.pause.show_alert = false;
          }
        }

        return item;
      });
    });
  }, []);

  const changeToPauseAlert = useCallback((agent) => {
    setAgents((ag) => {
      const currentAgents = cloneDeep(ag);
      return currentAgents.map((item) => {
        if (item.extension === agent.extension) {
          item.state = queueAgentStatus.PAUSE;
          item.date = agent.date;
          item.caller = null;
          item.destCaller = null;
          item.agent.pause = agent.agent.pause;
          item.agent.pause.show_alert = true;
        }

        return item;
      });
    });
  }, []);

  const changeToTabulationPause = useCallback((agent) => {
    setAgents((ag) => {
      return ag.map((item) => {
        if (
          item.extension === agent.extension &&
          item.state !== queueAgentStatus.TABULATION_PAUSE
        ) {
          item.state = queueAgentStatus.TABULATION_PAUSE;
          item.date = agent.date;
          item.caller = null;
          item.destCaller = null;
          if (item.agent.pause) {
            item.agent.pause.show_alert = false;
          }
        }

        return item;
      });
    });
  }, []);

  const eventHandler = useCallback(
    (event, data) => {
      if (!handledEvents.includes(event)) return;

      const handlers = {
        [queueAgentEvents.QUEUE_MEMBER]: (data) => addAgent(data),
        [queueAgentEvents.QUEUE_MEMBER_ADDED]: (data) => addAgent(data),
        [queueAgentEvents.QUEUE_MEMBER_REMOVED]: (data) => removeAgent(data),
        [queueAgentEvents.QUEUE_MEMBER_UNREGISTERED]: (data) =>
          changeToUnplaced(data),
        [queueAgentEvents.QUEUE_MEMBER_PAUSE]: (data) => changeToPause(data),
        [queueAgentEvents.QUEUE_MEMBER_AVAILABLE]: (data) =>
          changeToAvailable(data),
        [queueAgentEvents.QUEUE_MEMBER_RING]: (data) => {
          changeToRing(data);
        },
        [queueAgentEvents.QUEUE_MEMBER_RINGING]: (data) =>
          changeToRinging(data),
        [queueAgentEvents.QUEUE_MEMBER_TABULATION_PAUSE]: (data) =>
          changeToTabulationPause(data)
      };

      handlers[event](data);
    },
    [
      addAgent,
      removeAgent,
      changeToUnplaced,
      changeToPause,
      changeToAvailable,
      changeToRinging,
      changeToRing,
      changeToTabulationPause
    ]
  );

  useEffect(() => {
    socket.on(queueAgentEvents.QUEUE_MEMBER_PAUSE_ALERT, (data) => {
      changeToPauseAlert(data);
    });
  }, [changeToPauseAlert, socket]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('useAgentEvents Socket Connected');
    });
    socket.emit(queueAgentEvents.GET_QUEUE_MEMBERS);
    return () => socket.disconnect();
  }, [socket]);

  useEffect(() => {
    socket.on(queueAgentEvents.QUEUE_MEMBER_PAUSE_ALERT, (data) => {
      changeToPauseAlert(data);
    });
  }, [changeToPauseAlert, socket]);

  useEffect(() => {
    socket.onAny((event, member) => {
      eventHandler(event, member);
    });

    return () => {
      if (socket) socket.offAny();
    };
  }, [socket, eventHandler]);

  return {
    agents,
    socket
  };
};

export default useAgentEvents;
