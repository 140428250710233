import React from 'react';
import { Row } from 'reactstrap';

import DefaultModal from 'components/Modal/DefaultModal';

import { ColTemplate } from './style';
import userAgentSingleton from 'components/Navbars/ServicePanel/softphone/userAgent/userAgentSingleton';

export default function TransferModal({
  isModalOpen,
  onModalToggle,
  extension,
  ...props
}) {
  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title={`Transferir para ${extension}`}
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <>
        <Row>
          <ColTemplate
            sm="12"
            onClick={() => {
              onModalToggle(false);
              userAgentSingleton.attendedTransfer(extension);
            }}
            style={{
              cursor: 'pointer'
            }}
            // className={`colTemplateMessageHover${templateIndex}`}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <h4>{`Transferência Atendida`}</h4>
            </div>
          </ColTemplate>

          <ColTemplate
            sm="12"
            onClick={() => {
              onModalToggle(false);
              userAgentSingleton.blindedTransfer(extension);
            }}
            style={{
              cursor: 'pointer'
            }}
            // className={`colTemplateMessageHover${templateIndex}`}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <h4>{`Transferência Cega`}</h4>
            </div>
          </ColTemplate>
        </Row>
      </>
    </DefaultModal>
  );
}
