import React from 'react';
import './style.css';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import FlexSelect from 'components/Inputs/FlexSelect';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Loader from 'react-loader-spinner';

const SelectRamalSoftphone = ({ onSelect, isLoading, extensions }) => {
  const { control, handleSubmit, errors, setValue } = useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const onSubmit = (data) => {
    const filteredExtensions = extensions.filter(
      (extension) => data.extension_number === extension.extension_number
    );
    if (filteredExtensions.length > 0) {
      const extension = filteredExtensions[0];

      onSelect({
        number: extension.extension_number,
        secret: extension.secret
      });
    }
  };

  return (
    <div className="modalwebrtc">
      <p>Escolha o ramal desejado para iniciar o atendimento</p>
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={(props) => {
            return (
              <div
                style={{
                  marginBottom: '20px'
                }}
              >
                <FlexSelect
                  dataOptions={extensions}
                  isMulti={false}
                  value={props.value}
                  placeholder="Selecione um ramal"
                  isClearable={true}
                  valueController={setValue}
                  closeMenuOnSelect={true}
                  fieldName="extension_number"
                  labelName="extension_number"
                  valueName="extension_number"
                />
              </div>
            );
          }}
          defaultValue=""
          control={control}
          rules={{ required: 'Campo obrigatório!' }}
          name="extension_number"
        />
        <ErrorMessage
          errors={errors}
          name="extension_number"
          render={({ message }) => errorFormMessage(message)}
        />

        {isLoading ? (
          <Loader type="TailSpin" color="#9ab744" height={50} width={50} />
        ) : (
          <Button
            type="submit"
            title="Conectar"
            style={buttonStyle(systemConfiguration.primary_color)}
          >
            Conectar
          </Button>
        )}
      </Form>
    </div>
  );
};

export default SelectRamalSoftphone;
