import React, { useState } from 'react';
import { Modal, Button } from 'reactstrap';

import { ConversationCard } from './components/Card';

const ConversationDetails = ({
  conversationData,
  getDataOnModalOpen = false,
  disabled = false,
  id = ''
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        color="success"
        size="sm"
        title={'Detalhes'}
        onClick={toggleModal}
        disabled={disabled}
        id={`${id}-conversation-details`}
      >
        {<i className={'fas fa-info-circle'}></i>}
      </Button>
      <Modal
        backdrop={true}
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
        centered={true}
      >
        <ConversationCard
          conversationData={conversationData}
          getDataOnModalOpen={getDataOnModalOpen}
        />
      </Modal>
    </>
  );
};

export default ConversationDetails;
