import React, { useEffect, useState } from 'react';

import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import { TotalRegister } from '../../../../../components/TotalRegister';

const PauseTable = (props) => {
  const [item, SetItem] = useState([]);
  useEffect(() => {
    const itemsShowTable = props.showReports.map((item) =>
      Object.assign({}, item)
    );
    SetItem(itemsShowTable);
  }, [props.showReports]);

  return (
    <>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <TotalRegister totalRegister={props.totalRegister} />
            <Card>
              <ToolkitProvider
                data={item}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'name',
                    text: 'Atendente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'ccs',
                    text: 'Conversas com satisfação',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'sm',
                    text: 'Satisfação média',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (_, Row) => {
                      return Row.sm ? (+Row.sm).toFixed(2) : '-';
                    }
                  },
                  {
                    dataField: 'ct',
                    text: 'Total',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tma',
                    text: 'TMA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tme',
                    text: 'TME',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tta',
                    text: 'TTA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default PauseTable;
