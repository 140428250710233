import React, { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Label
} from 'reactstrap';

import QueueService from '../../../Queue/service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import HourMaskMMHHSS from '../../../../components/Inputs/HourMaskMMHHSS.js';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import ChatBotReportService from './service';
import Pagination from 'components/Pagination';

import {
  dateRules,
  hourRules,
  queueNameId,
  toISOFormat,
  watsonToId
} from './utils';
import TableAnalytical from './tables/Analytical';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

const errorFormMessage = (message) => <p style={{ color: 'red' }}>{message}</p>;

const formatDates = (date, time) => {
  const [day, month, year] = date.split('/');
  const [hour, minute, second] = time.split(':');
  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};

const ChatBotReport = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const [queues, setQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [watsonList, setWatsonList] = useState([]);
  const [optionsWatson, setOptionsWatson] = useState([]);
  const [queueShowDisable, setQueueShowDisable] = useState(false);
  const [showWatsonDisable, setShowWatsonDisable] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);

  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const optionWatson = watch('watson');

  async function loadTableConversation(pagina) {
    setLoadingReport(true);
    const response = await ChatBotReportService.getHistoryWatson({
      finalDate: formatDates(watch('dateBegin'), watch('timeStart')),
      initialDate: formatDates(watch('dateEnd'), watch('timeEnd')),
      watsonId: optionWatson,
      options: getValues()['watsonOption'],
      queueId: getValues()['queue'],
      page: pagina,
      limit: 10
    });

    setDataTable(response.data.data.data);
    setLoadingReport(false);
  }

  const handlePageChange = (page) => {
    setActivePage(page);
    loadTableConversation(page);
  };

  useEffect(() => {
    if (!optionWatson) return;
    ChatBotReportService.getOptionsWatson(optionWatson).then((response) => {
      const options = response?.data.data.map((option) => ({
        label: option,
        value: option
      }));
      setOptionsWatson(options ?? []);
    });
  }, [optionWatson]);

  useEffect(() => {
    QueueService.getAllShowDisable(queueShowDisable)
      .then((response) => {
        setQueues(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, [queueShowDisable]);

  useEffect(() => {
    getWatson({
      showDisable: showWatsonDisable
    });
  }, [showWatsonDisable]);

  const getWatson = ({ showDisable = true }) => {
    ChatBotReportService.getWatson({
      showDisable
    })
      .then((response) => {
        setLoading(false);
        setWatsonList(response?.data.data ?? []);
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar Watson!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  };

  const onSubmit = async (data) => {
    try {
      setLoadingReport(true);
      const response = await ChatBotReportService.getHistoryWatson({
        finalDate: formatDates(data?.dateEnd, data?.timeEnd),
        initialDate: formatDates(data?.dateBegin, data?.timeStart),
        watsonId: optionWatson,
        options: getValues()['watsonOption'],
        queueId: getValues()['queue'],
        showAllWatson: showWatsonDisable,
        page: 1,
        limit: 10
      });

      if (response?.data?.data.length === 0) {
        toast.info('Nenhum registro encontrado!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }

      setDataTable(response?.data?.data);
      setQuantity(response?.data?.totalFiltered);
      setLoadingReport(false);
    } catch (err) {
      console.log(err);
      setLoadingReport(false);
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Chatbot</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration?.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="4">
                          <label className="form-control-label">Watson</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={watsonList}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="watson"
                                labelName="nome_canal"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="watson"
                            defaultValue=""
                          />
                        </Col>
                        <Col sm="4">
                          <label className="form-control-label">
                            Opção do Fluxo
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={optionsWatson}
                                isMulti={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                fieldName="watsonOption"
                                labelName="label"
                                valueName="value"
                                isDisabled={
                                  optionWatson && optionWatson?.length > 0
                                    ? false
                                    : true
                                }
                              />
                            )}
                            control={control}
                            name="watsonOption"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">Fila</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="queue"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queue"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateBegin"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateBegin"
                            rules={dateRules}
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateBegin"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Hora inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeStart"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeStart"
                            rules={hourRules}
                            defaultValue="00:00:00"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeStart"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data final*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateEnd"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateEnd"
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                            rules={{
                              ...dateRules,
                              validate: (value) => {
                                if (
                                  new Date(toISOFormat(value, true)) <
                                  new Date(
                                    toISOFormat(getValues()['dateBegin'], true)
                                  )
                                ) {
                                  return 'A data final não pode ser anterior à inicial!';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Hora final*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeEnd"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeEnd"
                            rules={hourRules}
                            defaultValue="23:59:59"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        {/* <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignContent: 'flex-start'
                          }}
                        >
                          <div>
                            <Label>Mostrar desabilitados:</Label>
                          </div>
                          <div>
                            <ShowDisable
                              label="Filas"
                              active={!queueShowDisable}
                              onClick={() =>
                                setQueueShowDisable(!queueShowDisable)
                              }
                              Icon={HiQueueList}
                              maxWidth="100px"
                            />
                            <ShowDisable
                              label="Watson"
                              active={!showWatsonDisable}
                              onClick={() =>
                                setShowWatsonDisable(!showWatsonDisable)
                              }
                              Icon={BsRobot}
                              maxWidth="100px"
                            />
                          </div>
                        </div> */}

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignContent: 'flex-start'
                          }}
                        >
                          <Label>Mostrar desabilitados:</Label>
                          <div>
                            <div>
                              <ToggleSwitch
                                checked={queueShowDisable}
                                id="showDisableQueueReportChatBot"
                                onChange={() =>
                                  setQueueShowDisable(!queueShowDisable)
                                }
                              />

                              <Label>Filas</Label>
                            </div>

                            <div>
                              <ToggleSwitch
                                checked={showWatsonDisable}
                                id="showDisableWatsonReportChatBot"
                                onChange={() =>
                                  setShowWatsonDisable(!showWatsonDisable)
                                }
                              />

                              <Label>Watson</Label>
                            </div>
                          </div>
                        </div>
                      </Row>

                      <hr />

                      <Button
                        style={buttonStyle(systemConfiguration?.primary_color)}
                        type="submit"
                      >
                        Buscar
                      </Button>
                    </Form>
                  )}

                  {loadingReport ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration?.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    dataTable?.length > 0 && (
                      <>
                        <TableAnalytical
                          data={dataTable}
                          watson={watsonToId(watsonList)}
                          queues={queueNameId(queues)}
                          totalRegister={quantity}
                        />
                        <Pagination
                          activePage={activePage}
                          totalItemsCount={quantity}
                          itemsCountPerPage={10}
                          onChange={handlePageChange.bind(this)}
                        />
                      </>
                    )
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ChatBotReport;
