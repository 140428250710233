import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select/async';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import Tour from 'reactour';
import Loader from 'react-loader-spinner';
import { Alert } from 'reactstrap';

import { Row, Form, Col, Input, Button } from 'reactstrap';
import { FaHistory, FaRegCalendarAlt } from 'react-icons/fa';
import FlexSelect from 'components/Inputs/FlexSelect';

import ProtocolConfigService from '../../../../ProtocolConfiguration/service';

import ProtocolService from '../../../service';
import SubReasonService from '../../../../subReason/service';
import TelephoneMask from '../../../../../components/Inputs/TelephoneMask';
import { steps } from '../../assets/steps';
import {
  maskPhone,
  definedShowName,
  validateFields
} from '../../assets/functions';
import ModalFilterClients from '../../../../Clients/components/ModalFilterClients';
import { custom_select } from '../../../../../assets/styles/multiple_select';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { useSearchClients } from '../../hooks/useSearchClients';
import { useUsers } from '../../../../Report/Protocol/hooks/useUsers';
import { useProtocolDefaultFieldsSettings } from '../../../../fieldConfigs/hooks/useProtocolDefaultFieldsSettings';
import { useListReasonByRecordType } from '../../../../reason/list/hooks/useListReasonByRecordType';
import { useListStatusByRecordType } from '../../../../status/list/hooks/useListStatusByRecordType';
import { ClientProtocolModal } from 'views/Clients/list/components/ClientProtocol/components/ClientProtocolModal';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';

import { removeMaskSpecialCharacters } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { useSearchAccounts } from 'views/CRM/Account/hooks/useSearchAccounts';
import AddContactModal from '../AddContactModal';
import ModalSchedulingProtocol from '../ModalSchedulingProtocol';

// react-grid-layout
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import CustomFieldsInputsForm from 'views/CRM/shared/components/CustomFieldsInputsForm';
import { useCustomFields } from '../../context/CustomFieldsContext';
import Counter from '../Counter';
import {
  AreaPreviewProtocol,
  FileProtocolArea,
  UploadButtonProtocol
} from '../styledComponents/styled';
import { FiUpload } from 'react-icons/fi';
import { ListFileAttachments } from 'views/CRM/shared/components/ListFileAttachments/ListFileAttachments';
import { FileAttachments } from 'views/CRM/shared/components/ListFileAttachments/FileAttachments';
import { AddNewContactButton } from 'components/Buttons/AddNewContactButton';
import { SearchContactButton } from 'components/Buttons/SearchContactButton';
const ResponsiveGridLayout = WidthProvider(Responsive);
const _ = require('lodash');

const ProtocolForm = (props) => {
  const propsMemo = useMemo(() => props, [props]);
  const emailRegex = useMemo(() => {
    // eslint-disable-next-line no-useless-escape
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  }, []);

  const fileAttachmentsPreview = useMemo(
    () => new FileAttachments(),
    [loadingFile]
  );

  const attachments = useMemo(
    () => fileAttachmentsPreview.attachments,
    [fileAttachmentsPreview.attachments]
  );

  const loggedInUser = localStorage.getItem('login');

  const closeModal = propsMemo?.modalActions?.closeModal;
  const defaultFormData = propsMemo?.modalActions.defaultFormData;

  const selectedRecordTypeId =
    defaultFormData?.protocol?.record_type_id ??
    propsMemo?.selectedRecordTypeId;

  const {
    inAttendanceScreen = false,
    setProtocolCreated = () => {},
    conversation = '',
    activeFormCreatedProtocol = false,
    forceSaveProtocol,
    startCounter
  } = propsMemo;

  const protocolTelephoneNumber = defaultFormData?.telephoneNumber ?? '';
  const getProtocolConfig = async () => {
    const responseConfigProtocol = await ProtocolConfigService.getConfigs(
      recordType
    );
    setConfig(responseConfigProtocol?.data?.content);
  };

  // Dados disponíveis quando esse componente é rederizado por um modal (no caso no painel de atendimento)

  const [loadingSubreasons, setLoadingSubreasons] = useState(false);

  const setProtocol =
    propsMemo?.setProtocol && inAttendanceScreen ? propsMemo.setProtocol : '';

  const setActiveFormCreatedProtocol =
    propsMemo?.setActiveFormCreatedProtocol ?? '';

  const isProtocolAlreadyCreated = defaultFormData?.protocol;

  //Formulário
  const { control, handleSubmit, errors, setValue, reset, watch } = useForm();
  const formValues = watch();

  //States
  const [config, setConfig] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);
  const [subReasons, setSubReasons] = useState([]);
  const [nameClient, setNameClient] = useState('');
  const [recordType] = useState(selectedRecordTypeId);
  const [messageError, setMessageError] = useState('');
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [selectedClient, setSelectClient] = useState('');
  const [pauseCounter, setPauseCounter] = useState(true);
  const [errorInUpload, setErrorInUpload] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [clientDefaultData, setClientDefaultData] = useState({});
  const [isLayoutsUpdated, setIsLayoutsUpdated] = useState(false);
  const [loadingSubReasons, setLoadingSubReasons] = useState(false);
  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false);
  const [isClientSearchModalOpen, setIsClientSearchModalOpen] = useState(false);

  const [controlValidSelectClient, setControlValidSelectClient] =
    useState(false);
  const [controlValidSelectAccount, setControlValidSelectAccount] =
    useState(false);
  const [isHistoryModalProtocolOpen, setIsHistoryModalProtocolOpen] =
    useState(false);
  const [successCreateProtocolo, setSuccessCreateProtocolo] = useState({
    success: false,
    idProtocol: null
  });
  const [protocolNumber, setProtocolNumber] = useState(
    defaultFormData?.protocol?.number
  );

  const sortedLayouts = useMemo(() => {
    const sorted = layouts.sort((a, b) => {
      if (a.y === b.y) {
        return a.x - b.x;
      }

      return a.y - b.y;
    });

    return sorted;
  }, [layouts]);

  //hooks
  const { userList } = useUsers(false);
  const { changeFieldsReference } = useCustomFields();
  const { isSearching, filterClients } = useSearchClients();
  const { filterAccounts, isSearching: isSearchingAccounts } =
    useSearchAccounts();
  const { reasons, getReasonsByRecordType } =
    useListReasonByRecordType(recordType);
  const { status, getStatusByRecordType } =
    useListStatusByRecordType(recordType);
  const { protocolDefaultFieldsDisplaySettings } =
    useProtocolDefaultFieldsSettings(recordType);

  const { customFields, getCustomFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType('PROTOCOL', recordType);

  const protocolFields = useMemo(() => {
    let arrDefaultFields = [];

    for (const key in protocolDefaultFieldsDisplaySettings) {
      if (
        protocolDefaultFieldsDisplaySettings.hasOwnProperty(key) &&
        protocolDefaultFieldsDisplaySettings[key].isActive
      ) {
        {
          arrDefaultFields.push(key);
        }
      }
    }

    const arrCustomFields = customFields.map((item) => item.name);

    const info = [...arrDefaultFields, ...arrCustomFields];

    return info;
  }, [protocolDefaultFieldsDisplaySettings, customFields]);

  const validaReenvioDeAnexos = async () => {
    const existAttachments = fileAttachmentsPreview.attachmentsSubmited.length;
    const { success, idProtocol } = successCreateProtocolo;
    if (!errorFormMessage || !success || !idProtocol) return false;

    if (existAttachments) {
      if (!(await handlerUploadFiles(idProtocol))) {
        setLoading(false);
        return false;
      }

      toast.success('Anexos enviados com sucesso!.', {
        autoClose: 3000,
        closeOnClick: true
      });
      closeModal();
      fileAttachmentsPreview.reset();
      return true;
    }

    setSuccessCreateProtocolo({
      success: true,
      idProtocol: defaultFormData.protocol.id
    });

    toast.success('Protocolo atualizado sem anexos', {
      autoClose: 3000,
      closeOnClick: true
    });

    setLoading(false);
    return true;
  };

  const handleUpdateProtocol = async (dataDTO) => {
    const { id: protocolId } = defaultFormData.protocol;
    delete dataDTO.number;
    await ProtocolService.updateNoStrictByRecordType(
      protocolId,
      recordType,
      dataDTO
    );

    if (defaultFormData.protocol.id) {
      setSuccessCreateProtocolo({
        success: true,
        idProtocol: protocolId
      });

      const result = await handlerUploadFiles(protocolId);
      if (!result) {
        toast.success('Protocolo atualizado sem anexos', {
          autoClose: 3000,
          closeOnClick: true
        });

        setLoading(false);
        return;
      }
    }
  };

  const handleCreateProtocolEndChat = (dataDTO) => {
    setProtocol({ data: dataDTO, sucess: true });
    setProtocolCreated(true);
    setActiveFormCreatedProtocol(false);
    setLoading(false);
    changeFieldsReference({});
    toast.success('Protocolo salvo com sucesso ', {
      autoClose: 2000,
      closeOnClick: true
    });
    fileAttachmentsPreview.reset();
    closeModal();
    return;
  };

  const handleCreateProtocol = async (dataDTO, isEndChat = false) => {
    const protocol = await ProtocolService.createProtocolByRecordType(
      recordType,
      dataDTO
    );

    if (protocol) {
      if (!isEndChat) {
        setSuccessCreateProtocolo({
          success: true,
          idProtocol: protocol.data.content.id
        });
      }

      const result = await handlerUploadFiles(protocol.data.content.id);
      if (!result) {
        setProtocolCreated(true);
        setLoading(false);
        closeModal();
        return protocol;
      }

      if (!isEndChat) {
        setProtocolCreated(true);
        setLoading(false);
        closeModal();
      }
      return protocol;
    }

    return false;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    if (!validateFieldsAttachments()) return;

    if (await validaReenvioDeAnexos()) return;

    const skipValidation = !!data.saved_automatically;
    if (props.createdFrom) data.parent_id = props.createdFrom;
    if (!data.number) data.number = protocolNumber;
    try {
      const dataToSubmit = {
        ...data,
        acctid: propsMemo?.deafultDataAcctid || '',
        telephone: removeMaskSpecialCharacters(data.telephone)
      };

      const dataSubmitWithoutNull = Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(dataToSubmit).filter(([_, v]) => v != null)
      );

      const dataDTO = formatCustomFieldsToSubmit(
        dataSubmitWithoutNull,
        customFields
      );

      dataDTO.schedule = selectedSchedule;
      dataDTO.files = fileAttachmentsPreview.attachmentsSubmited.length;

      const { client_id, account_id } =
        protocolDefaultFieldsDisplaySettings || {};
      const clientIdActive = client_id?.isActive;
      const clientIdRequired = client_id?.isRequired;
      const isClientRequiredAndActive = clientIdRequired && clientIdActive;
      const accountRequired = account_id?.isRequired;
      const accountActive = account_id?.isActive;

      if (!selectedClient && isClientRequiredAndActive && !skipValidation) {
        setControlValidSelectClient(!controlValidSelectClient);
        setLoading(false);
        return;
      }
      if (clientIdActive) dataDTO.client_id = selectedClient;

      const isAccountRequiredAndActive = accountRequired && accountActive;

      if (!selectedAccount && isAccountRequiredAndActive && !skipValidation) {
        setControlValidSelectAccount(true);
        setLoading(false);
        return;
      }
      if (accountActive) dataDTO.account_id = selectedAccount?.id;

      if (isProtocolAlreadyCreated) return handleUpdateProtocol(dataDTO);

      //Quando o protocolo é criado no fim da conversa
      const isEndChat = propsMemo?.displayEndButton;
      let protocol;
      if (isEndChat) {
        protocol = await handleCreateProtocol(dataDTO, isEndChat);
        if (!protocol) return;
        let info = {};
        info = protocol?.data?.content;
        info.protocolAlreadyCreated = true;
        setActiveFormCreatedProtocol(false);
        setProtocolCreated(true);
        setProtocol({ data: info, sucess: true });
        toast.success('Protocolo criado com sucesso');

        return;
      }

      protocol = await handleCreateProtocol(dataDTO);
      toast.success('Protocolo criado com sucesso');
      if (inAttendanceScreen && protocol) {
        handleCreateProtocolEndChat(dataDTO);
      }

      setLoading(false);
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
      if (inAttendanceScreen && error) {
        setProtocol({ data: error, sucess: false });
        setProtocolCreated(true);
        setActiveFormCreatedProtocol(false);
      }
      const msg = error.response?.data?.message;
      toast.error(msg || 'Erro ao cadastrar protocolo', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const fetchProtocolPosition = async () => {
    setLoadingForm(true);
    try {
      const response = await ProtocolConfigService.getProtocolPosition(
        recordType,
        'PROTOCOL'
      );
      const positions = response?.data?.positions;
      if (positions) {
        setLayouts(positions);
        setIsLayoutsUpdated(true);
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao carregar a posição dos campos do protocolo', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setLoadingForm(false);
    }
  };

  const getSubReasonsByReasonId = async (id) => {
    setLoadingSubReasons(true);

    if (!id) {
      setSubReasons([]);
      setLoadingSubReasons(false);
      return;
    }

    try {
      const response = await SubReasonService.getByReasonId(id);
      const subReasons = response.data.content;
      const sortedSubReasons = subReasons.sort((a, b) => {
        let nameA = a.name.toUpperCase();
        let nameB = b.name.toUpperCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
      setSubReasons(sortedSubReasons);
    } catch (error) {
      const { data } = error.response;
      toast.error(data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setLoadingSubReasons(false);
    }
  };

  const uploadFiles = async (protocolId) => {
    const files = fileAttachmentsPreview.getAttachmentsSubmited();

    if (!files?.length) return false;

    const formData = new FormData();
    for (const file of files) {
      formData.append('attachments', file, file.name);
    }

    return ProtocolService.addAttachments(protocolId, formData);
  };

  const validateFieldsAttachments = () => {
    const { attachmentsWithoutType, messageErrors } =
      fileAttachmentsPreview.validateFiles();

    if (attachmentsWithoutType.length) {
      setErrorInUpload(true);
      setMessageError(
        `Os arquivos ${attachmentsWithoutType.join(', ')} não são permitidos `
      );
      setLoading(false);
      return false;
    }

    if (messageErrors.length) {
      setErrorInUpload(true);
      setLoading(false);
      setMessageError(
        `Os arquivos ${messageErrors.join(', ')} não são permitidos`
      );
      return false;
    }

    return true;
  };

  const handlerUploadFiles = async (protocolId) => {
    try {
      const data = await uploadFiles(protocolId);
      if (!data) return false;
      if (data.data.content) {
        fileAttachmentsPreview.reset();
        return true;
      }
      return false;
    } catch (error) {
      console.log('error', error);
      setErrorInUpload(true);
      setMessageError(error.response?.data?.message);
      toast.error(
        error.response?.data?.message ||
          'Alguns anexos não poderam ser salvos tente novamente',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
      setProtocolCreated(true);
      return false;
    }
  };

  const getPhoneWithoutCountryCode = (phone) => {
    const standardSizePhoneWithNine = 13;
    const standardSizePhoneWithoutNine = 12;

    if (phone.length === standardSizePhoneWithNine) {
      return phone.substr(2, 13);
    }

    if (phone.length === standardSizePhoneWithoutNine) {
      return phone.substr(2, 12);
    }

    return phone;
  };

  const handlerSelectFiles = (e) => {
    setLoadingFile(true);

    for (const file of e.target.files) {
      if (file.size > 10 * 1024 * 1024) {
        toast.error('Tamanho máximo permitido para anexo é de 10MB');
        setLoadingFile(false);
        return;
      }

      fileAttachmentsPreview.addAttachment({
        name: file.name,
        data: file
      });
    }

    setValue('files', e.target.files);
    e.target.value = null;
    setLoadingFile(false);
  };

  const toggleHistoryModalProtocol = () => {
    setIsHistoryModalProtocolOpen(!isHistoryModalProtocolOpen);
  };

  const handleGetSubReasons = useCallback(async (event) => {
    let value = '';
    if (event && event.target) {
      value = event.target.value;
    } else {
      value = event;
    }

    await getSubReasonsByReasonId(value);
  }, []);

  const forceSubmit = () => {
    onSubmit({ ...formValues, saved_automatically: true });
  };

  const openTour = () => {
    setTourOpen(true);
  };

  const closeTour = () => {
    setTourOpen(false);
  };

  useEffect(() => {
    if (!propsMemo.modalActions.isOpen) {
      fileAttachmentsPreview.reset();
    }
  }, [propsMemo.modalActions.isOpen]);

  useEffect(() => {
    if (startCounter) setPauseCounter(false);
  }, [startCounter]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    getProtocolConfig();
    fetchProtocolPosition();
  }, []);

  useEffect(() => {
    setLoadingForm(isLoadingCustomFields);
  }, [isLoadingCustomFields]);

  useEffect(() => {
    getReasonsByRecordType();
  }, [getReasonsByRecordType]);

  useEffect(() => {
    getStatusByRecordType();
  }, [getStatusByRecordType]);

  useEffect(() => {
    if (defaultFormData && !isProtocolAlreadyCreated) {
      setClientDefaultData(defaultFormData);

      const client =
        (defaultFormData?.client && defaultFormData?.client?.id) || '';

      setSelectClient(client);

      let objectData = {};

      //setar todos os valores possiveis que vem do socket
      Object.keys(defaultFormData).forEach((dt) => {
        if (protocolDefaultFieldsDisplaySettings) {
          if (`${dt}` === 'reason_id') {
            handleGetSubReasons(defaultFormData[dt]);
          }

          objectData[`${dt}`] = `${defaultFormData[dt]}`;
        }
      });

      reset(objectData);
    }
  }, [
    defaultFormData,
    protocolDefaultFieldsDisplaySettings,
    reset,
    handleGetSubReasons,
    isProtocolAlreadyCreated
  ]);

  useEffect(() => {
    if (!isProtocolAlreadyCreated) return;

    const { client, protocol } = defaultFormData || {};
    const clientId = client?.id || '';

    setClientDefaultData(defaultFormData);
    setSelectClient(clientId);

    handleGetSubReasons(protocol?.reason_id);

    delete defaultFormData?.protocol.number;
    delete defaultFormData?.protocol.telephone;
    delete defaultFormData?.protocol.current_responsible;

    reset({
      ...defaultFormData?.protocol,
      service_channel: defaultFormData?.serviceChannel
    });
  }, [reset, defaultFormData, handleGetSubReasons, isProtocolAlreadyCreated]);

  useEffect(() => {
    if (!isProtocolAlreadyCreated) return;

    const { client, protocol } = defaultFormData || {};
    const clientId = client?.id || '';

    setClientDefaultData(defaultFormData);
    setSelectClient(clientId);

    handleGetSubReasons(protocol?.reason_id);

    delete defaultFormData?.protocol.number;
    delete defaultFormData?.protocol.telephone;
    delete defaultFormData?.protocol.current_responsible;

    reset({
      ...defaultFormData?.protocol,
      service_channel: defaultFormData?.serviceChannel
    });
  }, [reset, defaultFormData, handleGetSubReasons, isProtocolAlreadyCreated]);

  useEffect(() => {
    // Gerar Número de protocolo assim que a tela carrega
    const generateProtocolNumber = async () => {
      if (isProtocolAlreadyCreated) return;
      try {
        const response = await ProtocolService.generateNumberProtocol();
        setProtocolNumber(response.data.protocol_number);
      } catch (error) {
        toast.error('Erro ao gerar número do protocolo');
      }
    };
    generateProtocolNumber();
  }, [isProtocolAlreadyCreated]);

  useEffect(() => {
    if (!conversation) return;
    const { cliente, canal } = conversation;
    const { id, id_postgres, nome, telefone, id_whatsapp } = cliente;
    const clientId = id_postgres || id;
    const clientPhone = ['whatsapp', 'sms'].includes(canal) ? telefone : '';

    alterDefaultModalData({
      clientId,
      clientName: nome,
      clientPhone
    });

    const phone = telefone || id_whatsapp;

    if (phone) setValue('telephone', phone);
  }, [alterDefaultModalData, conversation, setValue]);

  const alterDefaultModalData = useCallback(
    ({ clientId, clientName, clientPhone, clientEmail = '' }) => {
      const newDefaultData = {
        telephoneNumber: clientDefaultData && clientDefaultData.telephoneNumber,
        serviceChannel: clientDefaultData && clientDefaultData.serviceChannel,
        client: {
          id: clientId,
          nome: clientName,
          phone: clientPhone,
          email: clientEmail
        }
      };

      setClientDefaultData(newDefaultData);
      setSelectClient(clientId);
      setNameClient(clientName);
      setIsClientSearchModalOpen(false);

      const resetData = {
        telephone: maskPhone(newDefaultData?.client?.phone),
        email: clientEmail
      };

      if (resetData.telephone) {
        setValue('telephone', resetData.telephone);
      } else {
        setValue('telephone', '');
      }

      if (resetData.clientEmail) {
        setValue('email', resetData.clientEmail);
      } else {
        setValue('email', '');
      }
    },
    [clientDefaultData, setValue, reset]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  useEffect(() => {
    if (!activeFormCreatedProtocol) return;
    handleSubmit(onSubmit)();
    setActiveFormCreatedProtocol(false);
  }, [activeFormCreatedProtocol, handleSubmit]);

  useEffect(() => {
    if (!protocolDefaultFieldsDisplaySettings || !isLayoutsUpdated) {
      return;
    }

    const protocolDefaultFieldsDisplaySettingsKeys = new Set(
      Object.keys(protocolDefaultFieldsDisplaySettings)
    );

    const newLayouts = layouts.filter((item) => {
      const settings = protocolDefaultFieldsDisplaySettings[item.i];
      return !(
        protocolDefaultFieldsDisplaySettingsKeys.has(item.i) &&
        settings &&
        settings.isOnlyToShowOnEdit
      );
    });

    setLayouts(newLayouts);
    setIsLayoutsUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLayoutsUpdated, protocolDefaultFieldsDisplaySettings]);

  useEffect(() => {
    if (forceSaveProtocol) {
      forceSubmit();
    }
  }, [forceSaveProtocol]);

  const renderCustomFields = () => {
    if (!customFields.length) return null;

    const customFieldsDraggables = customFields.map((field, index) => {
      let tabIndex = sortedLayouts.findIndex((item) => item.i === field.id);
      if (tabIndex === -1) {
        tabIndex = 0;
      } else {
        tabIndex = tabIndex + 1;
      }

      return field ? (
        <div
          key={field.id}
          data-grid={
            (layouts &&
              layouts.length > 0 &&
              layouts.find((item) => item.i === field.id)) || {
              x: Math.floor(Math.random() * 2) * 2,
              y: 8 + index,
              w: 2,
              h: 1,
              minW: 2,
              maxW: 4,
              minH: 1,
              maxH: 1
            }
          }
          style={{
            width: '100%',
            marginRight: '0',
            zIndex: field.type === 'DATE' ? '1' : '-1'
          }}
        >
          <CustomFieldsInputsForm
            fields={[field]}
            control={control}
            errors={errors}
            tabIndex={tabIndex}
            watch={watch}
            setValue={setValue}
            Controller={Controller}
            draggableOptions={{
              itCanBeDragged: true,
              itIsDraggable: false
            }}
          />
        </div>
      ) : null;
    });

    return customFieldsDraggables;
  };

  const cloneParentProtocol = async () => {
    if (!props.fatherProtocol) return;
    const data = _.cloneDeep(props.fatherProtocol.protocol);
    const conta = props?.fatherProtocol?.account;
    const contato = props?.fatherProtocol?.contato;

    const newData = {};
    for (const key in data) {
      if (protocolFields.includes(key)) {
        newData[key] = data[key];
      }
    }
    if (data.reason_id) {
      await getSubReasonsByReasonId(data.reason_id);
    }

    if (protocolFields.includes('account_id') && conta) {
      setSelectedAccount({
        id: conta?.value,
        name: conta?.label
      });
    }

    if (protocolFields.includes('client_id') && contato) {
      setSelectClient(contato.id);
      setClientDefaultData({
        serviceChannel: undefined,
        tetelephoneNumber: undefined,
        client: {
          email: contato?.email,
          id: contato?.id,
          nome: contato?.nome,
          phone: contato?.telefone
        }
      });
    }

    newData.current_responsible = loggedInUser;

    reset(newData);
  };

  return (
    <>
      {loadingForm ? (
        <Row className="justify-content-md-center">
          <ReactLoader />
        </Row>
      ) : (
        <>
          {config && !!config?.timeToFillProtocolForm?.enabled && (
            <Counter
              isPaused={pauseCounter}
              initialSeconds={config?.timeToFillProtocolForm?.value ?? 0}
            ></Counter>
          )}
          {props.fatherProtocol && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: '13px'
              }}
            >
              <Button
                type="button"
                style={{
                  alignSelf: 'flex-end',
                  marginLeft: 10,
                  backgroundColor: `#0000ff`,
                  color: 'white'
                }}
                onClick={() => {
                  cloneParentProtocol();
                }}
              >
                Clonar Campos Pai
              </Button>
            </div>
          )}

          {propsMemo && (
            <Form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              {protocolDefaultFieldsDisplaySettings && recordType && (
                <ResponsiveGridLayout
                  className="layout"
                  layout={layouts}
                  breakpoints={{
                    lg: 1200,
                    md: 996,
                    sm: 768,
                    xs: 480,
                    xxs: 0
                  }}
                  cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 4 }}
                  rowHeight={84}
                  isDraggable={false}
                  isResizable={false}
                  style={{ zIndex: '1' }}
                >
                  <div
                    key={'protocolNumber'}
                    className="react-grid-layout-box"
                    data-grid={
                      (layouts &&
                        layouts.length > 0 &&
                        layouts.find(
                          (item) => item.i === 'protocolNumber'
                        )) || {
                        x: 2,
                        y: 0,
                        w: 2,
                        h: 1,
                        minW: 2,
                        maxW: 4,
                        minH: 1,
                        maxH: 1,
                        static: true
                      }
                    }
                  >
                    {protocolNumber && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <FaRegCalendarAlt
                          style={{
                            fontSize: '1.4rem',
                            display: 'inline',
                            color: 'rgb(0, 100, 140)',
                            cursor: 'pointer',
                            marginRight: '0.2rem',
                            marginTop: '2rem'
                          }}
                          id="tour4"
                          onClick={() => setIsSchedulingModalOpen(true)}
                        />
                        <Col id="tour1">
                          <label className="form-control-label">
                            Nº. do Protocolo
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="number"
                            disabled={true}
                            defaultValue={protocolNumber}
                          />
                        </Col>
                      </div>
                    )}
                  </div>
                  {protocolDefaultFieldsDisplaySettings?.title?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.title
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'title'}
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find((item) => item.i === 'title')) || {
                            x: 0,
                            y: 1,
                            w: 4,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                      >
                        <Row className="mb-3">
                          <Col className="tour2">
                            <label className="form-control-label">
                              {definedShowName(
                                'Título',
                                protocolDefaultFieldsDisplaySettings.title
                                  .isRequired
                              )}
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              tabIndex={
                                sortedLayouts.findIndex(
                                  (item) => item.i === 'title'
                                ) + 1
                              }
                              name="title"
                              rules={validateFields(
                                protocolDefaultFieldsDisplaySettings.title
                                  .isRequired
                              )}
                              defaultValue={
                                conversation
                                  ? `[Protocolo Automático]: Contato ${conversation.cliente.nome} via ${conversation.canal}`
                                  : ''
                              }
                            />
                            <ErrorMessage
                              errors={errors}
                              name="title"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.client_id?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.client_id
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'client_id'}
                        className="react-grid-layout-box"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find((item) => item.i === 'client_id')) || {
                            x: 0,
                            y: 2,
                            w: 2,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                        style={{
                          zIndex: '1'
                        }}
                      >
                        <div className="d-flex">
                          <div style={{ flexGrow: '10' }} className="tour3">
                            <label className="form-control-label">
                              {definedShowName(
                                'Contato',
                                protocolDefaultFieldsDisplaySettings.client_id
                                  .isRequired
                              )}
                            </label>

                            <Select
                              styles={custom_select}
                              placeholder="Busca rápida..."
                              required={
                                protocolDefaultFieldsDisplaySettings?.client_id
                                  ?.isRequired
                              }
                              tabIndex={
                                sortedLayouts.findIndex(
                                  (item) => item.i === 'client_id'
                                ) + 1
                              }
                              isClearable={false}
                              name="client"
                              loadOptions={async (inputValue) => {
                                const res = await filterClients(inputValue);
                                return res;
                              }}
                              isLoading={isSearching}
                              loadingMessage={() => 'Buscando...'}
                              noOptionsMessage={({ inputValue }) =>
                                !inputValue
                                  ? 'Digite para pesquisar'
                                  : 'Nenhum resultado encontrado'
                              }
                              onChange={(data) => {
                                if (data) {
                                  const { label, value, telephone } = data;
                                  alterDefaultModalData({
                                    clientId: value,
                                    clientName: label,
                                    clientPhone: telephone,
                                    clientEmail: data.email
                                  });
                                }
                              }}
                              value={
                                clientDefaultData && clientDefaultData.client
                                  ? {
                                      label:
                                        clientDefaultData.client.nome || '',
                                      value: clientDefaultData.client.id
                                    }
                                  : ''
                              }
                              defaultValue={
                                clientDefaultData && clientDefaultData.client
                                  ? {
                                      label:
                                        clientDefaultData.client.nome || '',
                                      value: clientDefaultData.client.id
                                    }
                                  : '' || conversation
                                  ? {
                                      label: conversation.cliente.nome || '',
                                      value: conversation.cliente.id_postgres
                                    }
                                  : ''
                              }
                            />
                          </div>

                          <div
                            style={{
                              alignSelf: 'flex-end',
                              justifyContent: 'space-between',
                              padding: '0.6rem 0.6rem'
                            }}
                          >
                            <SearchContactButton
                              onClick={() => setIsClientSearchModalOpen(true)}
                            />

                            <AddContactModal
                              setSelectedContact={alterDefaultModalData}
                              protocolTelephoneNumber={
                                protocolTelephoneNumber || ''
                              }
                              buttonOpenModal={({ onClick }) => (
                                <AddNewContactButton onClick={onClick} />
                              )}
                            />

                            {clientDefaultData.client?.nome && (
                              <FaHistory
                                style={{
                                  fontSize: '1.6rem',
                                  display: 'inline',
                                  color: 'rgb(0, 150, 0)',
                                  cursor: 'pointer',
                                  paddingLeft: '0.2rem'
                                }}
                                onClick={() => toggleHistoryModalProtocol()}
                                id="tour4"
                              />
                            )}
                          </div>
                        </div>

                        {controlValidSelectClient &&
                          errorFormMessage('Campo obrigatório')}
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.telephone?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.telephone
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'telephone'}
                        className="react-grid-layout-box"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find((item) => item.i === 'telephone')) || {
                            x: 2,
                            y: 2,
                            w: 2,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Telefone',
                            protocolDefaultFieldsDisplaySettings.telephone
                              .isRequired
                          )}
                        </label>
                        <Controller
                          render={(props) => (
                            <TelephoneMask
                              valueController={setValue}
                              fieldName="telephone"
                              tabIndex={
                                sortedLayouts.findIndex(
                                  (item) => item.i === 'telephone'
                                ) + 1
                              }
                              value={props.value}
                              placeholder="(88)8.9999-9999"
                            />
                          )}
                          control={control}
                          name="telephone"
                          defaultValue={
                            (clientDefaultData &&
                              clientDefaultData.telephoneNumber) ||
                            (clientDefaultData &&
                              clientDefaultData?.client?.phone &&
                              getPhoneWithoutCountryCode(
                                clientDefaultData?.client?.phone
                              )) ||
                            (conversation && conversation?.cliente?.telefone) ||
                            ''
                          }
                          rules={{
                            validate: (value) => {
                              if (
                                protocolDefaultFieldsDisplaySettings?.telephone
                                  ?.isRequired &&
                                !value
                              ) {
                                return 'Campo obrigatório';
                              }

                              let phoneFormated = value.replace(/\D/g, '');
                              phoneFormated = phoneFormated.replace(/^0/, '');
                              if (
                                protocolDefaultFieldsDisplaySettings?.telephone
                                  ?.isRequired &&
                                phoneFormated.length === 0
                              ) {
                                return 'Campo obrigatório';
                              } else if (
                                phoneFormated.length < 10 &&
                                phoneFormated.length > 0
                              ) {
                                return 'Telefone inválido';
                              }

                              return true;
                            }
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="telephone"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.description
                    ?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.description
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'description'}
                        className="react-grid-layout-box tour5"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find(
                              (item) => item.i === 'description'
                            )) || {
                            x: 0,
                            y: 3,
                            w: 2,
                            h: 3,
                            minW: 2,
                            maxW: 4,
                            minH: 2,
                            maxH: 6
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Descrição',
                            protocolDefaultFieldsDisplaySettings.telephone
                              .isRequired
                          )}
                        </label>
                        <Controller
                          as={
                            <Input
                              tabIndex={
                                sortedLayouts.findIndex(
                                  (item) => item.i === 'description'
                                ) + 1
                              }
                              type="textarea"
                              style={{ height: '90%', resize: 'none' }}
                              rows={
                                protocolDefaultFieldsDisplaySettings?.account_id
                                  ?.isActive
                                  ? '10'
                                  : '6'
                              }
                            />
                          }
                          control={control}
                          name="description"
                          defaultValue=""
                          rules={validateFields(
                            protocolDefaultFieldsDisplaySettings.description
                              .isRequired
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="description"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.reason_id?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.reason_id
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'reason_id'}
                        className="react-grid-layout-box"
                        id="tour6"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find((item) => item.i === 'reason_id')) || {
                            x: 2,
                            y: 3,
                            w: 2,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Motivo',
                            protocolDefaultFieldsDisplaySettings.reason_id
                              .isRequired
                          )}
                        </label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={
                                reasons
                                  ? reasons.map(({ id, name }) => ({
                                      label: name,
                                      value: id
                                    }))
                                  : []
                              }
                              value={props.value}
                              valueController={setValue}
                              fieldName="reason_id"
                              labelName="label"
                              tabIndex={
                                sortedLayouts.findIndex(
                                  (item) => item.i === 'reason_id'
                                ) + 1
                              }
                              valueName="value"
                              isClearable={true}
                              closeMenuOnSelect={true}
                              portalTarget={true}
                              onChange={handleGetSubReasons}
                            />
                          )}
                          control={control}
                          name="reason_id"
                          rules={validateFields(
                            protocolDefaultFieldsDisplaySettings.reason_id
                              .isRequired
                          )}
                          defaultValue={[]}
                        />

                        <ErrorMessage
                          errors={errors}
                          name="reason_id"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.subreason_id
                    ?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.subreason_id
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'subreason_id'}
                        className="react-grid-layout-box"
                        id="tour7"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find(
                              (item) => item.i === 'subreason_id'
                            )) || {
                            x: 2,
                            y: 4,
                            w: 2,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Submotivo',
                            protocolDefaultFieldsDisplaySettings.subreason_id
                              .isRequired
                          )}
                        </label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={
                                subReasons
                                  ? subReasons.map(({ id, name }) => ({
                                      label: name,
                                      value: id
                                    }))
                                  : []
                              }
                              tabIndex={
                                sortedLayouts.findIndex(
                                  (item) => item.i === 'subreason_id'
                                ) + 1
                              }
                              value={props.value}
                              valueController={setValue}
                              fieldName="subreason_id"
                              labelName="label"
                              valueName="value"
                              isClearable={true}
                              closeMenuOnSelect={true}
                              portalTarget={true}
                            />
                          )}
                          disabled={loadingSubreasons}
                          control={control}
                          name="subreason_id"
                          defaultValue=""
                          rules={validateFields(
                            protocolDefaultFieldsDisplaySettings.subreason_id
                              .isRequired
                          )}
                        />

                        <ErrorMessage
                          errors={errors}
                          name="subreason_id"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.account_id?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.account_id
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'account_id'}
                        className="react-grid-layout-box"
                        style={{ zIndex: '1' }}
                        id="tour7"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find(
                              (item) => item.i === 'account_id'
                            )) || {
                            x: 2,
                            y: 5,
                            w: 2,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Conta',
                            protocolDefaultFieldsDisplaySettings.account_id
                              .isRequired
                          )}
                        </label>

                        <Select
                          styles={custom_select}
                          placeholder="Buscar conta"
                          required={
                            protocolDefaultFieldsDisplaySettings?.account_id
                              ?.isRequired
                          }
                          isClearable={true}
                          tabIndex={
                            sortedLayouts.findIndex(
                              (item) => item.i === 'account_id'
                            ) + 1
                          }
                          name="account_id"
                          loadOptions={async (inputValue) => {
                            const res = await filterAccounts(inputValue);
                            return res.map((item) => ({
                              ...item,
                              label: item.name,
                              value: item.id
                            }));
                          }}
                          isLoading={isSearchingAccounts}
                          loadingMessage={() => 'Buscando...'}
                          noOptionsMessage={({ inputValue }) =>
                            !inputValue
                              ? 'Digite para pesquisar'
                              : 'Nenhum resultado encontrado'
                          }
                          onChange={(data) => {
                            if (data) {
                              setSelectedAccount({
                                id: data.id,
                                name: data.name
                              });
                            } else {
                              setSelectedAccount(null);
                            }
                          }}
                          value={
                            selectedAccount
                              ? {
                                  label: selectedAccount.name,
                                  value: selectedAccount.id
                                }
                              : ''
                          }
                          defaultValue=""
                        />
                        {controlValidSelectAccount &&
                          errorFormMessage('Campo obrigatório')}
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.service_channel
                    ?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.service_channel
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'service_channel'}
                        className="react-grid-layout-box tour8"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find(
                              (item) => item.i === 'service_channel'
                            )) || {
                            x: 0,
                            y: 6,
                            w: 2,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Canal de atendimento',
                            protocolDefaultFieldsDisplaySettings.service_channel
                              .isRequired
                          )}
                        </label>

                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={[
                                { label: 'EMAIL', value: 'EMAIL' },
                                { label: 'FACEBOOK', value: 'FACEBOOK' },
                                { label: 'TELEGRAM', value: 'TELEGRAM' },
                                { label: 'TELEFONIA', value: 'TELEFONIA' },
                                { label: 'WHATSAPP', value: 'WHATSAPP' },
                                { label: 'CHAT', value: 'CHAT' },
                                { label: 'FLEX4U', value: 'FLEX4U' },
                                { label: 'INSTAGRAM', value: 'INSTAGRAM' },
                                { label: 'SMS', value: 'SMS' }
                              ]}
                              value={props.value}
                              valueController={setValue}
                              tabIndex={
                                sortedLayouts.findIndex(
                                  (item) => item.i === 'service_channel'
                                ) + 1
                              }
                              fieldName="service_channel"
                              labelName="label"
                              valueName="value"
                              isClearable={true}
                              closeMenuOnSelect={true}
                              portalTarget={true}
                            />
                          )}
                          control={control}
                          name="service_channel"
                          rules={validateFields(
                            protocolDefaultFieldsDisplaySettings
                              ?.service_channel.isRequired
                          )}
                          defaultValue={
                            clientDefaultData?.serviceChannel ||
                            (conversation &&
                              conversation?.canal?.toUpperCase()) ||
                            ''
                          }
                        />

                        <ErrorMessage
                          errors={errors}
                          name="service_channel"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.current_status
                    ?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.current_status
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'current_status'}
                        className="react-grid-layout-box tour8"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find(
                              (item) => item.i === 'current_status'
                            )) || {
                            x: 2,
                            y: 6,
                            w: 2,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Status/Fase'
                            // protocolDefaultFieldsDisplaySettings.current_status
                            //   .isRequired
                          )}
                        </label>

                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={
                                status
                                  ? status.map(({ id, name }) => ({
                                      label: name,
                                      value: id
                                    }))
                                  : []
                              }
                              value={props.value}
                              valueController={setValue}
                              tabIndex={
                                sortedLayouts.findIndex(
                                  (item) => item.i === 'current_status'
                                ) + 1
                              }
                              fieldName="current_status"
                              labelName="label"
                              valueName="value"
                              isClearable={true}
                              closeMenuOnSelect={true}
                              portalTarget={true}
                            />
                          )}
                          control={control}
                          name="current_status"
                          rules={
                            validateFields()
                            // protocolDefaultFieldsDisplaySettings.current_status
                            //   .isRequired
                          }
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="current_status"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.current_responsible
                    ?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.current_responsible
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'current_responsible'}
                        className="react-grid-layout-box"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find(
                              (item) => item.i === 'current_responsible'
                            )) || {
                            x: 0,
                            y: 6,
                            w: 2,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Responsável Atual',
                            protocolDefaultFieldsDisplaySettings
                              .current_responsible.isRequired
                          )}
                        </label>

                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={
                                userList
                                  ? userList.map(({ login, name }) => ({
                                      label: name,
                                      value: login
                                    }))
                                  : []
                              }
                              value={props.value}
                              valueController={setValue}
                              tabIndex={
                                sortedLayouts.findIndex(
                                  (item) => item.i === 'current_responsible'
                                ) + 1
                              }
                              fieldName="current_responsible"
                              labelName="label"
                              valueName="value"
                              isClearable={true}
                              closeMenuOnSelect={true}
                              portalTarget={true}
                            />
                          )}
                          control={control}
                          name="current_responsible"
                          rules={validateFields(
                            protocolDefaultFieldsDisplaySettings
                              ?.current_responsible.isRequired
                          )}
                          defaultValue={loggedInUser}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="current_responsible"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </div>
                    )}
                  {protocolDefaultFieldsDisplaySettings?.email?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.email
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'email'}
                        className="react-grid-layout-box"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find((item) => item.i === 'email')) || {
                            x: 2,
                            y: 7,
                            w: 2,
                            h: 1,
                            minW: 2,
                            maxW: 4,
                            minH: 1,
                            maxH: 1,
                            static: true
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Email',
                            protocolDefaultFieldsDisplaySettings.email
                              .isRequired
                          )}
                        </label>
                        <Controller
                          as={Input}
                          type="email"
                          tabIndex={
                            sortedLayouts.findIndex(
                              (item) => item.i === 'email'
                            ) + 1
                          }
                          control={control}
                          name="email"
                          rules={{
                            validate: (value) => {
                              const isRequired =
                                protocolDefaultFieldsDisplaySettings.email
                                  .isRequired;
                              if (isRequired && !value)
                                return 'Campo obrigatório';
                              if (!isRequired && !value) return true;

                              const emailRegex =
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

                              return (
                                emailRegex.test(value) ||
                                'Email com formato incorreto'
                              );
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="email"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </div>
                    )}

                  {protocolDefaultFieldsDisplaySettings?.files?.isActive &&
                    !protocolDefaultFieldsDisplaySettings?.files
                      ?.isOnlyToShowOnEdit && (
                      <div
                        key={'files'}
                        className="react-grid-layout-box"
                        data-grid={
                          (layouts &&
                            layouts.length > 0 &&
                            layouts.find((item) => item.i === 'files')) || {
                            x: 2,
                            y: 7,
                            w: 4,
                            h: 2,
                            minW: 4,
                            maxW: 4,
                            minH: 4,
                            maxH: 4,
                            static: false
                          }
                        }
                      >
                        <label className="form-control-label">
                          {definedShowName(
                            'Arquivos',
                            protocolDefaultFieldsDisplaySettings?.files
                              ?.isRequired || false
                          )}
                        </label>
                        <Controller
                          name="files"
                          as={
                            <>
                              <FileProtocolArea htmlFor="files">
                                <label
                                  style={{
                                    margin: '0',
                                    padding: '0'
                                  }}
                                >
                                  <UploadButtonProtocol>
                                    {loadingFile ? (
                                      <>Carregando...</>
                                    ) : (
                                      <>
                                        <span>Enviar arquivo</span>
                                        <FiUpload />
                                      </>
                                    )}
                                  </UploadButtonProtocol>

                                  <Input
                                    id="files"
                                    style={{ display: 'none' }}
                                    type="file"
                                    disabled={loadingFile}
                                    onChange={handlerSelectFiles}
                                    multiple={true}
                                    accept="application/pdf, image/*,.txt"
                                  />
                                </label>
                              </FileProtocolArea>
                            </>
                          }
                          control={control}
                          rules={{
                            validate: (value) => {
                              const isRequired =
                                protocolDefaultFieldsDisplaySettings?.files
                                  ?.isRequired;
                              if (isRequired && !value)
                                return 'Campo obrigatório';
                              if (!isRequired && !value) return true;
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="files"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </div>
                    )}

                  {customFields && renderCustomFields()}
                </ResponsiveGridLayout>
              )}
              {!inAttendanceScreen && <hr />}
              {protocolDefaultFieldsDisplaySettings?.files?.isActive &&
                !loadingFile &&
                attachments && (
                  <>
                    <label className="form-control-label">
                      Arquivos anexados
                    </label>
                    {errorInUpload && successCreateProtocolo.success && (
                      <Alert color="secondary">
                        Protocolo criado com sucesso, mas houve erro no envio
                        dos anexos. Por favor, tente novamente.
                      </Alert>
                    )}
                    {messageError && (
                      <Alert color="secondary">
                        {errorFormMessage(messageError)}
                      </Alert>
                    )}

                    <AreaPreviewProtocol>
                      <ListFileAttachments
                        files={fileAttachmentsPreview}
                        setLoadingFile={setLoadingFile}
                        loadingFiles={loadingFile}
                        setValue={setValue}
                        setMessageError={setMessageError}
                      />
                    </AreaPreviewProtocol>
                  </>
                )}
              {propsMemo && (
                <Row>
                  <Col md="6">
                    {!inAttendanceScreen && (
                      <>
                        <ReactButton
                          btnColor="confirmation"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <Loader
                              type="TailSpin"
                              color="#fff"
                              height={20}
                              width={20}
                            />
                          ) : errorInUpload &&
                            successCreateProtocolo.success &&
                            fileAttachmentsPreview?.attachmentsName.length ? (
                            'Reenviar Anexos'
                          ) : successCreateProtocolo.success &&
                            fileAttachmentsPreview?.attachmentsName.length ==
                              0 ? (
                            'Atualizar Protocolo'
                          ) : (
                            'Salvar'
                          )}
                        </ReactButton>
                        <ReactButton
                          btnColor="cancelation"
                          onClick={() => {
                            if (
                              fileAttachmentsPreview.attachments.length > 0 &&
                              successCreateProtocolo.success &&
                              errorInUpload
                            ) {
                              const result = window.confirm(
                                'Se você cancelar, os arquivos anexados serão perdidos. Deseja continuar?'
                              );

                              if (!result) return;
                            }

                            closeModal();
                            changeFieldsReference({});
                            fileAttachmentsPreview.reset();
                          }}
                        >
                          Cancelar
                        </ReactButton>
                      </>
                    )}
                  </Col>
                  <Col md="6">
                    <Button
                      className="float-right "
                      type="button"
                      outline
                      color="info"
                      onClick={openTour}
                      title="Saiba mais sobre o protocolo"
                    >
                      Saiba Mais
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </>
      )}

      <ModalFilterClients
        className="modal-lg"
        show={isClientSearchModalOpen}
        onModalToggle={setIsClientSearchModalOpen}
        setSelectedClient={alterDefaultModalData}
      />

      <ModalSchedulingProtocol
        className="modal-lg"
        show={isSchedulingModalOpen}
        onModalToggle={setIsSchedulingModalOpen}
        setSelectedSchedule={setSelectedSchedule}
        isToCreate={false}
      />

      {clientDefaultData.client?.nome && (
        <ClientProtocolModal
          isModalOpen={isHistoryModalProtocolOpen}
          toggleModal={toggleHistoryModalProtocol}
          clientInfo={nameClient}
          clientId={clientDefaultData?.client?.id}
          recordTypes={1}
          isProtocolScreen={true}
        />
      )}

      <Tour steps={steps} isOpen={tourOpen} onRequestClose={closeTour} />
    </>
  );
};

export default ProtocolForm;
