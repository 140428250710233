import { calculateOffsetFromPageAndLimit } from 'views/CRM/shared/utils/pagination';
import { apiCrm } from '../../../../http';

class LeadService {
  getLeads(recordTypeId, params = {}, headers = { page: 1, limit: 10 }) {
    const offset = calculateOffsetFromPageAndLimit(headers.page, headers.limit);
    return apiCrm.get(`lead/${recordTypeId}`, {
      params,
      headers: { ...headers, offset }
    });
  }
  1;

  getReportLeads(recordTypeId, params = {}, headers = { page: 1, limit: 10 }) {
    const offset = calculateOffsetFromPageAndLimit(headers.page, headers.limit);
    return apiCrm.get(`lead-report/${recordTypeId}`, {
      params,
      headers: { ...headers, offset }
    });
  }

  getAllLeads(recordTypeId, params = {}) {
    const headers = {
      limit: '15000'
    };
    return apiCrm.get(`lead/${recordTypeId}`, {
      params,
      headers
    });
  }

  getById(recordTypeId, id) {
    return apiCrm.get(`lead/${recordTypeId}/${id}`);
  }

  createLead(recordTypeId, data) {
    return apiCrm.post(`lead/${recordTypeId}`, data);
  }

  getStages() {
    return apiCrm.get('lead-stage');
  }

  updateStage(leadId, data) {
    return apiCrm.put(`lead/change-stage/${leadId}`, data);
  }

  updateLead(recordTypeId, leadId, data) {
    return apiCrm.put(`lead/${recordTypeId}/${leadId}`, data);
  }

  delete(leadId) {
    return apiCrm.delete(`lead/${leadId}`);
  }

  importLeads(recordTypeId, data) {
    return apiCrm.post(`lead/${recordTypeId}/import`, data);
  }

  convertLead(leadId, data) {
    return apiCrm.post(`lead/${leadId}/convert`, data);
  }

  changeResponsible(leadId, responsibleLogin) {
    return apiCrm.put(`lead/change-responsible/${leadId}`, {
      responsibleLogin
    });
  }

  changeSeveralLeadsResponsible(leadIds, responsibleLogin) {
    const data = { leadIds: leadIds, responsibleLogin };
    return apiCrm.put(`lead/change-responsible-several-leads`, {
      data
    });
  }

  getLeadComments(leadId) {
    return apiCrm.get(`lead-comment/${leadId}`);
  }

  createLeadComments(leadId, data) {
    const content = data.content;
    return apiCrm.post(`lead-comment/${leadId}`, { content });
  }

  updateLeadComments(commentId, data) {
    const content = data.content;
    return apiCrm.put(`lead-comment/${commentId}`, { content });
  }

  deleteLeadComments(commentId) {
    return apiCrm.delete(`lead-comment/${commentId}`);
  }

  getLeadHistory(leadId) {
    return apiCrm.get(`lead-history/${leadId}`);
  }
  async removeAttachment(leadId, attachmentId) {
    return apiCrm.delete(`/lead-attachment/${leadId}/${attachmentId}`);
  }

  async addAttachments(leadId, formData) {
    return apiCrm.post(`/lead-attachment/${leadId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async getExcel(params) {
    return apiCrm.get('/report/lead/export/xlsx', {
      params,
      responseType: 'arraybuffer'
    });
  }

  async getCsv(params) {
    return apiCrm.get('/report/lead/export/csv', { params });
  }

  async getPdf(params) {
    return apiCrm.get('/report/lead/export/pdf', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType1Pdf(params) {
    return apiCrm.get('/report/lead/export1/pdf', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType1Xlsx(params) {
    return apiCrm.get('/report/lead/export1/xlsx', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType1Csv(params) {
    return apiCrm.get('/report/lead/export1/csv', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType2Pdf(params) {
    return apiCrm.get('/report/lead/export2/pdf', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType2Xlsx(params) {
    return apiCrm.get('/report/lead/export2/xlsx', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType2Csv(params) {
    return apiCrm.get('/report/lead/export2/csv', {
      params,
      responseType: 'blob'
    });
  }
}

export default new LeadService();
