export const templateParamsRegex = new RegExp('{{\\d+}}', 'gm'); // /{{\d}}/gm

export const fillTypes = Object.freeze({
  FREE_TEXT: 'free_text',
  CLIENT_FIELD: 'client_field'
});

// Padrão de tipo de template
export const broadcastTypeOfClosingConversationOptions = [
  {
    label: 'Enviar sem abrir conversa',
    value: 2
  },
  {
    label: 'Transferir para fila',
    value: 1
  }
];

export const broadcastTypeOfSmsModelOptions = [
  {
    label: 'Padrão',
    value: 1
  },
  {
    label: 'RCS',
    value: 2
  }
];
