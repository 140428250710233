export const styles = {
  container: {
    padding: '0.5rem',
    fontSize: '11px',
    margin: '0.5rem 0.5rem',
    color: '#FFF',
    width: '11rem',
    lineHeight: '14px'
  },
  itemRow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  transfer: {
    paddingTop: '0.5rem',
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid'
  }
};
