import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FiChevronDown, FiChevronRight, FiFilter } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Button, Form, Col } from 'reactstrap';
import styled from 'styled-components';

import CustomFieldsFilterForm from '../../../../../../shared/components/CustomFieldsFilterForm';
import DefaultFieldsFilterForm from './DefaultFieldsFilterForm';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { customFieldTypes } from 'views/fieldConfigs/utils/customFieldTypes';
import { parseIntFromString } from 'utils/fuctions/parseIntFromString';
import { useMemorizeFilters } from '../../../../../../../../hooks/useMemorizeInputsFilters';
import { AiOutlineClear } from 'react-icons/ai';
import { resetToFirstDayOfMonth } from '../../../../../../shared/helpers/getFirstDayOfTheMonth';
import { resetFields } from '../../../../../../shared/helpers/resetInputsFIeldsValues';
import xlsxImage from '../../../../../../../../assets/img/xlsx.png';
import csvImage from '../../../../../../../../assets/img/csv.png';
import ContactService from '../../../../../services/contactService';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { Tooltip } from '@material-ui/core';
import {
  ImageButton,
  ImageSize
} from '../../../../../../../../assets/styles/image_style';
import Loader from 'react-loader-spinner';

const CustomFieldsFilterToggle = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
  user-select: none;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const FilterContactsForm = ({
  handleSearch,
  customFields,
  isLoadingContacts,
  selectedRecordTypeId,
  setSelectedRecordType,
  recordTypes,
  recordTypeId,
  contacts,
  totalContacts
}) => {
  const [showCustomFieldsFilters, setShowCustomFieldsFilters] = useState(false);
  const [analyticalData, setAnalyticalData] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingXLSX, setLoadingXLSX] = useState(false);
  const [showDateForm, setshowDateForm] = useState(false);

  const { setValue, control, getValues, handleSubmit, register, reset } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { memorizeFilters, getMemorizedFilters, clearMemorizedFilters } =
    useMemorizeFilters('CRM - Contact');

  useEffect(() => {
    const filters = getMemorizedFilters();
    if (filters) {
      reset(filters);
    } else if (customFields) {
      customFields.forEach((field) => {
        if (field) {
          setValue(field.name, '');
        }
      });
    }
    onSubmit('');
  }, [
    customFields,
    getMemorizedFilters,
    reset,
    resetConfig,
    setValue,
    showCustomFieldsFilters
  ]);

  useEffect(() => {
    if (totalContacts) {
      setAnalyticalData(totalContacts);
    }
  }, [contacts]);

  const toISOFormat = useCallback((dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${DD}/${MM}/${YYYY}`;
  }, []);
  const saveFiltersWithRecordType = (filters) => {
    const filtersWithRecordType = {
      ...filters,
      recordTypeId: selectedRecordTypeId
    };
    memorizeFilters(filtersWithRecordType);
  };

  const onSubmit = async (data) => {
    if (showDateForm === false) {
      delete data.dateBegin;
      delete data.dateEnd;
      delete data.filterDateBy;
    }
    if (customFields && showCustomFieldsFilters) {
      customFields.forEach((field) => {
        if (field.type === customFieldTypes.LIST) {
          const possibleValues = field.options.map((value) => value.value);
          if (!possibleValues.includes(data[field.name])) {
            data[field.name] = '';
          }
        }
      });
    }
    saveFiltersWithRecordType(data);
    const currencyFieldsName =
      customFields &&
      customFields
        .filter((field) => field.type === customFieldTypes.CURRENCY)
        .map((field) => field.name);

    let formattedData = { ...data };

    Object.keys(data).forEach((key) => {
      if (currencyFieldsName.includes(key)) {
        if (data[key]) {
          formattedData[key] = parseIntFromString(data[key]);
        }
      }
    });

    if (data?.tags) {
      formattedData = {
        ...formattedData,
        tags: data?.tags?.map((tag) => tag.value)
      };
    }

    await handleSearch(formattedData);
  };
  const resetConfig = useMemo(
    () => ({
      dateBegin: resetToFirstDayOfMonth,
      dateEnd: () => new Date().toLocaleDateString('pt-br').toString(),
      filterDateBy: () => 'creation',
      createdByLogin: () => '',
      textQuery: () => '',
      tags: () => []
    }),
    []
  );

  const handleReset = () => {
    if (selectedRecordTypeId && recordTypes && recordTypes.length) {
      setSelectedRecordType(recordTypes[0]);
    }
    resetFields(resetConfig, customFields, control);
    clearMemorizedFilters();
  };

  const handleReportExport = (typeExport, e) => {
    e.preventDefault();

    const params = getValues();

    const dto = {
      ...params,
      dateStart: toISOFormat(params.dateBegin),
      dateEnd: toISOFormat(params.dateEnd),
      recordTypeId
    };

    if (showDateForm === false) {
      delete dto.dateBegin;
      delete dto.dateStart;
      delete dto.dateEnd;
      delete dto.filterDateBy;
    }

    if (typeExport == 'XLSX') {
      setLoadingXLSX(true);

      ContactService.getExcel(dto)
        .then((res) => {
          saveAs(
            new Blob([res.data], {
              type: 'application/octet-stream'
            }),
            `relatorio-contatos.xlsx`
          );

          setLoadingXLSX(false);
        })
        .catch(() => {
          setLoadingXLSX(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    } else if (typeExport == 'CSV') {
      setLoadingCSV(true);

      ContactService.getCsv(dto)
        .then((res) => {
          if (res.data.size === 0) {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          } else {
            saveAs(
              new Blob([res.data], { type: 'application/octet-stream' }),
              `realatorio-contatos.csv`
            );

            setLoadingCSV(false);
          }
        })
        .catch(() => {
          setLoadingCSV(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          position: 'absolute',
          right: '1rem',
          top: '2rem'
        }}
      >
        <span
          onClick={() => {
            handleReset();
          }}
          className="small"
          style={{ cursor: 'pointer' }}
        >
          <AiOutlineClear /> Limpar filtros
        </span>
      </div>
      <DefaultFieldsFilterForm
        handleSearch={handleSearch}
        setValue={setValue}
        control={control}
        getValues={getValues}
        register={register}
        showDateForm={showDateForm}
        setshowDateForm={setshowDateForm}
      />

      {customFields && customFields.length > 0 && (
        <>
          <CustomFieldsFilterToggle
            className="small"
            onClick={() => setShowCustomFieldsFilters(!showCustomFieldsFilters)}
          >
            Filtrar por campos customizados{' '}
            {showCustomFieldsFilters ? (
              <FiChevronDown size={18} />
            ) : (
              <FiChevronRight size={18} />
            )}
          </CustomFieldsFilterToggle>

          {showCustomFieldsFilters && (
            <CustomFieldsFilterForm
              toISOFormat={toISOFormat}
              getValues={getValues}
              customFields={customFields}
              setValue={setValue}
              control={control}
            />
          )}
        </>
      )}

      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Button
            style={buttonStyle(systemConfiguration.primary_color)}
            type="submit"
            size="md"
            disabled={isLoadingContacts}
          >
            <FiFilter />
            <span className="ml-1">Filtrar Contatos</span>
          </Button>
          <Col
            md="6"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <button
              id="button_csv"
              style={ImageButton}
              onClick={(e) => {
                handleReportExport('CSV', e);
              }}
              disabled={analyticalData && !loadingCSV ? false : true}
            >
              <Tooltip
                title={
                  <p style={{ fontSize: '14px' }}>
                    {analyticalData
                      ? `Fazer download do relatório de Contatos em CSV.`
                      : 'Nenhum registro para realizar download'}
                  </p>
                }
                arrow
              >
                {loadingCSV ? (
                  <Loader
                    type="ThreeDots"
                    visible={true}
                    height="40"
                    width="40"
                  />
                ) : (
                  <img src={csvImage} alt="csv" style={ImageSize} />
                )}
              </Tooltip>
            </button>
            <button
              id="button_xlsx"
              style={ImageButton}
              onClick={(e) => {
                handleReportExport('XLSX', e);
              }}
              disabled={analyticalData && !loadingXLSX ? false : true}
            >
              <Tooltip
                title={
                  <p style={{ fontSize: '14px' }}>
                    {analyticalData
                      ? `Fazer download do relatório de Contatos em XLSX.`
                      : 'Nenhum registro para realizar download'}
                  </p>
                }
                arrow
              >
                {loadingXLSX ? (
                  <Loader
                    type="ThreeDots"
                    visible={true}
                    height="40"
                    width="40"
                  />
                ) : (
                  <img src={xlsxImage} alt="xlsx" style={ImageSize} />
                )}
              </Tooltip>
            </button>
          </Col>
        </div>
        <div>
          <div style={{ fontSize: 'small', float: 'right' }}>
            {totalContacts} resultado(s) encontrado(s)
          </div>
        </div>
      </div>
    </Form>
  );
};

export default FilterContactsForm;
